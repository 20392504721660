import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Form from "components/Form";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InspectionDymoPrinter from "../../../components/DymoPrinter/InspectionDymoSticker";
import BundleDymoPrinter from "../../../components/DymoPrinter/BundleDymoSticker";

const PrintStickerPopup = ({
  formik,
  handleClose,
  saveButtonText,
  variant,
  popupSize,
  open,
  copyCount = 0,
  source,
  type,
  rowXmlMapping,
  auditGrid,
  bundleStickerData
}) => {
  const commonState = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const [count, setCount] = useState(copyCount);
  const [printStartPopup, openPrintStartPopup] = useState(false);

  const title =
    source == "LOGIN"
      ? "Print all bundle stickers"
      : `Print sticker for this ${source}`;

  formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      console.log("number of copies", count);
      openPrintStartPopup(true);
    },
  });
  return (
    <Form
      title={title}
      formik={formik}
      handleClose={handleClose}
      open={open}
      saveButtonText={saveButtonText}
      variant={variant}
      popupSize={popupSize}
      type={type}
    >
      <Grid container spacing={4}>
        {source == "LOGIN" ? (
          <Grid item xs={12} sm={12} md={12}>
            <InputLabel sx={{ display: "inline-flex", marginRight: 1 }}>
              Number of copies:
            </InputLabel>
            <TextField
              sx={{ width: 60 }}
              size="small"
              name="numberOfCopies"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
          </Grid>
        ) : null}
      </Grid>
      {commonState.isLoading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
      ) : null}

      {printStartPopup && auditGrid && (
        <InspectionDymoPrinter rowXmlMapping={rowXmlMapping} count={count} />
      )}

      {printStartPopup && !auditGrid && <BundleDymoPrinter rowXmlMapping={bundleStickerData} />}
    </Form>
  );
};

export default PrintStickerPopup;
