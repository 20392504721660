import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Tree from "components/Tree";
import { actions } from "./state/actions";
import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../state/actions";
import CircularProgress from "@mui/material/CircularProgress";
import { TreeResponse } from "../DictionaryDetails/CreateOfflineDummyResponse";
import { actions as arttributeAction } from "../CreateOfflineDictionary/state/actions";
import { AssistWalkerTwoTone } from "@mui/icons-material";

// const loginUserId = "a-aprabhat";
// const userId = 3680;
const initialState = {
  startingLevel: 0,
  noLevel: 1,
  userId: "",
  userLogin: "",
  dictionaryFilter: "",
  productFamilyFilter: "",
  familyFilter: "",
  categoryFilter: "",
  defectFilter: "",
  productFamilyAttributeId: "",
  showOnlyPublished: 0,
  showHidden: 0,
  prdNcTypeOnly: 0,
  languageCode: "",
  dictionaryMode: 0
};
const initialParentInfo = {
  level: 0,
  id: 0,
  code: "",
  label: "",
  imageUrl: "",
  parentId: 0,
  familyName: "",
  categoryName: "",
  thumbUrl: "",
  isPublish: 0,
  hide: 0,
  productType: "",
  componentId: "",
  codeForSorting: "",
  ncVersion: "",
  dictionaryType: "",
  children: []
};

const powerBiParams = {

};
const userLoginId = "";
export default function DictionaryTree({ addCheckbox, language, searchText, dictionaryMode = 2, treeFunc, filterParams, dictionaryType = "ALL" }) {

  const state = useSelector((state) => state.dictionaryTree);
  const stateAttribute = useSelector((state) => state.userDictionaryAttribute);
  const stateRoot = useSelector((state) => state.dictionaryRoot);
  const [dictionaryParam, setdictionaryParam] = useState(initialState);
  const [languageCode, setLanguageCode] = useState(language?.code);
  const [dicMode, setdictionaryMode] = useState(dictionaryMode);
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.customization);
  const [expandedNodes, setExpandedNodes] = useState(state?.expandedNodeIds);
  const [offlineDictionaryParams, setofflineDictionaryParams] = useState(state?.offlineDictionaryParams);
  const [isLoadFirstLevel, setLoadFirstLevel] = useState(false);
  const [treeList, setTreeList] = useState(state?.dictionaryTreeList);
  const [userLogin, setUserLogin] = useState(userLoginId);
  const [AttributesIds, setAttributesIds] = useState(null);
  const userRoleInfo = JSON.parse(sessionStorage.getItem("userRoleInfo"));
  let startingLevel = 0;
  let dictionaryFilter = "";
  let productFamilyFilter = "";
  let familyFilter = "";
  let categoryFilter = "";
  let defectFilter = "";

  useEffect(() => {
    treeFunc.current = handleCallbackNodeSelection;
    commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] ?
      setUserLogin('PMI\\' + commonState?.userInfo?.username?.split("@")[0]?.split("_")[1]) :
      setUserLogin(userRoleInfo?.login);
  }, []);
  useEffect(() => {
    setExpandedNodes(state?.expandedNodeIds);
  }, [state?.expandedNodeIds]);


  useEffect(() => {
    if (searchText) {
      const param = {
        userLogin: userLogin,
        languageCode: language?.code,
        searchTerm: searchText
      };
      dispatch(actions.searchTreeNodeByCode(param));
    }

  }, [searchText]);

  const getParentInfo = (searchItems) => {
    const levelInfo = {
      level: searchItems[0],
      id: searchItems[2],
      code: "",
      nodeId: 0,
      label: "",
      imageUrl: "",
      parentId: 0,
      familyName: "",
      categoryName: "",
      thumbUrl: "",
      isPublish: 0,
      hide: 0,
      productType: "",
      componentId: "",
      codeForSorting: "",
      ncVersion: "",
      dictionaryType: searchItems[1]
    };
    return levelInfo;
  }

  useEffect(() => {
    if (state?.searchTreeNodeIds.length > 0) {

      const nodeArray = state?.searchTreeNodeIds;
      let paramsArray = [];


      nodeArray.forEach(element => {
        let count = 0;
        let dicArray = element.dictionaryId.indexOf('_') > 0 ? element.dictionaryId.split('_') : [];
        let dictionaryId = dicArray.length > 0 ? dicArray[dicArray.length - 1] : 0;
        if (dictionaryId != 0) {
          count = count + 1;
          const startLevelParameter = {
            startingLevel: 1,
            noLevel: 1,
            userId: commonState?.userRoleInfo?.id,
            userLogin: userLogin,
            dictionaryFilter: dictionaryId,
            productFamilyFilter: "",
            familyFilter: "",
            categoryFilter: "",
            defectFilter: "",
            productFamilyAttributeId: "",
            showOnlyPublished: 0,
            showHidden: 0,
            prdNcTypeOnly: 0,
            languageCode: language?.code,
            dictionaryMode: dictionaryMode,
            attributeType: filterParams?.attributeType,
            productionCenter: filterParams?.productionCenter,
            auditSubType: filterParams?.auditSubType,
            sampleId: filterParams?.sampleId
          };
          paramsArray.push({ parameter: startLevelParameter, parentInfo: getParentInfo(dicArray) });
        }

        let prodArray = element.productFamilyId.indexOf('_') > 0 ? element.productFamilyId.split('_') : [];
        let ProductId = dicArray.length > 0 ? prodArray[prodArray.length - 1] : 0;
        if (ProductId != 0) {
          count = count + 1;
          const startLevelParameter = {
            startingLevel: 2,
            noLevel: 1,
            userId: commonState?.userRoleInfo?.id,
            userLogin: userLogin,
            dictionaryFilter: dictionaryId,
            productFamilyFilter: ProductId,
            familyFilter: "",
            categoryFilter: "",
            defectFilter: "",
            productFamilyAttributeId: "",
            showOnlyPublished: 0,
            showHidden: 0,
            prdNcTypeOnly: 0,
            languageCode: language?.code,
            dictionaryMode: dictionaryMode,
            attributeType: filterParams?.attributeType,
            productionCenter: filterParams?.productionCenter,
            auditSubType: filterParams?.auditSubType,
            sampleId: filterParams?.sampleId
          };
          paramsArray.push({ parameter: startLevelParameter, parentInfo: getParentInfo(prodArray) });
        }

        let famArray = element.familyId.indexOf('_') > 0 ? element.familyId.split('_') : [];
        let familyId = famArray.length > 0 ? famArray[famArray.length - 1] : 0;
        if (familyId != 0) {
          count = count + 1;
          const startLevelParameter = {
            startingLevel: 3,
            noLevel: 1,
            userId: commonState?.userRoleInfo?.id,
            userLogin: userLogin,
            dictionaryFilter: dictionaryId,
            productFamilyFilter: ProductId,
            familyFilter: familyId,
            categoryFilter: "",
            defectFilter: "",
            productFamilyAttributeId: "",
            showOnlyPublished: 0,
            showHidden: 0,
            prdNcTypeOnly: 0,
            languageCode: language?.code,
            dictionaryMode: dictionaryMode,
            attributeType: filterParams?.attributeType,
            productionCenter: filterParams?.productionCenter,
            auditSubType: filterParams?.auditSubType,
            sampleId: filterParams?.sampleId
          };
          paramsArray.push({ parameter: startLevelParameter, parentInfo: getParentInfo(famArray) });
        }

        let catArray = element.categoryId.indexOf('_') > 0 ? element.categoryId.split('_') : [];
        let categoryId = catArray.length > 0 ? catArray[catArray.length - 1] : 0;
        if (categoryId != 0) {
          count = count + 1;
          const startLevelParameter = {
            startingLevel: 4,
            noLevel: 1,
            userId: commonState?.userRoleInfo?.id,
            userLogin: userLogin,
            dictionaryFilter: dictionaryId,
            productFamilyFilter: ProductId,
            familyFilter: familyId,
            categoryFilter: categoryId,
            defectFilter: "",
            productFamilyAttributeId: "",
            showOnlyPublished: 0,
            showHidden: 0,
            prdNcTypeOnly: 0,
            languageCode: language?.code,
            dictionaryMode: dictionaryMode,
            attributeType: filterParams?.attributeType,
            productionCenter: filterParams?.productionCenter,
            auditSubType: filterParams?.auditSubType,
            sampleId: filterParams?.sampleId
          };
          paramsArray.push({ parameter: startLevelParameter, parentInfo: getParentInfo(catArray) });
        }

        let defectArray = element.ncId.indexOf('_') > 0 ? element.ncId.split('_') : [];
        let defectId = defectArray.length > 0 ? defectArray[defectArray.length - 1] : 0;
        if (defectId != 0) {
          count = count + 1;
          const startLevelParameter = {
            startingLevel: 4,
            noLevel: 1,
            userId: commonState?.userRoleInfo?.id,
            userLogin: userLogin,
            dictionaryFilter: dictionaryId,
            productFamilyFilter: ProductId,
            familyFilter: familyId,
            categoryFilter: categoryId,
            defectFilter: defectId,
            productFamilyAttributeId: "",
            showOnlyPublished: 0,
            showHidden: 0,
            prdNcTypeOnly: 0,
            languageCode: language?.code,
            dictionaryMode: dictionaryMode,
            attributeType: filterParams?.attributeType,
            productionCenter: filterParams?.productionCenter,
            auditSubType: filterParams?.auditSubType,
            sampleId: filterParams?.sampleId
          };
          paramsArray.push({ parameter: startLevelParameter, parentInfo: getParentInfo(defectArray) });
        }
      });
      dispatch(actions.setTreeWithSearchData(paramsArray));
      dispatch(actions.setTreeSearchResultByCode([]));
    }

  }, [state?.searchTreeNodeIds]);

  useEffect(() => {
    if (addCheckbox) {
      dispatch(actions.resetDictionaryTreeList());
      const parames = {
        userLogin: userLogin,
        languageCode: language?.code
      };
      dispatch(actions.getAllDictionary(parames));
    }
  }, [addCheckbox]);



  const handleCallbackNodeSelection = (nodeItem, type = "fistTime") => {
    if (nodeItem) {
      //if (nodeItem?.children.length > 0) {
      if (expandedNodes.includes(nodeItem.nodeId) && type != "pageLoad") {
        expandedNodes.splice(expandedNodes.indexOf(nodeItem.nodeId), 1);
        return;
      }

      dispatch(actions.setDictionaryTreeParentInfo(nodeItem));
      if (!expandedNodes.includes(nodeItem.nodeId))
        setExpandedNodes(expandedNodes => [...expandedNodes, nodeItem.nodeId]);

      // if (!addCheckbox)
      {
        startingLevel = parseInt(nodeItem.level) + 1;
        dictionaryFilter = "";
        productFamilyFilter = "";
        familyFilter = "";
        categoryFilter = "";
        defectFilter = "";

        dictionaryParam.userLogin = state.dictionaryTreeRequest.userLogin;
        dictionaryParam.startingLevel = startingLevel === 5 ? 4 : startingLevel;
        // dictionaryParam.dictionaryFilter = startingLevel < 1 ? "" : startingLevel == 1 ? nodeItem.id : null;// state.dictionaryTreeRequest.dictionaryFilter;
        // dictionaryParam.productFamilyFilter = startingLevel < 2 ? "" : startingLevel == 2 ? nodeItem.id : null;//state.dictionaryTreeRequest.productFamilyFilter;
        // dictionaryParam.familyFilter = startingLevel < 3 ? "" : startingLevel == 3 ? nodeItem.id : null;//state.dictionaryTreeRequest.familyFilter;
        // dictionaryParam.categoryFilter = startingLevel < 4 ? "" : startingLevel == 4 ? nodeItem.id : null;//state.dictionaryTreeRequest.categoryFilter;
        // dictionaryParam.defectFilter = startingLevel < 5 ? "" : startingLevel == 5 ? nodeItem.id : null;//state.dictionaryTreeRequest.defectFilter;
        dictionaryParam.dictionaryFilter = startingLevel < 1 ? "" : startingLevel == 1 ? nodeItem.id : state.dictionaryTreeRequest.dictionaryFilter;
        dictionaryParam.productFamilyFilter = startingLevel < 2 ? "" : startingLevel == 2 ? nodeItem.id : state.dictionaryTreeRequest.productFamilyFilter;
        dictionaryParam.familyFilter = startingLevel < 3 ? "" : startingLevel == 3 ? nodeItem.id : state.dictionaryTreeRequest.familyFilter;
        dictionaryParam.categoryFilter = startingLevel < 4 ? "" : startingLevel == 4 ? nodeItem.id : state.dictionaryTreeRequest.categoryFilter;
        dictionaryParam.defectFilter = startingLevel < 5 ? "" : startingLevel == 5 ? nodeItem.id : state.dictionaryTreeRequest.defectFilter;
        dictionaryParam.attributeType = filterParams?.attributeType ? filterParams?.attributeType : "";
        dictionaryParam.productionCenter = filterParams?.productionCenter ? filterParams?.productionCenter : "";
        dictionaryParam.auditSubType = filterParams?.auditSubType ? filterParams?.auditSubType : "";
        dictionaryParam.sampleId = filterParams?.sampleId ? filterParams?.sampleId : "";

        if (dictionaryParam.languageCode == "")
          dictionaryParam.languageCode = languageCode;
        else if (dictionaryParam.dictionaryMode == 0)
          dictionaryParam.dictionaryMode = dicMode;

        if (dictionaryParam.userLogin || dictionaryParam.userLogin == "")
          dictionaryParam.userLogin = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] ?
            'PMI\\' + commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] :
            userRoleInfo?.login;
        dictionaryParam.userId = commonState?.userRoleInfo?.id;

        // console.log("Dictionary Request Params state", state.dictionaryTreeRequest);
        // console.log("Dictionary Request Params node item", nodeItem);
        // console.log("Dictionary Request Params", dictionaryParam);
        if (dictionaryParam.startingLevel <= 4 && dictionaryParam.defectFilter == "")
          dispatch(actions.getDictionaryTreeList(dictionaryParam));
        // }
      }
    }
  };
  useEffect(() => {
    dispatch(rootAction.setTreeLoad(true));
    // console.log("Dictionary Tree load on dictionary mode true", stateRoot.isTreeLoaded);
  }, [dictionaryMode]);

  useEffect(() => {
    if (state?.dictionaryTreeList.length > 0)
      console.log("Create Offline Dictionary Tree Data", state?.dictionaryTreeList?.filter(
        dictionary => dictionaryType.toUpperCase() == "ALL" ? true : dictionaryType.includes(dictionary.dictionaryType)
      ));
  }, [state?.dictionaryTreeList]);

  useEffect(() => {
    // console.log("Dictionary Tree load on language change true", stateRoot.isTreeLoaded);
    dispatch(rootAction.setTreeLoad(true));
  }, [language?.code]);

  useEffect(() => {
    // console.log("Dictionary Tree load", stateRoot.isTreeLoaded);
    if (stateRoot.isTreeLoaded) {
      dispatch(actions.resetDictionaryTreeList());
      // console.log("Dictionary Tree Expanded before delete", expandedNodes);
      expandedNodes.splice(0, expandedNodes.length);
      // console.log("Dictionary Tree Expanded after delete", expandedNodes);
      setExpandedNodes(['1']);
      const startLevelParameter = {
        startingLevel: 0,
        noLevel: 1,
        userId: commonState?.userRoleInfo?.id,
        userLogin: commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] ?
          'PMI\\' + commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] :
          userRoleInfo?.login,
        dictionaryFilter: "",
        productFamilyFilter: "",
        familyFilter: "",
        categoryFilter: "",
        defectFilter: "",
        productFamilyAttributeId: "",
        showOnlyPublished: 0,
        showHidden: 0,
        prdNcTypeOnly: 0,
        languageCode: language?.code,
        dictionaryMode: dictionaryMode,
        attributeType: filterParams?.attributeType,
        productionCenter: filterParams?.productionCenter,
        auditSubType: filterParams?.auditSubType,
        sampleId: filterParams?.sampleId
      };

      let parameter = startLevelParameter;
      dispatch(actions.getDictionaryTreeList(parameter));
      setdictionaryParam(startLevelParameter);

      setLoadFirstLevel(true);
      dispatch(actions.setDictionaryTreeParentInfo(initialParentInfo));

      dispatch(rootAction.setTreeLoad(false));

    }

  }, [stateRoot.isTreeLoaded]);
  const isNeedPageloadCall = state.dictionarySelectedParentInfo.level == 0 && state?.dictionaryTreeList.length > 0 && isLoadFirstLevel;
  useEffect(() => {

    if (isNeedPageloadCall) {
      setLoadFirstLevel(false);
      const nodeItem = state?.dictionaryTreeList.filter((item) => item.nodeId == "1")?.[0];
      handleCallbackNodeSelection(nodeItem, "pageLoad");
    }
  }, [isNeedPageloadCall]);

  useEffect(() => {
    if (dictionaryType != "ALL") {
      setofflineDictionaryParams({
        ...offlineDictionaryParams,
        dictionaryIds: [],
        productids: [],
        familyIds: [],
        categoryIds: [],
        defectIds: [],
        attributeInfo: AttributesIds,
        languageCode: languageCode,
        dictionaryType: dictionaryType
      });
      console.log(state?.dictionaryTreeList.length > 0 ? state?.dictionaryTreeList?.filter(
        dictionary => dictionaryType.toUpperCase() == "ALL" ? true : dictionaryType.includes(dictionary.dictionaryType)
      ) : state?.dictionaryTreeList);
    }
  }, [dictionaryType]);

  const generateOfflineReport = (nodeItem) => {
    setofflineDictionaryParams({
      ...offlineDictionaryParams,
      dictionaryIds: nodeItem.level == 0 && !offlineDictionaryParams.dictionaryIds.includes(nodeItem.id) ? [...offlineDictionaryParams.dictionaryIds.filter(element => element !== nodeItem.id), nodeItem.id] : [...offlineDictionaryParams.dictionaryIds.filter(element => element != nodeItem.id)],
      productids: nodeItem.level == 0 ? getChildrenIdLists(offlineDictionaryParams.productids, nodeItem.children) : nodeItem.level == 1 && !offlineDictionaryParams.productids.includes(nodeItem.id) ? [...offlineDictionaryParams.productids.filter(element => element !== nodeItem.id), nodeItem.id] : [...offlineDictionaryParams.productids.filter(element => element != nodeItem.id)],
      familyIds: nodeItem.level == 2 && !offlineDictionaryParams.familyIds.includes(nodeItem.id) ? [...offlineDictionaryParams.familyIds.filter(element => element !== nodeItem.id), nodeItem.id] : [...offlineDictionaryParams.familyIds.filter(element => element != nodeItem.id)],
      categoryIds: nodeItem.level == 2 ? getChildrenIdLists(offlineDictionaryParams.categoryIds, nodeItem.children) : nodeItem.level == 3 && !offlineDictionaryParams.categoryIds.includes(nodeItem.id) ? [...offlineDictionaryParams.categoryIds.filter(element => element !== nodeItem.id), nodeItem.id] : [...offlineDictionaryParams.categoryIds.filter(element => element != nodeItem.id)],
      defectIds: nodeItem.level == 4 && !offlineDictionaryParams.defectIds.includes(nodeItem.id) ? [...offlineDictionaryParams.defectIds.filter(element => element !== nodeItem.id), nodeItem.id] : [...offlineDictionaryParams.defectIds.filter(element => element != nodeItem.id)],
      attributeInfo: AttributesIds,
      languageCode: languageCode,
      dictionaryType: dictionaryType
    });
  };

  const getChildrenIdLists = (Ids, childrenList) => {
    let result = Ids;
    childrenList.forEach(nodeItem => {
      result = !result.includes(nodeItem.id) ? [...result.filter(element => element !== nodeItem.id), nodeItem.id] : [...result.filter(element => element != nodeItem.id)];
    });
    console.log("Offline DictionaryChildren array", result);
    return result;
  }

  useEffect(() => {
    dispatch(actions.setOfflineDictionaryParams(offlineDictionaryParams));
    // console.log("offline Dictionary Params After", offlineDictionaryParams);
  }, [offlineDictionaryParams]);

  useEffect(() => {
    const params = {
      userLogin: commonState?.userInfo?.username?.split("@")[0]?.split("_")[1],
    };
    dispatch(arttributeAction.getUserDictionaryAttributes(params));
  }, []);


  useEffect(() => {
    if (stateAttribute?.dictionaryAttributesData)
      if (stateAttribute?.dictionaryAttributesData.length > 0) {
        let selectedAttributes = stateAttribute?.dictionaryAttributesData.filter(p => p.isSelected == 1);
        let AttributeIds = stateAttribute?.dictionaryAttributesData.filter(p => p.isSelected == 1).map(a => a.code);
        setAttributesIds({ IsAllSelected: stateAttribute?.dictionaryAttributesData.length == selectedAttributes.length, attributeIds: AttributeIds });
      }
  }, [stateAttribute?.dictionaryAttributesData]);



  return (
    <>
      {state.isTreeLoading ? <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: "10%",
          bottom: "40vh",
          position: "absolute"
        }}
      >
        <CircularProgress />
      </div> : ""}


      <Tree
        treeData={
          state?.dictionaryTreeList.length > 0 ? state?.dictionaryTreeList?.filter(
            dictionary => dictionaryType.toUpperCase() == "ALL" ? true : dictionaryType.includes(dictionary.dictionaryType)
          ) : state?.dictionaryTreeList
        }
        parentCallback={handleCallbackNodeSelection}
        expandedNodeIds={expandedNodes}
        addCheckbox={addCheckbox}
        generateReportCallBack={generateOfflineReport}
      />
      {/* <Tree
        treeData={
          state?.dictionaryTreeList

        }
        parentCallback={handleCallbackNodeSelection}
        expandedNodeIds={expandedNodes}
        addCheckbox={addCheckbox}
        generateReportCallBack={generateOfflineReport}
      /> */}

      {/* {commonState.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "50%"
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Tree
          treeData={state?.dictionaryTreeList}
          parentCallback={handleCallbackNodeSelection}
          expandedNodeIds={expandedNodes}
          addCheckbox={addCheckbox}
        />
      )} */}
    </>
  );
}
