export const actionTypes = {
    GET_FindInDictionaryLevel: "GET_FindInDictionaryLevel",
    SET_FindInDictionaryLevel: "SET_FindInDictionaryLevel",
    SET_Translation: "SET_Translation",
    GET_FindInDictionaryDefect: "GET_FindInDictionaryDefect",
    SET_FindInDictionaryDefect: "SET_FindInDictionaryDefect",
    SET_DefectTranslation: "SET_DefectTranslation",
    //SET_EditDefectTranslation: "SET_EditDefectTranslation",
  };
  
  export const actions = {
    getFindInDictionaryLevelById: (payload) => ({
        type: actionTypes.GET_FindInDictionaryLevel,
        payload,
      }),

    setFindInDictionaryLevelById: (payload) => ({
      type: actionTypes.SET_FindInDictionaryLevel,
      payload,
    }),

    setTranslation: (payload) => ({
      type: actionTypes.SET_Translation,
      payload,
    }),

    getFindInDictionaryDefectById: (payload) => ({
      type: actionTypes.GET_FindInDictionaryDefect,
      payload,
    }),

    setFindInDictionaryDefectById: (payload) => ({
    type: actionTypes.SET_FindInDictionaryDefect,
    payload,
  }),

  setDefectTranslation: (payload) => ({
    type: actionTypes.SET_DefectTranslation,
    payload,
  }),

  // setEditDefectTranslation: (payload) => ({
  //   type: actionTypes.SET_EditDefectTranslation,
  //   payload,
  // }),
  };
  