import Bundles from './Images/bundles.jpg';
import Case from './Images/case.jpg';
import Cigarattes from './Images/cigarates.jpg';
import CommunicationItems from './Images/communication_items.jpg';
import Filters from './Images/filters.jpg';
import Packs from './Images/packs.jpg';

export default [
    {
      img: Bundles,
      title: 'Bundles',
    },
    {
      img: Case,
      title: 'Cases',
    },
    {
      img: Cigarattes,
      title: 'Cigarattes'
    },
    {
      img: CommunicationItems,
      title: 'Communication Items'
    },
    {
      img: Filters,
      title: 'Filters',
    },
    {
      img: Packs,
      title: 'Packs',
    },
  ];