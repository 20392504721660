import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchProjectsAPI,
  deleteProjectsAPI,
  addProjectsAPI,
  getProjectsByIdAPI,
  editProjectsAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getProjectsSaga(action) {
  try {
    const _api = yield call(fetchProjectsAPI, action.payload);
    yield put(actions.setProjects(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* deleteProjectsSaga(action) {
  try {
    const _api = yield call(deleteProjectsAPI, action.payload);
    yield put(actions.getProjects());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProjectsById(action) {
  try {
    const _api = yield call(getProjectsByIdAPI, action.payload);
    yield put(actions.setProjectsById(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addProjectsSaga(action) {
  try {
    const _api = yield call(addProjectsAPI, action.payload);
    yield put(actions.getProjects());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editProjectsSaga(action) {
  try {
    const _api = yield call(editProjectsAPI, action.payload);
    yield put(actions.getProjects());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchProjectsSaga() {
  yield all([
    takeEvery(actionTypes.GET_PROJECTS, getProjectsSaga),
    takeEvery(actionTypes.DELETE_PROJECTS, deleteProjectsSaga),
    takeEvery(actionTypes.ADD_PROJECTS, addProjectsSaga),
    takeEvery(actionTypes.GET_PROJECTS_BY_ID, getProjectsById),
    takeEvery(actionTypes.EDIT_PROJECTS, editProjectsSaga),
  ]);
}
