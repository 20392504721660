import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { fetchHelpAPI, fetchHelpEditAPI } from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { decodeBase64 } from "utils";

function* getHelpSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchHelpAPI, action.payload);
    let updatedData = [];
    const reg =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (reg.test(_api.data.data[0].helpText)) {
      updatedData.push({
        id: _api.data.data[0].id,
        helpText: decodeBase64(_api.data.data[0].helpText),
      });
    } else {
      updatedData.push({
        id: _api.data.data[0].id,
        helpText: _api.data.data[0].helpText,
      });
    }
    yield put(actions.setHelp(updatedData));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editHelpSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchHelpEditAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getHelp());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error.error.message));
  }
}

export function* watchHelpSaga() {
  yield all([
    takeEvery(actionTypes.GET_HELP, getHelpSaga),
    takeEvery(actionTypes.EDIT_HELP, editHelpSaga),
  ]);
}
