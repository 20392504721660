import { actionTypes } from "../actions/common";

export const initialState = {
  userInfo: {
    principalName: "",
  },
  userRoleInfo: null,
  userRoleAccess: null,
  userMembershipRoles: null,
  redirection: {
    status: false,
    page: "",
  },
  isOpen: [],
  fontFamily: `'Lato', sans-serif`,
  borderRadius: 6,
  opened: true,
  loginInfo: {},
  isUserLoggedIn: false,
  isLoading: false,
  isUpdated: false,
  toast: {
    open: false,
    severity: "info",
    message: "",
  },
  // DROP DOWNS
  laboaratoryList: [],
  imageList: [],
  globalLanguage: {
    code: "EN",
    name: "English",
  },

  // IMDL Menus
  isMenuLoading: false,
  menus: null,
};

// ===========================|| CUSTOMIZATION REDUCER ||=========================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...action.payload,
          principalName:
            action.payload?.username?.split("@")[0]?.split("_")[1] || "",
        },
      };

    case actionTypes.SET_USER_LOGGEDIN:
      return {
        ...state,
        isUserLoggedIn: action.payload,
      };

    case actionTypes.SET_PAGE_REDIRECT:
      return {
        ...state,
        redirection: action.payload,
      };

    case actionTypes.MENU_OPEN:
      id = action.payload;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.payload,
      };

    case actionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case actionTypes.SET_UPDATED:
      return {
        ...state,
        isUpdated: action.payload,
      };
    case actionTypes.SHOW_TOAST:
      return {
        ...state,
        toast: action.payload,
      };
    case actionTypes.HIDE_TOAST:
      return {
        ...state,
        toast: action.payload,
      };

    case actionTypes.SET_LABOARATORY_LIST:
      return {
        ...state,
        laboaratoryList: action.payload,
      };

    case actionTypes.SET_ADMIN_ROLES:
      return {
        ...state,
        userRoleInfo: action.payload,
      };
    case actionTypes.SET_IMAGE_FROM_AWS:
      return {
        ...state,
        imageList: action.payload,
      };
    case actionTypes.SET_LANGUAGE:
      return {
        ...state,
        globalLanguage: action.payload,
      };

    case actionTypes.SET_IMDL_MENUS:
      return {
        ...state,
        menus: action.payload,
      };

    case actionTypes.SET_MENU_LOADING:
      return {
        ...state,
        isMenuLoading: action.payload,
      };

    case actionTypes.SET_USER_MEMBERSHIP_ROLES:
      return {
        ...state,
        userMembershipRoles: action.payload,
      };
    case actionTypes.SET_USER_ROLES_ACCESS:
      return {
        ...state,
        userRoleAccess: action.payload,
      };

    default:
      return state;
  }
};

export default customizationReducer;
