export const actionTypes = {
  GET_ACCESS_MANAGEMENT: "GET_ACCESS_MANAGEMENT",
  SET_ACCESS_MANAGEMENT: "SET_ACCESS_MANAGEMENT",
  GET_DICTIONARY_LIST: "GET_DICTIONARY_LIST",
  SET_DICTIONARY_LIST: "SET_DICTIONARY_LIST",
  ADD_PLATFORM_DICTIONARY_ACCESS: "ADD_PLATFORM_DICTIONARY_ACCESS",
  GET_PLATFORM_DICTIONARY_LIST: "GET_PLATFORM_DICTIONARY_LIST",
  SET_PLATFORM_DICTIONARY_LIST: "SET_PLATFORM_DICTIONARY_LIST",
  EDIT_PLATFORM_DICTIONARY_ACCESS: "EDIT_PLATFORM_DICTIONARY_ACCESS",
};

export const actions = {
  getAccessManagement: (payload) => ({
    type: actionTypes.GET_ACCESS_MANAGEMENT,
    payload: payload,
  }),
  setAccessManagement: (payload) => ({
    type: actionTypes.SET_ACCESS_MANAGEMENT,
    payload: payload,
  }),
  getDictionaryList: (payload) => ({
    type: actionTypes.GET_DICTIONARY_LIST,
    payload: payload,
  }),
  setDictionaryList: (payload) => ({
    type: actionTypes.SET_DICTIONARY_LIST,
    payload: payload,
  }),

  addPlatformDictionaryAccess: (payload) => ({
    type: actionTypes.ADD_PLATFORM_DICTIONARY_ACCESS,
    payload: payload,
  }),
  getPlatformDictionaryList: (payload) => ({
    type: actionTypes.GET_PLATFORM_DICTIONARY_LIST,
    payload: payload,
  }),
  setPlatformDictionaryList: (payload) => ({
    type: actionTypes.SET_PLATFORM_DICTIONARY_LIST,
    payload: payload,
  }),
  editPlatformDictionaryAccess: (payload) => ({
    type: actionTypes.EDIT_PLATFORM_DICTIONARY_ACCESS,
    payload: payload,
  }),
};
