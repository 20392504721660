/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getAuditReportsAPI,
  getAuditSubTypeAPI,
  getMarketListAPI,
  getMarketedBrandByMarketAPI,
  getPeriodDropDownAPI,
  updateSampleStatusAPI,
  validateSampleAPI,
  getBundlesListAPI,
  getSampleBySampleIdAPI,
  saveBundlesListAPI,
  inspectSampleAPI,
  getBundlesTreeAPI,
  getBeforeValidationReportAPI,
  assignInspectorForSampleAPI,
  getAfterValidationReportAPI,
  getAfterValidationReportExcelAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { downloadFile } from "utils";

function* inspectSampleSaga(action) {
  try {
    const _api = yield call(inspectSampleAPI, action.payload);
    yield put(actions.setInspectSample(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getSampleBySampleIdSaga(action) {
  try {
    const _api = yield call(getSampleBySampleIdAPI, action.payload);
    yield put(actions.setSampleBySampleId(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* validateSampleSaga(action) {
  try {
    const _api = yield call(validateSampleAPI, action.payload);
    yield put(actions.setValidateResult(true));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* updateSampleStatusSaga(action) {
  try {
    const _api = yield call(updateSampleStatusAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* getPeriodDropDownSaga(action) {
  try {
    const _api = yield call(getPeriodDropDownAPI, action.payload);
    //alert(JSON.stringify(_api.data.data));
    yield put(actions.setPeriodDropDown(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getMarketedBrandByMarketSaga(action) {
  try {
    const _api = yield call(getMarketedBrandByMarketAPI, action.payload);
    yield put(actions.setMarketedBrandByMarket(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
    yield put(actions.setMarketedBrandByMarket([]));
  }
}

function* getMarketListSaga(action) {
  try {
    const _api = yield call(getMarketListAPI, action.payload);
    const marketAll = {market: "All", marketGroup: "", marketDescription: "All", marketLongDesc: "All"};
    _api.data.data.unshift(marketAll);
    yield put(actions.setMarketList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAuditReportsSaga(action) {
  try {
    const _api = yield call(getAuditReportsAPI, action.payload);
    yield put(actions.setAuditReport(_api.data.data));
    yield put(actions.setAuditReportTotalCount(_api.data.totalCount));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAuditSubTypeSaga(action) {
  try {
    const _api = yield call(getAuditSubTypeAPI, action.payload);
    yield put(actions.setAuditSubType(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getBundleListSaga(action) {
  try {
    const _api = yield call(getBundlesListAPI, action.payload);
    yield put(actions.setBundleList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* saveBundleListSaga(action) {
  try {
    const _api = yield call(saveBundlesListAPI, action.payload.post);
    // navigate(
    //   "/audit/sample-process?actionType=validation&sampleId=" + action.payload.sampleId
    // );
    yield put(actions.setSaveResult(true));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getBundlesTreeSaga(action) {
  try {
    const _api = yield call(getBundlesTreeAPI, action.payload);

    yield put(actions.setBundleTreeData(_api.data.data));

    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}
function* getBeforeValidationReportSaga(action) {
  try {
    const _api = yield call(getBeforeValidationReportAPI, action.payload);

    yield put(actions.setBeforeValidationReport(_api.data.data)); 

    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* assignInspectorForSampleSaga(action) {
  try {
    const _api = yield call(assignInspectorForSampleAPI, action.payload);
    if (_api.data.status == 200) {
      yield put(actions.setAssignInspectorStatus(true));
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* getAfterValidationReportSaga(action) {
  try {
    const _api = yield call(getAfterValidationReportAPI, action.payload);
    yield put(actions.setAfterValidationReport(_api.data.data)); 
    yield put(commonActions.setLoading(false));
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Unable to fetch report"));
  }
}

function* getAfterValidationReportExcelSaga(action) {
  try {
    const _api = yield call(getAfterValidationReportExcelAPI, action.payload); 
    yield put(commonActions.setLoading(false));
    downloadFile(
      action.payload.reportType == "" || action.payload.reportType.toUpperCase() == "FACTORY" ?
        "FactoryAfterValidationReport.xlsx" :
        "DevelopmentAfterValidationReport.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      _api.data.data
    );
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Unable to export report"));
  }
}

export function* watchAuditReportsSaga() {
  yield all([
    takeEvery(actionTypes.GET_AUDIT_REPORT, getAuditReportsSaga),
    takeEvery(actionTypes.GET_AUDIT_SUBTYPE, getAuditSubTypeSaga),
    takeEvery(actionTypes.GET_MARKET_LIST, getMarketListSaga),
    takeEvery(actionTypes.GET_MARKET_BRAND, getMarketedBrandByMarketSaga),
    takeEvery(actionTypes.GET_PERIOD, getPeriodDropDownSaga),
    takeEvery(actionTypes.SET_SAMPLE_STATUS, updateSampleStatusSaga),
    takeEvery(actionTypes.VALIDATE_SAMPLE_STATUS, validateSampleSaga),
    takeEvery(actionTypes.GET_BUNDLE_LIST, getBundleListSaga),
    takeEvery(actionTypes.GET_SAMPLE_SAMPLEID, getSampleBySampleIdSaga),
    takeEvery(actionTypes.SAVE_BUNDLE_LIST, saveBundleListSaga),
    //takeEvery(actionTypes.GET_INSPECT_SAMPLE, inspectSampleSaga),
    takeEvery(actionTypes.GET_BUNDLE_TREE_DATA, getBundlesTreeSaga),
    takeEvery(actionTypes.GET_BEFORE_VALIDATION, getBeforeValidationReportSaga),
    takeEvery(actionTypes.ASSIGN_INSPECTOR_FOR_SAMPLE, assignInspectorForSampleSaga),
    takeEvery(actionTypes.GET_AFTER_VALIDATION_REPORT, getAfterValidationReportSaga),
    takeEvery(actionTypes.GET_AFTER_VALIDATION_REPORT_EXCEL, getAfterValidationReportExcelSaga),
  ]);
}
