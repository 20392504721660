import cryptoBrowserify from 'crypto-browserify';
const SCOPE = '';
const accessToken = window.localStorage.getItem('token');
const idToken = window.localStorage.getItem('idToken');
const refreshToken = window.localStorage.getItem('refreshToken');
const tokenExpiry = window.localStorage.getItem('tokenExpiry');
let tokens = accessToken === null ? null : {
    accessToken,
    idToken,
    refreshToken,
    tokenExpiry
};
const base64URLEncode = str => {
    return str.toString('base64')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
};
const sha256 = buffer => {
    return cryptoBrowserify.createHash('sha256').update(buffer).digest();
}
let storedVerifier = window.localStorage.getItem('verifier');
if (storedVerifier === null) {
    const newVerifier = base64URLEncode(cryptoBrowserify.randomBytes(32));
    window.localStorage.setItem('verifier', newVerifier);
    storedVerifier = newVerifier;
}
const verifier = storedVerifier;
const challenge = base64URLEncode(sha256(storedVerifier));
//For deployment
// const baseUrl = "/s-accounts/cognito/base_url";
// const clientId = "/s-accounts/cognito/client_id";
// const clientSecret = "/s-accounts/cognito/client_secret";
// const redirectUri = "/s-accounts/cognito/redirect_uri";
const baseUrl = process.env.REACT_APP_SIGNIN;
const clientId = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_KEY;
//const getUrl = window.location;
//const redirectUri = getUrl.protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
const redirectUri = process.env.REACT_APP_REDIRECTURI;  //"http://localhost:3000/login"; //   
const authString = ((clientSecret !== "") ? (clientId.toString() + ":" + clientSecret.toString()) : "");
const b64ueAuthorization = ((authString !== "") ? btoa(authString) : "");
const authorizationHeader = ((authString !== "") ? "Basic " + b64ueAuthorization.toString() : "");
console.log("Authorization : " + authorizationHeader);
const tokenUrl = `${baseUrl}/oauth2/token`;
export const loginUrl = `${baseUrl}/oauth2/authorize?scope=${SCOPE}&response_type=code&client_id=${clientId}&code_challenge=${challenge}&code_challenge_method=S256&redirect_uri=${redirectUri}`;
export const logoutUrl = `${baseUrl}/logout?client_id=${clientId}&logout_uri=${redirectUri}`;
export const userInfoUrl = `${baseUrl}/oauth2/userInfo`;
// console.log("Challenge : " + challenge);
// console.log("Base URL : " + baseUrl);
// console.log("Client ID : " + clientId);
// console.log("Redirect URI : " + redirectUri);
// console.log("Token URL : " + tokenUrl);
// console.log("Login URL : " + loginUrl);
// console.log("Logout URL : " + logoutUrl);
export const refreshTokens = async refreshToken => {
    let loginHeaders = new Headers();
    loginHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    if (authorizationHeader !== "") {
        loginHeaders.append('Authorization', authorizationHeader)
    }
    const body = `grant_type=refresh_token&client_id=${clientId}&refresh_token=${refreshToken}`;
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: loginHeaders,
        body,
    });
    if (!response.ok) {
        throw Error();
    }
    const { access_token, id_token } = await response.json();
    window.localStorage.setItem('token', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refreshToken);
    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);
    window.localStorage.setItem('tokenExpiry', expiryDateTime);
    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refreshToken,
        tokenExpiry: expiryDateTime
    };
    console.log(tokens);
};
export const login = async code => {
    //alert("Login");
    let loginHeaders = new Headers();
    loginHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
    if (authorizationHeader !== "") {
        loginHeaders.append('Authorization', authorizationHeader)
    }
    const body = `grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&code=${code}&redirect_uri=${redirectUri}`;
    console.log("Body : " + body);
    const response = await fetch(tokenUrl, {
        method: 'POST',
        headers: loginHeaders,
        body,
    });
    if (!response.ok) {
        throw Error();
    }
    const { access_token, id_token, refresh_token } = await response.json();
    console.log("Id token : " + id_token);
    window.localStorage.setItem('token', access_token);
    window.localStorage.setItem('idToken', id_token);
    window.localStorage.setItem('refreshToken', refresh_token);
    const expiryDateTime = new Date();
    expiryDateTime.setMinutes(expiryDateTime.getMinutes() + 10);
    window.localStorage.setItem('tokenExpiry', expiryDateTime);
    tokens = {
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
        tokenExpiry: expiryDateTime
    };
    console.log(tokens);
    return tokens;
};
export const logout = async () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('idToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('tokenExpiry');
    tokens = null;
    console.log(tokens);
};
export const getTokens = () => {
    console.log(tokens);
    return tokens;
}