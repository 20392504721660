import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getCategoryListAPI,
  addCategoryDictionaryAPI,
  updateCategoryDictionaryAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../../state/actions";

function* getDictionaryCategorySaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getCategoryListAPI, action.payload);
    yield put(actions.setDictionaryUpdateFamily(_api.data.data));

    yield put(commonActions.setLoading(false));
  } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(ErrorMessage));

  }
}

function* addDictionaryCategorySaga(action) {
  try {
    const _api = yield call(addCategoryDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if(error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }  
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

function* updateDictionaryCategorySaga(action) {
  try {
    const _api = yield call(updateCategoryDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if(error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }  
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

export function* watchDictionaryCategorySaga() {
  yield all([
    takeEvery(actionTypes.GET_Dictionary_Category, getDictionaryCategorySaga),
    takeEvery(actionTypes.ADD_Dictionary_Category, addDictionaryCategorySaga),
    takeEvery(actionTypes.UPDATE_Dictionary_Category, updateDictionaryCategorySaga),
  ]);
}