import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getDictionaryProductAPI,
  getDictionaryProductDataAPI,
  addProductDictionaryAPI,
  updateDictionaryObjectStatusAPI,
  updateProductDictionaryAPI,
  getDictionaryHierarchyAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../../state/actions";


function* getDictionaryProductSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryProductAPI, action.payload);
    yield put(actions.setDictionaryProduct(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(ErrorMessage));
  }
}

function* getDictionaryProductDataSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryProductDataAPI, action.payload);
    yield put(actions.setDictionaryProductData(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(ErrorMessage));
  }
}

function* addProductDictionarySaga(action) {
  try {
    const _api = yield call(addProductDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if (error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

function* updateDictionaryObjectStatusSaga(action) {
  try {
    const _api = yield call(updateDictionaryObjectStatusAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if (error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

function* updateProductDictionarySaga(action) {
  try {
    const _api = yield call(updateProductDictionaryAPI, action.payload);
    //console.log("Update Product Payload-->", action.payload);
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if (error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

function* getDictionaryHierarchySaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryHierarchyAPI, action.payload);
    yield put(actions.setDictionaryHierarchy(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchDictionaryProductSaga() {
  yield all([
    takeEvery(actionTypes.GET_Dictionary_Product, getDictionaryProductSaga),
    takeEvery(actionTypes.GET_Dictionary_Product_Data, getDictionaryProductDataSaga),
    takeEvery(actionTypes.ADD_Dictionary_Product, addProductDictionarySaga),
    takeEvery(actionTypes.UPDATE_Dictionary_Object_Status, updateDictionaryObjectStatusSaga),
    takeEvery(actionTypes.UPDATE_Dictionary_Product, updateProductDictionarySaga),
    takeEvery(actionTypes.GET_Dictionary_Hierarchy, getDictionaryHierarchySaga)
  ]);
}