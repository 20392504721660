import moment from "moment";

// Pass access token

export const getAccessToken = () => sessionStorage.getItem("token") || "";

// format date

export const formattedDate = (date) =>
  date ? moment.utc(date).format("DD-MM-YYYY") : null;

// get cookie

export const getCookie = (name) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
  return value ? value[2] : null;
};

// set Cookies

export const setCookie = (name, value, days) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};

export const encodeBase64 = (str) =>
  str ? btoa(unescape(encodeURIComponent(str))) : "";

export const decodeBase64 = (str) =>
  str ? decodeURIComponent(escape(atob(str))) : "";

export const downloadFile = (fileName, fileType, data) => {
  const linkSource = `data:${fileType};base64,${data}`;
  const link = document.createElement("a");
  link.href = linkSource;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const formatBytesToSize = (bytes) => {
  const units = [" B", " KB", " MB"];
  let i;
  for (i = 0; bytes >= 1024 && i < 4; i++) {
    bytes /= 1024;
  }

  return bytes.toFixed(2) + units[i];
};

// convert file to base64 encoded data

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
