// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "components/NavGroup";
import menuItem from "components/MenuItems";

// ==============================|| SIDEBAR MENU LIST ||============================== //

// function to render authorized menus

const renderAuthorizedMenus = (imdlMenus, menuItem) => {
  const menuKeys = imdlMenus?.map((menu) => menu?.pageKey || "") || [];
  const menusList = [];
  menuItem?.items[0]?.children?.map((item) => {
    let subMenus = {};
    if (item?.children?.length) {
      let children = [];
      item.children.filter((el) => {
        if (menuKeys?.length > 0 && menuKeys?.includes(el.id)) {
          subMenus["id"] = item.id;
          subMenus["title"] = item.title;
          subMenus["type"] = item.type;
          subMenus["breadcrumbs"] = true;
          children.push(el);
        }
      });
      subMenus["children"] = children;
    }
    menusList.push(subMenus);
  });

  // filter out non empty objects
  const results = menusList.filter((element) => {
    if (Object.keys(element).length !== 0 && element.children.length !== 0)
      return true;
    return false;
  });

  const authorizedMenusList = {
    items: [
      {
        type: "group",
        children: [
          {
            id: "dashboard",
            title: "Dashboard",
            type: "item",
            url: "/",
            breadcrumbs: true,
          },
          ...results,
        ],
      },
    ],
  };

  return authorizedMenusList;
};

const MenuList = ({ imdlMenus }) => {
  const menusToShow = renderAuthorizedMenus(imdlMenus, menuItem);
  const navItems = menusToShow?.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item} item={item} />;
      default:
        return (
          <Typography key={item} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
