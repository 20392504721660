const config = {
  // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
  // like '/react/default'
  basename: "",
  clientId: process.env.REACT_APP_CLIENT_ID,
  oauthApiUrl: process.env.REACT_APP_OAUTH_API_URL,
  oauthSigninRedirectUrl: process.env.REACT_APP_SIGNIN_REDIRECT,
};

export default config;
