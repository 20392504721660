/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchProductChecklistAPI,
  addProductCategoryChecklistAPI,
  editProductCategoryChecklistAPI,
  fetchInspectionListAPI,
  fetchDeviceChecklistInfoAPI,
  fetchInspectionChecklistNcCodesAPI,
  fetchInspectionChecklistNcCodesEditAPI,
  fetchInspectionChecklistNcCodesAddAPI,
  addProductChecklistAPI,
  editProductChecklistAPI,
  addDictionaryChecklistAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getProductChecklistInfoSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchProductChecklistAPI, action.payload);
    yield put(actions.setProductChecklistInfo(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addDictionaryChecklistSaga(action) {
  try {
    const _api = yield call(addDictionaryChecklistAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getInspectionChecklist());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* editProductCategoryChecklistInfoSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(editProductCategoryChecklistAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    const params = {
      id: action.payload.idProductInspectionChecklist,
    };
    yield put(actions.getProductChecklistInfo(params));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("add category::ERROR ==> ", error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* addProductCategoryChecklistInfoSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(addProductCategoryChecklistAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    const params = {
      id: action.payload.idProductInspectionChecklist,
    };
    yield put(actions.getProductChecklistInfo(params));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("add category::ERROR ==> ", error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getInspectionChecklistSaga(action) {
  try {
    const _api = yield call(fetchInspectionListAPI, action.payload);
    yield put(actions.setInspectionChecklist(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editNCCodeSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(
      fetchInspectionChecklistNcCodesEditAPI,
      action.payload
    );
    yield put(commonActions.successAlert(_api.data.data));
    const params = {
      id: action.payload.productCategoryId,
      productId: action.payload.idProductInspectionChecklist,
    };
    yield put(actions.getInspectionNCCodes(params));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("editNCCodeSaga::ERROR ==> ", error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}
function* addNCCodeSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(
      fetchInspectionChecklistNcCodesAddAPI,
      action.payload
    );
    yield put(commonActions.successAlert(_api.data.data));
    const params = {
      id: action.payload.productCategoryId,
      productId: action.payload.idProductInspectionChecklist,
    };
    yield put(actions.getInspectionNCCodes(params));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("addNCCodeSaga::ERROR ==> ", error.response);
    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.errorAlert(error?.response?.data?.data || "Unknown Error")
    );
  }
}

function* getDeviceChecklistInfoSaga(action) {
  try {
    const _api = yield call(fetchDeviceChecklistInfoAPI, action.payload);
    yield put(actions.setDeviceChecklistInfo(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addProductChecklistSaga(action) {
  try {
    const _api = yield call(addProductChecklistAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* editProductChecklistSaga(action) {
  try {
    const _api = yield call(editProductChecklistAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* getNCCodeSaga(action) {
  try {
    const _api = yield call(fetchInspectionChecklistNcCodesAPI, action.payload);
    yield put(actions.setInspectionNCCodes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchInpectionChecklistSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_PRODUCT_CHECKLIST_INFO,
      getProductChecklistInfoSaga
    ),
    takeEvery(
      actionTypes.EDIT_PRODUCT_CATEGORY_CHECKLIST_INFO,
      editProductCategoryChecklistInfoSaga
    ),
    takeEvery(actionTypes.ADD_DICTIONARY_CHECKLIST, addDictionaryChecklistSaga),
    takeEvery(
      actionTypes.ADD_PRODUCT_CATEGORY_CHECKLIST_INFO,
      addProductCategoryChecklistInfoSaga
    ),
    takeEvery(actionTypes.EDIT_PRODUCT_CHECKLIST, editProductChecklistSaga),
    takeEvery(actionTypes.GET_INSPECTION_CHECKLIST, getInspectionChecklistSaga),
    takeEvery(
      actionTypes.GET_DEVICE_CHECKLIST_INFO,
      getDeviceChecklistInfoSaga
    ),
    takeEvery(actionTypes.ADD_PRODUCT_CHECKLIST, addProductChecklistSaga),
    takeEvery(actionTypes.GET_INSPECTION_NCCode, getNCCodeSaga),
    takeEvery(actionTypes.EDIT_INSPECTION_NCCode, editNCCodeSaga),
    takeEvery(actionTypes.ADD_INSPECTION_NCCode, addNCCodeSaga),
  ]);
}
