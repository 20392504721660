import { combineReducers } from "redux";
import common from "./common";
import auditAdministration from "containers/Admin/AuditAdministration/state/reducer";
import attribute from "containers/Admin/Attribute/state/reducer";
import preliminaryChecklist from "containers/Admin/PreliminaryChecklist/state/reducer";
import inspectionChecklistReducer from "containers/Admin/InspectionChecklist/state/reducer";
import marketedBrand from "../../containers/Admin/MarketedBrand/state/reducer";
import laboratoryAdmin from "containers/Admin/LaboratoryAdmin/state/reducer";
import ipsmAttributes from "containers/Admin/IPSMAttributes/state/reducer";
import news from "containers/Admin/News/state/reducer";
import brandGroups from "containers/Admin/BrandGroups/state/reducer";
import productionCenter from "containers/Admin/ProductionCenter/state/reducer";
import machineModels from "containers/Admin/MachineModels/state/reducer";
import help from "containers/Admin/Help/state/reducer";
import accessManagement from "containers/Admin/AccessManagement/state/reducer";
import purchasingLocationType from "containers/Admin/PurchasingLocationType/state/reducer";
import project from "containers/Admin/Project/state/reducer";
import localProjects from "containers/Admin/LocalProjectTypes/state/reducer";
import inspectionChecklist from "containers/Admin/InspectionChecklist/state/reducer";
import loginHistory from "containers/Admin/LoginHistory/state/reducer";
import productionUnits from "containers/Admin/ProductionUnits/state/reducer";
import dictionaryTree from "containers/Admin/Dictionary/DictionaryTree/state/reducer";
import Translations from "containers/Translations/PendingTranslations/state/reducer";
import BatchDevice from "containers/Audit/BatchDevice/state/reducer";
import Search from "containers/Dashboard/MySamples/state/reducer";
import dictionaryFamily from "containers/Admin/Dictionary/DictionaryFamily/state/reducer";
import dictionaryCategory from "containers/Admin/Dictionary/DictionaryCategory/state/reducer";
import advanceSearch from "containers/Dashboard/DashboardDetails/state/reducer";
import addDictionary from "containers/Admin/Dictionary/DictionaryAdd/state/reducer";
import dictionaryProduct from "containers/Admin/Dictionary/DictionaryProduct/state/reducer";
import dictionaryDefect from "containers/Admin/Dictionary/DictionaryDefect/state/reducer";
import logNewSample from "containers/Audit/LogNewSample/state/reducer";
import userDictionaryAttribute from "containers/Admin/Dictionary/CreateOfflineDictionary/state/reducer";
import uploadTranslation from "containers/Admin/Dictionary/DictionaryTranslationUpload/state/reducer";
import dictionaryRoot from "containers/Admin/Dictionary/state/reducer";
import FindInDictionaryLevel from "containers/Translations/FindDictionary/state/reducer";
import dictionaryNcMappingTemplate from "containers/Admin/Dictionary/DictionaryDefectMappingUpload/state/reducer";
import uploadDictionary from "containers/Admin/Dictionary/DictionaryUpload/state/reducer";
import getImages from "containers/Admin/Dictionary/DictionaryUpload/state/getImageReducer";
import getXml from "containers/Admin/Dictionary/DictionaryUpload/state/getXmlReducer";
import getImageList from "containers/Admin/Dictionary/DictionaryUpload/state/getImageDataReducer";
import auditReport from "containers/Audit/state/reducer";
import sampleInspection from "containers/Audit/SampleDetails/SampleInspection/state/reducer";

const reducers = {
  customization: common,
  marketedBrand,
  auditAdministration,
  attribute,
  preliminaryChecklist,
  inspectionChecklistReducer,
  laboratoryAdmin,
  ipsmAttributes,
  news,
  brandGroups,
  productionCenter,
  machineModels,
  help,
  accessManagement,
  purchasingLocationType,
  project,
  localProjects,
  inspectionChecklist,
  loginHistory,
  productionUnits,
  dictionaryTree,
  Translations,
  BatchDevice,
  Search,
  dictionaryRoot,
  dictionaryFamily,
  dictionaryCategory,
  advanceSearch,
  addDictionary,
  dictionaryProduct,
  dictionaryDefect,
  logNewSample,
  userDictionaryAttribute,
  uploadTranslation,
  FindInDictionaryLevel,
  dictionaryNcMappingTemplate,
  uploadDictionary,
  getImages,
  getXml,
  getImageList,
  auditReport,
  sampleInspection
};

const combinedReducers = Object.keys(reducers).length
  ? { ...reducers }
  : {
    default: (state) => (state == null ? (state = []) : state),
  };

export default combineReducers(combinedReducers);
