export const actionTypes = {
  GET_PRODUCT_CHECKLIST_INFO: "GET_PRODUCT_CHECKLIST_INFO",
  SET_PRODUCT_CHECKLIST_INFO: "SET_PRODUCT_CHECKLIST_INFO",
  ADD_DICTIONARY_CHECKLIST: "ADD_DICTIONARY_CHECKLIST",
  EDIT_PRODUCT_CATEGORY_CHECKLIST_INFO: "EDIT_PRODUCT_CATEGORY_CHECKLIST_INFO",
  ADD_PRODUCT_CATEGORY_CHECKLIST_INFO: "ADD_PRODUCT_CATEGORY_CHECKLIST_INFO",
  GET_INSPECTION_CHECKLIST: "GET_INSPECTION_CHECKLIST",
  SET_INSPECTION_CHECKLIST: "SET_INSPECTION_CHECKLIST",
  ADD_PRODUCT_CHECKLIST: "ADD_PRODUCT_CHECKLIST",
  EDIT_PRODUCT_CHECKLIST: "EDIT_PRODUCT_CHECKLIST",
  GET_DEVICE_CHECKLIST_INFO: "GET_DEVICE_CHECKLIST_INFO",
  SET_DEVICE_CHECKLIST_INFO: "SET_DEVICE_CHECKLIST_INFO",
  GET_INSPECTION_NCCode: "GET_INSPECTION_NCCode",
  SET_INSPECTION_NCCode: "SET_INSPECTION_NCCode",
  EDIT_INSPECTION_NCCode: "EDIT_INSPECTION_NCCode",
  ADD_INSPECTION_NCCode: "ADD_INSPECTION_NCCode",
};

export const actions = {
  getProductChecklistInfo: (payload) => ({
    type: actionTypes.GET_PRODUCT_CHECKLIST_INFO,
    payload: payload,
  }),
  setProductChecklistInfo: (payload) => ({
    type: actionTypes.SET_PRODUCT_CHECKLIST_INFO,
    payload: payload,
  }),
  addDictionaryChecklist: (payload) => ({
    type: actionTypes.ADD_DICTIONARY_CHECKLIST,
    payload: payload,
  }),
  editProductCategoryChecklistInfo: (payload) => ({
    type: actionTypes.EDIT_PRODUCT_CATEGORY_CHECKLIST_INFO,
    payload: payload,
  }),
  addProductCategoryChecklistInfo: (payload) => ({
    type: actionTypes.ADD_PRODUCT_CATEGORY_CHECKLIST_INFO,
    payload: payload,
  }),
  addProductChecklistInfo: (payload) => ({
    type: actionTypes.ADD_PRODUCT_CHECKLIST_INFO,
    payload: payload,
  }),
  getInspectionChecklist: (payload) => ({
    type: actionTypes.GET_INSPECTION_CHECKLIST,
    payload: payload,
  }),
  setInspectionChecklist: (payload) => ({
    type: actionTypes.SET_INSPECTION_CHECKLIST,
    payload: payload,
  }),
  addProductChecklist: (payload) => ({
    type: actionTypes.ADD_PRODUCT_CHECKLIST,
    payload: payload,
  }),
  editProductChecklist: (payload) => ({
    type: actionTypes.EDIT_PRODUCT_CHECKLIST,
    payload: payload,
  }),

  getDeviceChecklistInfo: (payload) => ({
    type: actionTypes.GET_DEVICE_CHECKLIST_INFO,
    payload: payload,
  }),
  setDeviceChecklistInfo: (payload) => ({
    type: actionTypes.SET_DEVICE_CHECKLIST_INFO,
    payload: payload,
  }),

  getInspectionNCCodes: (payload) => ({
    type: actionTypes.GET_INSPECTION_NCCode,
    payload: payload,
  }),
  setInspectionNCCodes: (payload) => ({
    type: actionTypes.SET_INSPECTION_NCCode,
    payload: payload,
  }),

  editNCCode: (payload) => ({
    type: actionTypes.EDIT_INSPECTION_NCCode,
    payload: payload,
  }),

  addNCCode: (payload) => ({
    type: actionTypes.ADD_INSPECTION_NCCode,
    payload: payload,
  }),
};
