/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchDailyLoginHistoryAPI = (payload) => {
  return API.get("/adminService/api/Admin/getLoginHistoryDaily", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchWeeklyLoginHistoryAPI = (payload) => {
  return API.get("/adminService/api/Admin/getLoginHistoryWeekly", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchMonthlyLoginHistoryAPI = (payload) => {
  return API.get("/adminService/api/Admin/getLoginHistoryMonthly", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};
