import { actionTypes } from "./actions";

const initialState = {
  accessManagementData: [],
  dictionaryList: [],
  platformDictionaryList: [],
  platformDictionaryEdit: {
    platform: "",
    description: "",
    dictionary: "",
    dictionaryList: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ACCESS_MANAGEMENT:
      return {
        ...state,
        accessManagementData: payload,
      };

    case actionTypes.SET_DICTIONARY_LIST:
      return {
        ...state,
        dictionaryList: payload,
      };

    case actionTypes.SET_PLATFORM_DICTIONARY_LIST:
      return {
        ...state,
        platformDictionaryList: payload,
        platformDictionaryEdit: {
          ...state.platformDictionaryEdit,
          dictionaryList: payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
