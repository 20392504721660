import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { 
  //getAuditTypeAPI, 
  getAdvancedSearchDropdownsAPI, 
  getSampleStatusAPI, 
  getProductVariantAPI,
  getSamplesByAdvancedSearchAPI,
  getProductionUnitByProductionCenterIdAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { actions as searchActions } from "containers/Dashboard/MySamples/state/actions";

function* getAdvancedSearchDropdownsSaga(action){
    try{
      const _api = yield call(getAdvancedSearchDropdownsAPI, action.payload);
      // const objInsMe = {
      //   id: "Me",
      //   userName: "PMI\\All",
      //   fullName: "Me",        
      // };

      // const objInsAll = {
      //   id: "All",
      //   userName: "",
      //   fullName: "All",        
      // };
      // const objInsUnassigned ={
      //   id: "0",
      //   userName: "",
      //   fullName: "Unassigned",        
      // };
      // _api.data.data.inspectors.push(objInsMe);
      // _api.data.data.inspectors.push(objInsAll);
      // _api.data.data.inspectors.push(objInsUnassigned);
      const objProdAll = {
        id: '00',
        description: "All",
        manufacturerDescription: "ALL",
      };
      _api.data.data.productionCenter.push(objProdAll);
      yield put(actions.setSearchDropdowns(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  function* getSampleStatusSaga(){
    try{
      const _api = yield call(getSampleStatusAPI);
      const objAll = {
        id: "0",
        statusDescription: "All",
      };
      _api.data.data.push(objAll);
      yield put(actions.setSearchStatus(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  function* getProductVariantSaga(action){
    try{
      const _api = yield call(getProductVariantAPI, action.payload);
      yield put(actions.setProductVariant(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  function* getSamplesByAdvancedSearchSaga(action){
    try{
      const _api = yield call(getSamplesByAdvancedSearchAPI, action.payload);
      yield put(searchActions.setSearchBySampleIDGridCount(_api.data.totalCount));
      yield put(searchActions.setSearchBySampleID(_api.data.data));
      //yield put(actions.setAdvancedSearch(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }

  function* getProductionUnitByProductionCenterIdSaga(action){
    try{
      //console.log("Prod Unit Saga-->");
      const _api = yield call(getProductionUnitByProductionCenterIdAPI, action.payload);
      const objAll = {
        id: "0",
        productionUnit: "All",
      };
      _api.data.data.push(objAll);
      yield put(actions.setProductionUnit(_api.data.data));
      //yield put(actions.setAdvancedSearch(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      console.log(error);
    }
  }
  
  export function* watchAdvanceSearchSaga() {
    yield all([
      takeEvery(actionTypes.GET_Search_Dropdowns, getAdvancedSearchDropdownsSaga),
       takeEvery(actionTypes.GET_Search_Status, getSampleStatusSaga),
       takeEvery(actionTypes.GET_Product_Variant, getProductVariantSaga),
       takeEvery(actionTypes.GET_Advanced_Search, getSamplesByAdvancedSearchSaga),
       takeEvery(actionTypes.GET_Production_Unit, getProductionUnitByProductionCenterIdSaga)
    ]);
  }