/*eslint-disable */
export const actionTypes = {
    
      GET_Search_Dropdowns: "GET_Search_Dropdowns",
      SET_Search_Dropdowns: "SET_Search_Dropdowns",
      GET_Search_Status: "GET_Search_Status",
      SET_Search_Status: "SET_Search_Status",
      GET_Product_Variant: "GET_Product_Variant",
      SET_Product_Variant: "SET_Product_Variant",
      GET_Production_Unit: "GET_Production_Unit",
      SET_Production_Unit: "SET_Production_Unit",
      GET_Advanced_Search: "GET_Advanced_Search",
      SET_Advanced_Search: "SET_Advanced_Search",
      SET_Advanced_Search_Condition: "SET_Advanced_Search_Condition"
    };
    
    export const actions = {
      
      setAdvancedSearchCondition: (payload) => ({
        type: actionTypes.SET_Advanced_Search_Condition,
        payload,
      }),

        getAdvancedSearch: (payload) => ({
            type: actionTypes.GET_Advanced_Search,
            payload,
          }),
      
          setAdvancedSearch: (payload) => ({
            type: actionTypes.SET_Advanced_Search,
            payload,
          }),

        getProductionUnit: (payload) =>
        ({
            type: actionTypes.GET_Production_Unit,
            payload,
          }),
      
          setProductionUnit: (payload) => ({
            type: actionTypes.SET_Production_Unit,
            payload,
          }),

        getProductVariant: (payload) => ({
            type: actionTypes.GET_Product_Variant,
            payload,
          }),
      
          setProductVariant: (payload) => ({
            type: actionTypes.SET_Product_Variant,
            payload,
          }),

      getSearchDropdowns: (payload) => ({
        type: actionTypes.GET_Search_Dropdowns,
        payload,
      }),
  
      setSearchDropdowns: (payload) => ({
        type: actionTypes.SET_Search_Dropdowns,
        payload,
      }),

      getSearchStatus: (payload) => ({
        type: actionTypes.GET_Search_Status,
        payload,
      }),
  
      setSearchStatus: (payload) => ({
        type: actionTypes.SET_Search_Status,
        payload,
      }),
  
    };
    