import { API } from "api/config";
import axios from "axios";

// user info API
export const fetchUserInfoAPI = (token) => {
  return axios
    .get(`${process.env.REACT_APP_OAUTH_API_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res);
};

// admin role API

export const fetchAdminRoleAPI = ({ token, userName }) => {
  return axios
    .post(
      `${process.env.REACT_APP_USER_SERVICE_BASE_URL}/userService/api/Auth/authorizeUser`,
      {
        userName,
        isFromUI: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res);
};

export const fetchLogUserInfoAPI = (payload) => {
  return API.post("/adminService/api/Admin/loginInfo", payload)
    .then((res) => res)
    .catch((error) => error);
};

// Dropdown APIs

export const fetchLaboratoryListAPI = () => {
  return API.get("/adminService/api/MasterData/getLaboratoryList")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchImageFromAwsAPI = (payload) => {
  return API.get("/adminService/api/FileHandler/bulkImageDownload", {
    params: payload,
  }).then((res) => res);
};

export const fetchIMDLMenusAPI = (payload) => {
  return API.get("/adminService/api/AccessManagement/getPageAccess", {
    params: payload,
  }).then((res) => res);
};
