import { API } from "api/config";

export const fetchDictionaryDropdownsListAPI = () => {   
    return API.get("/adminService/api/AddNewDictionary/getAddNewDictionaryDropdowns")
        .then((res) => res);
};

export const addDictionaryAPI = (payload) => { 
    return API.post("/adminService/api/AddNewDictionary/AddNewDictionary", payload)
      .then((res) => res) ;
  };
