import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { fetchMachineListAPI, addMachineModelsAPI } from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getMachineListSaga(action) {
  try {
    const _api = yield call(fetchMachineListAPI, action.payload);
    yield put(actions.setMachineList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addMachineModelsSaga(action) {
  try {
    const _api = yield call(addMachineModelsAPI, action.payload);
    // yield put(actions.setMachineList(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getMachineList());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchMachineListSaga() {
  yield all([
    takeEvery(actionTypes.GET_MACHINE_LIST, getMachineListSaga),
    takeEvery(actionTypes.ADD_MACHINE_MODELS, addMachineModelsSaga),
  ]);
}
