import { actionTypes } from "./actions";

const initialState = {
  projectLocationTypeData: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PURCHASING_LOCATION_TYPE:
      return {
        ...state,
        projectLocationTypeData: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
