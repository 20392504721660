export const actionTypes = {
  GET_AUDIT_TYPE_AND_INSPECTOR: "GET_AUDIT_TYPE_AND_INSPECTOR",
  SET_AUDIT_TYPE_AND_INSPECTOR: "SET_AUDIT_TYPE_AND_INSPECTOR",
  GET_AUDIT_SUB_TYPE: "GET_AUDIT_SUB_TYPE",
  SET_AUDIT_SUB_TYPE: "SET_AUDIT_SUB_TYPE",
  GET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE: "GET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE",
  SET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE: "SET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE",
  SET_SELECTED_AUDIT_TYPE_CODE: "SET_SELECTED_AUDIT_TYPE_CODE",
  SET_SELECTED_AUDIT_SUB_TYPE_CODE: "SET_SELECTED_AUDIT_SUB_TYPE_CODE",
  GET_PRODUCTION_CENTER_LIST: "GET_PRODUCTION_CENTER_LIST",
  GET_PRODUCTION_CENTER_DATA: "GET_PRODUCTION_CENTER_DATA",
  SET_PRODUCTION_CENTER_DATA: "SET_PRODUCTION_CENTER_DATA",

  GET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER:
    "GET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER",
  SET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER:
    "SET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER",
  GET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER:
    "GET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER",
  SET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER:
    "SET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER",
  GET_PROJECT_AND_LOCATION_TYPES: "GET_PROJECT_AND_LOCATION_TYPES",
  SET_PROJECT_AND_LOCATION_TYPES: "SET_PROJECT_AND_LOCATION_TYPES",
  GET_FILTER_CODES: "GET_FILTER_CODES",
  SET_FILTER_CODES: "SET_FILTER_CODES",
  GET_SAMPLE_MARKET_LIST: "GET_SAMPLE_MARKET_LIST",
  SET_SAMPLE_MARKET_LIST: "SET_SAMPLE_MARKET_LIST",
  GET_MARKETED_BRAND_BY_MARKET: "GET_MARKETED_BRAND_BY_MARKET",
  SET_MARKETED_BRAND_BY_MARKET: "SET_MARKETED_BRAND_BY_MARKET",
  GET_PERIOD_DROP_DOWN: "GET_PERIOD_DROP_DOWN",
  SET_PERIOD_DROP_DOWN: "SET_PERIOD_DROP_DOWN",
  SAVE_SAMPLE_DATA: "SAVE_SAMPLE_DATA",
  SET_NEW_SAMPLE_ID: "SET_NEW_SAMPLE_ID",
  GET_SAMPLE_PRODUCTION_CODE: "GET_SAMPLE_PRODUCTION_CODE",
  SET_SAMPLE_PRODUCTION_CODE: "SET_SAMPLE_PRODUCTION_CODE",
  SET_SAMPLE_PRODUCTION_CODE_LOADING: "SET_SAMPLE_PRODUCTION_CODE_LOADING",
  SET_CODENTIFY_CODE_LOADING: "SET_CODENTIFY_CODE_LOADING",

  GET_CODENTIFY_CODE_DETAILS: "GET_CODENTIFY_CODE_DETAILS",
  SET_CODENTIFY_CODE_DETAILS: "SET_CODENTIFY_CODE_DETAILS",

  GET_CIGARETTE_CODE_DETAILS: "GET_CIGARETTE_CODE_DETAILS",
  SET_CIGARETTE_CODE_DETAILS: "SET_CIGARETTE_CODE_DETAILS",
  SET_UNIT_DETAILS_LOADING: "SET_UNIT_DETAILS_LOADING",
  GET_CIGARETTE_OPTIONS_DATA: "GET_CIGARETTE_OPTIONS_DATA",
  SET_CIGARETTE_OPTIONS_DATA: "SET_CIGARETTE_OPTIONS_DATA",
};

export const actions = {
  getAuditTypeAndInspector: (payload) => ({
    type: actionTypes.GET_AUDIT_TYPE_AND_INSPECTOR,
    payload,
  }),
  setAuditTypeAndInspector: (payload) => ({
    type: actionTypes.SET_AUDIT_TYPE_AND_INSPECTOR,
    payload,
  }),
  getAuditSubTypes: (payload) => ({
    type: actionTypes.GET_AUDIT_SUB_TYPE,
    payload,
  }),
  setAuditSubTypes: (payload) => ({
    type: actionTypes.SET_AUDIT_SUB_TYPE,
    payload,
  }),
  getAuditSubTypesFromAuditType: (payload) => ({
    type: actionTypes.GET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE,
    payload,
  }),
  setAuditSubTypesFromAuditType: (payload) => ({
    type: actionTypes.SET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE,
    payload,
  }),
  setSelectedAuditTypeCode: (payload) => ({
    type: actionTypes.SET_SELECTED_AUDIT_TYPE_CODE,
    payload,
  }),
  setSelectedAuditSubTypeCode: (payload) => ({
    type: actionTypes.SET_SELECTED_AUDIT_SUB_TYPE_CODE,
    payload,
  }),

  getProductionCenterData: (payload) => ({
    type: actionTypes.GET_PRODUCTION_CENTER_DATA,
    payload,
  }),

  getProductionCenterList: (payload) => ({
    type: actionTypes.GET_PRODUCTION_CENTER_LIST,
    payload,
  }),

  setProductionCenterData: (payload) => ({
    type: actionTypes.SET_PRODUCTION_CENTER_DATA,
    payload,
  }),

  getProductionUnitsByProductionCenter: (payload) => ({
    type: actionTypes.GET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER,
    payload,
  }),

  setProductionUnitsByProductionCenter: (payload) => ({
    type: actionTypes.SET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER,
    payload,
  }),

  getProductVariantAndBrandByProductionCenter: (payload) => ({
    type: actionTypes.GET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER,
    payload,
  }),

  setProductVariantAndBrandByProductionCenter: (payload) => ({
    type: actionTypes.SET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER,
    payload,
  }),

  getProjectAndLocationTypes: (payload) => ({
    type: actionTypes.GET_PROJECT_AND_LOCATION_TYPES,
    payload,
  }),

  setProjectAndLocationTypes: (payload) => ({
    type: actionTypes.SET_PROJECT_AND_LOCATION_TYPES,
    payload,
  }),

  getFilterCodes: (payload) => ({
    type: actionTypes.GET_FILTER_CODES,
    payload,
  }),

  setFilterCodes: (payload) => ({
    type: actionTypes.SET_FILTER_CODES,
    payload,
  }),

  getMarketList: (payload) => ({
    type: actionTypes.GET_SAMPLE_MARKET_LIST,
    payload,
  }),

  setMarketList: (payload) => ({
    type: actionTypes.SET_SAMPLE_MARKET_LIST,
    payload,
  }),

  getMarketedBrandByMarket: (payload) => ({
    type: actionTypes.GET_MARKETED_BRAND_BY_MARKET,
    payload,
  }),

  setMarketedBrandByMarket: (payload) => ({
    type: actionTypes.SET_MARKETED_BRAND_BY_MARKET,
    payload,
  }),

  getPeriodDropDown: (payload) => ({
    type: actionTypes.GET_PERIOD_DROP_DOWN,
    payload,
  }),

  setPeriodDropDown: (payload) => ({
    type: actionTypes.SET_PERIOD_DROP_DOWN,
    payload,
  }),

  saveSampleData: (payload) => ({
    type: actionTypes.SAVE_SAMPLE_DATA,
    payload,
  }),

  setNewSampleId: (payload) => ({
    type: actionTypes.SET_NEW_SAMPLE_ID,
    payload,
  }),

  getSampleProductionCode: (payload, productVariantParams) => ({
    type: actionTypes.GET_SAMPLE_PRODUCTION_CODE,
    payload,
    productVariantParams,
  }),

  setSampleProductionCode: (payload) => ({
    type: actionTypes.SET_SAMPLE_PRODUCTION_CODE,
    payload,
  }),
  setSampleProductionCodeLoading: (payload) => ({
    type: actionTypes.SET_SAMPLE_PRODUCTION_CODE_LOADING,
    payload,
  }),
  getCodentifyCodeDetails: (payload, productVariantAndBrandParams) => ({
    type: actionTypes.GET_CODENTIFY_CODE_DETAILS,
    payload,
    productVariantAndBrandParams,
  }),

  setCodentifyCodeDetails: (payload) => ({
    type: actionTypes.SET_CODENTIFY_CODE_DETAILS,
    payload,
  }),

  getCigaretteCodeDetails: (payload) => ({
    type: actionTypes.GET_CIGARETTE_CODE_DETAILS,
    payload,
  }),

  setCigaretteCodeDetails: (payload) => ({
    type: actionTypes.SET_CIGARETTE_CODE_DETAILS,
    payload,
  }),

  setCodentifyCodeLoading: (payload) => ({
    type: actionTypes.SET_CODENTIFY_CODE_LOADING,
    payload,
  }),
  setUnitDetailsLoading: (payload) => ({
    type: actionTypes.SET_UNIT_DETAILS_LOADING,
    payload,
  }),

  getCigaretteOptionsData: (payload) => ({
    type: actionTypes.GET_CIGARETTE_OPTIONS_DATA,
    payload,
  }),

  setCigaretteOptionsData: (payload) => ({
    type: actionTypes.SET_CIGARETTE_OPTIONS_DATA,
    payload,
  }),
};
