import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

// style constant
const useStyles = makeStyles((theme) => ({
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    // background: theme.palette.secondary.light,
    background: "#143d77",
    color: "#fff",
    '&[aria-controls="menu-list-grow"],&:hover': {
      color: "#1e81f4",
      backgroundColor: "#fff",
    },
  },
  box: {
    marginLeft: "10px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },
}));

// ===========================|| NOTIFICATION ||=========================== //

const Dictionary = ({handleDictionaryOpen}) => {
  const classes = useStyles();

  const handleToggle = () => {
    // navigate('/admin/help')
    handleDictionaryOpen();
  };

  return (
    <>
      <Box component="span" className={classes.box}>
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleDictionaryOpen}
            color="inherit"
          >
            <Tooltip title="Dictionary">
              <BookOutlinedIcon
                stroke={1.5}
                size="1.3rem"
                className={classes.feedbackIcon}
              />
            </Tooltip>
          </Avatar>
        </ButtonBase>
      </Box>
    </>
  );
};

export default Dictionary;
