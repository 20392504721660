import { API } from "api/config";

//  API

export const fetchAttributesAPI = async (payload) => {
  try {
    const res = await API.get("/adminService/api/Attribute/getAttribute", {
      params: payload,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const hideAttributeAPI = (payload) => {
  return API.put("/adminService/api/Attribute/hideAttribute", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const getAttributeValuesAPI = (payload) => {
  return API.get(`/adminService/api/Attribute/getAttributeValue`, {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getAttributeByIdAPI = (payload) => {
  return API.get(`/adminService/api/Attribute/getEditAttribute`, {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const saveAttributeAPI = (payload) => {
  return API.put("/adminService/api/Attribute/saveAttribute", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const AddAttributeValueAPI = (payload) => {
  return API.post("/adminService/api/Attribute/addAttributeValue", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const editAttributeValuesAPI = (payload) => {
  return API.put("/adminService/api/Attribute/saveAttributeValue", payload)
    .then((res) => res)
    .catch((error) => error);
};
