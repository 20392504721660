/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchLaboratoryAdminAPI,
  fetchLaboratoryAdminByIdAPI,
  fetchLaboratoryAdminEditAPI,
  fetchLaboratoryAdminAddAPI
} from "../api/endpoint";
import { actions as commonActions } from 'store/actions/common';

function* getLaboratoryAdminSaga(action) {
  try {
    const _api = yield call(fetchLaboratoryAdminAPI, action.payload);
    yield put(actions.setLaboratoryAdmin(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getLaboratoryAdminByIdSaga(action) {
  try {
    const _api = yield call(fetchLaboratoryAdminByIdAPI, action.payload);
    yield put(actions.setLaboratoryAdminById(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editLaboratoryAdminSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchLaboratoryAdminEditAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getLaboratoryAdmin());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("editLaboratoryAdminSaga::ERROR ==> ", error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* addLaboratoryAdminSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchLaboratoryAdminAddAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getLaboratoryAdmin());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log("addLaboratoryAdminSaga::ERROR ==> ", error.response);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.response?.data?.data || "Unknown Error"));
  }
}

export function* watchLaboratoryAdminSaga() {
  yield all([
    takeEvery(actionTypes.GET_LABORATORY_ADMIN, getLaboratoryAdminSaga),
    takeEvery(actionTypes.GET_LABORATORY_ADMIN_BY_ID, getLaboratoryAdminByIdSaga),
    takeEvery(actionTypes.EDIT_LABORATORY_ADMIN, editLaboratoryAdminSaga),
    takeEvery(actionTypes.ADD_LABORATORY_ADMIN, addLaboratoryAdminSaga),
  ]);
}
