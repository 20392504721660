/*eslint-disable */
export const actionTypes = {
  GET_USER_DICTIONARY_ATTRIBUTES: "GET_USER_DICTIONARY_ATTRIBUTES",
  SET_USER_DICTIONARY_ATTRIBUTES: "SET_USER_DICTIONARY_ATTRIBUTES",
  SAVE_USER_DICTIONARY_ATTRIBUTES: "SAVE_USER_DICTIONARY_ATTRIBUTES",
};

export const actions = {
  getUserDictionaryAttributes: (payload) => ({
    type: actionTypes.GET_USER_DICTIONARY_ATTRIBUTES,
    payload,
  }),
  setUserDictionaryAttributes: (payload) => ({
    type: actionTypes.SET_USER_DICTIONARY_ATTRIBUTES,
    payload,
  }),
  saveUserDictionaryAttributes: (payload) => ({
    type: actionTypes.SAVE_USER_DICTIONARY_ATTRIBUTES,
    payload,
  }),
};
