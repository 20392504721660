export const actionTypes = {
  GET_AUDIT_REPORT: "GET_AUDIT_REPORT",
  SET_AUDIT_REPORT: "SET_AUDIT_REPORT",
  SET_AUDIT_REPORT_COUNT: "SET_AUDIT_REPORT_COUNT",
  GET_AUDIT_SUBTYPE: "GET_AUDIT_SUBTYPE",
  SET_AUDIT_SUBTYPE: "SET_AUDIT_SUBTYPE",
  GET_MARKET_LIST: "GET_MARKET_LIST",
  SET_MARKET_LIST: "SET_MARKET_LIST",
  GET_MARKET_BRAND: "GET_MARKET_BRAND",
  SET_MARKET_BRAND: "SET_MARKET_BRAND",
  GET_PERIOD: "GET_PERIOD",
  SET_PERIOD: "SET_PERIOD",
  SET_AUDIT_REPORT_TYPE: "SET_AUDIT_REPORT_TYPE",
  SET_AUDIT_TYPE: "SET_AUDIT_TYPE",
  SET_AuditSubtype_ForGrid: "SET_AuditSubtype_ForGrid",
  SET_SAMPLE_STATUS: "SET_SAMPLE_STATUS",
  SET_SAMPLE_EDIT: "SET_SAMPLE_EDIT",
  SET_AUDIT_REPORT_SEARCH: "SET_AUDIT_REPORT_SEARCH",
  VALIDATE_SAMPLE_STATUS: "VALIDATE_SAMPLE_STATUS",
  GET_BUNDLE_LIST: "GET_BUNDLE_LIST",
  SET_BUNDLE_LIST: "SET_BUNDLE_LIST",
  GET_SAMPLE_SAMPLEID: "GET_SAMPLE_SAMPLEID",
  SET_SAMPLE_SAMPLEID: "SET_SAMPLE_SAMPLEID",
  SAVE_BUNDLE_LIST: "SAVE_BUNDLE_LIST",
  SET_SAVE_RESULT: "SET_SAVE_RESULT",
  SET_VALIDATE_RESULT: "SET_VALIDATE_RESULT",
  GET_BUNDLE_TREE_DATA: "GET_BUNDLE_TREE_DATA",
  SET_BUNDLE_TREE_DATA: "SET_BUNDLE_TREE_DATA",
  SET_PARENT_SAMPLE_INFO: "SET_PARENT_SAMPLE_INFO",
  SET_EXPAND_NODE_ID: "SET_EXPAND_NODE_ID",
  GET_BEFORE_VALIDATION: "GET_BEFORE_VALIDATION",
  SET_BEFORE_VALIDATION: "SET_BEFORE_VALIDATION",
  SET_EMPTY_BUNDLE_TREE: "SET_EMPTY_BUNDLE_TREE",
  ASSIGN_INSPECTOR_FOR_SAMPLE: "ASSIGN_INSPECTOR_FOR_SAMPLE",
  SET_ASSIGN_INSPECTOR_STATUS: "SET_ASSIGN_INSPECTOR_STATUS",
  GET_AFTER_VALIDATION_REPORT: "GET_AFTER_VALIDATION_REPORT",
  SET_AFTER_VALIDATION_REPORT: "SET_AFTER_VALIDATION_REPORT",
  GET_AFTER_VALIDATION_REPORT_EXCEL: "GET_AFTER_VALIDATION_REPORT_EXCEL",
};

export const actions = {
  setValidateResult: (payload) => ({
    type: actionTypes.SET_VALIDATE_RESULT,
    payload: payload,
  }),

  getSampleBySampleId: (payload) => ({
    type: actionTypes.GET_SAMPLE_SAMPLEID,
    payload: payload,
  }),

  setSampleBySampleId: (payload) => ({
    type: actionTypes.SET_SAMPLE_SAMPLEID,
    payload: payload,
  }),

  setValidateStatus: (payload) => ({
    type: actionTypes.VALIDATE_SAMPLE_STATUS,
    payload: payload,
  }),

  setAuditReportSearch: (payload) => ({
    type: actionTypes.SET_AUDIT_REPORT_SEARCH,
    payload: payload,
  }),

  setAuditReportTotalCount: (payload) => ({
    type: actionTypes.SET_AUDIT_REPORT_COUNT,
    payload: payload,
  }),

  setSampleEdit: (payload) => ({
    type: actionTypes.SET_SAMPLE_EDIT,
    payload: payload,
  }),

  setSampleStatus: (payload) => ({
    type: actionTypes.SET_SAMPLE_STATUS,
    payload: payload,
  }),

  setAuditType: (payload) => ({
    type: actionTypes.SET_AUDIT_TYPE,
    payload: payload,
  }),
  setAuditSubTypeForGrid: (payload) => ({
    type: actionTypes.SET_AuditSubtype_ForGrid,
    payload: payload,
  }),

  setAuditReportType: (payload) => ({
    type: actionTypes.SET_AUDIT_REPORT_TYPE,
    payload: payload,
  }),

  getPeriodDropDown: (payload) => ({
    type: actionTypes.GET_PERIOD,
    payload: payload,
  }),
  setPeriodDropDown: (payload) => ({
    type: actionTypes.SET_PERIOD,
    payload: payload,
  }),

  getMarketedBrandByMarket: (payload) => ({
    type: actionTypes.GET_MARKET_BRAND,
    payload: payload,
  }),
  setMarketedBrandByMarket: (payload) => ({
    type: actionTypes.SET_MARKET_BRAND,
    payload: payload,
  }),

  getMarketList: (payload) => ({
    type: actionTypes.GET_MARKET_LIST,
    payload: payload,
  }),
  setMarketList: (payload) => ({
    type: actionTypes.SET_MARKET_LIST,
    payload: payload,
  }),

  getAuditReport: (payload) => ({
    type: actionTypes.GET_AUDIT_REPORT,
    payload: payload,
  }),
  setAuditReport: (payload) => ({
    type: actionTypes.SET_AUDIT_REPORT,
    payload: payload,
  }),

  getAuditSubType: (payload) => ({
    type: actionTypes.GET_AUDIT_SUBTYPE,
    payload: payload,
  }),
  setAuditSubType: (payload) => ({
    type: actionTypes.SET_AUDIT_SUBTYPE,
    payload: payload,
  }),

  getBundleList: (payload) => ({
    type: actionTypes.GET_BUNDLE_LIST,
    payload: payload,
  }),

  setBundleList: (payload) => ({
    type: actionTypes.SET_BUNDLE_LIST,
    payload: payload,
  }),

  saveBundleList: (payload) => ({
    type: actionTypes.SAVE_BUNDLE_LIST,
    payload: payload,
  }),

  setSaveResult: (payload) => ({
    type: actionTypes.SET_SAVE_RESULT,
    payload: payload,
  }),

  getBundleTreeData: (payload) => ({
    type: actionTypes.GET_BUNDLE_TREE_DATA,
    payload: payload,
  }),

  setBundleTreeData: (payload) => ({
    type: actionTypes.SET_BUNDLE_TREE_DATA,
    payload: payload,
  }),
  setEmptyBundleTree: () => ({
    type: actionTypes.SET_EMPTY_BUNDLE_TREE
  }),

  setParentSampleInfo: (payload) => ({
    type: actionTypes.SET_PARENT_SAMPLE_INFO,
    payload,
  }),

  setExpandNodeId: (payload) => ({
    type: actionTypes.SET_EXPAND_NODE_ID,
    payload,
  }),
  getBeforeValidationReport: (payload) => ({
    type: actionTypes.GET_BEFORE_VALIDATION,
    payload,
  }),
  setBeforeValidationReport: (payload) => ({
    type: actionTypes.SET_BEFORE_VALIDATION,
    payload,
  }),
  assignInspector: (payload) => ({
    type: actionTypes.ASSIGN_INSPECTOR_FOR_SAMPLE,
    payload,
  }),
  setAssignInspectorStatus: (payload) => ({
    type: actionTypes.SET_ASSIGN_INSPECTOR_STATUS,
    payload,
  }),
  getAfterValidationReport: (payload) => ({
    type: actionTypes.GET_AFTER_VALIDATION_REPORT,
    payload,
  }),
  setAfterValidationReport: (payload) => ({
    type: actionTypes.SET_AFTER_VALIDATION_REPORT,
    payload,
  }),
  getAfterValidationReportExcel: (payload) => ({
    type: actionTypes.GET_AFTER_VALIDATION_REPORT_EXCEL,
    payload,
  }),
};
