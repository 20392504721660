/*eslint-disable */

import { API } from "api/config";

//  API

export const getProductionUnitAPI = (payload) => {
  return API.get("/adminService/api/ProductionUnit/getProductionUnit", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getEditProductionUnitAPI = (payload) => {
  return API.get("/adminService/api/ProductionUnit/getEditProductionUnit", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getProductionCenterListAPI = () => {
  return API.get("/adminService/api/MasterData/getProductionCenterList")
    .then((res) => res)
    .catch((error) => error);
};

export const getMachineListsAPI = () => {
  return API.get("/adminService/api/MasterData/getMachineList")
    .then((res) => res)
    .catch((error) => error);
};

//save
export const addProductionUnitAPI = (payload) => {
  console.log("Save Payload", payload);
  return API.post("/adminService/api/ProductionUnit/addProductionUnit", payload)
    .then((res) => res)
    .catch((error) => error);
};

//edit
export const saveProductionUnitAPI = (payload) => {
  console.log("Edit Payload", payload);
  return API.put("/adminService/api/ProductionUnit/saveProductionUnit", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const disableProductionUnitAPI = (payload) => {
  return API.put(
    "/adminService/api/ProductionUnit/disableProductionUnit?id=" + payload
  )
    .then((res) => res)
    .catch((error) => error);
};
