import { actionTypes } from "./actions";

const initialState = {
  productionCenterData: [],
  productionCenterEdit: {
    productionCenter: "",
    productionCenterDesc: "",
    laboratoryCode: [],
    nbCases: false,
    preliminaryChecklist: false,
  },
  laboratoryOptions: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PRODUCTION_CENTERS:
      return {
        ...state,
        productionCenterData: payload,
      };

    case actionTypes.SET_LABOATORY_OPTIONS:
      return {
        ...state,
        laboratoryOptions: payload,
      };

    case actionTypes.SET_PRODUCTION_CENTER_BY_ID:
      return {
        ...state,
        productionCenterEdit: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
