/*eslint-disable */

import { API } from "api/config";

//  API

export const getFindInDictionaryLevelByIdAPI = (payload) => {
    return API.get("/adminService/api/FindInDictionary/getFindInDictionaryLevelById", {
      params: payload,
    })
      .then((res) => res)
      .catch((error) => error);
  };

export const savePreliminaryChecklistStepAPI = (payload) => {
  return API.put(
    "",
    JSON.stringify(payload)
  )
    .then((res) => res)
    .catch((error) => error);
};

export const submitTranslationAPI = (payload) => {
  return API.post(
    "/adminService/api/FindInDictionary/submitTranslation",
    JSON.stringify(payload)
  ).then((res) => res);
};

export const getFindInDictionaryDefectByIdAPI = (payload) => {
  return API.get("/adminService/api/FindInDictionary/getFindInDictionaryDefectById", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

// export const ApproveOrRejectFindInDictionaryDefectByIdAPI = (payload) => {
//   return API.put(
//     "/adminService/api/FindInDictionary/ApproveOrRejectFindInDictionaryDefectById",
//     JSON.stringify(payload)
//   ).then((res) => res);
// };

export const ApproveOrRejectFindInDictionaryDefectByIdAPI = (payload) => {
  return API.put("/adminService/api/FindInDictionary/ApproveOrRejectFindInDictionaryDefectById",payload)
    .then((res) => res)
    .catch((error) => error);
};

