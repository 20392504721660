import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { API } from "api/config";

// routing
import Routes from "./routes";
import axios from "axios";

// defaultTheme
import themes from "./themes";

// project imports
import NavigationScroll from "components/NavgationScroll/NavigationScroll";
//import { getCookie } from "utils";
import { useDispatch } from "react-redux";
import { actions } from "store/actions/common";
import useSessionStorage from "sessionManager/sesionManager";
import { getLocalStorageOrDefault } from "sessionManager/localSesionManager";
// ===========================|| APP ||=========================== //

const App = () => {
  const state = useSelector((state) => state.customization);
  const dispatch = useDispatch();
  const [isAuthorized, setIsAuthorized] = useSessionStorage(
    "isAuthorized",
    false
  );

  useEffect(() => {
    //const userInfo = getCookie("userInfo");
    const userInfo = getLocalStorageOrDefault("userInfo",null);
    if (userInfo) {
      dispatch(actions.setUserInfo(JSON.parse(userInfo)));
    } else {
      setIsAuthorized(false);
    }
  }, []);

  useEffect(() => {
    if (!state?.userRoleInfo) {
      //const token = getCookie("token");
      const token = getLocalStorageOrDefault("token",null);

      if (isAuthorized && token) {
        const userRoleInfo = sessionStorage.getItem("userRoleInfo")
          ? JSON.parse(sessionStorage.getItem("userRoleInfo"))
          : null;
        const userMenus = sessionStorage.getItem("IMDLMenus")
          ? JSON.parse(sessionStorage.getItem("IMDLMenus"))
          : null;
        const userMembershipRoleInfo = sessionStorage.getItem(
          "userMembershipRoleInfo"
        )
          ? JSON.parse(sessionStorage.getItem("userMembershipRoleInfo"))
          : null;
        dispatch(actions.setAdminRoleInfo(userRoleInfo));
        dispatch(actions.setIMDLMenus(userMenus));
        dispatch(actions.setUserMembershipRoles(userMembershipRoleInfo));
        if (userMembershipRoleInfo != null) {
          const UserRoleAccess = {
            IsCentralAdmin: userMembershipRoleInfo.some((x) =>
              x
                .toUpperCase()
                .includes("CVQA Central Administrator".toUpperCase())
            ),
            IsAdministratorRRP: false,
            IsMvqaAdminConventional: false,
            IsMvqaConventional: false,
            IsFactoryAdminConventional: false,
            IsFactoryConventional: false,
            IsGcrsAdminConventional: false,
            IsTranslatorConventional: false,
            IsViewerConventional: false,
            IsMvqaAdminRRP: false,
            IsMvqaRRP: false,
            IsFactoryAdminRRP: false,
            IsFactoryRRP: false,
            IsGcrsAdminRRP: false,
            IsTranslatorRRP: false,
            IsViewerRRP: false,
            IsDeviceRRPInspector: userMembershipRoleInfo.some((x) =>
              x
                .toUpperCase()
                .includes("CVQA Device RRP Inspector".toUpperCase())
            ),
            IsDeviceRRPSupervisor: userMembershipRoleInfo.some((x) =>
              x
                .toUpperCase()
                .includes("CVQA Device RRP Supervisor".toUpperCase())
            ),
          };
          dispatch(actions.setUserRoleAccess(UserRoleAccess));
        }
      }
    }
  }, [state?.userRoleInfo?.userRole]);

  // needs to be checked later why actions are not dispatched here
  useEffect(async () => {
    try {
      // if (!state?.menus) {
      const payload = {
        userName: state?.userInfo?.principalName,
        userId: state?.userRoleInfo?.id,
      };
      if (payload.userName && payload.userId) {
        dispatch(actions.setMenuLoading(true));

        const _api = await API.get(
          "/adminService/api/AccessManagement/getPageAccess",
          {
            params: payload,
          }
        );
        dispatch(actions.setMenuLoading(false));
        dispatch(actions.setIMDLMenus(_api.data.data));
      }
      // }
    } catch (error) {
      console.log(error);
      dispatch(actions.setIMDLMenus(null));
      dispatch(actions.setMenuLoading(false));
    }
  }, [state?.userRoleInfo?.id, state?.userInfo?.principalName]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(state)}>
        <CssBaseline />
        <NavigationScroll>
          <Routes commonState={state} />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
