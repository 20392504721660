import { actionTypes } from "./actions";

const initialState = {
  dailyHistory: [],
  weeklyHistory: [],
  monthlyHistory: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_DAILY_LOGIN_HISTORY:
      return {
        ...state,
        dailyHistory: payload,
      };

    case actionTypes.SET_WEEKLY_LOGIN_HISTORY:
      return {
        ...state,
        weeklyHistory: payload,
      };

    case actionTypes.SET_MONTHLY_LOGIN_HISTORY:
      return {
        ...state,
        monthlyHistory: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
