/*eslint-disable */

import { API } from "api/config";
import axios from "axios";

//  API

// export const getAuditTypeAPI = (payload) => {
//   return API.get("/adminService/api/AuditHome/getAuditType",{
//     params: payload,
//   })
//     .then((res) => res)
//     .catch((error) => error);
// };

export const getCodentifyDataAPI = (payload) => {
  return API.post(
    "/adminService/api/ProductionCenter/GetProductInfoFromCodentify",
    payload
  ).then((res) => res);
};

export const getAuditDropdownsAPI = (payload) => {
  console.log("Edit call payload", payload);
  return API.get("/adminService/api/AuditHome/getAuditDropdowns", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getProductVariantAPI = (payload) => {
  //return API.get("/adminService/api/AuditHome/getProductVariant")
  return API.get("/adminService/api/AuditHome/getProductVariant", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

//save
export const saveLogNewDeviceBatchAPI = (payload) => {
  console.log("Save Payload", payload);
  return API.post("/adminService/api/AuditHome/saveLogNewDeviceBatch", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const getInspectorListApi = (payload) => {
  return API.get("/adminService/api/AuditHome/getAuditandInspector", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getBundleListApi = (payload) => {
  return API.get(
    "/adminService/api/InspectionChecklist/getAssignedBatchSampleList",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const saveBundleListApi = (payload, params) => {
  return API.post(
    "/adminService/api/InspectionChecklist/SaveAssignedBatchSampleList",
    payload,
    {
      params: params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getSampleBatchDetailsAPI = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/getSampleDataFromSampleId",
    {
      params,
    }
  ).then((res) => res);
};

export const savePopupSamplesApi = (payload) => {
  return API.post(
    "/adminService/api/SamplesListHandler/submitPopupSamples",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const finishInspectionApi = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/CheckBatchValidityToFinish",
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getBatchSampleBySampleIdAPI = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/getSampleNCDataFromSampleId",
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const saveSampleInspectionSummaryAPI = ({
  batchSummaryPayload,
  userId,
}) => {
  return API.post(
    `/adminService/api/SamplesListHandler/SaveBatchAssignedBundlesList?userId=${
      userId || ""
    }`,
    [...batchSummaryPayload]
  ).then((res) => res);
};

export const getBatchSampleAvailableDetailsApi = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/getSampleBatchAvailability",
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getMaterialCodeApi = (params) => {
  return API.get("/adminService/api/SamplesListHandler/getMaterialCodes", {
    params,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getValidationSampleListApi = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/GetValidationSummaryBatchList",
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getValidateBatchDetailsApi = (payload, params) => {
  return API.post(
    "/adminService/api/SamplesListHandler/UpdateBatchValidate",
    payload,
    {
      params: params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};
export const getSampleCodentifyCodeAPI = (params) => {
  return axios
    .get(`${process.env.REACT_APP_GET_CODENTIFY_DETAILS}`, {
      params,
    })
    .then((res) => res)
    .catch((error) => error);
};

export const getValidatedSamplesBySampleNoAPI = (params) => {
  return API.get(
    "/adminService/api/SamplesListHandler/getBatchReportBySampleNo",
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getAuditSubTypeListAPI = (payload) => {
  return API.get("/adminService/api/AuditHome/getAuditSubType", {
    params: payload,
  }).then((res) => res);
};

export const checkCodentifyAvailabilityApi = (payload) => {
  return API.get("/adminService/api/AuditHome/checkCodentifyForBatch", {
    params: payload,
  }).then((res) => res);
};

export const getSampleExistCountAPI = (params) => {
  return API.get("/adminService/api/SamplesListHandler/getSampleExists", {
    params,
  }).then((res) => res);
};
