import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getTranslationGridAPI,
  getTranslationGridbyIdAPI,
  updateTranslationAPI,
  getTranslationDictionaryTypesAPI,
  downloadTranslationTemplateAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { downloadFile } from "utils";

function* getTranslationSaga(action) {
  try {
    //yield put(commonActions.setLoading(true));
    const _api = yield call(getTranslationGridAPI, action.payload);
    yield put(actions.setWTranslation(_api.data.data));

    yield put(commonActions.setLoading(false));
    yield put(actions.setTranslationCount(_api.data.totalCount));
  } catch (error) {
    console.log(error);
  }
}

function* getTranslationEditSaga(action) {
  try {
    const _api = yield call(getTranslationGridbyIdAPI, action.payload);
    yield put(actions.setTranslationEditCell(action.payload));
    yield put(actions.setTranslationEdit(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* updateTranslationUpdateSaga(action) {
  try {
    const _api = yield call(updateTranslationAPI, action.payload);
    if (_api.data.status === 200) {
      yield put(actions.setTranslationUpdated(true));
    }
    //yield put(actions.setUpdateTranslation(_api.data.data));
    yield put(commonActions.successAlert(_api.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* getTranslationDictionaryTypesSaga(action) {
  try {
    const _api = yield call(getTranslationDictionaryTypesAPI, action.payload);
    yield put(actions.setTranslationDictionaryTypes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* downloadTranslationTemplateSaga(action) {
  try {
    const _api = yield call(downloadTranslationTemplateAPI, action.payload);
    let dicType = action.payload.dictionaryType;
    let downloadFileName = "";
    const fileNameMap = {
      "C": "TranslationTemplateCentral.rdl.xlsx",
      "L": "TranslationTemplateCentralLowHigh.rdl.xlsx",
      "N": "TranslationTemplateNormal.rdl.xlsx"
    };

    if (dicType in fileNameMap) {
      downloadFileName = fileNameMap[dicType];
    }

    // provide fileName, fileType, data
    downloadFile(
      downloadFileName,
      "application/vnd.ms-excel",
      _api.data.data
    );

    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(
      commonActions.errorAlert(
        error.response.data.error.errorMessage
      )
    );
    yield put(commonActions.setLoading(false));
  }
}

export function* watchTranslationSaga() {
  yield all([
    takeEvery(actionTypes.GET_W_Translation, getTranslationSaga),
    takeEvery(actionTypes.GET_Translation_Edit, getTranslationEditSaga),
    takeEvery(actionTypes.UpdateTranslation, updateTranslationUpdateSaga),
    takeEvery(
      actionTypes.GET_TRANSLATION_DICTIONARY_TYPES,
      getTranslationDictionaryTypesSaga
    ),

    takeEvery(
      actionTypes.DOWNLOAD_TRANSLATION_TEMPLATE,
      downloadTranslationTemplateSaga
    ),
  ]);
}
