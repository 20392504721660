import { actionTypes } from "./actions";

const initialState = {
  inspectionChecklistData: [],
  deviceChecklistInfo: {
    name: "",
    description: "",
    productList: [],
  },
  productCheckListData: {
    title: "",
    productCode: "",
    productCategory: "",
    categorys: [],
  },
  productCategoryCheckListEdit: {
    id: 0,
    idProductInspectionChecklist: 0,
    categoryDescription: "",
    category: "",
  },
  NCCodeData: {
    categoryDescription: "",
    category: "",
    ncCodes: [],
  },
  NCCodeEdit: {
    id: 1,
    idProductInspectionChecklist: 1,
    ncCode: "",
    description: "",
    values: null,
    productCategoryId: "",
  },
};

const SortGridData = (payload) => {
  if (payload.categorys !== undefined) {
    payload.categorys.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      }
      if (a.id > b.id) {
        return -1;
      }
      return 0;
    });
  }
  return payload;
}
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_INSPECTION_CHECKLIST:
      return {
        ...state,
        inspectionChecklistData: payload,
      };

    case actionTypes.SET_DEVICE_CHECKLIST_INFO:
      return {
        ...state,
        deviceChecklistInfo: payload,
      };
    case actionTypes.SET_PRODUCT_CHECKLIST_INFO:
      return {
        ...state,
        productCheckListData: SortGridData(payload),
      };
    case actionTypes.EDIT_PRODUCT_CATEGORY_CHECKLIST_INFO:
      return {
        ...state,
        productCategoryCheckListEdit: {
          ...state.productCheckListEdit,
          ...payload,
        },
      };
    case actionTypes.ADD_PRODUCT_CATEGORY_CHECKLIST_INFO:
      return {
        ...state,
        productCategoryCheckListEdit: {
          ...state.productCheckListEdit,
          ...payload,
        },
      };
    case actionTypes.SET_INSPECTION_NCCode:
      return {
        ...state,
        NCCodeData: payload,
      };

    case actionTypes.EDIT_INSPECTION_NCCode:
      return {
        ...state,
        NCCodeEdit: { ...state.NCCodeEdit, ...payload },
      };

    case actionTypes.ADD_INSPECTION_NCCode:
      return {
        ...state,
        NCCodeEdit: { ...state.NCCodeEdit, ...payload },
      };

    default:
      return {
        ...state,
      };
  }
};
