import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getSearchBySampleIdAPI,
  getSamplesToInspectionAPI,
  getSamplesToValidateAPI
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getSearchBySampleIDSaga(action) {
  try {
    const _api = yield call(getSearchBySampleIdAPI, action.payload);    
    yield put(actions.setSearchBySampleID(_api.data.data));
    yield put(actions.setSearchBySampleIDGridCount(_api.data.data.totalCount));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

// Unused code

// function* getTranslationEditSaga(action) {
//   try {
//     console.log("Translation saga");
//     //const _api = yield call(getTranslationGridbyIdAPI, action.payload);
//     yield put(actions.setTranslationEditCell(action.payload));
//     //yield put(actions.setTranslationEdit(_api.data.data));
//     yield put(commonActions.setLoading(false));
//   } catch (error) {
//     console.log(error);
//   }
// }
function* getSamplesToInspectionSaga(action) { 
  try {
    yield put(actions.setInspectLoader(true));
    const _api = yield call(getSamplesToInspectionAPI, action.payload);
    
    yield put(actions.setSamplesToInspectionList(_api.data.data));
    yield put(actions.setInspectLoader(false));
    yield put(actions.setSamplesToInspectionTotalCount(_api.data.totalCount));
  } catch (error) {
    console.log("Sample to Inspect Error",error);
  }
}
function* getSamplesToValidateSaga(action) { 
  try {
    yield put(actions.setValidateLoader(true));
    const _api = yield call(getSamplesToValidateAPI, action.payload);
    yield put(actions.setSamplesToValidateList(_api.data.data)); 
    yield put(actions.setValidateLoader(false));
    yield put(actions.setSamplesToValidateTotalCount(_api.data.totalCount));
  } catch (error) {
    console.log(error);
  }
}

export function* watchSearchSaga() {
  yield all([takeEvery(actionTypes.GET_SearchBySampleID, getSearchBySampleIDSaga)]);
  yield all([takeEvery(actionTypes.GET_SAMPLE_TO_INSPECTION, getSamplesToInspectionSaga)]);
  yield all([takeEvery(actionTypes.GET_SAMPLE_TO_VALIDATE, getSamplesToValidateSaga)]); 
}
