import { actionTypes } from "./actions";

const initialState = {
  searchDropdown:{
  auditType:[],
  inspectors:[],
  productionCenter:[],
  },
searchStatus:[],
productVariant:[],
productionUnit:[],
advancedSearch:[],
advancedSearchCondition:{},
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      
      case actionTypes.SET_Advanced_Search_Condition:
        return {
          ...state,
          advancedSearchCondition: payload,
        };

      case actionTypes.SET_Advanced_Search:
        return {
          ...state,
          advancedSearch: payload,
        };

      case actionTypes.SET_Search_Dropdowns:
        return {
          ...state,
          searchDropdown: payload,
        };

        case actionTypes.SET_Search_Status:
          return {
            ...state,
            searchStatus: payload,
          };
          case actionTypes.SET_Production_Unit:
          return {
            ...state,
            productionUnit: payload,
          };

          case actionTypes.SET_Product_Variant:
          return {
            ...state,
            productVariant: payload,
          };
  
      default:
        return {
          ...state,
        };
    }
  };
  