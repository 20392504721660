import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { 
  getProductionUnitAPI, 
  getEditProductionUnitAPI, 
  saveProductionUnitAPI, 
  addProductionUnitAPI,
  disableProductionUnitAPI,
  getProductionCenterListAPI,
  getMachineListsAPI, 
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getProductionUnitSaga(action){
  try{
    const _api = yield call(getProductionUnitAPI, action.payload);
    console.log("Get ProductionUnit Data",  _api.data.data);
    yield put(actions.setProductionUnit(_api.data.data));
    yield put(commonActions.setLoading(false));
    
    yield put(actions.setProductionUnitCount(_api.data.totalCount));
  } catch (error) {
    console.log(error);
  }
}

function* getProductionCenterListSaga(action){
  try{
    const _api = yield call(getProductionCenterListAPI, action.payload);
    yield put(actions.setProductionCenter(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getMachineListsSaga(action){
  try{
    const _api = yield call(getMachineListsAPI, action.payload);
    yield put(actions.setMachineList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* editProductionUnitSaga(action) {
  try {
    
    const _api = yield call(getEditProductionUnitAPI, action.payload);
    
    yield put(actions.setEditProductionUnit(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

//edit
function* saveProductionUnitSaga(action){
  try{
    const _api = yield call(saveProductionUnitAPI, action.payload);
    //yield put(actions.(_api.data.data));
    yield put(actions.setProductionUnitSaveSuccess(true));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  }catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

//save
function* addProductionUnitSaga(action){
  try{
    const _api = yield call(addProductionUnitAPI, action.payload);
    //yield put(actions.(_api.data.data));
    yield put(actions.setProductionUnitSaveSuccess(true));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  }catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* disableProductionUnitSaga(action){
  try{
    const _api = yield call(disableProductionUnitAPI, action.payload.rorId);
    yield put(commonActions.setLoading(false));
    const params = {
      Disable : 0,
      UserLogin : action.payload.userLogin,
  };
    yield put(actions.getProductionUnit(params));
  }catch(error){
    console.log(error);
  }
}

export function* watchProductionUnitListSaga() {
  yield all([
    takeEvery(actionTypes.GET_PRODUCTION_UNIT, getProductionUnitSaga),
    takeEvery(actionTypes.SAVE_PRODUCTION_UNIT, saveProductionUnitSaga),
    takeEvery(actionTypes.ADD_PRODUCTION_UNIT, addProductionUnitSaga),

    takeEvery(actionTypes.DISABLE_PRODUCTION_UNIT,disableProductionUnitSaga),
    //takeEvery(actionTypes.GET_EDIT_PRODUCTION_UNIT, editProductionUnitSaga),
    takeEvery(
      actionTypes.GET_EDIT_PRODUCTION_UNIT,
      editProductionUnitSaga
    ),
    takeEvery(actionTypes.GET_PRODUCTION_CENTER, getProductionCenterListSaga),
    takeEvery(actionTypes.GET_MACHINE_LISTS, getMachineListsSaga),
  ]);
}
