import { API } from "api/config";

export const validateTranslationApi = (payload) => {
  const config = {    
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post("/adminService/api/Translation/validateUploadTranslation", payload, config)
    .then((res) => res)
};

export const uploadTranslationApi = (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post("/adminService/api/Translation/uploadTranslation", payload, config)
    .then((res) => res)
};
