export const actionTypes = {
    GET_Dictionary_Product: "GET_Dictionary_Product",
    SET_Dictionary_Product: "SET_Dictionary_Product",
    GET_Dictionary_Product_Data: "GET_Dictionary_Product_Data",
    SET_Dictionary_Product_Data: "SET_Dictionary_Product_Data",
    ADD_Dictionary_Product: "ADD_Dictionary_Product",
    UPDATE_Dictionary_Product: "UPDATE_Dictionary_Product",
    UPDATE_Dictionary_Object_Status: "UPDATE_Dictionary_Object_Status",
    GET_Dictionary_Hierarchy: "GET_Dictionary_Hierarchy",
    SET_Dictionary_Hierarchy: "SET_Dictionary_Hierarchy",
  };
  
  export const actions = {

    getDictionaryHierarchy: (payload) => ({
      type: actionTypes.GET_Dictionary_Hierarchy,
      payload,
    }),
  
    setDictionaryHierarchy: (payload) => ({
      type: actionTypes.SET_Dictionary_Hierarchy,
      payload,
    }),

    getDictionaryProduct: (payload) => ({
      type: actionTypes.GET_Dictionary_Product,
      payload,
    }),
  
    setDictionaryProduct: (payload) => ({
      type: actionTypes.SET_Dictionary_Product,
      payload,
    }),

    getDictionaryProductData: (payload) => ({
      type: actionTypes.GET_Dictionary_Product_Data,
      payload,
    }),

    setDictionaryProductData: (payload) => ({
      type: actionTypes.SET_Dictionary_Product_Data,
      payload,
    }),

    addtDictionaryProduct: (payload) => ({
        type: actionTypes.ADD_Dictionary_Product,
        payload,
      }),

    updateDictionaryProduct: (payload) => ({
        type: actionTypes.UPDATE_Dictionary_Product,
        payload,
      }),

      updateDictionaryObjectStatus: (payload) => ({
        type: actionTypes.UPDATE_Dictionary_Object_Status,
        payload,
      }),
  };
  