import { actionTypes } from "./actions";

const initialState = {
  isUpload: false,
  message: [],
  isNcUploadFailure: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_TEMPLATE_UPLOAD:
      return {
        ...state,
        ...payload,
      };

    case actionTypes.SET_TEMPLATE_UPLOAD_FAILURE:
      return {
        ...state,
        isNcUploadFailure: payload,
      };

    case actionTypes.SET_IS_UPLOAD:
      return {
        ...state,
        isUpload: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
