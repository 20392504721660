import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import {
  Grid,
  Button,
  CardContent,
  FormControl,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
//import Form from "components/Form";
//import { Formik, Field, Form } from 'formik';
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector, useDispatch } from "react-redux";
import { actions as commonActions } from "store/actions/common";
import { actions } from "../../Dashboard/DashboardDetails/state/actions";
import { actions as auditActions } from "../state/actions";
import { actions as searchActions } from "containers/Dashboard/MySamples/state/actions";
import { actions as inspectionAction } from "../SampleDetails/SampleInspection/state/actions";
import MainCard from "components/MainCard/MainCard";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

// const validationSchema = yup.object({
//   ncCode: yup.string().required("The NC Code field is required"),
//   description: yup.string().required("The Description field is required"),
// });
const initialSearchState = {
  //   SearchCode: "",
  //   SearchType: "",
  //   active: "",
  SearchId: "",
  AuditType: "ROUTINE",
  AuditSubType: "ROUTINE_CIG",
  Status: "0",
  Inspector: "Me",
  ProductionCenter: "00",
  ProductCode: "",
  ProductionUnit: "",
  PO: "",
  Batch: "",
};

const controlsDisplayonAuditType = [
  {
    auditType: "ROUTINE",
    auditSubType: ["ROUTINE_RRP-P1DEVICE"],
    type: "typeOne",
  },
  { auditType: "TPM", type: "typeOne" },
  {
    auditType: "LOCAL",
    auditSubType: ["LOCAL_RRP-P1", "LOCAL_RRP-P1_MIX"],
    type: "typeOne",
  },
  {
    auditType: "LOCAL",
    auditSubType: [ 'LOCAL_MAR'],
    type: "typeTwo",
  },
  
  { auditType: "DEV", type: "typeOne" },
  { auditType: "BENCHMARK", type: "typeOne" },
  { auditType: "MARKET",  auditSubType: [ 'MARKET_CIG'], type: "typeTwo" },
  { auditType: "ICT", type: "typeThree" },
];
const pagewiseStatus = [
  {
    pageType: "INSPECTION",
    status: [
      { id: "0", value: "All" },
      { id: "Pending", value: "Pending" },
      { id: "InProgress", value: "InProgress" },
      { id: "Finished", value: "Finished" },
    ],
  },
  { pageType: "VALIDATION", status: [{ id: "Finished", value: "Finished" }] },
  { pageType: "REPORT", status: [{ id: "Validated", value: "Validated" }] },
];

// {
//      typeOne :["DEV","ROUTINE","TPM","LOCAL","BENCHMARK"],
//      typeTwo :["MARKET"],
//      typeThree :["ICT"]
// };
export default function AuditFilterForm({ sourcePage, parentCallback }) {
  const [SearchFields, setSearchFields] = useState(initialSearchState);
  const [auditType, setAuditType] = useState("");
  const [auditSubType, setAuditSubType] = useState("");
  const [prodCenter, setProdCenter] = useState("All");
  const [status, setStatus] = useState("Validated"); //Reports Page default status
  const [inspector, setInspector] = useState("Me");
  const [prodCode, setProductCode] = useState("All");
  const [market, setMarket] = useState("");
  const [marketedBrand, setMarketedBrand] = useState("");
  const [period, setPeriod] = useState("");
  const [periodFrom, setPeriodFrom] = useState("");
  const [periodTo, setPeriodTo] = useState("");
  const [periodDateFrom, setPeriodDateFrom] = useState(null);
  const [periodDateTo, setPeriodDateTo] = useState(null);
  const [prodUnit, setProdUnit] = useState("All");
  const [type, setType] = useState("typeOne");
  const [SampleID, setSampleID] = useState("");
  const Input = styled("input")({
    display: "none",
  });
  const [auditTypeVal, setAuditTypeVal] = useState("");
  const [auditSubTypeVal, setAuditSubTypeVal] = useState("");
  const [statusVal, setStatusVal] = useState("");
  const commonState = useSelector((state) => state.customization);
  const [fromDateValue, setFromDateValue] = useState(null);
  const [dateOfAnlysis, setDateOfAnalysis] = useState(null);
  const [toDateValue, setToDateValue] = useState(null);
  const [releaseDateFrom, setReleaseDateFrom] = useState(null);
  const [releaseDateTo, setReleaseDateTo] = useState(null);
  const [addmissionFromDateValue, setAdmissionFromDateValue] = useState(null);
  const [addmissionToDateValue, setAdmissionToDateValue] = useState(null);
  const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1];
  //const userRoleInfo = commonState?.userMembershipRoles;

  const state = useSelector((state) => state.advanceSearch);
  const auditState = useSelector((state) => state.auditReport);
  const [sortedStatus, setSortedStatus] = useState([]);
  const [sortedInspector, setSortedInspector] = useState([]);
  const [sortedProdCenter, setSortedProdCenter] = useState([]);
  const [sortedProdUnit, setSortedProdUnit] = useState([]);
  const [disable, setDisable] = useState(true);
  const [pageType, setPageType] = useState(sourcePage);
  const [userRoleAccess, setuserRoleAccess] = useState(commonState?.userRoleAccess);
  const [IsDeviceBatchRole, setIsDeviceBatchRole] = useState(false);
  const navigate = useNavigate();
  const userMembershipRoles = JSON.parse(sessionStorage.getItem("userMembershipRoleInfo"));

  // Added new logic for audit sub-type to fix SIT defect
  const alloweduserBatchRoles = (roles) =>
    roles?.map((role) =>
      role?.split(" ")?.join("")?.toLowerCase()
    ).join(",");

  const isUserAdmin =
    alloweduserBatchRoles(userMembershipRoles)?.includes("cvqacentraladministrator");

  const isUserInspectorOrSupervisor =
    alloweduserBatchRoles(userMembershipRoles)?.includes(
      "cvqadevicerrpinspector"
    ) ||
    alloweduserBatchRoles(userMembershipRoles)?.includes(
      "cvqadevicerrpsupervisor"
    );

  const formik = useFormik({
    initialValues: SearchFields,
    enableReinitialize: true,
    //validationSchema,
    onSubmit: (values) => {
      dispatch(auditActions.setAuditReport([])); // Clear grid data before every API call  
      callDispatch();
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("User Role Access", userRoleAccess);

  }, [userRoleAccess]);
  useEffect(() => {
    //setPageType(sourcePage == "INSPECTION" ? "INSPECT" : sourcePage) //bundle values are not populating
    setPageType(sourcePage)
  }, [sourcePage]);

  const callDispatch = (values) => {
    if (sourcePage === "ADVSEARCH") {
      parentCallback();
    }
    //if (formik.values.SearchId == "") {
    // if (type == "typeOne") {
    //   //console.log("typeOne", SearchFields);
    //   const callParam = {
    //     SampleType: SearchFields?.AuditType,
    //     SampleSubType: SearchFields?.AuditSubType,
    //     SampleStatus: statusVal === "0" ? "All" : statusVal,
    //     Inspector: inspector,
    //     ProductionCenter: prodCenter,
    //     ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
    //     ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
    //     ProductCode: prodCenter === "00" ? "All"
    //       : SearchFields?.ProductCode === "0"
    //         ? "All"
    //         : SearchFields?.ProductCode,
    //     ProductionUnit: prodCenter === "00" ? "All"
    //       : SearchFields?.ProductionUnit === "0"
    //         ? "All"
    //         : SearchFields?.ProductionUnit,
    //     UserLogin: login,
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   //console.log("typeOne", callParam);
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }
    // if (type == "typeTwo") {
    //   //console.log("typeTwo", SearchFields);
    //   const callParam = {
    //     SampleType: SearchFields?.AuditType,
    //     SampleSubType: SearchFields?.AuditSubType,
    //     SampleStatus: statusVal === "0" ? "All" : statusVal,
    //     Inspector: inspector,
    //     ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
    //     ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
    //     PeriodDateFrom: periodDateFrom ? moment(periodDateFrom).format("YYYY-MM-DD") : "",
    //     PeriodDateTo: periodDateTo ? moment(periodDateTo).format("YYYY-MM-DD") : "",
    //     AdmissionNumber: formik.values.admissionNumber,
    //     AdmissionDateFrom: addmissionFromDateValue ? moment(addmissionFromDateValue).format("YYYY-MM-DD") : "",
    //     AdmissionDateTo: addmissionToDateValue ? moment(addmissionToDateValue).format("YYYY-MM-DD") : "",
    //     Market: market,
    //     MarketedBrand: marketedBrand,
    //     UserLogin: login,
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }
    // if (type == "typeThree") {
    //   //console.log("typeThree", SearchFields);
    //   const callParam = {
    //     SampleType: SearchFields?.AuditType,
    //     SampleSubType: SearchFields?.AuditSubType,
    //     SampleStatus: statusVal === "0" ? "All" : statusVal,
    //     Inspector: inspector,
    //     ProductionCenter: prodCenter,
    //     ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
    //     ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
    //     PeriodDateFrom: periodDateFrom ? moment(periodDateFrom).format("YYYY-MM-DD") : "",
    //     PeriodDateTo: periodDateTo ? moment(periodDateTo).format("YYYY-MM-DD") : "",
    //     ProductCode:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductCode === "0"
    //           ? "All"
    //           : SearchFields?.ProductCode,
    //     ProductionUnit:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductionUnit === "0"
    //           ? "All"
    //           : SearchFields?.ProductionUnit,
    //     UserLogin: login,
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }
    // if (type == "typeFour") {
    //   //console.log("typeOne", SearchFields);
    //   const callParam = {
    //     SampleType: SearchFields?.AuditType,
    //     SampleSubType: SearchFields?.AuditSubType,
    //     SampleStatus: statusVal === "0" ? "All" : statusVal,
    //     Inspector: inspector,
    //     ProductionCenter: prodCenter,
    //     ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
    //     ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
    //     ProductCode:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductCode === "0"
    //           ? "All"
    //           : SearchFields?.ProductCode,
    //     ProductionUnit:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductionUnit === "0"
    //           ? "All"
    //           : SearchFields?.ProductionUnit,
    //     UserLogin: login,
    //     ProductionOrder: formik.values.PO,
    //     Batch: formik.values.Batch,
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   //console.log("typeOne", callParam);
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }
    // if (type == "typeFive") {
    //   //console.log("typeOne", SearchFields);
    //   const callParam = {
    //     SampleType: SearchFields?.AuditType,
    //     SampleSubType: SearchFields?.AuditSubType,
    //     SampleStatus: statusVal === "0" ? "All" : statusVal,
    //     Inspector: inspector,
    //     ProductionCenter: prodCenter,
    //     ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
    //     ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
    //     ProductCode:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductCode === "0"
    //           ? "All"
    //           : SearchFields?.ProductCode,
    //     ProductionUnit:
    //       prodCenter === "00"
    //         ? "All"
    //         : SearchFields?.ProductionUnit === "0"
    //           ? "All"
    //           : SearchFields?.ProductionUnit,
    //     SupplierCOC: "",
    //     ProductionOrder: "",
    //     Batch: "",
    //     UserLogin: login,
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   //console.log("typeOne", callParam);
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }

    const callParam = {
      BatchIDFilter: formik.values.BatchIDFilter,
      SampleId: formik.values.SearchId,
      SampleType: SearchFields?.AuditType,
      SampleSubType: SearchFields?.AuditSubType,
      SampleStatus: statusVal === "0" ? sourcePage === "LOGIN" ? "All" : "Pending,InProgress,Finished" : statusVal,
      Inspector: inspector,
      ProductionCenter: prodCenter,
      ProductionDateFrom: fromDateValue ? moment(fromDateValue).format("YYYY-MM-DD") : "",
      ProductionDateTo: toDateValue ? moment(toDateValue).format("YYYY-MM-DD") : "",
      ProductCode: prodCenter === "00" ? "All" : SearchFields?.ProductCode === "0" ? "All" : SearchFields?.ProductCode,
      ProductionUnit: prodCenter === "00" ? "All" : SearchFields?.ProductionUnit === "0" ? "All" : SearchFields?.ProductionUnit,
      PeriodDateFrom: periodDateFrom ? moment(periodDateFrom).format("YYYY-MM-DD") : "",
      PeriodDateTo: periodDateTo ? moment(periodDateTo).format("YYYY-MM-DD") : "",
      AdmissionNumber: formik.values.admissionNumber,
      AdmissionDateFrom: addmissionFromDateValue ? moment(addmissionFromDateValue).format("YYYY-MM-DD") : "",
      AdmissionDateTo: addmissionToDateValue ? moment(addmissionToDateValue).format("YYYY-MM-DD") : "",
      Market: market,
      MarketedBrand: marketedBrand,
      ProductionOrder: formik.values.PO,
      Batch: formik.values.Batch,
      SupplierCOC: formik.values.SupplierCOC,
      ReleaseDateFrom: releaseDateFrom ? moment(releaseDateFrom).format("YYYY-MM-DD") : "",
      RelaseDateTo: releaseDateTo ? moment(releaseDateTo).format("YYYY-MM-DD") : "",
      DateOfAnalysis: SearchFields?.AuditSubType.includes("RRP-P1") && dateOfAnlysis ? moment(dateOfAnlysis).format("YYYY-MM-DD") : "",
      UserLogin: login,
      PageMode: pageType,
      PageNumber: "1",
      PageSize: "5",
    };

    //console.log("typeOne", callParam);
    dispatch(commonActions.setLoading(true));
    dispatch(auditActions.getAuditReport(callParam));
    dispatch(auditActions.setAuditReportSearch(callParam));
    dispatch(inspectionAction.setSavePackDetailsResult([])); // reset array for cigLeft and pack details validation


    // } else {
    //   const callParam = {
    //     SampleId: formik.values.SearchId,
    //     SampleType: "ROUTINE",
    //     UserLogin: login,
    //     Inspector: "Me",
    //     PageMode: pageType,
    //     PageNumber: "1",
    //     PageSize: "5",
    //   };
    //   dispatch(commonActions.setLoading(true));
    //   dispatch(auditActions.getAuditReport(callParam));
    //   dispatch(auditActions.setAuditReportSearch(callParam));
    // }
    formik.resetForm();
  };
  useEffect(() => {
    const sorted = state?.searchStatus.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    setSortedStatus(sorted);
    //setStatus(0); commented for sending hardcoded data to API
  }, [state?.searchStatus]);

  useEffect(() => {
    dispatch(commonActions.setLoading(true));
    dispatch(auditActions.setAuditReport(""));
    dispatch(auditActions.setAuditReportType(sourcePage));
    // console.log("status-->",sourcePage);
    // console.log("status-->", pagewiseStatus.filter((b) =>b.pageType == sourcePage)[0].status);
    const fromday = new Date(),
      fromdate =
        fromday.getFullYear() +
        "-" +
        (fromday.getMonth() + 1) +
        "-" +
        fromday.getDate();
    //console.log("Date-->", moment(fromdate).format("YYYY-MM-DD"));
    setToDateValue(moment(fromdate).format("YYYY-MM-DD"));
    setAdmissionToDateValue(moment(fromdate).format("YYYY-MM-DD"));
    setReleaseDateTo(moment(fromdate).format("YYYY-MM-DD"));
    //  let today = new Date(),
    //  today = today.getFullYear() + '-' + (today.getMonth() +1) + '-' + (today.getDate() -2);

    const days = 7;
    const date = new Date();
    const last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    //console.log("Date-->", moment(last).format("YYYY-MM-DD"));
    setFromDateValue(moment(last).format("YYYY-MM-DD"));
    setAdmissionFromDateValue(moment(last).format("YYYY-MM-DD"));
    setReleaseDateFrom(moment(last).format("YYYY-MM-DD"));
    setDateOfAnalysis(moment(date).format("YYYY-MM-DD"));
    const params = {
      userLogin: login,
    };

    formik.setValues({
      ...formik.values,
      Status:
        sourcePage === "REPORT"
          ? "Validated"
          : sourcePage === "VALIDATION"
            ? "Finished"
            // : sourcePage === "LOGIN" || sourcePage === "INSPECTION"
            // ? ""
            : "0",
    });
    setStatusVal(
      sourcePage === "REPORT"
        ? "Validated"
        : sourcePage === "VALIDATION"
          ? "Finished"
          : "0"
    )
    // dispatch(commonActions.setLoading(true));
    dispatch(actions.getSearchDropdowns(params));
    dispatch(actions.getSearchStatus());
    const params_1 = {
      userLogin: login,
      auditType: "ROUTINE", //auditTypeVal,
      isBatch: 2, // This value will always be true for search page
      admin: isUserAdmin ? 1 : 0
    };
    dispatch(auditActions.getAuditSubType(params_1));
    const params_2 = {
      userLogin: login,
    };
    dispatch(auditActions.getMarketList(params_2));
    setAuditType(1);
    setMarket("All");
    setInspector("Me");
    setStatus("Validated");
    const params_3 = {
      auditType: "BENCHMARK_TIN",
    };
    dispatch(auditActions.getPeriodDropDown(params_3));
  }, []);

  useEffect(() => {
    const sortedU = state?.productionUnit.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    setSortedProdUnit(sortedU);
  }, [state?.productionUnit]);

  useEffect(() => {
    const sortedI = state?.searchDropdown?.inspectors.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });
    //setInspector(1); //commented for sending hardcoded value in API
    setSortedInspector(sortedI);
  }, [state?.searchDropdown?.inspectors]);

  useEffect(() => {
    if (auditState?.auditSubType.length > 0) {
      const value = auditState?.auditSubType[0].auditSubTypeCode;
      dispatch(auditActions.setAuditSubTypeForGrid(value));
      setSearchFields({
        ...SearchFields,
        AuditSubType: value,
      });
      if (value == "ROUTINE_RRP-P1DEVICE")
        setType("typeFive")
      // formik.handleChange({
      //   target: { name: "AuditSubType", value },
      // });
    }
  }, [auditState?.auditSubType]);
  useEffect(() => {
    const sortedP = state?.searchDropdown?.productionCenter
      .slice()
      .sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    //setProdCenter("00"); //commented for sending hardcoded value in API
    setProdCenter("All");
    setSortedProdCenter(sortedP);
    //console.log("Prod Center-->",sortedP);
  }, [state?.searchDropdown?.productionCenter]);

  useEffect(() => {
    if (typeof auditState?.marketList[0]?.market != 'undefined' &&
      auditState?.marketList[0]?.market.toUpperCase() != "ALL"
    ) {
      setMarket(auditState?.marketList[0].market);
      const params = {
        market: auditState?.marketList[0].market,
      };
      dispatch(commonActions.setLoading(true));
      dispatch(auditActions.getMarketedBrandByMarket(params));
    }
  }, [auditState?.marketList])

  // useEffect(() => {
  //   if (typeof auditState?.marketBrand[0]?.marketedBrand != 'undefined') {
  //     setMarketedBrand(auditState?.marketBrand[0]?.marketedBrand);
  //   }
  // }, [auditState?.marketBrand])

  const handleChange = (e, option) => {
    //console.log("Event", e.target.name);
    // setType('typeOne');
    if (e.target.name == "auditType") {
      dispatch(auditActions.setAuditType(e.target.value));
      let controlName = e.target.name;
      let controlValue = e.target.value;
      //console.log("Controls value", option);
      if (controlName == "auditType") {
        let typeArray = controlsDisplayonAuditType.filter((item) => {
          return item.auditType == controlValue;
        });
        if (typeArray) {
          if (typeArray.length > 0) {
            setType(typeArray[0].type);
          } 
        }
      }
      setAuditTypeVal(option.props.children);
      setSearchFields({
        ...SearchFields,
        AuditType: option.props.value,
      });
      setAuditType(option.props.value);

      const params = {
        userLogin: login,
        auditType: option.props.value.toUpperCase(),
        isBatch: 2, // This value will always be true for search page
        admin: isUserAdmin ? 1 : 0
      };
      dispatch(commonActions.setLoading(true));
      dispatch(auditActions.getAuditSubType(params));
    }
    if (e.target.name == "AuditSubType") {
      setType('typeOne');
      dispatch(auditActions.setAuditSubTypeForGrid(e.target.value));
      let localTypeArray = controlsDisplayonAuditType.filter((item) => {
        return item.auditType == "LOCAL" && item.auditSubType.includes(option.props.value);
      });
      if (localTypeArray) {
        if (localTypeArray.length > 0) {
          console.log(option.props.value,type, localTypeArray[0].type, 'Test12');
          setType(localTypeArray[0].type);
          console.log(option.props.value,type, localTypeArray[0].type, 'Test'); 
        } 
      }

      let marketTypeArray = controlsDisplayonAuditType.filter((item) => {
        return item.auditType == "MARKET" && item.auditSubType.includes(option.props.value);
      });
      if (marketTypeArray) {
        if (marketTypeArray.length > 0) {
          setType(marketTypeArray[0].type);
          
        }
      }
    
        
      // setAuditSubTypeVal(option.props.children);
      // setAuditSubType(option.props.value);
      // setSearchFields({
      //   ...SearchFields,
      //   AuditSubType: option.props.value,
      // });
      //alert(option.props.value);
      // controlsDisplayonAuditType.filter(
      //   (b) =>
      //     b.auditType == "ROUTINE" &&
      //     b.auditSubType.includes(option.props.value)
      // ).length > 0
      //   ? setType("typeFive")
      //   : setType("typeOne");
        let routineTypeArray = controlsDisplayonAuditType.filter((b) => {
          return  b.auditType == "ROUTINE" &&
          b.auditSubType.includes(option.props.value)
        });
        if (routineTypeArray) {
          if (routineTypeArray.length > 0) {
            setType(routineTypeArray[0].type);
          }
        }

      setAuditSubTypeVal(option.props.children);
      setAuditSubType(option.props.value);
      setSearchFields({
        ...SearchFields,
        AuditSubType: option.props.value,
      });
    }
    if (e.target.name == "Status") {
      setStatusVal(option.props.children);
      setSearchFields({
        ...SearchFields,
        Status: option.props.value,
      });
      setStatus(option.props.value);
    }
    if (e.target.name == "Inspector") {
      //alert(JSON.stringify(option.props))
      setSearchFields({
        ...SearchFields,
        Inspector: option.props.value,
      });
      setInspector(option.props.value);
    }
    if (e.target.name == "ProductionCenter") {
      setProdCenter(option.props.value);
      // alert(SearchFields.AuditSubType);
      const params = {
        productionCenter: option.props.value,
        auditSubType: SearchFields.AuditSubType,
      };
      const params_1 = {
        productionCenterId: option.props.value,
        productionDate: "",
      };
      //console.log("Prod Center-->", option.props.value);

      dispatch(commonActions.setLoading(true));
      dispatch(actions.getProductVariant(params));
      dispatch(actions.getProductionUnit(params_1));

      setSearchFields({
        ...SearchFields,
        ProductionCenter: option.props.value,
      });
      if (option.props.value == "00") {
        //alert("true");
        setDisable(true);
      } else {
        //alert("false");
        setDisable(false);
      }
    }
    if (e.target.name == "ProductionUnit") {
      //alert("ProductionUnit");
      setSearchFields({
        ...SearchFields,
        ProductionUnit: option.props.value,
      });
      setProdUnit(option.props.value);
    }
    if (e.target.name == "ProductCode") {
      setSearchFields({
        ...SearchFields,
        ProductCode: option.props.value,
      });
      setProductCode(option.props.value);
    }
    if (e.target.name == "period") {
      // console.log("Period-->", option.props.value);
      // console.log("Period-->", option.props.value.substring(0, 10));
      // console.log("Period-->", option.props.value.substring(11, 21));
      setPeriod(option.props.value);
      setPeriodFrom(option.props.value.substring(0, 10));
      setPeriodTo(option.props.value.substring(11, 21));
    }
    if (e.target.name == "market") {
      //alert("market");
      setMarket(option.props.value);
      const params = {
        market: option.props.value,
      };
      dispatch(commonActions.setLoading(true));
      dispatch(auditActions.getMarketedBrandByMarket(params));
    }
    if (e.target.name == "marketedBrand") {
      setMarketedBrand(option.props.value);
    }

    // if (e.target.name == "admissionNumber") {
    //   setAdmissionNumber(option.props.value);
    // }
  };

  const handleSampleIDChange = (e) => {
    //console.log("event-->", e.target.value);

    //console.log("Option-->", option);
    //console.log("Sample ID-->",SampleID);
  };

  const handleKeyPress = (e, option) => {
    // console.log("event-->", e.key);
    // console.log("Option-->", e.key);
    setSampleID(SampleID + "" + e.key);
  };

  const logSampleHandleClick = () => {
    navigate("/audit/log-new-sample");
  };

  return (
    //   <Formik
    //   initialValues = {SearchFields}
    //   enableReinitialize = {true}
    //   onSubmit = {(values) => {
    //     callDispatch(values);
    //   }
    // }
    //   >
    //   <Form>
    <form onSubmit={formik.handleSubmit}>

      {commonState.isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : null}
      {sourcePage != "ADVSEARCH" ? (
        <Grid container spacing={1} justifyContent="left" alignItems="left" pb={2}>
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
          >
            <Typography variant="h3" component="h4">
              Logged samples
            </Typography>
          </Grid>
          {sourcePage == "LOGIN" && (
          <Grid
            item
            xs={6}
            sm={6}
            md={6}
            style={{ justifyContent: "right", textAlign: "right" }}
          >
            <Button size="small" color="primary" variant="contained" type="button" onClick={logSampleHandleClick}>
              LOG A NEW SAMPLE
            </Button>
          </Grid>
        )}
        </Grid>
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl
            fullWidth
          // error={formik.touched.AuditType && Boolean(formik.errors.AuditType)}
          >
            <InputLabel id="AuditType">Audit Type</InputLabel>
            <Select
              size="small"
              labelId="AuditType"
              label="Audit Type"
              name="auditType"
              value={formik.values.AuditType}
              //value={auditType}
              onChange={handleChange}
              defaultValue={{ auditTypeName: "All", auditTypeId: 0 }}
            //onChange={handleAuditTypeChange}>
            >
              {state?.searchDropdown.auditType.map((model, i) => {
                // Here goes your models option
                return (
                  <MenuItem
                    value={model.auditTypeCode}
                    key={model.auditTypeCode}
                  >
                    {model.auditTypeName}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <FormHelperText>
                {formik.touched.AuditType && formik.errors.AuditType}
              </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <FormControl
            fullWidth
          // error={ formik.touched.AuditSubType &&  Boolean(formik.errors.AuditSubType)              }
          >
            <InputLabel id="AuditSubType">Audit Sub Type</InputLabel>

            <Select
              size="small"
              labelId="AuditSubType"
              label="Audit Sub Type"
              name="AuditSubType"
              value={formik.values.AuditSubType}
              //value={auditSubType}
              //defaultValue={{ auditTypeName: "Cigarettes", auditTypeId: 1 }}
              //onChange={handleAuditSubTypeChange}

              onChange={handleChange}
            >
              {auditState?.auditSubType.map((model, i) => {
                // Here goes your models option
                return (
                  <MenuItem
                    value={model.auditSubTypeCode}
                    key={model.auditSubTypeId}
                  >
                    {model.auditSubTypeName}
                  </MenuItem>
                );
              })}
            </Select>
            {/* <FormHelperText>
                {formik.touched.AuditSubType && formik.errors.AuditSubType}
              </FormHelperText> */}
          </FormControl>
        </Grid>
        {type != "typeFive" &&
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              size="small"
              autoFocus
              margin="normal"
              label="Sample ID"
              type="text"
              fullWidth
              sx={{ mt: "-1px" }}
              name="SearchId"
              value={formik.values.SearchId}
              onChange={formik.handleChange}
              inputProps={{
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </Grid>
        }
        {type == "typeFive" &&
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              size="small"
              autoFocus
              margin="normal"
              label="Batch ID"
              type="text"
              fullWidth
              sx={{ mt: "-1px" }}
              name="BatchIDFilter"
              value={formik.values.BatchIDFilter}
              onChange={formik.handleChange}
              inputProps={{
                form: {
                  autocomplete: 'off',
                },
              }}
            />
          </Grid>
        }
        <Grid item xs={12} sm={12} md={3}>
          <FormControl
            fullWidth
          // error={formik.touched.Status && Boolean(formik.errors.Status)}
          >
            <InputLabel id="Status">Status </InputLabel>
            <Select
              size="small"
              labelId="Status"
              label="Status*"
              name="Status"
              value={sourcePage === "LOGIN" || sourcePage === "ADVSEARCH" ? formik.values.Status : statusVal}
              // value={statusVal}
              onChange={handleChange}
            //onChange={handleStatusChange}
            >
              {/* <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="InProgress">InProgress</MenuItem> */}
              {sourcePage === "LOGIN" || sourcePage === "ADVSEARCH"
                ? sortedStatus.map((model, i) => {
                  // Here goes your models option
                  return (
                    <MenuItem value={model.id} key={model.id}>
                      {model.statusDescription}
                    </MenuItem>
                  );
                })
                : pagewiseStatus
                  .filter((b) => b.pageType == sourcePage)[0]
                  .status.map((model, i) => {
                    // Here goes your models option
                    return (
                      <MenuItem value={model.id} key={model.id}>
                        {model.value}
                      </MenuItem>
                    );
                  })}
            </Select>
            {/* <FormHelperText>
                {formik.touched.Status && formik.errors.Status}
              </FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <FormControl
            fullWidth
          // error={                formik.touched.Inspector && Boolean(formik.errors.Inspector)              }
          >
            <InputLabel id="Inspector">Inspector </InputLabel>
            <Select
              size="small"
              labelId="Inspector"
              label="Inspector*"
              name="Inspector"
              value={formik.values.Inspector}
              //value={inspector}
              onChange={handleChange}
            //onChange={handleInspectorChange}
            >
              {/* <MenuItem value="Me">Me</MenuItem>
              <MenuItem value="">----</MenuItem>
              <MenuItem value="Patra, Alexzander">Patra, Alexzander</MenuItem>
              <MenuItem value="Datta, Partha">Datta, Partha</MenuItem> */}
              {
                <MenuItem
                  value="Me" key="Me"> Me </MenuItem>
              }
              {/* {sortedInspector.map((model, i) => { */}
              {state?.searchDropdown?.inspectors.map((model, i) => {
                // Here goes your models option
                return (
                  <MenuItem value={model.id} key={model.id}>
                    {model.fullName}
                  </MenuItem>
                );
              })}
              {

                <MenuItem value="All">All</MenuItem>}
              {<MenuItem value="0">Unassigned</MenuItem>

              }
            </Select>
            {/* <FormHelperText>
                {formik.touched.Inspector && formik.errors.Inspector}
              </FormHelperText> */}
          </FormControl>
        </Grid>
        {type != "typeTwo" ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl
                  fullWidth
                // error={   formik.touched.ProductionCenter &&  Boolean(formik.errors.ProductionCenter) }
                >
                  <InputLabel id="ProductionCenter">
                    Production Center
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="ProductionCenter"
                    label="Production Center"
                    name="ProductionCenter"
                    value={formik.values.ProductionCenter}
                    //value={prodCenter}
                    onChange={handleChange}
                  //onChange={handleproductionCenterChange}
                  >
                    {sortedProdCenter.map((model, i) => {
                      // Here goes your models option
                      return (
                        <MenuItem value={model.id} key={model.id}>
                          {model.id != "00"
                            ? model.id + " - " + model.description
                            : model.description}
                          {/* {model.id} - {model.description} */}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <FormHelperText>
                      {formik.touched.ProductionCenter &&
                        formik.errors.ProductionCenter}
                    </FormHelperText> */}
                </FormControl>
              </Grid>
              {type != "typeFive" &&
                <Grid item xs={12} sm={12} md={4}>
                  <FormControl
                    fullWidth
                  // error={ formik.touched.ProductionUnit && Boolean(formik.errors.ProductionUnit)}
                  >
                    <InputLabel id="ProductionUnit">
                      Production Unit
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="ProductionUnit"
                      label="Production Unit"
                      name="ProductionUnit"
                      //value={prodUnit}
                      value={formik.values.ProductionUnit}
                      onChange={handleChange}
                      //onChange={formik.handleChange}
                      disabled={disable}
                    >
                      {sortedProdUnit.map((model, i) => {
                        // Here goes your models option
                        return (
                          <MenuItem value={model.id} key={model.id}>
                            {model.productionUnit}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <FormHelperText>
                      {formik.touched.ProductionUnit &&
                        formik.errors.ProductionUnit}
                    </FormHelperText> */}
                  </FormControl>
                </Grid>
              }
              <Grid item xs={12} sm={12} md={4}>
                <FormControl
                  fullWidth
                // error={                      formik.touched.ProductCode &&                      Boolean(formik.errors.ProductCode)                    }
                >
                  <InputLabel id="ProductCode">{type == "typeFive" ? 'Product Variant' : 'Product Code'}</InputLabel>
                  <Select
                    size="small"
                    labelId="ProductCode"
                    label="Product Code"
                    name="ProductCode"
                    value={formik.values.ProductCode}
                    //value={prodCode}
                    onChange={handleChange}
                    //onChange={handleProductCodeChange}
                    disabled={disable}
                  >
                    {/* <MenuItem value="All">All</MenuItem>
              <MenuItem value="01-US PMPI LLC (Mc Ken)">
                01-US PMPI LLC (Mc Ken)
              </MenuItem> */}
                    {state?.productVariant.map((model, i) => {
                      // Here goes your models option
                      return (
                        <MenuItem
                          value={model.productCode}
                          key={model.productCode}
                        >
                          {model.productCode} -{" "}
                          {model.productCodeDescription}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <FormHelperText>
                      {formik.touched.ProductCode && formik.errors.ProductCode}
                    </FormHelperText> */}
                </FormControl>
              </Grid>

            </Grid>
          </Grid>
        ) : null}

        {type == "typeFour" ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    autoFocus
                    margin="normal"
                    label="PO"
                    type="text"
                    fullWidth
                    sx={{ mt: "-1px" }}
                    name="PO"
                    value={formik.values.PO}
                    onChange={formik.handleChange}
                  // value={formik.values.PO}
                  // onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    autoFocus
                    margin="normal"
                    label="Batch"
                    type="text"
                    fullWidth
                    sx={{ mt: "-1px" }}
                    name="Batch"
                    value={formik.values.Batch}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {type == "typeFive" ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    size="small"

                    margin="normal"
                    label="Supplier COC"
                    type="text"
                    fullWidth
                    sx={{ mt: "-1px" }}
                    name="SupplierCOC"
                    value={formik.values.SupplierCOC}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Release Date From"
                      value={releaseDateFrom}
                      onChange={(newValue) => {
                        setReleaseDateFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Release Date To"
                      value={releaseDateTo}
                      onChange={(newValue) => {
                        setReleaseDateTo(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>


            </Grid>
          </Grid>
        ) : null}

        {
          type != "typeFive" &&
          <>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    size="small"
                    label="Production Date From"
                    value={fromDateValue}
                    onChange={(newValue) => {
                      setFromDateValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    size="small"
                    label="Production Date To"
                    value={toDateValue}
                    onChange={(newValue) => {
                      setToDateValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} size="small" />}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
          </>
        }



        {type == "typeThree" ? (
          <Grid item xs={12} sm={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="period">Period</InputLabel>
              <Select
                size="small"
                labelId="period"
                label="Period"
                name="period"
                onChange={handleChange}
                value={period}
              //onChange={handlePeriodChange}
              >
                {auditState?.period?.periodModel?.map((model, i) => {
                  // Here goes your models option
                  return (
                    <MenuItem value={model.value} key={model.id}>
                      {model.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        ) : null}

        {type == "typeTwo" ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  autoFocus
                  margin="normal"
                  label="Admission Number"
                  type="text"
                  fullWidth
                  sx={{ mt: "-1px" }}
                  name="admissionNumber"
                  //value={admissionNumber}
                  value={formik.values.admissionNumber}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Admission Date From"
                      //value={toDateValue}
                      value={addmissionFromDateValue}
                      onChange={(newValue) => {
                        setAdmissionFromDateValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Admission Date To"
                      value={addmissionToDateValue}
                      onChange={(newValue) => {
                        setAdmissionToDateValue(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {type == "typeTwo" ? (
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="market">Market</InputLabel>
                  <Select
                    size="small"
                    labelId="market"
                    label="Market"
                    name="market"
                    //value={formik.values.ProductCode}
                    value={market}
                    onChange={handleChange}
                  //onChange={handleMarketChange}
                  >
                    {auditState?.marketList?.map((model, i) => {
                      // Here goes your models option
                      return (
                        <MenuItem
                          value={model.market}
                          key={model.marketGroup}
                        >
                          {model.marketDescription}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel id="marketedBrand">Marketed Brand</InputLabel>
                  <Select
                    size="small"
                    labelId="marketedBrand"
                    label="Marketed Brand"
                    name="marketedBrand"
                    //value={formik.values.ProductCode}
                    value={marketedBrand}
                    onChange={handleChange}
                  //onChange={handleMarketBrandChange}
                  >
                    {auditState?.marketBrand?.map((model, i) => {
                      // Here goes your models option
                      return (
                        <MenuItem
                          value={model.marketedBrand}
                          key={model.marketedBrand}
                        >
                          {model.marketedBrandLongDesc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Period From"
                      value={periodDateFrom}
                      onChange={(newValue) => {
                        setPeriodDateFrom(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      size="small"
                      label="Period To"
                      value={periodDateTo}
                      onChange={(newValue) => {
                        setPeriodDateTo(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} size="small" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        ) : null}
        {type != "typeFive" && (
          <Grid item xs={12} sm={12} md={3}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  size="small"
                  label="Date of Analysis"
                  value={dateOfAnlysis}
                  onChange={(newValue) => {
                    setDateOfAnalysis(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        )
        }
        {/* <Grid
              container
              spacing={1}
              justifyContent="right"
              alignItems="right"
            > */}
        {sourcePage != "ADVSEARCH" ? (
          <>
            <Grid item
              xs={12}
              sm={12}
              md={12}
              style={{ justifyContent: "left", textAlign: "left" }}>
              <b>Note: Sample ID</b> and <b>Reject</b> button are not available for ICT Global and Calibration parent samples.
            </Grid>
          </>
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        style={{ justifyContent: "right", textAlign: "right" }}
        sx={{ mt: 2 }}
      >
        <Button color="primary" variant="contained" type="submit">
          Search
        </Button>
      </Grid>
    </form>
  );
}
