/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchPreliminaryChecklistAPI = () => {
  return API.get(
    "/adminService/api/PreliminaryChecklist/getPreliminaryChecklist"
  )
    .then((res) => res)
    .catch((error) => error);
};

export const editPreliminaryChecklistAPI = (payload) => {
  return API.get(
    "/adminService/api/PreliminaryChecklist/editPreliminaryChecklist",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const editPreliminaryCheckSteplistAPI = (payload) => {
  return API.get(
    "/adminService/api/PreliminaryChecklist/editPreliminaryCheckSteplist",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const addPreliminaryChecklistStepAPI = (payload) => {
  return API.post(
    "/adminService/api/PreliminaryChecklist/addPreliminaryChecklistStep",
    payload
  ).then((res) => res);
};

export const getPreliminaryChecklistStepAttributeAPI = (payload) => {
  return API.get(
    "/adminService/api/PreliminaryChecklist/getPreliminaryChecklistStepAttribute",
    {
      params: payload,
    }
  ).then((res) => res);
};

export const getCheckListAttributeSourceListAPI = (payload) => {
  //console.log("Master API");
  //console.log(payload);
  return API.get(    
    //"/adminService/api/MasterData/getCheckListAttributeSourceList?preliminaryChecklistStepId=1",
    "/adminService/api/MasterData/getCheckListAttributeSourceList",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const savePreliminaryChecklistAttributeAPI = (payload) => {
  return API.put(
    "/adminService/api/PreliminaryChecklist/savePreliminaryChecklistAttribute", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const updatePreliminaryChecklistStepAPI = (payload) => {
  return API.put(
    "/adminService/api/PreliminaryChecklist/savePreliminaryChecklistStep", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const addPreliminaryChecklistAttributeAPI = (payload) => {
  return API.post(
    "/adminService/api/PreliminaryChecklist/addPreliminaryChecklistAttribute",
    JSON.stringify(payload)
  ).then((res) => res);
};
