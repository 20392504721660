import { API } from "api/config";

// API

export const getCategoryListAPI = (payload) => {
  return API.get("/adminService/api/DictionaryCategory/getCategoryList", {
    params: payload,
  })
    .then((res) => res);
    
};

export const addCategoryDictionaryAPI = (payload) => {
    return API.post("/adminService/api/DictionaryCategory/addCategoryDictionary", payload)
      .then((res) => res);
  };
  
  export const updateCategoryDictionaryAPI = (payload) => {
      return API.put("/adminService/api/DictionaryCategory/updateCategoryDictionary",payload)
        .then((res) => res);
    };