/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {

};


export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_IMAGE_NAMES:
            return {
                ...state,
                ...payload,
            };
        case actionTypes.RESET:
            return {

            }
        default:
            return {
                ...state,
            };
    }
};
