/*eslint-disable */
export const actionTypes = {
    GET_PRODUCTION_UNIT: "GET_PRODUCTION_UNIT",
    SET_PRODUCTION_UNIT: "SET_PRODUCTION_UNIT",
    GET_EDIT_PRODUCTION_UNIT: "GET_EDIT_PRODUCTION_UNIT",
    SET_EDIT_PRODUCTION_UNIT: "SET_EDIT_PRODUCTION_UNIT",
    ADD_PRODUCTION_UNIT: "ADD_PRODUCTION_UNIT",
    SAVE_PRODUCTION_UNIT: "SAVE_PRODUCTION_UNIT",
    DISABLE_PRODUCTION_UNIT : "DISABLE_PRODUCTION_UNIT",
    GET_MACHINE_LISTS: "GET_MACHINE_LISTS",
    SET_MACHINE_LIST: "SET_MACHINE_LIST",
    GET_PRODUCTION_CENTER: "GET_PRODUCTION_CENTER",
    SET_PRODUCTION_CENTER: "SET_PRODUCTION_CENTER",
    SET_PRODUCTION_UNIT_COUNT: "SET_PRODUCTION_UNIT_COUNT",
    SAVE_PRODUCTION_UNIT_SUCCESS: "SAVE_PRODUCTION_UNIT_SUCCESS",
  };
  
  export const actions = {

    setProductionUnitCount: (payload) => ({
      type: actionTypes.SET_PRODUCTION_UNIT_COUNT,
      payload,
    }),

    getProductionCenter: (payload) => ({
      type: actionTypes.GET_PRODUCTION_CENTER,
      payload,
    }),

    setProductionCenter: (payload) => ({
      type: actionTypes.SET_PRODUCTION_CENTER,
      payload,
    }),
    
    getMachineLists: (payload) => ({
      type: actionTypes.GET_MACHINE_LISTS,
      payload,
    }),

    setMachineList: (payload) => ({
      type: actionTypes.SET_MACHINE_LIST,
      payload,
    }),

    getProductionUnit: (payload) => ({
      type: actionTypes.GET_PRODUCTION_UNIT,
      payload,
    }),

    setProductionUnit: (payload) => ({
      type: actionTypes.SET_PRODUCTION_UNIT,
      payload,
    }),
  
    getEditProductionUnit: (payload) => ({
      type: actionTypes.GET_EDIT_PRODUCTION_UNIT,
      payload,
    }),

    setEditProductionUnit: (payload) => ({
      type: actionTypes.SET_EDIT_PRODUCTION_UNIT,
      payload,
    }),
  
    addProductionUnit: (payload) => ({
      type: actionTypes.ADD_PRODUCTION_UNIT,
      payload,
    }),

    saveProductionUnit: (payload) => ({
        type: actionTypes.SAVE_PRODUCTION_UNIT,
        payload,
      }),

    disableProductionUnit: (payload) => ({
      type:actionTypes.DISABLE_PRODUCTION_UNIT,
      payload: payload,
    }),

    setProductionUnitSaveSuccess: (payload) => ({
      type:actionTypes.SAVE_PRODUCTION_UNIT_SUCCESS,
      payload: payload,
    })

  };
  