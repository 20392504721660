import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchUserDictionaryAttributeAPI,
  saveUserDictionaryAttributeAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getUserDictionaryAttributeSaga(action) {
  try {
    const _api = yield call(fetchUserDictionaryAttributeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(actions.setUserDictionaryAttributes(_api.data.data));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* saveUserDictionaryAttributeSaga(action) {
  try {
    const _api = yield call(saveUserDictionaryAttributeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    // yield put(commonActions.successAlert("sucess"));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

export function* watchUserDictionaryAttributeSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_USER_DICTIONARY_ATTRIBUTES,
      getUserDictionaryAttributeSaga
    ),
    takeEvery(
      actionTypes.SAVE_USER_DICTIONARY_ATTRIBUTES,
      saveUserDictionaryAttributeSaga
    ),
  ]);
}
