/*eslint-disable */
export const actionTypes = {
  GET_BRAND_GROUPS: "GET_BRAND_GROUPS",
  SET_BRAND_GROUPS: "SET_BRAND_GROUPS",
  EDIT_BRAND_GROUPS: "EDIT_BRAND_GROUPS",
  ADD_BRAND_GROUPS: "ADD_BRAND_GROUPS",
  SET_BRAND_GROUPS_EDIT: "SET_BRAND_GROUPS_EDIT",
  GET_BRAND_GROUPS_BY_ID: "GET_BRAND_GROUPS_BY_ID",
  SET_BRAND_GROUPS_BY_ID: "SET_BRAND_GROUPS_BY_ID",
  // drop downs
  GET_MARKETS_LIST: "GET_MARKETS_LIST",
  SET_MARKETS_LIST: "SET_MARKETS_LIST",
  GET_MARKETED_BRANDS_LIST: "GET_MARKETED_BRANDS_LIST",
  SET_MARKETED_BRANDS_LIST: "SET_MARKETED_BRANDS_LIST",
  GET_LABORATORY_LIST: "GET_LABORATORY_LIST",
  SET_LABORATORY_LIST: "SET_LABORATORY_LIST",
  SET_BRAND_GROUPS_COUNT: "SET_BRAND_GROUPS_COUNT",
};

export const actions = {
  getBrandGroups: (payload) => ({
    type: actionTypes.GET_BRAND_GROUPS,
    payload,
  }),

  setBrandGroups: (payload) => ({
    type: actionTypes.SET_BRAND_GROUPS,
    payload,
  }),

  // drop downs
  getMarketsList: (payload) => ({
    type: actionTypes.GET_MARKETS_LIST,
    payload: payload,
  }),

  setMarketsList: (payload) => ({
    type: actionTypes.SET_MARKETS_LIST,
    payload: payload,
  }),

  getMarketedBrandsList: (payload) => ({
    type: actionTypes.GET_MARKETED_BRANDS_LIST,
    payload: payload,
  }),

  setMarketedBrandsList: (payload) => ({
    type: actionTypes.SET_MARKETED_BRANDS_LIST,
    payload: payload,
  }),

  getLaboratoryList: (payload) => ({
    type: actionTypes.GET_LABORATORY_LIST,
    payload: payload,
  }),

  setLaboratoryList: (payload) => ({
    type: actionTypes.SET_LABORATORY_LIST,
    payload: payload,
  }),

  addBrandGroups: (payload) => ({
    type: actionTypes.ADD_BRAND_GROUPS,
    payload: payload,
  }),

  editBrandGroups: (payload) => ({
    type: actionTypes.EDIT_BRAND_GROUPS,
    payload: payload,
  }),

  setBrandGroupsById: (payload) => ({
    type: actionTypes.SET_BRAND_GROUPS_BY_ID,
    payload: payload,
  }),

  getBrandGroupsById: (payload) => ({
    type: actionTypes.GET_BRAND_GROUPS_BY_ID,
    payload: payload,
  }),

  setBrandGroupsEdit: (payload) => ({
    type: actionTypes.SET_BRAND_GROUPS_EDIT,
    payload: payload,
  }),
  
  setBrandGroupsCount: (payload) => ({
    type: actionTypes.SET_BRAND_GROUPS_COUNT,
    payload: payload,
  }),
};
