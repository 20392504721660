import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Form from "components/Form";
import { actions as commonAction } from "store/actions/common";
import { useSelector, useDispatch } from "react-redux";
import MainCard from "components/MainCard/MainCard";
import AuditFilterForm from "../AuditDetails/AuditFilterForm";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { actions as auditActions } from "../state/actions";
import { actions as commonActions } from "store/actions/common";
import { actions } from "../state/actions";
import moment from "moment";

import { actions as batchDeviceActions } from "../BatchDevice/state/actions";
import ConfirmationPopup from "./ConfirmationBox";
import BundlesInspectionPopup from "./BundlesInspectionPopup";
import PrintStickerPopup from "./PrintStickerPopup";
import { makeStyles, useTheme } from "@mui/styles";
import AssignInspector from "../BatchDevice/AssignInspector/AssignInspector";

const useStyles = makeStyles({
  root: {
    "& .MuiDataGrid-columnHeaderTitleContainer": {
      whiteSpace: "break-spaces !important",
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      whiteSpace: "break-spaces !important",
    },
  },
});

//audit login
const rows = [
  {
    idSample: 45689,
    auditSubType: "RU04 2G - PH",
    status: "panding",
    productionCenter: "RU04 2G - PH",
    productionDate: "29-09-2022",
    productionCode: "BDG6009",
    productionUnit: "BDG6009",
    inspector: "BDG6009",
  },
];
const gridColumnManagement = [
  {
    auditType: "ROUTINE",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
  // {
  //   sourcePage: "INSPECTION",
  //   RequiredColumn: [
  //     "My bundles to Inspect / available",
  //   ],
  // },
  {
    auditType: "MARKET",
    notRequiredColumn: [
      "Production Center",
      "Production Date",
      "Product Code",
      "Production Unit",
    ],
  },
  {
    auditType: "TPM",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
  {
    auditType: "ICT",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
  {
    auditType: "LOCAL",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
  {
    auditType: "DEV",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
  {
    auditType: "BENCHMARK",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
    ],
  },
];
const gridColumnManagementOnSubtype = [
  {
    auditSubType: "ROUTINE_RRP-P1DEVICE",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
      "Production Date",
      "Production Unit",
      "Action",
    ],
    fieldName: [{ columnName: "ID", fieldName: "batchNo" }],
  },
  {
    auditSubType: "LOCAL_MAR",
    notRequiredColumn: [
      "Admission Number",
      "Admission Date",
      "Period",
      "Market",
      "Marketed Brand",
      "Production Unit",
    ]
  }
];
const pagewiseStatus = [
  {
    pagename: "LOGIN",
    status: [
      "Pending",
      "InProgress",
      "Finished",
      "Validated",
      "Rejected",
      "Canceled",
      "Ripped",
    ],
  },
  { pagename: "INSPECTION", status: ["Pending", "InProgress", "Finished"] },
  { pagename: "VALIDATION", status: ["Finished"] },
  { pagename: "REPORT", status: ["Validated"] },
];

const subTypeArr = [
  "ROUTINE_HND",
  "MARKET_CIG",
  "MARKET_OTP",
  "MARKET_RRP-P1",
  "TPM_CIG",
  "TPM_HND",
  "TPM_TIN",
  "TPM_POU", //this is Okay
  "TPM_TUB", // added to fix SIT defect
  "ICT_GLO",
  "ICT_GLO_RRP-P1",
  "LOCAL_MAR",
  "LOCAL_CIGARILLO", //to match the similar behaviour with old CVQA
  "LOCAL_RRP-P1", //to match the similar behaviour with old CVQA
  "LOCAL_RRP-P2", //to match the similar behaviour with old CVQA
  "LOCAL_RRP-P4", //to match the similar behaviour with old CVQA
  //"BENCHMARK_POUCH", //added to fix UAT defect 205 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"BENCHMARK_BUCK", //added to fix UAT defect 210 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"BENCHMARK_TINS", //added to fix UAT defect 213 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"BENCHMARK_STAUPBG", //added to fix UAT defect 217 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"BENCHMARK_TOB_BLO", //added to fix UAT defect 221 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"ICT_CAL", //added to fix UAT defect 1016 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  //"ICT_LOC", //added to fix UAT defect 1023 -- commemted on 21/06/2022 just to match the similar behaviour with old CVQA
  "LOCAL_CIG", // added to fix SIT defect
];
const linkManagement = [
  {
    auditType: "ROUTINE",
    auditSubType: "ROUTINE_HND",
  },
  {
    auditType: "MARKET",
    auditSubType: "MARKET_CIG",
  },
  {
    auditType: "MARKET",
    auditSubType: "MARKET_OTP",
  },
  {
    auditType: "MARKET",
    auditSubType: "MARKET_RRP-P1",
  },
  {
    auditType: "TPM",
    auditSubType: "TPM_CIG",
  },
  {
    auditType: "TPM",
    auditSubType: "TPM_HND",
  },
  {
    auditType: "TPM",
    auditSubType: "TPM_TIN",
  },
  {
    auditType: "TPM",
    auditSubType: "TPM_POU",
  },
  {
    auditType: "ICT",
    auditSubType: "ICT_GLO",
  },
  {
    auditType: "ICT",
    auditSubType: "ICT_GLO_RRP-P1",
  },
  {
    auditType: "LOCAL",
    auditSubType: "LOCAL_MAR",
  },
];

const AuditCommonGrid = ({ sourcePage, handleFormClose }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const state = useSelector((state) => state.auditReport);
  const batchDeviceState = useSelector((state) => state.BatchDevice);
  const [pageSize, setPageSize] = useState(5);
  const [filteredAuditReport, setFilteredAuditReport] = useState([]);
  const [page, setPage] = useState(1);
  const [deletePopup, setDeletePop] = useState(false);
  const [rejectPopup, setRejectPop] = useState(false);
  const [validatePopup, setValidatePop] = useState(false);
  const [auditType, setAuditType] = useState("ROUTINE");
  const [auditSubType, setAuditSubType] = useState("");
  const [count, setCount] = useState(5);
  const [checked, setChecked] = useState([]);
  const [openSampleToInspect, setopenSampleToInspect] = useState(false);
  const [bundlesToInspect, setBundlesToInspect] = useState(false);
  const [idSample, setIdSample] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [openPrintSticker, setPrintStickerOpen] = useState(false);

  const [copyCount, setCopyCount] = useState(0);
  const [redirectionData, setRedirectionData] = useState({});

  const [rowXmlMapping, setRowXmlMapping] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const commonState = useSelector((state) => state.customization);
  const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1];
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  useEffect(() => {
    return () => {
      dispatch(batchDeviceActions.setValidateBatchDetails(false));
      dispatch(batchDeviceActions.setFinishInspectionPageStatus(false));
    };
  }, []);

  useEffect(() => {
    if (state?.inspectorAssigned) {
      dispatch(actions.setAssignInspectorStatus(false));
      dispatch(
        actions.setSampleEdit(
          state?.auditReport?.find(
            (sample) => sample.idSample == redirectionData.sampleId
          )
        )
      );
      navigate(
        "/audit/sample-process?auditType=" +
        redirectionData.auditType +
        "&auditSubType=" +
        redirectionData.auditSubType +
        "&sampleId=" +
        redirectionData.sampleId
      );
    }
  }, [state?.inspectorAssigned]);

  const handleEditForm = (row, actionType) => {
    //console.log("Audit Grid Click Event", { details: row, action: actionType });
    if (actionType == "sampleIdClick") {
      if (
        row.statusDescription == "Pending" ||
        row.statusDescription == "Canceled" ||
        row.statusDescription == "Rejected"
      ) {
        navigate(
          "/audit/log-new-sample?sampleId=" +
          row.idSample +
          "&status=" +
          row.statusDescription
        );
      } else if (row.statusDescription == "InProgress") {
        // refer to old application for batch flow. should be redirected to after validation page
        if (row.auditTypeId == "RRP-P1 Device") {
          navigate(
            "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&pageType=validationInProgress&validationState=1" +
            "&batchNo=" +
            row.batchNo
          );
          return;
        }
        navigate(
          "/audit/sample-process?auditType=" +
          row.auditTypeCode +
          "&auditSubType=" +
          row.auditSubTypeCode +
          "&sampleId=" +
          row.idSample
        );
      } else if (row.statusDescription == "Finished") {
        if (row.auditTypeId == "RRP-P1 Device") {
          navigate(
            "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&validationState=1" +
            "&batchNo=" +
            row.batchNo
          );
        } else {
          navigate(
            "/audit/sample-validate?auditType=" +
            row.auditTypeCode +
            "&auditSubType=" +
            row.auditSubTypeCode +
            "&sampleId=" +
            row.idSample
          );
        }
      } else if (row.statusDescription == "Validated") {
        if (row.auditTypeId == "RRP-P1 Device") {
          navigate(
            "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&pageType=reports&validationState=0" +
            "&batchNo=" +
            row.batchNo
          );
        } else {
          navigate(
            "/audit/sample-report?auditType=" +
            row.auditTypeCode +
            "&auditSubType=" +
            row.auditSubTypeCode +
            "&sampleId=" +
            row.idSample
          );
        }
      }
    }
    if (actionType == "Reject") {
      dispatch(actions.setSampleEdit(row));
      setRejectPop(true);
    }
    if (actionType == "Inspect") {
      if (row.auditTypeId == "RRP-P1 Device") {
        navigate(
          "/audit/batch-inspection?inspectionState=0&auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE" +
          "&batchNo=" +
          row.batchNo
        );
      } else {
        const inspector =
          row.subTypewithType != "-" && row.subTypewithType != ""
            ? row.inspectorName
            : row.inspectorId;

        if (
          row.auditTypeCode !== "MARKET" &&
          row.auditTypeCode !== "TPM" &&
          row.auditSubTypeCode !== "ROUTINE_HND" &&
          row.auditSubTypeCode !== "ICT_GLO_RRP-P1" &&
          row.auditSubTypeCode !== "ICT_GLO" &&
          row.auditSubTypeCode !== "LOCAL_MAR" &&
          inspector == "Unassigned"
        ) {
          const payload = {
            sampleId: row.idSample,
            userLogin: "PMI\\" + commonState?.userInfo?.principalName,
            userId: commonState?.userRoleInfo?.id,
          };
          setRedirectionData({
            auditType: row.auditTypeCode,
            auditSubType: row.auditSubTypeCode,
            sampleId: row.idSample,
          });
          dispatch(commonActions.setLoading(true));
          dispatch(actions.assignInspector(payload));
        } else {
          dispatch(actions.setSampleEdit(row));
          navigate(
            "/audit/sample-process?auditType=" +
            row.auditTypeCode +
            "&auditSubType=" +
            row.auditSubTypeCode +
            "&sampleId=" +
            row.idSample
          );
        }
      }
    }
    if (sourcePage == "ADVSEARCH") {
      handleFormClose();
    }
  };

  const handleCheckboxClick = (e, row) => {
    console.log("Audit checkbox Click Event", { event: e, row: row });
    checked.indexOf(row.idSample) >= 0
      ? // ? setChecked(checked.splice(checked.indexOf(row.idSample), 1))
      setChecked(checked.filter((id) => id != row.idSample))
      : setChecked([...checked, row.idSample]);
  };

  const handleClose = () => {
    setDeletePop(false);
    setRejectPop(false);
    setValidatePop(false);
    setBundlesToInspect(false);
    setopenSampleToInspect(false);
  };

  const handleStickerClose = () => {
    setPrintStickerOpen(false);
  };

  const handleBundleInspectionClick = (rowData) => {
    const userRoleInfo = JSON.parse(sessionStorage.getItem("userRoleInfo"));
    if (rowData.auditTypeId == "RRP-P1 Device") {
      setopenSampleToInspect(true);
      setBatchNo(rowData.batchNo);
    } else {
      const params = {
        sampleId: rowData.idSample,
        userId: userRoleInfo && userRoleInfo.id,
      };
      setBundlesToInspect(true);
      setIdSample(rowData);
      dispatch(auditActions.getBundleList(params));
    }
  };

  // useEffect(() => {
  //   if (state?.auditReport.length > 0) {
  //     setFilteredAuditReport(state?.auditReport.filter(p => pagewiseStatus.filter(p => p.pagename == sourcePage)[0]?.status.includes(p.statusDescription)));

  //   }
  // }, [state?.auditReport]);

  // useEffect(() => {
  //   console.log("after Filter -->", filteredAuditReport)

  // }, [filteredAuditReport]);

  useEffect(() => {
    if (batchDeviceState?.bundleListSaved) {
      navigate(
        "/audit/batch-inspection?inspectionState=0&auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE" +
        "&batchNo=" +
        batchNo
      );
    }

    return () => {
      dispatch(batchDeviceActions.getAssignedBundleListData(false));
    };
  }, [batchDeviceState?.bundleListSaved]);

  useEffect(() => {
    if (state?.auditReportSearch) {
      const params = state?.auditReportSearch;
      params.PageNumber = page;
      params.PageSize = pageSize;
      console.log("Search-->", state?.auditReportSearch);
      if (!(page == "1" && pageSize == "5")) {
        dispatch(commonActions.setLoading(true));
        dispatch(auditActions.getAuditReport(params));
      }
    }
  }, [page, pageSize]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      console.log("Delete-->", state?.auditEdit);
      const params = {
        sampleId: state?.auditEdit?.idSample,
        status: "Canceled",
        userLogIn: "PMI\\" + login,
        updateSampleSequenceStatus: 1,
        rejectReason: null,
        ncrNumber: null,
      };
      dispatch(actions.setSampleStatus(params));
      handleClose();
      formik.resetForm();
    },
  });
  const title = "";
  const saveButtonText = "Yes";
  const variant = "contained";
  const popupSize = "sm"; //lg-large
  const type = "confirmation";
  const extraButtonText = "No";

  const handleLoginEdit = (row, actionType) => {
    // console.log("LOGIN fields", row);
    // console.log("LOGIN actionType", actionType);
    if (actionType == "Copy" || actionType == "Edit") {
      //alert("Copy");
      //dispatch(actions.setSampleEdit(row));
      navigate(
        "/audit/log-new-sample?sampleId=" +
        row.idSample +
        "&status=" +
        actionType
      );
    }
    // if (actionType == "Edit") {
    //   //alert("Edit");
    //   dispatch(actions.setSampleEdit(row));
    // }
    else if (actionType == "Delete") {
      dispatch(actions.setSampleEdit(row));
      setDeletePop(true);
    } else if (actionType == "Print") {
      const rowData = {
        id: row.idSample || "",
        auditSubType: `${row.auditTypeName} -${row.auditSubTypeName}` || "",
        status: row.statusDescription || "",
        inspector: row.inspectorName || "",
        inspectionDate: row.inspectionDate || "",
        admissionDate: moment(row.admissionDate).format("YYYY-MM-DD") || "",
        market: row.market || "",
        marketerBrand: row.marketedBrand || "",
        marketDesc: row.marketDesc || "",
        admissionNumber: row.admissionNumber || "",
        noPacks: row.noPacks || "",
        marketedBrandLongDesc: row.marketedBrandLongDesc || "",
        periodStart: moment(row.periodStart)?.format("MMMM") || "",
        periodEnd: moment(row.periodEnd).format("MMMM YYYY") || "",
        noBundles: row.noBundles || "",
      };
      setRowXmlMapping(rowData);
      setCopyCount(row.noBundles);
      setPrintStickerOpen(true);
    }
  };

  useEffect(() => {
    console.log("Grid-->", state?.auditType);
    setAuditType(state?.auditType);
  }, [state?.auditType]);

  useEffect(() => {
    console.log("Audit SubType", state?.auditSubTypeForGrid);
    //setAuditType(state?.auditReportSearch?.SampleType);
    setAuditSubType(state?.auditSubTypeForGrid);
  }, [state?.auditSubTypeForGrid]);

  useEffect(() => {
    //console.log("Grid-->", state?.auditType);
    setCount(state?.auditReportCount);
  }, [state?.auditReportCount]);

  useEffect(() => {
    if (state?.validateSuccess == true) {
      setChecked([]);
      dispatch(auditActions.setValidateResult(false));
      dispatch(commonActions.setLoading(true));
      dispatch(auditActions.getAuditReport(state?.auditSearchCondition));
    }
  }, [state?.validateSuccess]);

  const isColumnNeeded = (columnName) => {
    if (sourcePage == "" && columnName == "Audit Sub Type") {
      return true;
    } else if (
      sourcePage != "INSPECTION" &&
      columnName == "My bundles to Inspect / available"
    ) {
      return true;
    } else if (sourcePage == "VALIDATION" && columnName == "Status") {
      return false;
    } else if (
      sourcePage == "INSPECTION" &&
      columnName == "Action" &&
      auditSubType == "ROUTINE_RRP-P1DEVICE"
    ) {
      return false;
    } else {
      let isRequired = gridColumnManagement.some(
        (item) =>
          item.auditType == auditType &&
          item.notRequiredColumn.includes(columnName)
      );
      if (auditSubType == "LOCAL_MAR") {
        isRequired = gridColumnManagementOnSubtype.some(
          (item) =>
            item.auditSubType == auditSubType &&
            item.notRequiredColumn.includes(columnName)
        );
      }

      if (auditSubType == "ROUTINE_RRP-P1DEVICE") {
        isRequired = gridColumnManagementOnSubtype.some(
          (item) =>
            item.auditSubType == auditSubType &&
            item.notRequiredColumn.includes(columnName)
        );
      } else if (columnName == "Release Date") {
        isRequired = true;
      }
      return isRequired;
    }
  };
  // const checkAvailability = (arr, val) => {
  //   return arr.some(function(arrVal) {
  //     return val === arrVal;
  //   });
  // }
  function checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
      return val === arrVal;
    });
  }

  const columns = [
    {
      field: "idSample", //getFieldName("ID","idSample"),//(params) => { if(params.auditTypeId == "RRP-P1 Device") return "batchNo"; else return "idSample";},
      headerName: "ID",
      width: 150,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      renderCell: (params) => (
        <>
          {params.row.auditTypeId != "RRP-P1 Device" ? (
            <Link
              href="#"
              color="#2196f3"
              fontWeight={"bold"}
              onClick={() => handleEditForm(params.row, "sampleIdClick")}
              underline="always"
            >
              {params.row.idSample}
            </Link>
          ) : sourcePage == "LOGIN" || sourcePage == "INSPECTION" ? (
            params.row.batchNo
          ) : (
            <Link
              href="#"
              color="#2196f3"
              fontWeight={"bold"}
              onClick={() => handleEditForm(params.row, "sampleIdClick")}
              underline="always"
            >
              {params.row.batchNo}
            </Link>
          )}
        </>
      ),
    },
    {
      field: "subTypewithType",
      headerName: "Audit Sub Type",
      width: 120,
      headerClassName: "super-app-theme--header",
      cellClassName: "super-app-theme--cell",
      hide: isColumnNeeded("Audit Sub Type"),
      renderCell: (params) => (
        <>
          {params.row.subTypewithType != "-" && params.row.subTypewithType != ""
            ? params.row.subTypewithType
            : params.row.auditTypeId}
        </>
      ),
    },
    {
      field: "statusDescription",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Status"),
    },
    {
      field: "releaseDate",
      headerName: "Release Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Release Date"),
    },
    {
      field: "productionCenter",
      headerName: "Production Center",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Production Center"),
    },
    {
      field: "productionDate",
      headerName: "Production Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Production Date"),
    },
    {
      field: "productVariant", //{"productionCode" === "" ? "productionCode" : "brandCode"}, //this will be conditional
      headerName: "Product Code",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Product Code"),
      renderCell: (params) => (
        <>
          {params.row.auditTypeCode == "TPM" ||
            params.row.auditTypeCode == "ICT"
            ? params.row.brandCode
            : params.row.productVariant}
        </>
      ),
    },
    {
      field: "productionUnit",
      headerName: "Production Unit",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Production Unit"),
    },
    {
      field: "inspectorName",
      headerName: "Inspector",
      width: 130,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <>
          {params.row.subTypewithType != "-" && params.row.subTypewithType != ""
            ? params.row.inspectorName
            : params.row.inspectorId}
        </>
      ),
    },
    {
      field: "admissionNumber",
      headerName: "Admission Number",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Admission Number"),
    },
    {
      field: "admissionDate",
      headerName: "Admission Date",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Admission Date"),
    },
    {
      field: "period",
      headerName: "Period",
      width: 100,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Period"),
    },
    {
      field: "market",
      headerName: "Market",
      width: 75,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Market"),
    },
    {
      field: "marketedBrand",
      headerName: "Marketed Brand",
      width: 120,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Marketed Brand"),
    },
    // {
    //   field: "assignedToMeBundles" + "availableBundles",
    //   headerName: "My bundles to Inspect / available",
    //   width: 250,
    //   headerClassName: "super-app-theme--header",
    //   hide: isColumnNeeded("My bundles to Inspect / available"),
    // },
    {
      field: "dummy",
      headerName: "My bundles to Inspect / available",
      headerClassName: "super-app-theme--header",
      width: 100,
      hide: isColumnNeeded("My bundles to Inspect / available"),
      renderCell: (params) => (
        <>
          {(sourcePage == "INSPECTION" &&
            checkAvailability(subTypeArr, params.row.auditSubTypeCode) ==
            true) ||
            params.row.auditTypeId == "RRP-P1 Device" ? (
            // linkManagement?.findIndex((p) => p.auditSubType == params.row.auditSubTypeCode)
            <Link
              href="#"
              color="#2196f3"
              fontWeight={"bold"}
              onClick={() => handleBundleInspectionClick(params.row)}
              underline="always"
            >
              {params.row.assignedToMeBundles} /{params.row.availableBundles}
            </Link>
          ) : (
            <>
              {params.row.assignedToMeBundles} /{params.row.availableBundles}
            </>
          )}
        </>
      ),
    },
    {
      field: "",
      headerName: "",
      width: 150,
      headerClassName: "super-app-theme--header",
      hide: isColumnNeeded("Action"),
      renderCell: (params) => (
        <>
          {sourcePage == "LOGIN" && params.row.statusDescription != "Canceled" && (
            <Stack direction="row">
              <IconButton
                aria-label="edit"
                title="Copy Sample"
                onClick={() => handleLoginEdit(params.row, "Copy")}
                // size="small"
                color="primary"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
              {params.row.statusDescription != "Validated" && (
                <IconButton
                  aria-label="edit"
                  title="Edit Sample"
                  onClick={() => handleLoginEdit(params.row, "Edit")}
                  // size="small"
                  color="primary"
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              )}
              <IconButton
                aria-label="edit"
                title="Delete Sample"
                onClick={() => handleLoginEdit(params.row, "Delete")}
                // size="small"
                color="primary"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              {params.row.auditTypeCode.toUpperCase() == "MARKET" && (
                <IconButton
                  aria-label="print"
                  title="Print bundle"
                  color="primary"
                  onClick={() => handleLoginEdit(params.row, "Print")}
                >
                  <LocalPrintshopIcon fontSize="small" />
                </IconButton>
              )}
            </Stack>
          )}

          {sourcePage == "VALIDATION" && (
            //   <Checkbox
            //   checked={params.row.csvqi == 0 ? false : true}
            //   onChange={(e) => handleCheckboxClick(e, params.row)}
            // />
            <Checkbox
              {...label}
              onChange={(e) => handleCheckboxClick(e, params.row)}
            />
          )}

          {sourcePage == "REPORT" && (
            <Link
              href="#"
              color="#2196f3"
              fontWeight={"bold"}
              onClick={() => handleEditForm(params.row, "Reject")}
              underline="always"
            >
              Reject
            </Link>
          )}

          {//Please test prperly before/after changing this logic
            sourcePage == "INSPECTION" &&
              params.row.statusDescription != "Validated" &&
              (
                (params.row.auditTypeId == "RRP-P1 Device" && parseInt(params.row.canInspect) > 0) ||
                (
                  params.row.auditTypeId != "RRP-P1 Device" &&
                  (
                    parseInt(params.row.assignedToMeBundles) > 0 ||
                    params.row.inspectorName == "Unassigned" ||
                    (
                      params.row.inspectorName.includes(login.slice(0, 3).toUpperCase()) &&
                      (params.row.auditTypeCode != "MARKET" || params.row.auditTypeCode != "TPM") &&
                      params.row.inspectorName == "Unassigned"
                    )
                  )
                )
              ) ? (
              <Link
                href="#"
                color="#2196f3"
                fontWeight={"bold"}
                onClick={() => handleEditForm(params.row, "Inspect")}
                underline="always"
              >
                Inspect
              </Link>
            ) : null
          }
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          style={{ justifyContent: "right", textAlign: "right" }}
        >
          {sourcePage == "VALIDATION" && checked.length > 0 && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setValidatePop(true)}
            >
              Validate
            </Button>
          )}
        </Grid>
        <Grid
          container
          sx={{
            background: "#fff",
            "& .super-app-theme--header": {
              whiteSpace: "break-spaces !important",
              lineHeight: "initial",
              fontSize: 12,
            },
            "& .super-app-theme--row": {
              height: "30px",
            },
            "& .super-app-theme--cell": {
              whiteSpace: "break-spaces !important",
              lineHeight: "initial",
              fontSize: 12,
            },
          }}
        >
          <div style={{ height: 400, width: "100%", marginTop: "10px" }}>
            <DataGrid
              rows={state?.auditReport || []}
              getRowId={(row) =>
                row.idSample != null ? row.idSample : row.batchNo
              }
              columns={columns}
              disableSelectionOnClick
              loading={commonState?.isLoading}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              paginationMode="server"
              rowCount={state?.auditReportCount}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onPageChange={(newPage) => {
                setPage((newPage += 1));
              }}
              page={page - 1}
              classes={{ root: classes.root }}
              getCellClassName={() => `super-app-theme--cell`}
            //get={() => `super-app-theme--header`}
            />
          </div>
        </Grid>
      </Grid>

      {openSampleToInspect && (
        <AssignInspector
          open={openSampleToInspect}
          handleClose={handleClose}
          batchNo={batchNo}
        />
      )}
      {deletePopup ? (
        <ConfirmationPopup
          title="Delete"
          formik={formik}
          handleClose={handleClose}
          open={open}
          saveButtonText={saveButtonText}
          variant={variant}
          popupSize={popupSize}
          type={type}
          extraButtonText={extraButtonText}
          popup="DELETE"
          message="Would you like to proceed with canceling Sample?"
          data={null}
        />
      ) : null}
      {validatePopup ? (
        <ConfirmationPopup
          title="Validation"
          formik={formik}
          handleClose={handleClose}
          open={open}
          saveButtonText={saveButtonText}
          variant={variant}
          popupSize={popupSize}
          type={type}
          extraButtonText={extraButtonText}
          popup="VALIDATE"
          message="Would you like to proceed with Sample Validation?"
          data={checked}
        />
      ) : null}
      {rejectPopup ? (
        <ConfirmationPopup
          title="Reject"
          formik={formik}
          handleClose={handleClose}
          open={open}
          saveButtonText={saveButtonText}
          variant={variant}
          popupSize={popupSize}
          type={type}
          extraButtonText={extraButtonText}
          popup="REJECT"
          message="Would you like to proceed with rejecting Sample?"
          data={null}
        />
      ) : null}
      {bundlesToInspect ? (
        <BundlesInspectionPopup
          sample={idSample}
          formik={formik}
          handleClose={handleClose}
          open={bundlesToInspect}
          saveButtonText={"Save"}
          variant={variant}
          popupSize={"sm"}
        />
      ) : null}

      {openPrintSticker ? (
        <PrintStickerPopup
          formik={formik}
          handleClose={handleStickerClose}
          open={openPrintSticker}
          saveButtonText={"Print"}
          variant={variant}
          popupSize={"xs"}
          copyCount={copyCount}
          source="LOGIN"
          rowXmlMapping={rowXmlMapping}
          auditGrid={true}
        />
      ) : null}
    </>
  );
};

export default AuditCommonGrid;
