import { all, fork } from "redux-saga/effects";
import { watchCommonSaga } from "./common";
import { watchMarketedBrandSaga } from "containers/Admin/MarketedBrand/state/saga";
import { watchLaboratoryAdminSaga } from "containers/Admin/LaboratoryAdmin/state/saga";
import { watchIpsmAttributesSaga } from "containers/Admin/IPSMAttributes/state/saga";
import { watchNewsSaga } from "containers/Admin/News/state/saga";
import { watchBrandGroupsListSaga } from "containers/Admin/BrandGroups/state/saga";
import { watchProductionCenterSaga } from "containers/Admin/ProductionCenter/state/saga";
import { watchMachineListSaga } from "containers/Admin/MachineModels/state/saga";
import { watchHelpSaga } from "containers/Admin/Help/state/saga";
import { watchAttributesSaga } from "containers/Admin/Attribute/state/saga";
import { watchAccessManagementSaga } from "containers/Admin/AccessManagement/state/saga";
import { watchPurchasingLocationTypeSaga } from "containers/Admin/PurchasingLocationType/state/saga";
import { watchProjectsSaga } from "containers/Admin/Project/state/saga";
import { watchLocalProjectTypesSaga } from "containers/Admin/LocalProjectTypes/state/saga";
import { watchInpectionChecklistSaga } from "containers/Admin/InspectionChecklist/state/saga";
import { watchLoginHistorySaga } from "containers/Admin/LoginHistory/state/saga";
import { watchPreliminaryChecklistSaga } from "containers/Admin/PreliminaryChecklist/state/saga";
import { watchProductionUnitListSaga } from "containers/Admin/ProductionUnits/state/saga";
import { watchAuditAdministrationSaga } from "containers/Admin/AuditAdministration/state/saga";
import { watchDictionaryTreeListSaga } from "containers/Admin/Dictionary/DictionaryTree/state/saga";
import { watchTranslationSaga } from "containers/Translations/PendingTranslations/state/saga";
import { watchBatchDeviceSaga } from "containers/Audit/BatchDevice/state/saga";
import { watchSearchSaga } from "containers/Dashboard/MySamples/state/saga";
import { watchDictionaryFamilySaga } from "containers/Admin/Dictionary/DictionaryFamily/state/saga";
import { watchDictionaryCategorySaga } from "containers/Admin/Dictionary/DictionaryCategory/state/saga";
import { watchAdvanceSearchSaga } from "containers/Dashboard/DashboardDetails/state/saga";
import { watchAddDictionarySaga } from "containers/Admin/Dictionary/DictionaryAdd/state/saga";
import { watchDictionaryProductSaga } from "containers/Admin/Dictionary/DictionaryProduct/state/saga";
import { watchDictionaryDefecttSaga } from "containers/Admin/Dictionary/DictionaryDefect/state/saga";
import { watchLogNewSamplesSaga } from "containers/Audit/LogNewSample/state/saga";
import { watchUserDictionaryAttributeSaga } from "containers/Admin/Dictionary/CreateOfflineDictionary/state/saga";
import { watchuploadTranslation } from "containers/Admin/Dictionary/DictionaryTranslationUpload/state/saga";
import { watchFindInDictionaryLevelSaga } from "containers/Translations/FindDictionary/state/saga";
import { watchNcMappingTemplateSaga } from "containers/Admin/Dictionary/DictionaryDefectMappingUpload/state/saga";
import { watchUploadDictionarySaga } from "containers/Admin/Dictionary/DictionaryUpload/state/saga";
import { watchAuditReportsSaga } from "containers/Audit/state/saga";
import { watchSampleInspectionSaga } from "containers/Audit/SampleDetails/SampleInspection/state/saga";

export default function* rootSaga() {
  yield all([
    fork(watchCommonSaga),
    fork(watchMarketedBrandSaga),
    fork(watchLaboratoryAdminSaga),
    fork(watchIpsmAttributesSaga),
    fork(watchNewsSaga),
    fork(watchBrandGroupsListSaga),
    fork(watchProductionCenterSaga),
    fork(watchMachineListSaga),
    fork(watchHelpSaga),
    fork(watchAttributesSaga),
    fork(watchAccessManagementSaga),
    fork(watchPurchasingLocationTypeSaga),
    fork(watchProjectsSaga),
    fork(watchLocalProjectTypesSaga),
    fork(watchInpectionChecklistSaga),
    fork(watchLoginHistorySaga),
    fork(watchPreliminaryChecklistSaga),
    fork(watchProductionUnitListSaga),
    fork(watchAuditAdministrationSaga),
    fork(watchDictionaryTreeListSaga),
    fork(watchTranslationSaga),
    fork(watchBatchDeviceSaga),
    fork(watchSearchSaga),
    fork(watchDictionaryFamilySaga),
    fork(watchDictionaryCategorySaga),
    fork(watchAdvanceSearchSaga),
    fork(watchAddDictionarySaga),
    fork(watchDictionaryProductSaga),
    fork(watchDictionaryDefecttSaga),
    fork(watchLogNewSamplesSaga),
    fork(watchUserDictionaryAttributeSaga),
    fork(watchuploadTranslation),
    fork(watchFindInDictionaryLevelSaga),
    fork(watchNcMappingTemplateSaga),
    fork(watchUploadDictionarySaga),
    fork(watchAuditReportsSaga),
    fork(watchSampleInspectionSaga),
  ]);
}
