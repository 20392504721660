import { actionTypes } from "./actions";

const initialState = {
  dictionaryAttribute: {},
  dictionaryHierarchy: {},
  dictionaryDefect: {
    // imageList: {},
    // description: {},
    // attribute: [],
    // comments: "",
    // defectMapping: "",
  },
  defectDropDowns: {
    dictionaryId: "",
    productId: "",
    familyId: "",
    familyCodeDesc: "",
    productCodeDesc: "",
    productDropdown: [],
    categoryDropdown: [],
    familyDropdown: [],
  },
  defectDropDownsForCopy: {
    dictionaryId: "",
    productId: "",
    familyId: "",
    categoryId: "",
    familyCodeDesc: "",
    productCodeDesc: "",
    categoryCodeDesc: "",
    productDropdown: [],
    categoryDropdown: [],
    familyDropdown: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_Dictionary_NC_Attribute:
      return {
        ...state,
        dictionaryAttribute: payload,
      };

    case actionTypes.SET_Dictionary_Hierarchy:
      return {
        ...state,
        dictionaryHierarchy: payload,
      };

    case actionTypes.SET_Dictionary_Expand_Defect:
      return {
        ...state,
        dictionaryDefect: payload,
      };

    case actionTypes.SET_DEFECT_DROPDOWNS:
      return {
        ...state,
        defectDropDowns: payload,
      };

    case actionTypes.SET_DEFECT_DROPDOWNS_FOR_COPY:
      return {
        ...state,
        defectDropDownsForCopy: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
