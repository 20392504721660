/*eslint-disable */
export const actionTypes = {
  GET_HELP: "GET_HELP",
  SET_HELP: "SET_HELP",
  GET_HELP_BY_ID: "GET_HELP_BY_ID",
  SET_HELP_BY_ID: "SET_HELP_BY_ID",
  EDIT_HELP: "EDIT_HELP",
  SET_HELP_EDIT: "SET_HELP_EDIT"
};

export const actions = {
  getHelp: (payload) => ({
    type: actionTypes.GET_HELP,
    payload: payload,
  }),

  setHelp: (payload) => ({
    type: actionTypes.SET_HELP,
    payload: payload,
  }),

  setHelpById: (payload) => ({
    type: actionTypes.SET_HELP_BY_ID,
    payload: payload,
  }),

  getHelpById: (payload) => ({
    type: actionTypes.GET_HELP_BY_ID,
    payload: payload,
  }),

  editHelp: (payload) => ({
    type: actionTypes.EDIT_HELP,
    payload: payload,
  }),

  setHelpEdit: (payload) => ({
    type: actionTypes.SET_HELP_EDIT,
    payload: payload,
  }),
};
