import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchBrandGroupsListAPI,
  fetchAddBrandGroupsListAPI,
  fetchBrandGroupsByIdAPI,
  fetchEditBrandGroupsListAPI,
} from "../api/endpoint";
import {
  fetchMarketsListAPI,
  fetchMarketedBrandsListAPI,
  fetchLaboratoryListAPI,
} from "api/common";
import { actions as commonActions } from "store/actions/common";

function* getBrandGroupsListSaga(action) {
  try {
    const _api = yield call(fetchBrandGroupsListAPI, action.payload);
    yield put(actions.setBrandGroups(_api.data.data));
    yield put(actions.setBrandGroupsCount(_api.data.totalCount));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addBrandGroupsSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchAddBrandGroupsListAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getBrandGroups());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* editBrandGroupsSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchEditBrandGroupsListAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getBrandGroups());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* getBrandGroupsByIdSaga(action) {
  try {
    const _api = yield call(fetchBrandGroupsByIdAPI, action.payload);
    yield put(actions.setBrandGroupsById(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

// drop downs

function* getMarketsListSaga(action) {
  try {
    const _api = yield call(fetchMarketsListAPI, action.payload);
    yield put(actions.setMarketsList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getMarketedBrandListSaga(action) {
  try {
    const _api = yield call(fetchMarketedBrandsListAPI, action.payload);
    yield put(actions.setMarketedBrandsList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getLaboratoryListSaga(action) {
  try {
    const _api = yield call(fetchLaboratoryListAPI, action.payload);
    yield put(actions.setLaboratoryList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchBrandGroupsListSaga() {
  yield all([
    takeEvery(actionTypes.GET_BRAND_GROUPS, getBrandGroupsListSaga),
    takeEvery(actionTypes.ADD_BRAND_GROUPS, addBrandGroupsSaga),
    takeEvery(actionTypes.EDIT_BRAND_GROUPS, editBrandGroupsSaga),
    takeEvery(actionTypes.GET_MARKETS_LIST, getMarketsListSaga),
    takeEvery(actionTypes.GET_MARKETED_BRANDS_LIST, getMarketedBrandListSaga),
    takeEvery(actionTypes.GET_LABORATORY_LIST, getLaboratoryListSaga),
    takeEvery(actionTypes.GET_BRAND_GROUPS_BY_ID, getBrandGroupsByIdSaga),
  ]);
}
