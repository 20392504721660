export const actionTypes = {
  GET_Dictionary_Expand_Defect: "GET_Dictionary_Expand_Defect",
  SET_Dictionary_Expand_Defect: "SET_Dictionary_Expand_Defect",
  GET_Dictionary_NC_Dropdown: "GET_Dictionary_NC_Dropdown",
  SET_Dictionary_NC_Dropdown: "SET_Dictionary_NC_Dropdown",
  GET_Dictionary_NC_Attribute: "GET_Dictionary_NC_Attribute",
  SET_Dictionary_NC_Attribute: "SET_Dictionary_NC_Attribute",
  GET_Dictionary_Hierarchy: "GET_Dictionary_Hierarchy",
  SET_Dictionary_Hierarchy: "SET_Dictionary_Hierarchy",
  Save_Dictionary_Defect: "Save_Dictionary_Defect",
  GET_DEFECT_DROPDOWNS: "GET_DEFECT_DROPDOWNS",
  SET_DEFECT_DROPDOWNS: "SET_DEFECT_DROPDOWNS",
  GET_DEFECT_DROPDOWNS_FOR_COPY: "GET_DEFECT_DROPDOWNS_FOR_COPY",
  SET_DEFECT_DROPDOWNS_FOR_COPY: "SET_DEFECT_DROPDOWNS_FOR_COPY",
  PUBLISH_DICTIONARY_DEFECT: "PUBLISH_DICTIONARY_DEFECT",
};

export const actions = {
  saveDictionaryDefect: (payload) => ({
    type: actionTypes.Save_Dictionary_Defect,
    payload,
  }),

  getDictionaryHierarchy: (payload) => ({
    type: actionTypes.GET_Dictionary_Hierarchy,
    payload,
  }),

  setDictionaryHierarchy: (payload) => ({
    type: actionTypes.SET_Dictionary_Hierarchy,
    payload,
  }),

  getDictionaryExpandDefect: (payload) => ({
    type: actionTypes.GET_Dictionary_Expand_Defect,
    payload,
  }),

  setDictionaryExpandDefect: (payload) => ({
    type: actionTypes.SET_Dictionary_Expand_Defect,
    payload,
  }),

  getDictionaryNCDropdown: (payload) => ({
    type: actionTypes.GET_Dictionary_NC_Dropdown,
    payload,
  }),

  setDictionaryNCDropdown: (payload) => ({
    type: actionTypes.SET_Dictionary_NC_Dropdown,
    payload,
  }),

  getDictionaryNCAttribute: (payload) => ({
    type: actionTypes.GET_Dictionary_NC_Attribute,
    payload,
  }),

  setDictionaryNCAttribute: (payload) => ({
    type: actionTypes.SET_Dictionary_NC_Attribute,
    payload,
  }),

  getDefectDropdowns: (payload) => ({
    type: actionTypes.GET_DEFECT_DROPDOWNS,
    payload,
  }),

  setDefectDropdowns: (payload) => ({
    type: actionTypes.SET_DEFECT_DROPDOWNS,
    payload,
  }),

  getDefectDropdownsForCopy: (payload) => ({
    type: actionTypes.GET_DEFECT_DROPDOWNS_FOR_COPY,
    payload,
  }),

  setDefectDropdownsForCopy: (payload) => ({
    type: actionTypes.SET_DEFECT_DROPDOWNS_FOR_COPY,
    payload,
  }),

  publishDictionaryDefect: (payload) => ({
    type: actionTypes.PUBLISH_DICTIONARY_DEFECT,
    payload,
  }),
};
