import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PopupTitleCard from "components/PopupTitleCard/PopupTitleCard";

const CVQADialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  dialog: {
    position: "absolute",
    // top: 60
  },
  modelHeader: {
    backgroundColor: "rgb(30,129,244)",
    fontSize: "1rem",
    color: "#fff",
  },
  cancelButton: {
    border: "1px solid #938f8f",
    color: "#938f8f",
  },
  popupBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100px",
    margin: "0 auto",
    minWidth: "175px",
  },
  ApprovalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100px",
    margin: "0 auto",
    minWidth: "175px",
    marginRight: "12px",
    "& button": {
      marginLeft: "1rem",
    },
  },
}));

export default function CDialog({
  title,
  children,
  open,
  handleClose,
  popupSize = "sm",
  saveButtonText = "",
  variant = "outlined",
  type,
  handleApproveOrReject,
}) {
  const classes = useStyles();
  const handleFormClose = (e) => {
    handleClose(e);
  };

  return (
    <div>
      <CVQADialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleFormClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={popupSize}
      >
        <PopupTitleCard
          id="customized-dialog-title"
          className={classes.modelHeader}
          onClose={handleFormClose}
        >
          {title}
        </PopupTitleCard>        
          <DialogContent>{children}</DialogContent>       
      </CVQADialog>
    </div>
  );
}
