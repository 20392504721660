/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
    fetchAuditAdministartionDataAPI,
    fetchAuditSubTypeDictionaryListAPI,
    fetchAuditSubTypeDetailsAPI,
    fetchAuditAdminDetailsAPI,
    editAuditSubtypeDetailsAPI,
    saveVFIConfigurationAPI,
    getFilterColumnListAPI,
    getFilterTypeListAPI,
    addAuditSubTypeFilterConfigAPI,
    deleteFilterConfigValueAPI
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getAuditAdministrationListSaga() {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(fetchAuditAdministartionDataAPI);
        yield put(actions.setAuditAdministrationInfo(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* saveVFIConfigurationSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(saveVFIConfigurationAPI, action.payload);
        yield put(commonActions.successAlert(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        yield put(commonActions.setLoading(false));
    }
}
function* getAuditSubTypeDictionaryListSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(fetchAuditSubTypeDictionaryListAPI, action.payload);
        yield put(actions.setAuditSubTypeDictionaryInfo(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* getAuditSubTypeDetailsSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(fetchAuditSubTypeDetailsAPI, action.payload);
        yield put(actions.setAuditSubTypeDetailsInfo(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* editAuditSubTypeDetailsSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(editAuditSubtypeDetailsAPI, action.payload);
        yield put(commonActions.successAlert(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* getAuditAdminDetailsAPISaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(fetchAuditAdminDetailsAPI, action.payload);
        yield put(actions.setAuditAdminDetailsInfo(_api.data.data));

        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* getFilterColumnListSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(getFilterColumnListAPI);
        yield put(actions.setFilterColumnList(_api.data.data));
        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* getFilterTypeListSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(getFilterTypeListAPI);
        yield put(actions.setFilterTypeList(_api.data.data));

        yield put(commonActions.setLoading(false));
    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* addAuditSubTypeFilterConfigSaga(action) {
    try {
        yield put(commonActions.setLoading(true));
        const _api = yield call(addAuditSubTypeFilterConfigAPI, action.payload);
        yield put(commonActions.successAlert(_api.data.data));
        var auditSubtypeId = action.payload.auditSubtypeId;
        var auditAdminParam = {
            code: auditSubtypeId
        }
        yield put(actions.getAuditAdminDetailsInfo(auditAdminParam));
        yield put(commonActions.setLoading(false));

    } catch (error) {
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}
function* deleteFilterConfigValueSaga(action) {
    try {
        var payload = action.payload;
        yield put(commonActions.setLoading(true));
        const _api = yield call(deleteFilterConfigValueAPI, payload.id);
        yield put(commonActions.successAlert(_api.data.data));

        var auditSubtypeId = action.payload.auditSubTypeId;
        var auditAdminParam = {
            code: auditSubtypeId
        }
        yield put(actions.getAuditAdminDetailsInfo(auditAdminParam));
        yield put(commonActions.setLoading(false));
    } catch (error) {   
        console.log(error);
        // yield put(commonActions.setLoading(false));
    }
}



export function* watchAuditAdministrationSaga() {
    yield all([
        takeEvery(
            actionTypes.GET_AUDIT_ADMINISTRATION_INFO,
            getAuditAdministrationListSaga
        ),
        takeEvery(
            actionTypes.GET_AUDIT_SUBTYPE_DICTIONARY_INFO,
            getAuditSubTypeDictionaryListSaga
        ),
        takeEvery(
            actionTypes.SAVE_VFI_CONFIGURATION,
            saveVFIConfigurationSaga
        ),
        takeEvery(
            actionTypes.GET_AUDIT_SUBTYPE_DETAILS_INFO,
            getAuditSubTypeDetailsSaga
        ),
        takeEvery(
            actionTypes.GET_AUDIT_ADMIN_DETAILS_INFO,
            getAuditAdminDetailsAPISaga
        ),
        takeEvery(
            actionTypes.EDIT_AUDIT_SUBTYPE_DETAILS_INFO,
            editAuditSubTypeDetailsSaga
        ),
        takeEvery(
            actionTypes.GET_FILTER_COLUMN_LIST,
            getFilterColumnListSaga
        ),
        takeEvery(
            actionTypes.GET_FILTER_TYPE_LIST,
            getFilterTypeListSaga
        ),
        takeEvery(
            actionTypes.ADD_SUBTYPE_FILTER_CONFIG,
            addAuditSubTypeFilterConfigSaga
        ),
        takeEvery(
            actionTypes.DELETE_FILTER_CONFIG,
            deleteFilterConfigValueSaga
        )
    ]);
}
