export const actionTypes = {
  SET_FIELDS: "SET_FIELDS",
  RESET_FIELDS: "RESET_FIELDS",
  GET_ATTRIBUTES: "GET_ATTRIBUTES",
  SET_ATTRIBUTES: "SET_ATTRIBUTES",
  HIDE_ATTRIBUTE: "HIDE_ATTRIBUTE",
  GET_ATTRIBUTE_VALUES: "GET_ATTRIBUTE_VALUES",
  SET_ATTRIBUTE_VALUES: "SET_ATTRIBUTE_VALUES",
  GET_ATTRIBUTE_BY_ID: "GET_ATTRIBUTE_BY_ID",
  SET_ATTRIBUTE_BY_ID: "SET_ATTRIBUTE_BY_ID",
  SAVE_ATTRIBUTE: "SAVE_ATTRIBUTE",
  ADD_ATTRIBUTE_VALUE: "ADD_ATTRIBUTE_VALUE",
  EDIT_ATTRIBUTE_VALUE: "EDIT_ATTRIBUTE_VALUE",
};

export const actions = {
  getAttributes: (payload) => ({
    type: actionTypes.GET_ATTRIBUTES,
    payload: payload,
  }),
  setAttributes: (payload) => ({
    type: actionTypes.SET_ATTRIBUTES,
    payload: payload,
  }),

  setFields: (payload) => ({
    type: actionTypes.SET_FIELDS,
    payload: payload,
  }),
  resetFields: () => ({
    type: actionTypes.RESET_FIELDS,
  }),
  hideAttribute: (payload) => ({
    type: actionTypes.HIDE_ATTRIBUTE,
    payload: payload,
  }),
  getAttributeValues: (payload) => ({
    type: actionTypes.GET_ATTRIBUTE_VALUES,
    payload: payload,
  }),
  setAttributeValues: (payload) => ({
    type: actionTypes.SET_ATTRIBUTE_VALUES,
    payload: payload,
  }),

  getAttributeById: (payload) => ({
    type: actionTypes.GET_ATTRIBUTE_BY_ID,
    payload: payload,
  }),
  setAttributeById: (payload) => ({
    type: actionTypes.SET_ATTRIBUTE_BY_ID,
    payload: payload,
  }),
  saveAttribute: (payload) => ({
    type: actionTypes.SAVE_ATTRIBUTE,
    payload: payload,
  }),
  addAttributeValue: (payload) => ({
    type: actionTypes.ADD_ATTRIBUTE_VALUE,
    payload: payload,
  }),
  editAttributeValue: (payload) => ({
    type: actionTypes.EDIT_ATTRIBUTE_VALUE,
    payload: payload,
  }),
};
