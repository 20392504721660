/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchIpsmAttributesAPI = () => {
  return API.get("/adminService/api/IpsmAttributes/getIpsmAttributes")
    .then((res) => res)
    .catch((error) => error);
};

export const updateIpsmAttributesAPI = (payload) => {
  return API.put(
    "/adminService/api/IpsmAttributes/updateIpsmAttribute",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};
