export const actionTypes = {
  GET_PURCHASING_LOCATION_TYPE: "GET_PURCHASING_LOCATION_TYPE",
  SET_PURCHASING_LOCATION_TYPE: "SET_PURCHASING_LOCATION_TYPE",
  DISABLE_PURCHASING_LOCATION_TYPE: "DISABLE_PURCHASING_LOCATION_TYPE",
  EDIT_PURCHASING_LOCATION_TYPE: "EDIT_PURCHASING_LOCATION_TYPE",
  ADD_PURCHASING_LOCATION_TYPE: "ADD_PURCHASING_LOCATION_TYPE",
};

export const actions = {
  getPurchasingLocationType: (payload) => ({
    type: actionTypes.GET_PURCHASING_LOCATION_TYPE,
    payload,
  }),
  setPurchasingLocationType: (payload) => ({
    type: actionTypes.SET_PURCHASING_LOCATION_TYPE,
    payload,
  }),
  disablePurchasingLocationType: (payload) => ({
    type: actionTypes.DISABLE_PURCHASING_LOCATION_TYPE,
    payload,
  }),
  editPurchasingLocationType: (payload) => ({
    type: actionTypes.EDIT_PURCHASING_LOCATION_TYPE,
    payload,
  }),
  addPurchasingLocationType: (payload) => ({
    type: actionTypes.ADD_PURCHASING_LOCATION_TYPE,
    payload,
  }),
};
