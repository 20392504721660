/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchProductionCenterAPI = () => {
  return API.get("adminService/api/ProductionCenter/getProductionCenter")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchLaboratoryOptionsAPI = () => {
  return API.get("/adminService/api/MasterData/getLaboratoryList")
    .then((res) => res)
    .catch((error) => error);
};

export const getProductionCenterByIdAPI = (payload) => {
  return API.get("/adminService/api/ProductionCenter/getEditProductionCenter", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const editProductionCenterAPI = (payload) => {
  return API.put(
    "/adminService/api/ProductionCenter/saveProductionCenter",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};
