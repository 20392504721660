import React from "react";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { languages } from "./languages";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  //other part of the code//
  select: {
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
    '&:not(.Mui-disabled):hover::before': {
      borderColor: 'white',
    },
  },
  icon: {
    color: 'white'
  },
  root: {
    color: 'white'
  },
  languagePosition: {
    right: "170px",
    position: "fixed"
  }
}));
const Languages = ({ language, handleLanguageChange, label, isGlobal = false, sourcePage = "Other" }) => {

  const classes = useStyles();
  const handleChange = (event) => {
    console.log("Global Language");
  };


  return (
    <div className={isGlobal ? classes.languagePosition : ""}>
      {
        !isGlobal ?
          <FormControl fullWidth>
            <Autocomplete
              id="disable-close-on-select"
              getOptionLabel={(option) => option.name}
              onChange={handleLanguageChange}
              value={language}
              options={languages.filter(language => sourcePage.toUpperCase() == "FIND_DICTIONARY" ? language.code != "EN" : true)}
              size="small"
              renderInput={(params) => (
                <TextField
                  InputLabelProps={{ shrink: true }}
                  name="language"
                  {...params}
                  label="Translation To"
                />
              )}
            />
          </FormControl>
          :
          <FormControl variant="standard" sx={{ m: 1, minWidth: 10 }}>
            <Select
              id="headerLanguage"
              className={classes.select}
              value={language?.code}
              onChange={handleLanguageChange}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  root: classes.root,
                },
              }}

            >
              {languages.map((model, i) => {
                // Here goes your models option
                return (
                  <MenuItem value={model.code} key={model.code}>
                    {model.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

      }

    </div>
  )
};

export default Languages;
