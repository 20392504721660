/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchDailyLoginHistoryAPI,
  fetchWeeklyLoginHistoryAPI,
  fetchMonthlyLoginHistoryAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getDailyLoginHistorySaga(action) {
  try {
    const _api = yield call(fetchDailyLoginHistoryAPI, action.payload);
    yield put(actions.setDailyLoginHistory(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getWeeklyLoginHistorySaga(action) {
  try {
    const _api = yield call(fetchWeeklyLoginHistoryAPI, action.payload);
    yield put(actions.setWeeklyLoginHistory(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getMonthlyLoginHistorySaga(action) {
  try {
    const _api = yield call(fetchMonthlyLoginHistoryAPI, action.payload);
    yield put(actions.setMonthlyLoginHistory(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchLoginHistorySaga() {
  yield all([
    takeEvery(actionTypes.GET_DAILY_LOGIN_HISTORY, getDailyLoginHistorySaga),
    takeEvery(actionTypes.GET_WEEKLY_LOGIN_HISTORY, getWeeklyLoginHistorySaga),
    takeEvery(
      actionTypes.GET_MONTHLY_LOGIN_HISTORY,
      getMonthlyLoginHistorySaga
    ),
  ]);
}
