import React, { lazy } from "react";

// project imports
import MainLayout from "components/Layout/MainLayout";
import MinimalLayout from "components/Layout/MinimalLayout";
import Loadable from "components/Loadable/Loadable";

import { Navigate } from "react-router-dom";
// project imports

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("containers/Dashboard/Dashboard"))
);

// pages routing
const Login = Loadable(lazy(() => import("containers/Pages/Login")));
const Dictionary = Loadable(
  lazy(() => import("containers/Admin/Dictionary/Dictionary"))
);
const AuditLoginDetails = Loadable(
  lazy(() => import("containers/Audit/Login/AuditLoginDetails"))
);
const AuditReportDetails = Loadable(
  lazy(() => import("containers/Audit/Reports/AuditReport"))
);
const AuditInspection = Loadable(
  lazy(() => import("containers/Audit/Inspection/AuditInspection"))
);
const AuditValidation = Loadable(
  lazy(() => import("containers/Audit/Validation/AuditValidation"))
);
const BrandGroups = Loadable(
  lazy(() => import("containers/Admin/BrandGroups/BrandGroups"))
);
const MarketedBrand = Loadable(
  lazy(() => import("containers/Admin/MarketedBrand/MarketedBrand"))
);
const LaboratoryAdmin = Loadable(
  lazy(() => import("containers/Admin/LaboratoryAdmin/LaboratoryAdmin"))
);
const ProductionCenter = Loadable(
  lazy(() => import("containers/Admin/ProductionCenter/ProductionCenter"))
);
const News = Loadable(lazy(() => import("containers/Admin/News/News")));
const Help = Loadable(lazy(() => import("containers/Admin/Help/Help")));
const AuditAdministration = Loadable(
  lazy(() => import("containers/Admin/AuditAdministration/AuditAdministration"))
);
const EditAuditAdministration = Loadable(
  lazy(() =>
    import(
      "containers/Admin/AuditAdministration/AuditAdministrationDetails/AuditConfigurationEdit"
    )
  )
);
const Attribute = Loadable(
  lazy(() => import("containers/Admin/Attribute/Attribute"))
);
const EditAttribute = Loadable(
  lazy(() =>
    import("containers/Admin/Attribute/AttributeDetails/AttributeEdit")
  )
);
const IPSMAttributes = Loadable(
  lazy(() => import("containers/Admin/IPSMAttributes/IPSMAttributes"))
);

const PreliminaryChecklist = Loadable(
  lazy(() =>
    import("containers/Admin/PreliminaryChecklist/PreliminaryChecklist")
  )
);
const EditPreliminaryChecklist = Loadable(
  lazy(() =>
    import(
      "containers/Admin/PreliminaryChecklist/PreliminaryChecklistDetails/PreliminaryChecklistEdit"
    )
  )
);
const EditPreliminaryChecklistStep = Loadable(
  lazy(() =>
    import(
      "containers/Admin/PreliminaryChecklist/PreliminaryChecklistDetails/PreliminaryChecklistStepEdit"
    )
  )
);

const AddPreliminaryChecklistStep = Loadable(
  lazy(() =>
    import(
      "containers/Admin/PreliminaryChecklist/PreliminaryChecklistDetails/PreliminaryChecklistStepAdd"
    )
  )
);

const ProductionUnits = Loadable(
  lazy(() => import("containers/Admin/ProductionUnits/ProductionUnits"))
);
const MachineModels = Loadable(
  lazy(() => import("containers/Admin/MachineModels/MachineModels"))
);
const PurchasingLocationType = Loadable(
  lazy(() =>
    import("containers/Admin/PurchasingLocationType/PurchasingLocationType")
  )
);
const LocalProjectTypes = Loadable(
  lazy(() => import("containers/Admin/LocalProjectTypes/LocalProjectTypes"))
);
const Project = Loadable(
  lazy(() => import("containers/Admin/Project/Project"))
);
const LoginHistory = Loadable(
  lazy(() => import("containers/Admin/LoginHistory/LoginHistory"))
);
const AccessManagement = Loadable(
  lazy(() => import("containers/Admin/AccessManagement/AccessManagement"))
);

const InspectionChecklist = Loadable(
  lazy(() => import("containers/Admin/InspectionChecklist/InspectionChecklist"))
);

const DictionaryChecklistAdd = Loadable(
  lazy(() =>
    import(
      "containers/Admin/InspectionChecklist/InspectionChecklistDetails/DictionaryChecklistAdd"
    )
  )
);

const EditDeviceChecklist = Loadable(
  lazy(() =>
    import(
      "containers/Admin/InspectionChecklist/InspectionChecklistDetails/DeviceChecklistEdit"
    )
  )
);
const EditProductChecklist = Loadable(
  lazy(() =>
    import(
      "containers/Admin/InspectionChecklist/InspectionChecklistDetails/ProductChecklistEdit"
    )
  )
);
const AddProductChecklist = Loadable(
  lazy(() =>
    import(
      "containers/Admin/InspectionChecklist/InspectionChecklistDetails/ProductChecklistAdd"
    )
  )
);
const EditCategory = Loadable(
  lazy(() =>
    import(
      "containers/Admin/InspectionChecklist/InspectionChecklistDetails/CategoryEdit"
    )
  )
);

// Translation Routes

const PendingTranslations = Loadable(
  lazy(() =>
    import("containers/Translations/PendingTranslations/PendingTranslations")
  )
);
const FindDictionary = Loadable(
  lazy(() =>
    import("containers/Translations/FindDictionary/FindDicionaryDetails")
  )
);

// Audit Routes
const LogNewSample = Loadable(
  lazy(() => import("containers/Audit/LogNewSample/LogNewSample"))
);
const SampleInspection = Loadable(
  lazy(() => import("containers/Audit/SampleDetails/SampleInspection"))
);
const SampleValidation = Loadable(
  lazy(() =>
    import("containers/Audit/SampleDetails/SampleValidation/SampleValidation")
  )
);
const SampleReport = Loadable(
  lazy(() => import("containers/Audit/SampleDetails/SampleReport/SampleReport"))
);

const BatchInspectionSummary = Loadable(
  lazy(() =>
    import(
      "containers/Audit/BatchDevice/InspectionSummary/BatchInspectionSummary"
    )
  )
);

const BatchValidationDetails = Loadable(
  lazy(() =>
    import("containers/Audit/BatchDevice/Validation/BatchValidationDetails")
  )
);

const DataDownloadXmlImage = Loadable(
  lazy(() =>
    import("containers/DataDownload/DataDownloadXmlImage")
  )
);

// ===========================|| MAIN ROUTING ||=========================== //

// Add your application routes here

export const routesMap = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      {
        id: "auditLogin",
        path: "/audit/login",
        element: <AuditLoginDetails />,
      },
      {
        id: "auditReports",
        path: "/audit/reports",
        element: <AuditReportDetails />,
      },
      {
        id: "auditInspection",
        path: "/audit/inspection",
        element: <AuditInspection />,
      },
      {
        id: "auditValidation",
        path: "/audit/validation",
        element: <AuditValidation />,
      },
      {
        id: "auditSampleValidate",
        path: "/audit/sample-validate",
        element: <SampleValidation />,
      },
      {
        id: "auditBatchInspection",
        path: "/audit/batch-inspection",
        element: <BatchInspectionSummary />,
      },
      {
        id: "auditBatchValidation",
        path: "/audit/batch-validation",
        element: <BatchValidationDetails />,
      },
      {
        id: "auditSampleReport",
        path: "/audit/sample-report",
        element: <SampleReport />,
      },
      {
        id: "pendingTranslations",
        path: "/translation/pending-translations",
        element: <PendingTranslations />,
      },
      {
        id: "findInDictionary",
        path: "/translation/find-dictionary",
        element: <FindDictionary />,
      },
      {
        id: "adminDictionary",
        path: "/admin/dictionary",
        element: <Dictionary />,
      },
      {
        id: "logNewSample",
        path: "/audit/log-new-sample",
        element: <LogNewSample />,
      },
      {
        id: "auditLogin",
        path: "/audit/sample-process",
        element: <SampleInspection />,
      },
      {
        id: "adminBrandGroups",
        path: "/admin/brand-groups",
        element: <BrandGroups />,
      },
      {
        id: "adminMarketedBrand",
        path: "/admin/maketed-brand",
        element: <MarketedBrand />,
      },
      {
        id: "adminLocalProjectTypes",
        path: "/admin/local-project-types",
        element: <LocalProjectTypes />,
      },
      {
        id: "adminProject",
        path: "/admin/project",
        element: <Project />,
      },
      {
        id: "adminLaboratoryAdmin",
        path: "/admin/laboratory-admin",
        element: <LaboratoryAdmin />,
      },
      {
        id: "adminProductionCenter",
        path: "/admin/production-center",
        element: <ProductionCenter />,
      },
      {
        id: "adminNews",
        path: "/admin/news",
        element: <News />,
      },
      {
        id: "adminHelp",
        path: "/admin/help",
        element: <Help />,
      },
      {
        id: "adminAuditAdministration",
        path: "/admin/audit-administration",
        element: <AuditAdministration />,
      },
      {
        id: "adminAuditAdministration",
        path: "/admin/audit-administration/edit",
        element: <EditAuditAdministration />,
      },
      {
        id: "adminAttribute",
        path: "/admin/attribute",
        element: <Attribute />,
      },
      {
        id: "adminAttribute",
        path: "/admin/attribute/edit",
        element: <EditAttribute />,
      },
      {
        id: "adminAccessManagement",
        path: "/admin/access-management",
        element: <AccessManagement />,
      },
      {
        id: "adminIpsmAttributes",
        path: "/admin/ipsm-attributes",
        element: <IPSMAttributes />,
      },
      {
        id: "adminLoginHistory",
        path: "/admin/login-history",
        element: <LoginHistory />,
      },
      {
        id: "adminPreliminaryChecklist",
        path: "/admin/preliminary-checklist",
        element: <PreliminaryChecklist />,
      },
      {
        id: "adminPreliminaryChecklist",
        path: "/admin/preliminary-checklist/edit",
        element: <EditPreliminaryChecklist />,
      },
      {
        id: "adminPreliminaryChecklist",
        path: "/admin/preliminary-checklist/step/edit",
        element: <EditPreliminaryChecklistStep />,
      },
      {
        id: "adminPreliminaryChecklist",
        path: "/admin/preliminary-checklist/step/add",
        element: <AddPreliminaryChecklistStep />,
      },
      {
        id: "adminProductionUnits",
        path: "/admin/production-units",
        element: <ProductionUnits />,
      },
      {
        id: "adminMachineModels",
        path: "/admin/machine-models",
        element: <MachineModels />,
      },
      {
        id: "adminPurchasingLocationType",
        path: "/admin/purchasing-location-type",
        element: <PurchasingLocationType />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist",
        element: <InspectionChecklist />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist/add-dictionary-checklist",
        element: <DictionaryChecklistAdd />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist/edit-device-checklist",
        element: <EditDeviceChecklist />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist/edit-product-checklist",
        element: <EditProductChecklist />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist/add-product-checklist",
        element: <AddProductChecklist />,
      },
      {
        id: "adminInspectionChecklist",
        path: "/admin/inspection-checklist/edit-category",
        element: <EditCategory />,
      },
      {
        id: "downloadXmlImage",
        path: "/data_download/download-xml-image",
        element: <DataDownloadXmlImage />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/" />,
    children: [
      {
        id: "login",
        path: "/login/",
        element: <Login />,
      },
    ],
  },
];

// function to render only routes that are authorized

const renderAuthorizedRoutes = (isLoggedIn, menus, routesMap) => {
  const routesList = [
    {
      path: "/",
      element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
      children: [
        {
          id: "dashboard",
          path: "/",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "/login",
      element: !isLoggedIn ? <MinimalLayout /> : <Navigate to="/" />,
      children: [
        {
          id: "login",
          path: "/login/",
          element: <Login />,
        },
      ],
    },
  ];
  const menuKeys = menus?.map((menu) => menu?.pageKey || "") || [];

  routesMap[0]?.children?.filter((route) => {
    if (menuKeys?.length > 0 && menuKeys?.includes(route?.id)) {
      routesList[0]?.children.push(route);
    }
  });

  return routesList;
};

export const MainRoutes = (isLoggedIn, menus) =>
  renderAuthorizedRoutes(isLoggedIn, menus, routesMap(isLoggedIn));
