export const actionTypes = {
    GET_AUDIT_ADMINISTRATION_INFO: "GET_AUDIT_ADMINISTRATION_INFO",
    SET_AUDIT_ADMINISTRATION_INFO: "SET_AUDIT_ADMINISTRATION_INFO",
    SET_VFI_CONFIGURATION: "SET_VFI_CONFIGURATION",
    SAVE_VFI_CONFIGURATION: "SAVE_VFI_CONFIGURATION",
    GET_AUDIT_SUBTYPE_DICTIONARY_INFO: "GET_AUDIT_SUBTYPE_DICTIONARY_INFO",
    SET_AUDIT_SUBTYPE_DICTIONARY_INFO: "SET_AUDIT_SUBTYPE_DICTIONARY_INFO",
    SELECT_UNSELECT_DICTIONARY_DATA: "SELECT_UNSELECT_DICTIONARY_DATA",
    GET_AUDIT_SUBTYPE_DETAILS_INFO:"GET_AUDIT_SUBTYPE_DETAILS_INFO",
    SET_AUDIT_SUBTYPE_DETAILS_INFO:"SET_AUDIT_SUBTYPE_DETAILS_INFO",
    CONVERT_AUDIT_SUBTYPE_DETAILS_FORMIK:"CONVERT_AUDIT_SUBTYPE_DETAILS_FORMIK",
    EDIT_AUDIT_SUBTYPE_DETAILS_INFO:"EDIT_AUDIT_SUBTYPE_DETAILS_INFO",
    GET_AUDIT_ADMIN_DETAILS_INFO:"GET_AUDIT_ADMIN_DETAILS_INFO",
    SET_AUDIT_ADMIN_DETAILS_INFO:"SET_AUDIT_ADMIN_DETAILS_INFO",
    GET_FILTER_COLUMN_LIST: "GET_FILTER_COLUMN_LIST",
    SET_FILTER_COLUMN_LIST: "SET_FILTER_COLUMN_LIST",
    GET_FILTER_TYPE_LIST: "GET_FILTER_TYPE_LIST",
    SET_FILTER_TYPE_LIST: "SET_FILTER_TYPE_LIST",
    ADD_SUBTYPE_FILTER_CONFIG: "ADD_SUBTYPE_FILTER_CONFIG",
    DELETE_FILTER_CONFIG: "DELETE_FILTER_CONFIG"
};

export const actions = {
    getAuditAdministrationInfo: () => ({
        type: actionTypes.GET_AUDIT_ADMINISTRATION_INFO
    }),
    setAuditAdministrationInfo: (payload) => ({
        type: actionTypes.SET_AUDIT_ADMINISTRATION_INFO,
        payload: payload,
    }),
    setVfiConfigAuditAdministrationInfo: (payload) => ({
        type: actionTypes.SET_VFI_CONFIGURATION,
        payload: payload,
    }),
    saveVFIconfiguration: (payload) => ({
        type: actionTypes.SAVE_VFI_CONFIGURATION,
        payload: payload,
    }),
    getAuditSubTypeDictionaryInfo: (payload) => ({
        type: actionTypes.GET_AUDIT_SUBTYPE_DICTIONARY_INFO,
        payload: payload,
    }),
    setAuditSubTypeDictionaryInfo: (payload) => ({ 
        type: actionTypes.SET_AUDIT_SUBTYPE_DICTIONARY_INFO,
        payload: payload,
    }),
    getAuditSubTypeDetailsInfo: (payload) => ({
        type: actionTypes.GET_AUDIT_SUBTYPE_DETAILS_INFO,
        payload: payload,
    }),
    setAuditSubTypeDetailsInfo: (payload) => ({ 
        type: actionTypes.SET_AUDIT_SUBTYPE_DETAILS_INFO,
        payload: payload,
    }),
    convertToSubtypeFormikFieldsData: (payload) => ({ 
        type: actionTypes.CONVERT_AUDIT_SUBTYPE_DETAILS_FORMIK,
        payload: payload,
    }),
    doSelectDictionaryData: (payload) => ({ 
        type: actionTypes.SELECT_UNSELECT_DICTIONARY_DATA, 
        payload: payload,
    }),
    editAuditSubTypeDetails: (payload) => ({ 
        type: actionTypes.EDIT_AUDIT_SUBTYPE_DETAILS_INFO,
        payload: payload,
    }),

    getAuditAdminDetailsInfo: (payload) => ({
        type: actionTypes.GET_AUDIT_ADMIN_DETAILS_INFO,
        payload: payload,
    }),
    setAuditAdminDetailsInfo: (payload) => ({ 
        type: actionTypes.SET_AUDIT_ADMIN_DETAILS_INFO,
        payload: payload,
    }),
    getFilterColumnList: () => ({ 
        type: actionTypes.GET_FILTER_COLUMN_LIST
    }),
    setFilterColumnList: (payload) => ({ 
        type: actionTypes.SET_FILTER_COLUMN_LIST,
        payload: payload,
    }),
    getFilterTypeList: () => ({ 
        type: actionTypes.GET_FILTER_TYPE_LIST
    }),
    setFilterTypeList: (payload) => ({ 
        type: actionTypes.SET_FILTER_TYPE_LIST,
        payload: payload,
    }),
    addAuditSubTypeFilterConfigSaga: (payload) => ({
        type: actionTypes.ADD_SUBTYPE_FILTER_CONFIG,
        payload: payload,
    }),
    deleteAuditFilterConfig: (payload) => ({
        type: actionTypes.DELETE_FILTER_CONFIG,
        payload: payload,
    }),
};
