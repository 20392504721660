/*eslint-disable */

import { API } from "api/config";

//  API

export const getSearchBySampleIdAPI = (payload) => {
  return API.get("/adminService/api/SampleId/getSearchBySampleId",{
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

// Unused code

// export const getTranslationGridbyIdAPI = (payload) => {
//   return API.get("/adminService/api/Translation/getTranslationGridById",{
//     params: payload,
//   })
//     .then((res) => res)
//     .catch((error) => error);
// };
export const getSamplesToInspectionAPI = (payload) => {
  return API.get("/adminService/api/SampleId/getSamplesToValidate",{
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};
export const getSamplesToValidateAPI = (payload) => {
  return API.get("/adminService/api/SampleId/getSamplesToValidate",{
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

