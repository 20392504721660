/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {
  marketedBrandData: [],
  subMarketData: [],
  companyGroupsData: [],

  // drop downs
  marketsList: [],
  brandGroupsList: [],
  companyGroupsList: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_MARKETED_BRAND:
      return {
        ...state,
        marketedBrandData: payload,
      };

    case actionTypes.SET_SUBMARKET:
      return {
        ...state,
        subMarketData: payload,
      };

    case actionTypes.SET_COMPANY_GROUPS:
      return {
        ...state,
        companyGroupsData: payload,
      };

    // drop downs

    case actionTypes.SET_MARKETS_LIST:
      return {
        ...state,
        marketsList: payload,
      };

    case actionTypes.SET_BRAND_GROUPS_LIST:
      return {
        ...state,
        brandGroupsList: payload,
      };

    case actionTypes.SET_COMPANY_GROUPS_LIST:
      return {
        ...state,
        companyGroupsList: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
