/*eslint-disable */
export const actionTypes = {
  SET_Prod_Center: "SET_Prod_Center",
  SET_Prod_Variant: "SET_Prod_Variant",
  GET_Audit_Dropdowns: "GET_Audit_Dropdowns",
  SET_Audit_Dropdowns: "SET_Audit_Dropdowns",
  GET_Product_Variants: "GET_Product_Variants",
  SET_Product_Variant: "SET_Product_Variant",
  SAVE_LogNewDeviceBatch: "SAVE_LogNewDeviceBatch",
  GET_INSPECTOR_LIST: "GET_INSPECTOR_LIST",
  SET_INSPECTOR_LIST: "SET_INSPECTOR_LIST",
  GET_ASSIGNED_BUNDLE_LIST: "GET_ASSIGNED_BUNDLE_LIST",
  SET_ASSIGNED_BUNDLE_LIST: "SET_ASSIGNED_BUNDLE_LIST",
  SET_ASSIGNED_OLD_BUNDLE_LIST: "SET_ASSIGNED_OLD_BUNDLE_LIST",
  SAVE_ASSIGNED_BUNDLE_LIST: "SAVE_ASSIGNED_BUNDLE_LIST",
  GET_Product_VARIANT_BY_PRODUCTION_CENTER:
    "GET_Product_VARIANT_BY_PRODUCTION_CENTER",
  SET_PRODUCT_VARIANT_BY_PRODUCTION_CENTER:
    "SET_PRODUCT_VARIANT_BY_PRODUCTION_CENTER",
  GET_SAMPLE_BATCH_DETAILS: "GET_SAMPLE_BATCH_DETAILS",
  SET_SAMPLE_BATCH_DETAILS: "SET_SAMPLE_BATCH_DETAILS",
  SET_POPUP_SAMPLES: "SET_POPUP_SAMPLES",
  FINISH_INSPECTION: "FINISH_INSPECTION",
  FINISH_INSPECTION_SUCCESS: "FINISH_INSPECTION_SUCCESS",
  SET_FINISH_INSPECTION: "SET_FINISH_INSPECTION",
  GET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID:
    "GET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID",
  SET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID:
    "SET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID",
  SET_SAMPLE_BATCH_FORM_EDIT: "SET_SAMPLE_BATCH_FORM_EDIT",
  SET_BATCH_SAMLE_AVAILABLE_DETAILS: "SET_BATCH_SAMLE_AVAILABLE_DETAILS",
  GET_BATCH_SAMLE_AVAILABLE_DETAILS: "GET_BATCH_SAMLE_AVAILABLE_DETAILS",
  SAVE_SAMPLE_INSPECTION_SUMMARY: "SAVE_SAMPLE_INSPECTION_SUMMARY",
  SET_BATCH_ERROR_STATUS: "SET_BATCH_ERROR_STATUS",
  SET_FINISH_INSPECTION_PAGE_STATUS: "SET_FINISH_INSPECTION_PAGE_STATUS",
  SET_MATERIAL_CODE_FOR_HOLDER: "SET_MATERIAL_CODE_FOR_HOLDER",
  GET_MATERIAL_CODE_FOR_HOLDER: "GET_MATERIAL_CODE_FOR_HOLDER",
  GET_MATERIAL_CODE_FOR_CHARGER: "GET_MATERIAL_CODE_FOR_CHARGER",
  SET_MATERIAL_CODE_FOR_CHARGER: "SET_MATERIAL_CODE_FOR_CHARGER",
  GET_VALIDATION_SAMPLE_LIST: "GET_VALIDATION_SAMPLE_LIST",
  SET_VALIDATION_SAMPLE_LIST: "SET_VALIDATION_SAMPLE_LIST",
  SET_VALIDATE_BATCH_DETAILS: "SET_VALIDATE_BATCH_DETAILS",
  GET_VALIDATE_BATCH_DETAILS: "GET_VALIDATE_BATCH_DETAILS",
  GET_CODENTIFY_COE_DETAILS_CHARGER: "GET_CODENTIFY_COE_DETAILS_CHARGER",
  SET_CODENTIFY_COE_DETAILS_CHARGER: "SET_CODENTIFY_COE_DETAILS_CHARGER",
  GET_CODENTIFY_COE_DETAILS_KIT: "GET_CODENTIFY_COE_DETAILS_KIT",
  SET_CODENTIFY_COE_DETAILS_KIT: "SET_CODENTIFY_COE_DETAILS_KIT",
  GET_CODENTIFY_COE_DETAILS_HOLDER: "GET_CODENTIFY_COE_DETAILS_HOLDER",
  SET_CODENTIFY_COE_DETAILS_HOLDER: "SET_CODENTIFY_COE_DETAILS_HOLDER",
  GET_ASSIGNED_BUNDLE_LIST_DATA: "GET_ASSIGNED_BUNDLE_LIST_DATA",
  GET_VALLIDATED_SAMPLES_BY_SAMPLENO: "GET_VALLIDATED_SAMPLES_BY_SAMPLENO",
  SET_VALLIDATED_SAMPLES_BY_SAMPLENO: "SET_VALLIDATED_SAMPLES_BY_SAMPLENO",
  SET_SAVE_SUMMARY_LOADING: "SET_SAVE_SUMMARY_LOADING",
  GET_FINISH_INSPECTION_SAMPLE_LIST: "GET_FINISH_INSPECTION_SAMPLE_LIST",
  SET_FINISH_INSPECTION_SAMPLE_LIST: "SET_FINISH_INSPECTION_SAMPLE_LIST",
  GET_AUDIT_SUB_TYPE_LIST: "GET_AUDIT_SUB_TYPE_LIST",
  SET_AUDIT_SUB_TYPE_LIST: "SET_AUDIT_SUB_TYPE_LIST",
  CHECK_CODENTIFY_AVAILABILITY: "CHECK_CODENTIFY_AVAILABILITY",
  SET_CODENTIFY_AVAILABILITY: "SET_CODENTIFY_AVAILABILITY",
  SET_REDIRECT_ON_FINISH_INSPECTION: "SET_REDIRECT_ON_FINISH_INSPECTION",
  SET_CODENTIFY_VISIBLITY_KIT: "SET_CODENTIFY_VISIBLITY_KIT",
};

export const actions = {
  saveLogNewDeviceBatch: (payload) => ({
    type: actionTypes.SAVE_LogNewDeviceBatch,
    payload,
  }),
  setCodentifyVisibilityKit: (payload) => ({
    type: actionTypes.SET_CODENTIFY_VISIBLITY_KIT,
    payload,
  }),

  setProdCenter: (payload) => ({
    type: actionTypes.SET_Prod_Center,
    payload,
  }),

  setProdVariant: (payload) => ({
    type: actionTypes.SET_Prod_Variant,
    payload,
  }),

  getAuditDropdowns: (payload) => ({
    type: actionTypes.GET_Audit_Dropdowns,
    payload,
  }),

  setAuditDropdowns: (payload) => ({
    type: actionTypes.SET_Audit_Dropdowns,
    payload,
  }),

  getAuditSubTypeDropdowns: (payload) => ({
    type: actionTypes.GET_AUDIT_SUB_TYPE_LIST,
    payload,
  }),

  setAuditSubTypeDropdowns: (payload) => ({
    type: actionTypes.SET_AUDIT_SUB_TYPE_LIST,
    payload,
  }),

  getProductVariantByProdutionCenter: (payload) => ({
    type: actionTypes.GET_Product_VARIANT_BY_PRODUCTION_CENTER,
    payload,
  }),

  setProductVariantByProductionCenter: (payload) => ({
    type: actionTypes.SET_PRODUCT_VARIANT_BY_PRODUCTION_CENTER,
    payload,
  }),

  getProductVariants: (payload) => ({
    type: actionTypes.GET_Product_Variants,
    payload,
  }),

  setProductVariant: (payload) => ({
    type: actionTypes.SET_Product_Variant,
    payload,
  }),

  getInspectorList: (payload) => ({
    type: actionTypes.GET_INSPECTOR_LIST,
    payload,
  }),

  setInspectorList: (payload) => ({
    type: actionTypes.SET_INSPECTOR_LIST,
    payload,
  }),

  getAssignedBundleList: (payload) => ({
    type: actionTypes.GET_ASSIGNED_BUNDLE_LIST,
    payload,
  }),

  setAssignedBundleList: (payload) => ({
    type: actionTypes.SET_ASSIGNED_BUNDLE_LIST,
    payload,
  }),

  saveAssignedBundleList: (payload, params) => ({
    type: actionTypes.SAVE_ASSIGNED_BUNDLE_LIST,
    payload: payload,
    params: params,
  }),

  getAssignedBundleListData: (payload) => ({
    type: actionTypes.GET_ASSIGNED_BUNDLE_LIST_DATA,
    payload,
  }),

  getSampleBatchDetails: (payload) => ({
    type: actionTypes.GET_SAMPLE_BATCH_DETAILS,
    payload,
  }),

  setSampleBatchDetails: (payload) => ({
    type: actionTypes.SET_SAMPLE_BATCH_DETAILS,
    payload,
  }),

  submitPopupSamples: (payload, batchParams) => ({
    type: actionTypes.SET_POPUP_SAMPLES,
    payload,
    batchParams,
  }),

  finishInspection: (payload) => ({
    type: actionTypes.FINISH_INSPECTION,
    payload,
  }),

  setFinishInspection: (payload) => ({
    type: actionTypes.SET_FINISH_INSPECTION,
    payload,
  }),

  getSampleBatchDetailsBySampleId: (payload) => ({
    type: actionTypes.GET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID,
    payload,
  }),

  setSampleBatchDetailsBySampleId: (payload) => ({
    type: actionTypes.SET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID,
    payload,
  }),

  setSampleBatchFormEdit: (payload) => ({
    type: actionTypes.SET_SAMPLE_BATCH_FORM_EDIT,
    payload,
  }),

  saveSampleInspectionSummary: (payload) => ({
    type: actionTypes.SAVE_SAMPLE_INSPECTION_SUMMARY,
    payload,
  }),

  setBatchErrorStatus: (payload) => ({
    type: actionTypes.SET_BATCH_ERROR_STATUS,
    payload,
  }),

  setFinishInspectionPageStatus: (payload) => ({
    type: actionTypes.SET_FINISH_INSPECTION_PAGE_STATUS,
    payload,
  }),

  getbatchSampleDetails: (payload) => ({
    type: actionTypes.GET_BATCH_SAMLE_AVAILABLE_DETAILS,
    payload,
  }),

  setbatchSampleDetails: (payload) => ({
    type: actionTypes.SET_BATCH_SAMLE_AVAILABLE_DETAILS,
    payload,
  }),

  getMaterialCodeForHolder: (payload) => ({
    type: actionTypes.GET_MATERIAL_CODE_FOR_HOLDER,
    payload,
  }),

  setMaterialCodeForHolder: (payload) => ({
    type: actionTypes.SET_MATERIAL_CODE_FOR_HOLDER,
    payload,
  }),

  getMaterialCodeForCharger: (payload) => ({
    type: actionTypes.GET_MATERIAL_CODE_FOR_CHARGER,
    payload,
  }),

  setMaterialCodeForCharger: (payload) => ({
    type: actionTypes.SET_MATERIAL_CODE_FOR_CHARGER,
    payload,
  }),

  getValidationSampleList: (payload) => ({
    type: actionTypes.GET_VALIDATION_SAMPLE_LIST,
    payload,
  }),

  setValidationSampleList: (payload) => ({
    type: actionTypes.SET_VALIDATION_SAMPLE_LIST,
    payload,
  }),

  getCodentifyCodeDetailsKit: (payload) => ({
    type: actionTypes.GET_CODENTIFY_COE_DETAILS_KIT,
    payload,
  }),

  setCodentifyCodeDetailsKit: (payload) => ({
    type: actionTypes.SET_CODENTIFY_COE_DETAILS_KIT,
    payload,
  }),

  getCodentifyCodeDetailsHolder: (payload) => ({
    type: actionTypes.GET_CODENTIFY_COE_DETAILS_HOLDER,
    payload,
  }),

  setCodentifyCodeDetailsHolder: (payload) => ({
    type: actionTypes.SET_CODENTIFY_COE_DETAILS_HOLDER,
    payload,
  }),

  getCodentifyCodeDetailsCharger: (payload) => ({
    type: actionTypes.GET_CODENTIFY_COE_DETAILS_CHARGER,
    payload,
  }),

  setCodentifyCodeDetailsCharger: (payload) => ({
    type: actionTypes.SET_CODENTIFY_COE_DETAILS_CHARGER,
    payload,
  }),

  validateBatchDetails: (payload, params) => ({
    type: actionTypes.GET_VALIDATE_BATCH_DETAILS,
    payload: payload,
    params: params,
  }),

  setValidateBatchDetails: (payload) => ({
    type: actionTypes.SET_VALIDATE_BATCH_DETAILS,
    payload,
  }),

  getValidatedSamplesBySampleNo: (payload) => ({
    type: actionTypes.GET_VALLIDATED_SAMPLES_BY_SAMPLENO,
    payload,
  }),

  setValidatedSamplesBySampleNo: (payload) => ({
    type: actionTypes.SET_VALLIDATED_SAMPLES_BY_SAMPLENO,
    payload,
  }),

  setSaveSummaryLoading: (payload) => ({
    type: actionTypes.SET_SAVE_SUMMARY_LOADING,
    payload,
  }),

  getFinishInspectionSamplesList: (payload) => ({
    type: actionTypes.GET_FINISH_INSPECTION_SAMPLE_LIST,
    payload,
  }),

  setFinishInspectionSamplesList: (payload) => ({
    type: actionTypes.SET_FINISH_INSPECTION_SAMPLE_LIST,
    payload,
  }),

  setFinishInspectionSuccess: (payload) => ({
    type: actionTypes.FINISH_INSPECTION_SUCCESS,
    payload,
  }),

  checkCodentifyUsability: (payload) => ({
    type: actionTypes.CHECK_CODENTIFY_AVAILABILITY,
    payload,
  }),

  setCodentifyAvailabilty: (payload) => ({
    type: actionTypes.SET_CODENTIFY_AVAILABILITY,
    payload,
  }),

  setRedirectOnFinishInspection: (payload) => ({
    type: actionTypes.SET_REDIRECT_ON_FINISH_INSPECTION,
    payload,
  }),
};
