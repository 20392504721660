import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getDictionaryUpdateFamilyAPI,
  addFamilyDictionaryAPI,
  updateFamilyDictionaryAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../../state/actions";

function* getDictionaryFamilySaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryUpdateFamilyAPI, action.payload);

    yield put(actions.setDictionaryUpdateFamily(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addDictionaryFamilySaga(action) {
  try {
    const _api = yield call(addFamilyDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if(error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }  
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

function* updateDictionaryFamilySaga(action) {
  try {
    const _api = yield call(updateFamilyDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    if (error.response.data.status == 400) {
      yield put(commonActions.errorAlert(error.response.data.data));
    }
    else if(error.response.data.status == 409) {
      yield put(commonActions.infoAlert(error.response.data.data));
    }  
    else {
      yield put(commonActions.errorAlert(error.response.data.error.ErrorMessage || "Something went wrong!"));
    }
  }
}

export function* watchDictionaryFamilySaga() {
  yield all([
    takeEvery(actionTypes.GET_Dictionary_Family, getDictionaryFamilySaga),
    takeEvery(actionTypes.ADD_Dictionary_Family, addDictionaryFamilySaga),
    takeEvery(actionTypes.UPDATE_Dictionary_Family, updateDictionaryFamilySaga),
  ]);
}