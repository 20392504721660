/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchNewsAPI = (id) => {
  return API.get("/adminService/api/News/getNews")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchAddNewsAPI = (payload) => {
  return API.post("/adminService/api/News/addNews", JSON.stringify(payload))
    .then((res) => res)
    .catch((error) => error);
}

export const fetchNewsByIdAPI = (id) => {
  return API.get("/adminService/api/News/editNews?id=" + id)
    .then((res) => res)
    .catch((error) => error);
};

export const fetchNewsEditAPI = (payload) => {
  return API.put("/adminService/api/News/saveNews", JSON.stringify(payload))
    .then((res) => res)
    .catch((error) => error);
};

export const fetchDeleteNewsAPI = (payload) => {
  return API.delete("/adminService/api/News/deleteNews", { data: JSON.stringify(payload) })
    .then((res) => res)
    .catch((error) => error);
}