/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {
  newsData: [],
  newsEdit: {
    title: '',
    abstract: '',
    body: '',
    publishingDate: null
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_NEWS:
      return {
        ...state,
        newsData: payload,
      };

    case actionTypes.SET_NEWS_BY_ID:
      return {
        ...state,
        newsEdit: payload[0],
      };

    case actionTypes.SET_NEWS_EDIT:
      return {
        ...state,
        newsEdit: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
