/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchMarketedBrandAPI = () => {
  return API.get("/adminService/api/Admin/getMarketCompanyGroup")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchSubMarketAPI = () => {
  return API.get("/adminService/api/Admin/getMarketSubMarket")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchCompanyGroupAPI = () => {
  return API.get("/adminService/api/Admin/getCompanyGroup")
    .then((res) => res)
    .catch((error) => error);
};

export const deleteCompanyGroupAPI = (payload) => {
  return API.delete("/adminService/api/Admin/deleteMarketCompanyGroup", {
    data: JSON.stringify(payload),
  })
    .then((res) => res)
    .catch((error) => error);
};

export const addMarkedBrandAPI = (payload) => {
  return API.post("/adminService/api/Admin/addMarketCompanyGroup", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const editMarketedBrandAPI = (payload) => {
  return API.put("/adminService/api/Admin/saveMarketCompanyGroup", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const addSubMarketAPI = (payload) => {
  return API.post("/adminService/api/Admin/addMarketSubMarket", payload).then(
    (res) => res
  );
};

export const editSubMarketAPI = (payload) => {
  return API.put(
    "/adminService/api/Admin/saveSubMarketCompanyGroup",
    payload
  ).then((res) => res);
};

export const deleteSubMarketAPI = (payload) => {
  return API.delete("/adminService/api/Admin/deleteMarketSubMarket", {
    data: JSON.stringify(payload),
  }).then((res) => res);
};

export const addCompanyGroupAPI = (payload) => {
  return API.post("/adminService/api/Admin/addCompanyGroup", payload).then(
    (res) => res
  );
};

export const editCompanyGroupAPI = (payload) => {
  return API.put("/adminService/api/Admin/saveCompanyGroup", payload).then(
    (res) => res
  );
};

//  drop downs

export const fetchAllMarketListAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getMarketList", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchBrandGroupListAPI = (payload) => {
  return API.get("/adminService/api/BrandGroups/getBrandGroupsByMarket", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchCompanyGroupListAPI = () => {
  return API.get("/adminService/api/MasterData/getCompanyGroupList")
    .then((res) => res)
    .catch((error) => error);
};
