/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchNewsAPI,
  fetchNewsByIdAPI,
  fetchNewsEditAPI,
  fetchAddNewsAPI,
  fetchDeleteNewsAPI
} from "../api/endpoint";
import { actions as commonActions } from 'store/actions/common';

function* getNewsSaga(action) {
  try {
    const _api = yield call(fetchNewsAPI, action.payload);
    yield put(actions.setNews(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addNewsSaga(action) {
  try {
    const _api = yield call(fetchAddNewsAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getNews());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error.error.message));
  }
}

function* getNewsByIdSaga(action) {
  try {
    const _api = yield call(fetchNewsByIdAPI, action.payload);
    yield put(actions.setNewsById(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editNewsSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(fetchNewsEditAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getNews());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error.error.message));
  }
}

function* deleteNewsSaga(action) {
  try {
    const _api = yield call(fetchDeleteNewsAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getNews());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error.error.message));
  }
}

export function* watchNewsSaga() {
  yield all([
    takeEvery(actionTypes.GET_NEWS, getNewsSaga),
    takeEvery(actionTypes.GET_NEWS_BY_ID, getNewsByIdSaga),
    takeEvery(actionTypes.EDIT_NEWS, editNewsSaga),
    takeEvery(actionTypes.ADD_NEWS, addNewsSaga),
    takeEvery(actionTypes.DELETE_NEWS, deleteNewsSaga)
  ]);
}
