/*eslint-disable */
export const actionTypes = {
  VALIDATE_UPLOAD_FILE: "VALIDATE_UPLOAD_FILE",
  SET_UPLOAD_FILE: "SET_UPLOAD_FILE",
  UPLOAD_FILE: "UPLOAD_FILE",
  GET_IMAGE_NAME: "GET_IMAGE_NAME"
};

export const actions = {
  validateUploadFile: (payload) => ({
    type: actionTypes.VALIDATE_UPLOAD_FILE,
    payload
  }),
  setUploadFile: (payload) => ({
    type: actionTypes.SET_UPLOAD_FILE,
    payload
  }),
  uploadFile: (payload) => ({
    type: actionTypes.UPLOAD_FILE,
    payload
  }),
  getImageNames: (payload) => ({
    type: actionTypes.GET_IMAGE_NAME,
    payload
  })
};
