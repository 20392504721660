import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "components/LogoSection/LogoSection";
import SearchSection from "components/Search/Search";
import HelpAndSupport from "components/Help/Help";
import Dictionary from "components/NavbarDictionary/NavbarDictionary";
import ELearning from "components/ELearning/ELearning";
import Button from "@mui/material/Button";
import DefectDictionary from "../../containers/Dashboard/DashboardDetails/DashboardDictionaryDisplay";

// assets
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AdvanceSearch from "../../containers/Dashboard/DashboardDetails/AdvanceSearch";
import SearchResult from "../../containers/Dashboard/MySamples/MySamplesDetails/MySampleSearchResult";
import LanguagesList from "../../containers/Translations/FindDictionary/LanguagesList/LanguagesList";
import { actions as commonActions } from "store/actions/common";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

import { actions } from "../../containers/Dashboard/MySamples/state/actions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  flex: {
    display: "flex",
    paddingLeft: "3rem",
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    transition: "0.5s ease",
  },
  hide: {
    display: "none",
  },
}));

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openAdvanceSearch, setAdvanceSearchOpen] = useState(false);

  const handleAdvanceSearchClose = () => setAdvanceSearchOpen(false);
  const commonState = useSelector((state) => state.customization);
  const [openDefectDictionary, setDefectDictionaryOpen] = useState(false);
  const handleDefectDictionaryOpen = () => {
    setDefectDictionaryOpen(true);
  };
  const menuKeys = commonState?.menus?.map((menu) => menu?.pageKey || "") || [];
  const handleDefectDictionaryClose = () => setDefectDictionaryOpen(false);
  const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1]; //"bghosh";
  const [languageCode, setLanguageCode] = useState({
    code: "EN",
    name: "English",
  });
  const userMembershipRoles = JSON.parse(
    sessionStorage.getItem("userMembershipRoleInfo")
  );
  const handleLanguageChange = (e, value) => {
    setLanguageCode((prevState) => ({
      ...prevState,
      code: value.props.value || "EN",
      name: value.props.children || "English",
    }));
    const languageSelected = {
      code: value.props.value,
      name: value.props.children,
    };
    dispatch(commonActions.setLanguageCode(languageSelected));
  };

  const alloweduserBatchRoles = (roles) =>
    roles?.map((role) => role?.split(" ")?.join("")?.toLowerCase()).join(",");

  const isUserAdmin = alloweduserBatchRoles(userMembershipRoles)?.includes(
    "cvqacentraladministrator"
  );

  const [openSearchResult, setSearchresultOpen] = React.useState(false);
  const [searchType, setSearchType] = React.useState("");
  const [openSearchResultData, setSearchresultData] = React.useState([]);
  const HandleSearchResultOpen = (value, type) => {
    //alert(type,value);
    const params = {
      sampleId: value, //4541465
      userLogin: "PMI\\" + login,
    };
    setSearchType(type);
    setSearchresultData(params);
    setSearchresultOpen(true);
  };
  const HandleAdvanceSearchOpen = () => {
    setAdvanceSearchOpen(true);
    //setSearchType("AdvSearch")
  };
  const HandleFindDictionaryOpen = () => {};
  const handleSearchResultClose = () => setSearchresultOpen(false);

  return (
    <>
      {/* logo & toggler button */}
      <div className={classes.header}>
        <Box
          sx={{
            width: leftDrawerOpened ? 270 : 30,
            display: "flex",
            [theme.breakpoints.down("md")]: {
              width: "auto",
            },
          }}
        >
          <Box
            component="span"
            className={leftDrawerOpened ? classes.flex : classes.hide}
            sx={{
              display: { xs: "none", md: "block" },
              flexGrow: 1,
            }}
          >
            <LogoSection />
          </Box>
          <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                transition: "all .2s ease-in-out",
                // background: theme.palette.secondary.light,
                // color: theme.palette.secondary.dark,
                color: "#fff",
                background: "#143d77",
                "&:hover": {
                  background: "#fff",
                  color: "#143d77",
                },
              }}
              onClick={handleLeftDrawerToggle}
              color="inherit"
            >
              <MenuOutlinedIcon size="1.3rem" />
            </Avatar>
          </ButtonBase>
        </Box>

        {/* header search */}
        {menuKeys.includes("auditLogin") && (
          <>
            <SearchSection onSearchButtonClik={HandleSearchResultOpen} />
            <Button size="small" onClick={HandleAdvanceSearchOpen}>
              Advance Search
            </Button>
            {openAdvanceSearch && (
              <AdvanceSearch
                open={openAdvanceSearch}
                handleClose={handleAdvanceSearchClose}
                onAdvanceSearchClick={HandleSearchResultOpen}
              />
            )}
            {openSearchResult && (
              <SearchResult
                open={openSearchResult}
                handleClose={handleSearchResultClose}
                param={openSearchResultData}
                type={searchType}
              />
            )}
          </>
        )}

        {openDefectDictionary && (
          <DefectDictionary
            open={openDefectDictionary}
            handleClose={handleDefectDictionaryClose}
          />
        )}
        <LanguagesList
          language={languageCode}
          handleLanguageChange={handleLanguageChange}
          isGlobal={true}
        />
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ flexGrow: 1 }} />
        {/* <div>User: Admin - User name</div> */}

        {isUserAdmin && <HelpAndSupport />}
        <Dictionary handleDictionaryOpen={handleDefectDictionaryOpen} />
        {/* <ELearning /> */}
      </div>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
