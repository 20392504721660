/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchLocalProjectTypesAPI = (payload) => {
  return API.get("/adminService/api/LocalProject/getLocalProject", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const disableLocalProjectTypeAPI = (payload) => {
  return API.put("/adminService/api/LocalProject/disableLocalProject", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const addLocalProjectTypeAPI = (payload) => {
  return API.post(
    "/adminService/api/LocalProject/addLocalProject",
    payload
  ).then((res) => res);
};

export const updateLocalProjectTypeAPI = (payload) => {
  return API.put(
    "/adminService/api/LocalProject/saveLocalProject",
    payload
  ).then((res) => res);
};
