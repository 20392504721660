/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchProductionCenterAPI,
  fetchLaboratoryOptionsAPI,
  getProductionCenterByIdAPI,
  editProductionCenterAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getProductionCenterSaga(action) {
  try {
    const _api = yield call(fetchProductionCenterAPI, action.payload);
    yield put(actions.setProductionCenters(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getLaboratoryOptionsSaga(action) {
  try {
    const _api = yield call(fetchLaboratoryOptionsAPI, action.payload);
    yield put(actions.setLaboratoryOptions(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProductionCenterByIdSaga(action) {
  try {
    const _api = yield call(getProductionCenterByIdAPI, action.payload);
    yield put(actions.setProductionCenterById(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editProductionCenterSaga(action) {
  try {
    const _api = yield call(editProductionCenterAPI, action.payload);

    // yield put(actions.setProductionCenters(_api.data.data));
    //yield put(actions.getProductionCenters()); --commented for PI testing
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchProductionCenterSaga() {
  yield all([
    takeEvery(actionTypes.GET_PRODUCTION_CENTERS, getProductionCenterSaga),
    takeEvery(actionTypes.GET_LABOATORY_OPTIONS, getLaboratoryOptionsSaga),
    takeEvery(
      actionTypes.GET_PRODUCTION_CENTER_BY_ID,
      getProductionCenterByIdSaga
    ),
    takeEvery(actionTypes.EDIT_PRODUCTION_CENTER, editProductionCenterSaga),
  ]);
}
