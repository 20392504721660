/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchAttributesAPI,
  hideAttributeAPI,
  getAttributeValuesAPI,
  getAttributeByIdAPI,
  saveAttributeAPI,
  AddAttributeValueAPI,
  editAttributeValuesAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getAttributeSaga(action) {
  try {
    const _api = yield call(fetchAttributesAPI, action.payload);
    yield put(actions.setAttributes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* hideAttributeSaga(action) {
  try {
    const _api = yield call(hideAttributeAPI, action.payload);
    // yield put(actions.setAttributes(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(actions.getAttributes(action.payload));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAttributeValuesSaga(action) {
  try {
    const _api = yield call(getAttributeValuesAPI, action.payload);
    yield put(actions.setAttributeValues(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAttributesByIdSaga(action) {
  try {
    const _api = yield call(getAttributeByIdAPI, action.payload);
    yield put(actions.setAttributeById(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* saveAttributeSaga(action) {
  try {
    const _api = yield call(saveAttributeAPI, action.payload);
    // yield put(actions.setAttributeById(_api.data.data[0]));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Attribute saved successfully"));
    yield put(actions.getAttributes(action.payload));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* addAttributeValueSaga(action) {
  try {
    const _api = yield call(AddAttributeValueAPI, action.payload);
    // yield put(actions.setAttributeById(_api.data.data[0]));
    if (_api.status && _api.status === 200) {
      const params = {
        requestData: action.payload.attributeCodeDomain,
        dictionaryType: action.payload.dictionaryType,

      };
      yield put(
        commonActions.successAlert("Attribute value added successfully")
      );
      yield put(actions.getAttributeValues(params));
    }
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* editAttributeValueSaga(action) {
  try {
    const _api = yield call(editAttributeValuesAPI, action.payload);
    // yield put(actions.setAttributeById(_api.data.data[0]));

    if (_api.status && _api.status === 200) {
      const params = {
        requestData: action.payload.attributeCodeDomain,
        dictionaryType: action.payload.dictionaryType,
      };
      yield put(
        commonActions.successAlert("Attribute value edited successfully")
      );
      yield put(actions.getAttributeValues(params));
    }
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

export function* watchAttributesSaga() {
  yield all([
    takeEvery(actionTypes.GET_ATTRIBUTES, getAttributeSaga),
    takeEvery(actionTypes.HIDE_ATTRIBUTE, hideAttributeSaga),
    takeEvery(actionTypes.GET_ATTRIBUTE_VALUES, getAttributeValuesSaga),
    takeEvery(actionTypes.GET_ATTRIBUTE_BY_ID, getAttributesByIdSaga),
    takeEvery(actionTypes.SAVE_ATTRIBUTE, saveAttributeSaga),
    takeEvery(actionTypes.ADD_ATTRIBUTE_VALUE, addAttributeValueSaga),
    takeEvery(actionTypes.EDIT_ATTRIBUTE_VALUE, editAttributeValueSaga),
  ]);
}
