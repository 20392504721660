import { useState, useEffect } from 'react';

export const getLocalStorageOrDefault = (key, defaultValue) => {
  const stored = localStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return isJsonParsable(stored) ? JSON.parse(stored) : stored;
}

export const useLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
}


var isJsonParsable = string => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
}