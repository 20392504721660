import { actionTypes } from "./actions";

const initialState = {
    TransDictionary: {
      id:"",
      objectId: "",
      translatedField: "",
      isPublish: "",
      originalField: "",
      canApprove: "",
      canEdit:"",
      languageCode:"",
      dictionaryType:"",
      level:"",
    },
    TransDefectDictionary:{},
    // TransDefectDictionaryEdit:{
    //   low:"",
    //   high: "",
    //   comments: "",
    // },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
      
    case actionTypes.SET_FindInDictionaryLevel:
      return {
        ...state,
        TransDictionary: payload,
      };

      
      // case actionTypes.SET_EditDefectTranslation:
      //   return {
      //     ...state,
      //     TransDefectDictionaryEdit: payload,
      //   };

      case actionTypes.SET_FindInDictionaryDefect:
        return {
          ...state,
          TransDefectDictionary: payload,
        };

       

    default:
      return {
        ...state,
      };
  }
};
