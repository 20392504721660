import { takeEvery, call, put, delay, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  //getAuditTypeAPI,
  getAuditDropdownsAPI,
  getProductVariantAPI,
  saveLogNewDeviceBatchAPI,
  getBundleListApi,
  getInspectorListApi,
  saveBundleListApi,
  getSampleBatchDetailsAPI,
  savePopupSamplesApi,
  finishInspectionApi,
  getBatchSampleBySampleIdAPI,
  getBatchSampleAvailableDetailsApi,
  saveSampleInspectionSummaryAPI,
  getMaterialCodeApi,
  getValidationSampleListApi,
  getValidateBatchDetailsApi,
  getSampleCodentifyCodeAPI,
  getValidatedSamplesBySampleNoAPI,
  getCodentifyDataAPI,
  getAuditSubTypeListAPI,
  checkCodentifyAvailabilityApi,
  getSampleExistCountAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

// function* getAuditTypeSaga(action){
//   try{
//     const _api = yield call(getAuditTypeAPI, action.payload);
//     console.log("Get Audit Type",  _api.data.data);
//     yield put(actions.setAuditType(_api.data.data));
//     yield put(commonActions.setLoading(false));
//   } catch (error) {
//     console.log(error);
//   }
// }

function* getAuditDropdownsSaga(action) {
  try {
    const _api = yield call(getAuditDropdownsAPI, action.payload);
    yield put(actions.setAuditDropdowns(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getProductVariantsSaga(action) {
  try {
    console.log("Saga--->", action.payload);
    const _api = yield call(getProductVariantAPI, action.payload);
    yield put(actions.setProductVariant(_api.data.data));
    console.log("Saga--->", _api.data.data);
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getProductVariantByProdCenterSaga(action) {
  try {
    const _api = yield call(getProductVariantAPI, action.payload);
    yield put(actions.setProductVariantByProductionCenter(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getBundleListSaga(action) {
  try {
    const _api = yield call(getBundleListApi, action.payload);
    yield put(actions.setAssignedBundleList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}
function* saveAssignedBundleListSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(saveBundleListApi, action.payload, action.params);
    yield put(actions.getAssignedBundleListData(true));

    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* finishInspection(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(finishInspectionApi, action.payload);
    yield put(actions.setFinishInspection(_api.data.data));
    if (
      _api.data?.data?.availablE_SAMPLE_NO_DETAILS == "0" &&
      _api.data?.data?.availablE_SAMPLE_NO_SAVED_NC == "0"
    ) {
      yield put(actions.setFinishInspectionSuccess(true));
      yield put(actions.setRedirectOnFinishInspection(true));
      yield put(commonActions.successAlert("Finished Inspection Successfully"));
    } else {
      yield put(actions.setRedirectOnFinishInspection(false));
      yield put(actions.setFinishInspectionSuccess(false));
    }
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* savePopupSamplesSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(savePopupSamplesApi, action.payload);

    if (_api.data.status == 200) {
      yield put(commonActions.successAlert(_api.data.data));
      yield put(commonActions.setLoading(false));
      // console.log(action.sampleParams, "sampleParams");
      // yield delay(10000);
      // yield put(actions.getSampleBatchDetails(action.batchParams));
      // yield put(actions.getSampleExistCount(action.sampleParams));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

// function* getSampleExistCountSaga(action) {
//   try {
//     const _api = yield call(getSampleExistCountAPI, action.payload);
//     yield put(actions.setSampleExistCount(_api.data.data));
//     // yield put(commonActions.setLoading(false));
//     console.log(_api);
//   } catch (error) {
//     // console.log(error);
//     yield put(commonActions.setLoading(false));
//     yield put(commonActions.errorAlert("Something went wrong"));
//   }
// }

function* getInspectorListSaga(action) {
  try {
    const _api = yield call(getInspectorListApi, action.payload);
    yield put(actions.setInspectorList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

//save
function* saveLogNewDeviceBatchSaga(action) {
  try {
    const _api = yield call(saveLogNewDeviceBatchAPI, action.payload);
    const successAlertMessage =
      _api.data.data.message + " " + _api.data.data.batchId;
    yield put(commonActions.successAlert(successAlertMessage));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getSampleBatchDetailsSaga(action) {
  try {
    const _api = yield call(getSampleBatchDetailsAPI, action.payload);
    yield put(actions.setSampleBatchDetails(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(actions.setBatchErrorStatus(true));
    yield put(
      commonActions.errorAlert(
        error?.response?.data?.error?.errorMessage || "Something Went Wrong"
      )
    );
  }
}

function* getBatchSampleDetailsBySampleIdSaga(action) {
  try {
    const _api = yield call(getBatchSampleBySampleIdAPI, action.payload);
    yield put(actions.setSampleBatchDetailsBySampleId(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* saveSampleBatchInspectionSummarySaga(action) {
  try {
    const _api = yield call(saveSampleInspectionSummaryAPI, action.payload);
    yield put(commonActions.successAlert("Saved Successfully"));
    yield put(commonActions.setLoading(false));
    yield put(actions.setSaveSummaryLoading(false));
    yield put(actions.setFinishInspectionPageStatus(true));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(actions.setFinishInspectionPageStatus(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getBatchSampleAvailableDetails(action) {
  try {
    const _api = yield call(getBatchSampleAvailableDetailsApi, action.payload);
    yield put(actions.setbatchSampleDetails(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getMaterialCodeForHolderSaga(action) {
  try {
    const _api = yield call(getMaterialCodeApi, action.payload);
    yield put(actions.setMaterialCodeForHolder(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getMaterialCodeForChargerSaga(action) {
  try {
    const _api = yield call(getMaterialCodeApi, action.payload);
    yield put(actions.setMaterialCodeForCharger(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getValidationSampleListSaga(action) {
  try {
    const _api = yield call(getValidationSampleListApi, action.payload);
    yield put(actions.setValidationSampleList(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getcodentifyCodeDetailsKit(action) {
  try {
    const _api = yield call(getSampleCodentifyCodeAPI, action.payload);
    if (_api.status === 200) {
      const _apiData = yield call(getCodentifyDataAPI, _api.data);
      if (_apiData.status === 200) {
        if (
          _apiData.data?.data?.productVariant == action.payload.product_variant
        ) {
          yield put(actions.setCodentifyCodeDetailsKit(_apiData.data.data));
          yield put(actions.setCodentifyVisibilityKit(true));
        } else {
          yield put(actions.setCodentifyVisibilityKit(false));

          yield put(
            commonActions.errorAlert(
              "Issue on the Product Variant.It must be matched on Batch Form Product Variant"
            )
          );
        }
      }
    }
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}
function* getValidateBatchDetailsSaga(action) {
  try {
    const _api = yield call(
      getValidateBatchDetailsApi,
      action.payload,
      action.params
    );

    yield put(actions.setValidateBatchDetails(true));
    yield put(commonActions.successAlert("Batch Validated Sucessfully"));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}
function* getcodentifyCodeDetailsCharger(action) {
  try {
    const _api = yield call(getSampleCodentifyCodeAPI, action.payload);
    if (_api.status === 200) {
      const _apiData = yield call(getCodentifyDataAPI, _api.data);

      yield put(actions.setCodentifyCodeDetailsCharger(_apiData.data.data));
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getcodentifyCodeDetailsHolder(action) {
  try {
    const _api = yield call(getSampleCodentifyCodeAPI, action.payload);
    if (_api.status === 200) {
      const _apiData = yield call(getCodentifyDataAPI, _api.data);
      yield put(actions.setCodentifyCodeDetailsHolder(_apiData.data.data));
    }
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getValidatedSamplesBySampleNoSaga(action) {
  try {
    const _api = yield call(getValidatedSamplesBySampleNoAPI, action.payload);
    yield put(actions.setValidatedSamplesBySampleNo(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getFinishInspectionSampleListSaga(action) {
  try {
    const _api = yield call(getValidationSampleListApi, action.payload);
    yield put(actions.setFinishInspectionSamplesList(_api.data.data));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getAuditSubTypeListSaga(action) {
  try {
    const _api = yield call(getAuditSubTypeListAPI, action.payload);
    yield put(actions.setAuditSubTypeDropdowns(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

function* checkCodentifyAvailabilitySaga(action) {
  try {
    const _api = yield call(checkCodentifyAvailabilityApi, action.payload);
    yield put(actions.setCodentifyAvailabilty(_api.data.data));

    if (_api?.data?.data?.isExist === 1) {
      yield put(actions.setCodentifyAvailabilty(true));

      yield put(
        commonActions.errorAlert(
          "Codentify Code is already used in another sample of this batch "
        )
      );
    } else {
      yield put(actions.setCodentifyAvailabilty(false));
    }
    yield put(commonActions.setLoading(false));
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

export function* watchBatchDeviceSaga() {
  yield all([
    //takeEvery(actionTypes.GET_Audit_Type, getAuditTypeSaga),
    takeEvery(actionTypes.GET_Audit_Dropdowns, getAuditDropdownsSaga),
    takeEvery(actionTypes.GET_Product_Variants, getProductVariantsSaga),
    takeEvery(actionTypes.SAVE_LogNewDeviceBatch, saveLogNewDeviceBatchSaga),
    takeEvery(actionTypes.GET_ASSIGNED_BUNDLE_LIST, getBundleListSaga),
    takeEvery(actionTypes.GET_INSPECTOR_LIST, getInspectorListSaga),
    takeEvery(
      actionTypes.SAVE_ASSIGNED_BUNDLE_LIST,
      saveAssignedBundleListSaga
    ),
    takeEvery(
      actionTypes.GET_Product_VARIANT_BY_PRODUCTION_CENTER,
      getProductVariantByProdCenterSaga
    ),
    takeEvery(actionTypes.GET_SAMPLE_BATCH_DETAILS, getSampleBatchDetailsSaga),
    takeEvery(actionTypes.SET_POPUP_SAMPLES, savePopupSamplesSaga),
    takeEvery(actionTypes.FINISH_INSPECTION, finishInspection),
    takeEvery(
      actionTypes.GET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID,
      getBatchSampleDetailsBySampleIdSaga
    ),
    takeEvery(
      actionTypes.SAVE_SAMPLE_INSPECTION_SUMMARY,
      saveSampleBatchInspectionSummarySaga
    ),
    takeEvery(
      actionTypes.GET_BATCH_SAMLE_AVAILABLE_DETAILS,
      getBatchSampleAvailableDetails
    ),
    takeEvery(
      actionTypes.GET_MATERIAL_CODE_FOR_HOLDER,
      getMaterialCodeForHolderSaga
    ),
    takeEvery(
      actionTypes.GET_MATERIAL_CODE_FOR_CHARGER,
      getMaterialCodeForChargerSaga
    ),
    takeEvery(
      actionTypes.GET_VALIDATION_SAMPLE_LIST,
      getValidationSampleListSaga
    ),
    takeEvery(
      actionTypes.GET_CODENTIFY_COE_DETAILS_CHARGER,
      getcodentifyCodeDetailsCharger
    ),
    takeEvery(
      actionTypes.GET_CODENTIFY_COE_DETAILS_KIT,
      getcodentifyCodeDetailsKit
    ),
    takeEvery(
      actionTypes.GET_CODENTIFY_COE_DETAILS_HOLDER,
      getcodentifyCodeDetailsHolder
    ),
    takeEvery(
      actionTypes.GET_VALIDATE_BATCH_DETAILS,
      getValidateBatchDetailsSaga
    ),
    takeEvery(
      actionTypes.GET_VALLIDATED_SAMPLES_BY_SAMPLENO,
      getValidatedSamplesBySampleNoSaga
    ),
    takeEvery(
      actionTypes.GET_FINISH_INSPECTION_SAMPLE_LIST,
      getFinishInspectionSampleListSaga
    ),
    takeEvery(actionTypes.GET_AUDIT_SUB_TYPE_LIST, getAuditSubTypeListSaga),
    takeEvery(
      actionTypes.CHECK_CODENTIFY_AVAILABILITY,
      checkCodentifyAvailabilitySaga
    ),
    // takeEvery(actionTypes.GET_SAMPLE_EXIST_COUNT, getSampleExistCountSaga),
  ]);
}
