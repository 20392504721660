import { actionTypes } from "./actions";
const initialState = {
  TranslationGridEdit: [
    {
      ncId: 0,
      ncIntensityLevel: 0,
      defTransId: 0,
      defTransCodeLang: "",
      defTransLow: "",
      defTransHigh: "",
      defTransDescription: "",
      defTransTitle: "",
      defTransComments: "",
      toTransId: 0,
      toTransCodeLang: "",
      toTransLow: "",
      toTransHigh: "",
      toTransDescription: "",
      toTransTitle: "",
      toTransComments: "",
      toTransWoekflowStatusCode: "",
      toTransCreatedBy: "",
      toTransModifiedBy: "",
      toTransVersion: "",
      toTransIsPublish: "",
      canApprove: 0,
      canEdit: 0,
      dictionaryType: "",
    },
  ],
  TranslationGrid: [],
  TranslationEditCell: {},
  TranslationUpdate: {
    translationDescriptionId: "",
    userLogin: "",
    codeLanguage: "",
    version: "",
    description: "",
    low: "",
    high: "",
    comments: "",
    title: "",
    parentDescriptionId: "",
    oldRefVersion: "",
    workFlowStatus: "",
    enabled: "",
    ncId: "",
    dictionaryType: "",
    isPublish: "",
  },

  TranslationDescription: {
    low: "",
    high: "",
    comments: "",
  },

  TranslationGridCount:0,
  isTranslationUpdated: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case actionTypes.SET_IS_TRANSLATION_UPDATE:
      return {
        ...state,
        isTranslationUpdated: payload,
      };

    case actionTypes.SET_W_Translation:
      return {
        ...state,
        TranslationGrid: payload,
      };

    case actionTypes.SET_Translation_Count:
      return {
        ...state,
        TranslationGridCount: payload,
      };

    case actionTypes.SET_Translation_Edit:
      return {
        ...state,
        TranslationGridEdit: payload,
      };

    case actionTypes.SET_Translation_Edit_Cell:
      return {
        ...state,
        TranslationEditCell: payload,
      };

    case actionTypes.Set_Description:
      if (payload.message !== "") {
        return {
          ...state,
          TranslationDescription: {
            ...state.TranslationDescription,
            [payload.name]:
              payload.message !== ""
                ? payload.message
                : state.TranslationDescription[payload.name],
          },
        };
      } else {
        console.log(
          "Payload Else",
          JSON.stringify(state.TranslationDescription)
        );
        return {
          ...state,
          TranslationDescription: {
            ...state.TranslationDescription,
            [payload.name]: state.TranslationDescription[payload.name],
          },
        };
      }

    case actionTypes.SET_TRANSLATION_DICTIONARY_TYPES:
      return {
        ...state,
        translationDictionaryTypes: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
