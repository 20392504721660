import { actionTypes } from "./actions";

const initialState = {
  sampleDataFromSampleId: null,
  inspectSampleTable: null,
  inspectSampleTableTabs: [],
  nonConformityBundleOptions: [],
  nonConformityPackOptions: [],
  nonConformityCaseOptions: [],
  nonConformityFilterOptions: [],
  nonConformityCigaretteOptions: [],
  preliminaryChecklist: [],
  inspectionData: {},
  preliminaryChecklistSaved: false,
  nonConfirmityDetail: {},
  packDetails: {},
  nonConfirmityDetailsID: "",
  savePackDetailsResult: [],
  checkSaveInspectionStatus: false
};

const auditReportReducer = (state = initialState, action) => {

  const updatePackStatus = (payload) => {
    const updatedStatus = state.savePackDetailsResult.map(pack =>
      pack.id == payload.id ?
        {id: pack.id, saved: payload.saved, totalCigLeft: pack.totalCigLeft, cigLeft: pack.cigLeft} :
        pack
    )
    return {
      ...state,
      savePackDetailsResult: [
        ...updatedStatus
      ]
    };
  }

  const setPackDetailsResult = (payload) => {
    let allPacks = state.savePackDetailsResult;
    if (payload.length == 0) {
      return {
        ...state,
        savePackDetailsResult: payload,
      };
    } else {
      if (state.savePackDetailsResult.length == 0) {
        return {
          ...state,
          savePackDetailsResult: payload,
        };
      } else {
        state.savePackDetailsResult.forEach((savedPack) => {
          payload.forEach((pack) => {
            if (savedPack.id != pack.id) {
              const index = allPacks.findIndex(addedPack => addedPack.id == pack.id);
              if (index < 0) {
                allPacks.push(pack);
              }
            } else {
              if (savedPack.saved != pack.saved ||
                savedPack.totalCigLeft != pack.totalCigLeft ||
                savedPack.cigLeft != pack.cigLeft
              ) {
                const index = allPacks.findIndex(existingPack => existingPack.id == pack.id)
                allPacks[index].saved = pack.saved;
                allPacks[index].totalCigLeft = pack.totalCigLeft;
                allPacks[index].cigLeft = pack.cigLeft;
              }
            }
          })
        })
        return {
          ...state,
          savePackDetailsResult: allPacks,
        };
      }
    }
  }

  switch (action.type) {

    case actionTypes.SET_NC_DETAILS_ID:
      return {
        ...state,
        nonConfirmityDetailsID: action.payload,
      };

    case actionTypes.SET_NC_DETAILS:
      return {
        ...state,
        nonConfirmityDetail: action.payload,
      };
    case actionTypes.SET_SAMPLE_DETAILS:
      return {
        ...state,
        sampleDataFromSampleId: action.payload,
      };
    case actionTypes.SET_SAMPLE_TABLES:
      return {
        ...state,
        inspectSampleTable: action.payload,
      };
    case actionTypes.SET_SAMPLE_TABLES_TABS:
      return {
        ...state,
        inspectSampleTableTabs: action.payload,
      };
    case actionTypes.SET_BUNDLE_OPTIONS:
      return {
        ...state,
        nonConformityBundleOptions: action.payload,
      };
    case actionTypes.SET_PACKS_OPTIONS:
      return {
        ...state,
        nonConformityPackOptions: action.payload,
      };
    case actionTypes.SET_CASE_OPTIONS:
      return {
        ...state,
        nonConformityCaseOptions: action.payload,
      };
    case actionTypes.SET_FILTER_OPTIONS:
      return {
        ...state,
        nonConformityFilterOptions: action.payload,
      };
    case actionTypes.SET_CIGARETTE_OPTIONS:
      return {
        ...state,
        nonConformityCigaretteOptions: action.payload,
      };
    case actionTypes.SET_PRELIMINARY_CHECKLIST:
      return {
        ...state,
        preliminaryChecklist: action.payload,
      };
    case actionTypes.SET_INSPECTION_DATA:
      return {
        ...state,
        inspectionData: action.payload,
      };
    case actionTypes.SET_SAVE_PRELIMINARY_RESULT:
      return {
        ...state,
        preliminaryChecklistSaved: action.payload,
      };
    case actionTypes.SET_PACK_DETAILS:
      return {
        ...state,
        packDetails: action.payload,
      };
    case actionTypes.SET_SAVE_PACK_DETAILS_RESULT:
      return setPackDetailsResult(action.payload);

    case actionTypes.UPDATE_PACK_DETAILS:
      return updatePackStatus(action.payload);

    case actionTypes.SET_SAVE_INSPECTION_STATUS:
      return {
        ...state,
        checkSaveInspectionStatus: action.payload,
      };
      
    default:
      return state;
  }
};

export default auditReportReducer;
