export const actionTypes = {
  SET_SAMPLE_DETAILS: "SET_SAMPLE_DETAILS",
  SET_SAMPLE_TABLES: "SET_SAMPLE_TABLES",
  SET_SAMPLE_TABLES_TABS: "SET_SAMPLE_TABLES_TABS",
  SET_BUNDLE_OPTIONS: "SET_BUNDLE_OPTIONS",
  SET_PACKS_OPTIONS: "SET_PACKS_OPTIONS",
  SET_CASE_OPTIONS: "SET_CASE_OPTIONS",
  SET_FILTER_OPTIONS: "SET_FILTER_OPTIONS",
  SET_CIGARETTE_OPTIONS: "SET_CIGARETTE_OPTIONS",
  SET_PRELIMINARY_CHECKLIST: "SET_PRELIMINARY_CHECKLIST",
  GET_INSPECTION_DATA: "GET_INSPECTION_DATA",
  SET_INSPECTION_DATA: "SET_INSPECTION_DATA",
  SAVE_PRELIMINARY_CHECKLIST_DATA: "SAVE_PRELIMINARY_CHECKLIST_DATA",
  SET_SAVE_PRELIMINARY_RESULT: "SET_SAVE_PRELIMINARY_RESULT",
  GET_NC_DETAILS: "GET_NC_DETAILS",
  SET_NC_DETAILS: "SET_NC_DETAILS",
  SAVE_INSPECTION_DATA: "SAVE_INSPECTION_DATA",
  SUBMIT_PACK_DETAILS: "SUBMIT_PACK_DETAILS",
  GET_PACK_DETAILS: "GET_PACK_DETAILS",
  SET_PACK_DETAILS: "SET_PACK_DETAILS",
  SET_NC_DETAILS_ID: "SET_NC_DETAILS_ID",
  SET_SAVE_PACK_DETAILS_RESULT: "SET_SAVE_PACK_DETAILS_RESULT",
  UPDATE_PACK_DETAILS: "UPDATE_PACK_DETAILS",
  SET_SAVE_INSPECTION_STATUS: "SET_SAVE_INSPECTION_STATUS",
};

export const actions = {

  setNonConfirmityDetailID: (payload) => ({
    type: actionTypes.SET_NC_DETAILS_ID,
    payload: payload,
  }),

  saveInspectionDetail: (payload) => ({
    type: actionTypes.SAVE_INSPECTION_DATA,
    payload: payload,
  }),

  getNonConfirmityDetail: (payload) => ({
    type: actionTypes.GET_NC_DETAILS,
    payload: payload,
  }),

  setNonConfirmityDetail: (payload) => ({
    type: actionTypes.SET_NC_DETAILS,
    payload: payload,
  }),

  setInspectSampleDetails: (payload) => ({
    type: actionTypes.SET_SAMPLE_DETAILS,
    payload: payload,
  }),
  setInspectSampleTable: (payload) => ({
    type: actionTypes.SET_SAMPLE_TABLES,
    payload: payload,
  }),
  setInspectSampleTableTabs: (payload) => ({
    type: actionTypes.SET_SAMPLE_TABLES_TABS,
    payload: payload,
  }),
  setnonConformityBundleOptions: (payload) => ({
    type: actionTypes.SET_BUNDLE_OPTIONS,
    payload: payload,
  }),
  setNonConformityPackOptions: (payload) => ({
    type: actionTypes.SET_PACKS_OPTIONS,
    payload: payload,
  }),
  setNonConformityCaseOptions: (payload) => ({
      type: actionTypes.SET_CASE_OPTIONS,
      payload: payload,
  }),
  setNonConformityFilterOptions: (payload) => ({
      type: actionTypes.SET_FILTER_OPTIONS,
      payload: payload,
  }),
  setNonConformityCigaretteOptions: (payload) => ({
      type: actionTypes.SET_CIGARETTE_OPTIONS,
      payload: payload,
  }),
  setPreliminaryChecklists: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_CHECKLIST,
    payload: payload,
  }),
  getInspectionData: (payload) => ({
    type: actionTypes.GET_INSPECTION_DATA,
    payload: payload,
  }),
  setInspectionData: (payload) => ({
    type: actionTypes.SET_INSPECTION_DATA,
    payload: payload,
  }),
  savePreliminarychecklistData: (payload) => ({
    type: actionTypes.SAVE_PRELIMINARY_CHECKLIST_DATA,
    payload: payload,
  }),
  setSavePreliminaryChecklistResult: (payload) => ({
    type: actionTypes.SET_SAVE_PRELIMINARY_RESULT,
    payload: payload,
  }),
  submitPackDetails: (payload) => ({
    type: actionTypes.SUBMIT_PACK_DETAILS,
    payload: payload
  }),
  getPackDetails: (payload) => ({
    type: actionTypes.GET_PACK_DETAILS,
    payload: payload
  }),
  setPackDetails: (payload) => ({
    type: actionTypes.SET_PACK_DETAILS,
    payload: payload
  }),
  setSavePackDetailsResult: (payload) => ({
    type: actionTypes.SET_SAVE_PACK_DETAILS_RESULT,
    payload: payload
  }),
  updatePackDetails: (payload) => ({
    type: actionTypes.UPDATE_PACK_DETAILS,
    payload: payload
  }),
  setSaveInspectionStatus: (payload) => ({
    type: actionTypes.SET_SAVE_INSPECTION_STATUS,
    payload: payload
  })
};
