import React from "react";
import ReactDOM from "react-dom";

// third party
import { BrowserRouter } from "react-router-dom";

// project imports
import App from './App';

import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "store/reducers";
import rootSaga from "store/sagas";
// style + assets
import './assets/scss/style.scss';
// ===========================|| REACT DOM RENDER  ||=========================== //

const composeEnhancers =
  (process.env.NODE_ENV !== "production" &&
    typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const sagaMiddleware = createSagaMiddleware();
const middlewares = [applyMiddleware(sagaMiddleware)];
const store = createStore(rootReducer, composeEnhancers(...middlewares));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

sagaMiddleware.run(rootSaga);
