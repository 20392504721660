/*eslint-disable */

import { API } from "api/config";
import axios from "axios";

//  API

export const fetchAuditAndInspectorAPI = (payload) => {
  return API.get("/adminService/api/AuditHome/getAuditandInspector", {
    params: payload,
  }).then((res) => res);
};

export const fetchAuditSubTypeAPI = (payload) => {
  return API.get("/adminService/api/AuditHome/getAuditSubType", {
    params: payload,
  }).then((res) => res);
};

export const fetchAuditSubTypeFromAuditTypeAPI = (payload) => {
  return API.get("/adminService/api/AuditHome/getSampleAttributes", {
    params: payload,
  }).then((res) => res);
};

export const getProductionCenterAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getProductionCenters", {
    params: payload,
  }).then((res) => res);
};

export const fetchProductionCenterAPI = () => {
  return API.get("/adminService/api/MasterData/getProductionCenterList").then(
    (res) => res
  );
};

export const fetchProductionUnitsByProductionCenterAPI = (params) => {
  return API.get(
    "adminService/api/SampleId/getProductionUnitByProductionCenterId",
    {
      params,
    }
  ).then((res) => res);
};

export const fetchProductVariantAndBrandCodeAPI = (params) => {
  return API.get("adminService/api/AuditHome/getProductVariantAndBrandCode", {
    params,
  }).then((res) => res);
};

export const fetchProjectAndPurchasingLocationAPI = () => {
  return API.get(
    "adminService/api/AuditHome/getProjectAndPurchasingLocationType"
  ).then((res) => res);
};

export const fetchFilterCodesAPI = (params) => {
  return API.get("adminService/api/AuditHome/getFilterCodes", {
    params,
  }).then((res) => res);
};

export const fetchMarketListAPI = (params) => {
  return API.get("/adminService/api/MasterData/getMarketList", {
    params,
  }).then((res) => res);
};

export const fetchMarketedBrandByMarketAPI = (params) => {
  return API.get("/adminService/api/MasterData/getMarketedBrandByMarket", {
    params,
  }).then((res) => res);
};

export const fetchPeriodDropDownAPI = (params) => {
  return API.get("/adminService/api/Report/getPeriodDropDown", {
    params,
  }).then((res) => res);
};

export const saveSampleDataAPI = (payload) => {
  return API.post(
    "/adminService/api/SamplesListHandler/saveSamplelog",
    payload
  ).then((res) => res);
};

export const getSampleProductionCodeAPI = (params) => {
  return API.get("/adminService/api/AuditHome/getProductionCode", {
    params,
  }).then((res) => res);
};

export const getSampleCodentifyCodeAPI = (params) => {
  return axios
    .get(`${process.env.REACT_APP_GET_CODENTIFY_DETAILS}`, {
      params,
    })
    .then((res) => res);
};

export const getCigarettePackDetailsAPI = (params) => {
  return API.get("/adminService/api/SamplesListHandler/getCigarattesPerPack", {
    params,
  }).then((res) => res);
};

export const getCodentifyDataAPI = (payload) => {
  return API.post(
    "/adminService/api/ProductionCenter/GetProductInfoFromCodentify",
    payload
  ).then((res) => res);
};

export const getCigaretteOptionsAPI = (params) => {
  return API.get("/adminService/api/SamplesListHandler/getCigarattesOptions", {
    params,
  }).then((res) => res);
};
