import { API } from "api/config";

//  API

export const getAuditReportsAPI = (payload) => {
  return API.get(`/adminService/api/Report/GetAuditReports`, {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getAuditSubTypeAPI = (payload) => {
  return API.get("/adminService/api/AuditHome/getAuditSubType", {
    params: payload,
  }).then((res) => res);
};

export const getMarketedBrandByMarketAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getMarketedBrandByMarket", {
    params: payload,
  }).then((res) => res);
};

export const getPeriodDropDownAPI = (payload) => {
  return API.get("/adminService/api/Report/getPeriodDropDown", {
    params: payload,
  }).then((res) => res);
};

export const inspectSampleAPI = (payload) => {
  return API.get("/adminService/api/SamplesListHandler/inspectSample", {
    params: payload,
  }).then((res) => res);
};

export const getMarketListAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getMarketList", {
    params: payload,
  }).then((res) => res);
};

export const getSampleBySampleIdAPI = (payload) => {
  return API.get("/adminService/api/SamplesListHandler/getSampleBySampleId", {
    params: payload,
  }).then((res) => res);
};

export const PUTAPI = (payload) => {
  return API.put("/adminService/api/Attribute/saveAttribute", payload)
    .then((res) => res)
    .catch((error) => error);
};

//Delete sampleID
export const updateSampleStatusAPI = (payload) => {
  return API.put(
    "/adminService/api/SamplesListHandler/updateSampleStatus",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const POSTAPI = (payload) => {
  return API.post("/adminService/api/Attribute/addAttributeValue", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const validateSampleAPI = (payload) => {
  return API.put("/adminService/api/SamplesListHandler/validateSample", payload)
    .then((res) => res)
    .catch((error) => error);
};

// My samples to validate

export const getBundlesListAPI = (payload) => {
  return API.get(`/adminService/api/InspectionChecklist/getBundlesList`, {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const saveBundlesListAPI = (payload) => {
  return API.post(
    `/adminService/api/InspectionChecklist/SaveAssignedBundleList`,
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getBundlesTreeAPI = (params) => {
  return API.get(
    `/adminService/api/Dictionary/DictionaryTreeView/getBundleTree`,
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};
export const getBeforeValidationReportAPI = (params) => {
  return API.get(
    `/adminService/api/Report/getReports`,
    {
      params,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const assignInspectorForSampleAPI = (payload) => {
  return API.put(`/adminService/api/AuditHome/UpdateInspectorForSample`, payload)
    .then((res) => res)
};

export const getAfterValidationReportAPI = (params) => {
  return API.get(`/adminService/api/Report/getReportAfterValidation`, { params })
    .then((res) => res)
};

export const getAfterValidationReportExcelAPI = (params) => {
  return API.get(`/adminService/api/Report/getReportAfterValidationExcel`, { params })
    .then((res) => res)
};
