import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

// style constant
const useStyles = makeStyles((theme) => ({
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    // background: theme.palette.secondary.light,
    background: "#143d77",
    color: "#fff",
    '&[aria-controls="menu-list-grow"],&:hover': {
      color: "#1e81f4",
      backgroundColor: "#fff",
    },
  },
  box: {
    marginLeft: "10px",
    marginRight: "5px",
    paddingLeft: "10px",
    borderLeft: "2px solid #a9a9a9",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },
}));

// ===========================|| NOTIFICATION ||=========================== //

const HelpAndSupport = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleToggle = () => {
    navigate("/admin/help");
  };

  return (
    <>
      <Box component="span" className={classes.box}>
        <ButtonBase sx={{ borderRadius: "12px" }}>
          <Tooltip title="Help">
            <Avatar
              variant="rounded"
              className={classes.headerAvatar}
              onClick={handleToggle}
              color="inherit"
            >
              <HelpOutlineOutlinedIcon
                stroke={1.5}
                size="1.3rem"
                className={classes.feedbackIcon}
              />
            </Avatar>
          </Tooltip>
        </ButtonBase>
      </Box>
    </>
  );
};

export default HelpAndSupport;
