export const actionTypes = {
  GET_NC_MAPPING_TEMPLATE_FILE: "GET_NC_MAPPING_TEMPLATE_FILE",
  GET_LATEST_NC_MAPPING_TEMPLATE_FILE: "GET_NC_LATEST_MAPPING_TEMPLATE_FILE",
  UPLOAD_NC_TEMPLATE: "UPLOAD_NC_TEMPLATE",
  SET_TEMPLATE_UPLOAD: "SET_TEMPLATE_UPLOAD",
  SET_TEMPLATE_UPLOAD_FAILURE: "SET_TEMPLATE_UPLOAD_FAILURE",
  SET_IS_UPLOAD: "SET_IS_UPLOAD",
};

export const actions = {
  getNcMappingTemplateFile: (payload) => ({
    type: actionTypes.GET_NC_MAPPING_TEMPLATE_FILE,
    payload,
  }),

  getLatestNcMappingTemplateFile: (payload) => ({
    type: actionTypes.GET_LATEST_NC_MAPPING_TEMPLATE_FILE,
    payload,
  }),
  uploadNcTemplate: (payload) => ({
    type: actionTypes.UPLOAD_NC_TEMPLATE,
    payload,
  }),
  setUploadNcTemplate: (payload) => ({
    type: actionTypes.SET_TEMPLATE_UPLOAD,
    payload,
  }),
  setUploadNcFailure: (payload) => ({
    type: actionTypes.SET_TEMPLATE_UPLOAD_FAILURE,
    payload,
  }),
  setIsUpload: (payload) => ({
    type: actionTypes.SET_IS_UPLOAD,
    payload,
  }),
};
