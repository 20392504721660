import { actionTypes } from "./actions";

const initialState = {
  fields: {},
  attributeData: [],
  attributesEdit: {
    codeDomain: "",
    name: "",
    description: "",
    isReadonly: 0,
    hide: 0,
    isMultivalued: 0,
  },
  attributeValuesData: [],
};

const attributesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case actionTypes.RESET_FIELDS:
      return {
        ...state,
        fields: initialState.fields,
      };

    case actionTypes.SET_ATTRIBUTES:
      return {
        ...state,
        attributeData: action.payload,
      };

    case actionTypes.SET_ATTRIBUTE_VALUES:
      return {
        ...state,
        attributeValuesData: action.payload,
      };

    case actionTypes.SET_ATTRIBUTE_BY_ID:
      return {
        ...state,
        attributesEdit: action.payload,
      };

    default:
      return state;
  }
};

export default attributesReducer;
