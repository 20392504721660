import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div hidden={value !== index} id={`tab-panel-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
