export const languages = [
  { code: "EN", name: "English" },
  { code: "CS", name: "Ceská(Czech)" },
  { code: "DE", name: "Deutsch(German)" },
  { code: "ES", name: "Español(Spanish)" },
  { code: "Fl", name: "Filipino" },
  { code: "FR", name: "Français(French)" },
  { code: "FC", name: "French (Canada)" },
  { code: "ID", name: "Indonesian" },
  { code: "IT", name: "Italiano(Italian)" },
  { code: "LT", name: "Lietuviu(Lithuanian)" },
  { code: "NL", name: "Nederlands(Dutch)" },
  { code: "PL", name: "Polski(Polish)" },
  { code: "PT-BR", name: "Português(Brazil) (Portuguese)" },
  { code: "PT", name: "Português(Portuguese)" },
  { code: "RO", name: "Româna(Romanian)" },
  { code: "LT-SR", name: "Serbian (Latin)" },
  { code: "TR", name: "Türkçe(Turkish)" },
  { code: "GR", name: "Ελληνικά(Greek)" },
  { code: "RU", name: "Русский(Russian)" },
  { code: "AR", name: "العربية(Arabic)" },
  { code: "KO", name: "한국어(Korean)" },
  { code: "ZH-CN", name: "简体中文 (Simplified Chinese)" },
  { code: "ZH-TW", name: "繁體中文(Traditional Chinese)" },
  { code: "UK", name: "Ukrainian" }
];

export const downloadXmlLanguages = [
  { code: "en", name: "English" },
  { code: "CS", name: "Ceská(Czech)" },
  { code: "DE", name: "Deutsch(German)" },
  { code: "ES", name: "Español(Spanish)" },
  { code: "Fl", name: "Filipino" },
  { code: "FR", name: "Français(French)" },
  { code: "FC", name: "French (Canada)" },
  { code: "ID", name: "Indonesian" },
  { code: "IT", name: "Italiano(Italian)" },
  { code: "LT", name: "Lietuviu(Lithuanian)" },
  { code: "NL", name: "Nederlands(Dutch)" },
  { code: "pl", name: "Polski(Polish)" },
  { code: "pt-BR", name: "Português(Brazil) (Portuguese)" },
  { code: "pt", name: "Português(Portuguese)" },
  { code: "ro", name: "Româna(Romanian)" },
  { code: "Lt-sr", name: "Serbian (Latin)" },
  { code: "TR", name: "Türkçe(Turkish)" },
  { code: "GR", name: "Ελληνικά(Greek)" },
  { code: "ru", name: "Русский(Russian)" },
  { code: "AR", name: "العربية(Arabic)" },
  { code: "KO", name: "한국어(Korean)" },
  { code: "ZH-CN", name: "简体中文 (Simplified Chinese)" },
  { code: "ZH-TW", name: "繁體中文(Traditional Chinese)" },
  { code: "UK", name: "Ukrainian" }
];
