/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "../actions/common";
import { useLocalStorage } from "../../sessionManager/localSesionManager";
import {
  fetchLaboratoryListAPI,
  fetchUserInfoAPI,
  fetchLogUserInfoAPI,
  fetchAdminRoleAPI,
  fetchImageFromAwsAPI,
  fetchIMDLMenusAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
//import { setCookie } from "utils";

function* getUserInfoSaga(action) {
  try {
    const _api = yield call(fetchUserInfoAPI, action.payload);
    if (_api.status && _api.status === 200) {
      //setCookie("userInfo", JSON.stringify(_api.data), 1);
      //localStorage.setItem("test", JSON.stringify(_api.data));
      useLocalStorage("userInfo", JSON.stringify(_api.data));
      yield put(actions.setUserInfo(_api.data));
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getUserAdminRoleSaga(action) {
  try {
    const _api = yield call(fetchAdminRoleAPI, action.payload);

    if (_api.status && _api.status === 200) {
      yield put(actions.setAdminRoleInfo(_api.data.data.userIdData));
      sessionStorage.setItem(
        "userRoleInfo",
        JSON.stringify({
          userLaboratoryCode: _api.data.data.labCode,
          userLaboratoryDescription: _api.data.data.labDescription,
          ..._api.data.data.userIdData,
        })
      );
      sessionStorage.setItem(
        "userMembershipRoleInfo",
        JSON.stringify(_api.data?.data?.userMembershipRoles || [])
      );
      //setCookie("userRole", _api.data.data?.userRole, 1);
      useLocalStorage("userRole", _api.data.data?.userRole);
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* logUserInfoSaga(action) {
  try {
    const _api = yield call(fetchLogUserInfoAPI, action.payload);
    if (_api.status && _api.status === 200) {
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getLaboratoryListsSaga(action) {
  try {
    const _api = yield call(fetchLaboratoryListAPI, action.payload);
    yield put(actions.setLaboaratoryList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getImageListsFromAwsSaga(action) {
  try {
    console.log("Image Request", action.payload);
    const _api = yield call(fetchImageFromAwsAPI, action.payload);
    console.log("Image Response", _api.data.data);
    yield put(actions.setImageListsFromAws(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getIMDLMenusSaga(action) {
  try {
    const _api = yield call(fetchIMDLMenusAPI, action.payload);
    yield put(actions.setIMDLMenus(_api.data.data));
    sessionStorage.setItem("IMDLMenus", JSON.stringify(_api.data.data));

    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

export function* watchCommonSaga() {
  yield all([
    takeEvery(actionTypes.GET_USER_INFO, getUserInfoSaga),
    takeEvery(actionTypes.GET_LABOARATORY_LIST, getLaboratoryListsSaga),
    takeEvery(actionTypes.GET_ADMIN_ROLES, getUserAdminRoleSaga),
    takeEvery(actionTypes.LOG_USER_INFO, logUserInfoSaga),
    takeEvery(actionTypes.GET_IMAGE_FROM_AWS, getImageListsFromAwsSaga),
    takeEvery(actionTypes.GET_IMDL_MENUS, getIMDLMenusSaga),
  ]);
}
