import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { validateTranslationApi, uploadTranslationApi } from "../api/endpoint";

import { actions as commonActions } from "store/actions/common";

function* validateTranslation(action) {  
  try {
    const _api = yield call(validateTranslationApi,action.payload);
    yield put(actions.setUploadFile(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data));
  }
}

function* uploadTranslation(action) {  
    try {
      const _api = yield call(uploadTranslationApi,action.payload);
      yield put(actions.setUploadFile(_api.data.data));
      yield put(commonActions.setLoading(false));
    } catch (error) {
      yield put(commonActions.setLoading(false));
      yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data));
    }
  }

export function* watchuploadTranslation() {  
  yield all([
    takeEvery(actionTypes.VALIDATE_UPLOAD_FILE, validateTranslation),
    takeEvery(actionTypes.UPLOAD_FILE, uploadTranslation),
  ]);
}
