import { actionTypes } from "./actions";

const initialState = {
  auditReport: [],
  auditSubType: [],
  marketList: [],
  marketBrand: [],
  period: [],
  auditReportType: {},
  auditType: "ROUTINE",
  auditSubTypeForGrid: "",
  auditEdit: {},
  auditReportCount: 0,
  auditReportSearch: null,
  myBundlesToInspect: {},
  sampleID: {},
  saveSuccess: false,
  validateSuccess: false,
  auditSearchCondition: {},
  bundleTreeData: [],
  expandedNodeIds: [],
  parentSampleInfo: {
    nodeId: "",
    sampleId: "",
  },
  beforeValidationReport: {},
  inspectorAssigned: false,
  afterValidationReport: [],
};

// let nodeId = 0;
// let isParentFound = false;

const updateSampleChildrens = (parentSampleInfo, bundlesTreeData, payload) => {
  if (bundlesTreeData?.length > 0) {
    const sampleData = bundlesTreeData[0]; // always there will be only one sample
    let bundlesInfo = {
      ...sampleData,
    };

    const userSelectedBundle = sampleData.children.filter(
      (bundle) => bundle.nodeId === parentSampleInfo.nodeId
    );

    const modiifiedBundles = {
      ...userSelectedBundle[0],
      children: payload,
    };

    const arr = [];

    sampleData.children.forEach((bundle) => {
      if (bundle.nodeId === parentSampleInfo.nodeId) {
        //test
        const s = {
          ...bundle,
          children: payload,
        };
        arr.push(s);
      } else {
        arr.push(bundle);
      }
    });

    bundlesInfo.children = arr;

    // sampleData?.children?.forEach((bundle) => {
    //   if (bundle.nodeId === parentSampleInfo.nodeId) {
    //     // bundlesInfo = { ...bundle };
    //     console.log(bundlesInfo, "bundle");
    //   }
    // });
    // // bundlesInfo?.children?.push(payload);
    // bundlesInfo.children = payload;
    // const updatedSample = [
    //   {
    //     ...sampleData,
    //     children: [bundlesInfo],
    //   },
    // ];
    // // return updatedSample;
    // console.log("updatedSample", updatedSample);
    return [bundlesInfo];
  }
  return payload;
};

const auditReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BUNDLE_TREE_DATA: {
      const updatedBudlesList = updateSampleChildrens(
        state?.parentSampleInfo,
        state?.bundleTreeData,
        action.payload
      );

      return {
        ...state,
        bundleTreeData: [...updatedBudlesList],
      };
    }
    case actionTypes.SET_EMPTY_BUNDLE_TREE:
      return {
        ...state,
        bundleTreeData: [],
      };
      
    case actionTypes.SET_AUDIT_REPORT_SEARCH:
      return {
        ...state,
        auditSearchCondition: action.payload,
      };

    case actionTypes.SET_SAMPLE_SAMPLEID:
      return {
        ...state,
        sampleID: action.payload,
      };

    case actionTypes.SET_AUDIT_REPORT_COUNT:
      return {
        ...state,
        auditReportCount: action.payload,
      };
    case actionTypes.SET_SAMPLE_EDIT:
      return {
        ...state,
        auditEdit: action.payload,
      };

    case actionTypes.SET_AUDIT_TYPE:
      return {
        ...state,
        auditType: action.payload,
      };
    case actionTypes.SET_AuditSubtype_ForGrid:
      return {
        ...state,
        auditSubTypeForGrid: action.payload,
      };

    case actionTypes.SET_AUDIT_REPORT_TYPE:
      return {
        ...state,
        auditReportType: action.payload,
      };

    case actionTypes.SET_PERIOD:
      return {
        ...state,
        period: action.payload,
      };

    case actionTypes.SET_MARKET_BRAND:
      return {
        ...state,
        marketBrand: action.payload,
      };

    case actionTypes.SET_MARKET_LIST:
      return {
        ...state,
        marketList: action.payload,
      };

    case actionTypes.SET_AUDIT_REPORT:
      return {
        ...state,
        auditReport: action.payload,
      };
    case actionTypes.GET_AUDIT_REPORT:
      return {
        ...state,
        auditReportSearch: action.payload,
      };
    case actionTypes.SET_AUDIT_SUBTYPE:
      return {
        ...state,
        auditSubType: action.payload,
      };
    case actionTypes.SET_BUNDLE_LIST:
      return {
        ...state,
        myBundlesToInspect: action.payload,
      };
    case actionTypes.SET_SAVE_RESULT:
      return {
        ...state,
        saveSuccess: action.payload,
      };

    case actionTypes.SET_VALIDATE_RESULT:
      return {
        ...state,
        validateSuccess: action.payload,
      };

    case actionTypes.SET_EXPAND_NODE_ID:
      return {
        ...state,
        expandedNodeIds: [action.payload],
      };
    case actionTypes.SET_BEFORE_VALIDATION:
      return {
        ...state,
        beforeValidationReport: action.payload,
      };

    case actionTypes.SET_PARENT_SAMPLE_INFO:
      return {
        ...state,
        parentSampleInfo: {
          ...state.parentSampleInfo,
          ...action.payload,
        },
      };

    case actionTypes.SET_ASSIGN_INSPECTOR_STATUS:
      return {
        ...state,
        inspectorAssigned: action.payload
      };

    case actionTypes.SET_AFTER_VALIDATION_REPORT:
      return {
        ...state,
        afterValidationReport: action.payload,
      };

    default:
      return state;
  }
};

export default auditReportReducer;
