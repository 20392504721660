export const actionTypes = {
  GET_LOCAL_PROJECT_TYPES: "GET_LOCAL_PROJECT_TYPES",
  SET_LOCAL_PROJECT_TYPES: "SET_LOCAL_PROJECT_TYPES",
  DISABLE_LOCAL_PROJECT_TYPE: "DISABLE_LOCAL_PROJECT_TYPE",
  ADD_LOCAL_PROJECT_TYPE: "ADD_LOCAL_PROJECT_TYPE",
  UPDATE_LOCAL_PROJECT_TYPE: "UPDATE_LOCAL_PROJECT_TYPE",
};

export const actions = {
  getLocalProjectTypes: (payload) => ({
    type: actionTypes.GET_LOCAL_PROJECT_TYPES,
    payload,
  }),
  setLocalProjectTypes: (payload) => ({
    type: actionTypes.SET_LOCAL_PROJECT_TYPES,
    payload,
  }),
  disableLocalProjectType: (payload) => ({
    type: actionTypes.DISABLE_LOCAL_PROJECT_TYPE,
    payload,
  }),
  addLocalProjectType: (payload) => ({
    type: actionTypes.ADD_LOCAL_PROJECT_TYPE,
    payload,
  }),
  updateLocalProjectType: (payload) => ({
    type: actionTypes.UPDATE_LOCAL_PROJECT_TYPE,
    payload,
  }),
};
