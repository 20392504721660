/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {
  brandGroupsList: [],
  brandGroupsEdit: {
    id: null,
    active: "0",
    brandGroupCode: "",
    market: "",
    marketedBrand: "",
    laboratoryCode: "",
    activationDate: null,
    deactivationDate: null,
  },
  brandGroupCount: 0,

  //dropdowns
  marketsList: [],
  marketedBrandsList: [],
  laboratoryList: [],
};

//TODO: Remove the function once the api teams returns proper response
function editResponseFieldMapping(payload) {
  const result = {};
  for (const p in initialState.brandGroupsEdit)
    result[p] = (p in payload ? payload : initialState.brandGroupsEdit)[p];

  return { ...result, id: payload.idBrandGroup, code: payload.brandGroupCode };
}

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_BRAND_GROUPS:
      return {
        ...state,
        brandGroupsList: payload,
      };

    case actionTypes.SET_MARKETS_LIST:
      return {
        ...state,
        marketsList: payload,
      };

    case actionTypes.SET_MARKETED_BRANDS_LIST:
      return {
        ...state,
        marketedBrandsList: payload,
      };

    case actionTypes.SET_LABORATORY_LIST:
      return {
        ...state,
        laboratoryList: payload,
      };

    case actionTypes.SET_BRAND_GROUPS_EDIT:
      return {
        ...state,
        brandGroupsEdit: payload,
      };

    case actionTypes.SET_BRAND_GROUPS_BY_ID:
      return {
        ...state,
        brandGroupsEdit: payload,
      };

    case actionTypes.SET_BRAND_GROUPS_COUNT:
      return {
        ...state,
        brandGroupCount: payload
      }

    default:
      return {
        ...state,
      };
  }
};
