/*eslint-disable */
export const actionTypes = {
  GET_MARKETED_BRAND: "GET_MARKETED_BRAND",
  SET_MARKETED_BRAND: "SET_MARKETED_BRAND",
  GET_SUBMARKET: "GET_SUBMARKET",
  SET_SUBMARKET: "SET_SUBMARKET",
  GET_COMPANY_GROUPS: "GET_COMPANY_GROUPS",
  SET_COMPANY_GROUPS: "SET_COMPANY_GROUPS",
  DELETE_SUBMARKET: "DELETE_SUBMARKET",
  DELETE_COMPANY_GROUP: "DELETE_COMPANY_GROUP",
  ADD_COMPANY_GROUP: "ADD_COMPANY_GROUP",
  EDIT_COMPANY_GROUP: "EDIT_COMPANY_GROUP",
  ADD_MARKETED_BRAND: "ADD_MARKETED_BRAND",
  EDIT_MARKETED_BRAND: "EDIT_MARKETED_BRAND",
  ADD_SUBMARKET: "ADD_SUBMARKET",
  EDIT_SUBMARKET: "EDIT_SUBMARKET",
  DELETE_SUBMARKET: "DELETE_SUBMARKET",

  // drop downs
  GET_ALL_MARKET_LIST: "GET_ALL_MARKET_LIST",
  SET_MARKETS_LIST: "SET_MARKETS_LIST",
  GET_BRAND_GROUPS_LIST: "GET_BRAND_GROUPS_LIST",
  SET_BRAND_GROUPS_LIST: "SET_BRAND_GROUPS_LIST",
  GET_COMPANY_GROUPS_LIST: "GET_COMPANY_GROUPS_LIST",
  SET_COMPANY_GROUPS_LIST: "SET_COMPANY_GROUPS_LIST",
};

export const actions = {
  getMarketedBrand: (payload) => ({
    type: actionTypes.GET_MARKETED_BRAND,
    payload: payload,
  }),

  setMarketedBrand: (payload) => ({
    type: actionTypes.SET_MARKETED_BRAND,
    payload: payload,
  }),

  getSubmarket: (payload) => ({
    type: actionTypes.GET_SUBMARKET,
    payload: payload,
  }),

  setSubmarket: (payload) => ({
    type: actionTypes.SET_SUBMARKET,
    payload: payload,
  }),

  getCompanyGroups: (payload) => ({
    type: actionTypes.GET_COMPANY_GROUPS,
    payload: payload,
  }),

  setCompanyGroups: (payload) => ({
    type: actionTypes.SET_COMPANY_GROUPS,
    payload: payload,
  }),

  deleteCompanyGroup: (payload) => ({
    type: actionTypes.DELETE_COMPANY_GROUP,
    payload: payload,
  }),

  deleteSubmarket: (payload) => ({
    type: actionTypes.DELETE_SUBMARKET,
    payload: payload,
  }),

  addCompanyGroup: (payload) => ({
    type: actionTypes.ADD_COMPANY_GROUP,
    payload: payload,
  }),

  editCompanyGroup: (payload) => ({
    type: actionTypes.EDIT_COMPANY_GROUP,
    payload: payload,
  }),

  addMarketedBrand: (payload) => ({
    type: actionTypes.ADD_MARKETED_BRAND,
    payload: payload,
  }),

  editMarketedBrand: (payload) => ({
    type: actionTypes.EDIT_MARKETED_BRAND,
    payload: payload,
  }),

  addSubmarket: (payload) => ({
    type: actionTypes.ADD_SUBMARKET,
    payload: payload,
  }),

  editSubmarket: (payload) => ({
    type: actionTypes.EDIT_SUBMARKET,
    payload: payload,
  }),

  deleteSubMarket: (payload) => ({
    type: actionTypes.DELETE_SUBMARKET,
    payload: payload,
  }),

  // drop downs
  getAllMarketList: (payload) => ({
    type: actionTypes.GET_ALL_MARKET_LIST,
    payload: payload,
  }),

  setMarketsList: (payload) => ({
    type: actionTypes.SET_MARKETS_LIST,
    payload: payload,
  }),

  getBrandGroupsList: (payload) => ({
    type: actionTypes.GET_BRAND_GROUPS_LIST,
    payload: payload,
  }),

  setBrandGroupsList: (payload) => ({
    type: actionTypes.SET_BRAND_GROUPS_LIST,
    payload: payload,
  }),

  getCompanyGroupsList: (payload) => ({
    type: actionTypes.GET_COMPANY_GROUPS_LIST,
    payload: payload,
  }),

  setCompanyGroupsList: (payload) => ({
    type: actionTypes.SET_COMPANY_GROUPS_LIST,
    payload: payload,
  }),
};
