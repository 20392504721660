import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { alpha, styled } from "@mui/material/styles";

const StyledTreeItem = styled((props) => <TreeItem {...props} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      "& .close": {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
    // "& .MuiTreeItem-content": {
    //   height: "2em",
    // },
  })
);

export default StyledTreeItem;
