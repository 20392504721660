/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchAuditAndInspectorAPI,
  fetchAuditSubTypeAPI,
  fetchAuditSubTypeFromAuditTypeAPI,
  fetchProductionCenterAPI,
  fetchProductionUnitsByProductionCenterAPI,
  fetchProductVariantAndBrandCodeAPI,
  fetchProjectAndPurchasingLocationAPI,
  fetchFilterCodesAPI,
  fetchMarketListAPI,
  fetchPeriodDropDownAPI,
  fetchMarketedBrandByMarketAPI,
  saveSampleDataAPI,
  getSampleProductionCodeAPI,
  getSampleCodentifyCodeAPI,
  getCigarettePackDetailsAPI,
  getCodentifyDataAPI,
  getProductionCenterAPI,
  getCigaretteOptionsAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getAuditTypeAndInspectorSaga(action) {
  try {
    const _api = yield call(fetchAuditAndInspectorAPI, action.payload);
    yield put(actions.setAuditTypeAndInspector(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAuditSubTypeSaga(action) {
  try {
    const _api = yield call(fetchAuditSubTypeAPI, action.payload);
    yield put(actions.setAuditSubTypes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getAuditSubTypeFromAuditTypeSaga(action) {
  try {
    const _api = yield call(fetchAuditSubTypeFromAuditTypeAPI, action.payload);
    yield put(actions.setAuditSubTypesFromAuditType(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProductionCentersSaga(action) {
  try {
    const _api = yield call(getProductionCenterAPI, action.payload);
    yield put(actions.setProductionCenterData(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getProductionCenterSaga(action) {
  try {
    const _api = yield call(fetchProductionCenterAPI, action.payload);
    yield put(actions.setProductionCenterData(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProductionUnitsByProductionCenterSaga(action) {
  try {
    const _api = yield call(
      fetchProductionUnitsByProductionCenterAPI,
      action.payload
    );
    yield put(actions.setProductionUnitsByProductionCenter(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProductVariantAndBrandByProductionCenterSaga(action) {
  try {
    const _api = yield call(fetchProductVariantAndBrandCodeAPI, action.payload);
    yield put(
      actions.setProductVariantAndBrandByProductionCenter(_api.data.data)
    );
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getProjectsAndLocationTypeSaga(action) {
  try {
    const _api = yield call(
      fetchProjectAndPurchasingLocationAPI,
      action.payload
    );
    yield put(actions.setProjectAndLocationTypes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getFilterCodeSaga(action) {
  try {
    const _api = yield call(fetchFilterCodesAPI, action.payload);
    yield put(actions.setFilterCodes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getMarketListSaga(action) {
  try {
    const _api = yield call(fetchMarketListAPI, action.payload);
    yield put(actions.setMarketList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getMarketedBrandByMarketSaga(action) {
  try {
    const _api = yield call(fetchMarketedBrandByMarketAPI, action.payload);
    yield put(actions.setMarketedBrandByMarket(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(actions.setMarketedBrandByMarket([]));
  }
}

function* getPeriodDropDownSaga(action) {
  try {
    const _api = yield call(fetchPeriodDropDownAPI, action.payload);
    yield put(actions.setPeriodDropDown(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* saveSampleDataSaga(action) {
  try {
    const _api = yield call(saveSampleDataAPI, action.payload);
    if (_api.data.status === 200) {
      yield put(commonActions.setLoading(false));
      if (action.payload.PageMode != 1) {
        yield put(
          commonActions.successAlert(
            `New Sample is created with id: ${_api.data.data}`
          )
        );
      }
      if (action.payload.submitRedirectionType === "0") {
        yield put(
          commonActions.setPageRedirect({
            status: true,
            page: "audit-save",
          })
        );
        return;
      }
      yield put(actions.setNewSampleId(_api.data.data));

      yield put(
        commonActions.setPageRedirect({
          status: true,
          page: "go-to-inspection",
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.errorAlert(error.response.data.error.errorMessage || "")
    );
  }
}

//09925EB
function* getSampleProductionCodeSaga(action) {
  try {
    const _api = yield call(getSampleProductionCodeAPI, action.payload);
    if (_api.status === 200) {
      const prodVariantPrams = {
        ...action.productVariantParams,
        productionCenter: _api.data?.data?.productionCenter || "",
      };
      yield put(actions.setSampleProductionCode(_api.data.data));
      yield put(actions.setSampleProductionCodeLoading(false));
      yield put(
        actions.getProductVariantAndBrandByProductionCenter(prodVariantPrams)
      );
      yield put(actions.setUnitDetailsLoading(false));
      const paramsForProductionUnit = {
        productionCenterId: _api?.data?.data?.productionCenter
      };

      yield put(
        actions.getProductionUnitsByProductionCenter(
          paramsForProductionUnit
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setSampleProductionCodeLoading(false));
    yield put(actions.setUnitDetailsLoading(false));
    yield put(commonActions.errorAlert(error.response.data.error.errorMessage));
  }
}

function* getCodentifyCodeDetailsSaga(action) {
  try {
    const _api = yield call(getSampleCodentifyCodeAPI, action.payload);
    if (_api.status === 200) {
      const _apiData = yield call(getCodentifyDataAPI, _api.data);
      if (_apiData.status === 200) {
        if(_apiData.data.data.codentifyResult.validCode == true) {
        // need to get production units after codentify
        const paramsForProductVariant = {
          productionCenter: _apiData?.data?.data?.productionCenter || "",
          ...action.productVariantAndBrandParams,
        };
        yield put(
          actions.getProductVariantAndBrandByProductionCenter(
            paramsForProductVariant
          )
        );

        const paramsForProductionUnit = {
          productionCenterId: _apiData?.data?.data?.productionCenter
        };

        yield put(
          actions.getProductionUnitsByProductionCenter(
            paramsForProductionUnit
          )
        );

        const response = {
          brandCode: _apiData?.data?.data?.brandCode || "",
          description: _apiData?.data?.data?.brandCodeDescription || "",
          productionOrder: _apiData?.data?.data?.productionOrder || "",
          productionCenter: _apiData?.data?.data?.productionCenter || "",
          productionFullName:
            _apiData?.data?.data?.productionCenterDescription || "",
          productionDate: _apiData?.data?.data?.productionDate || new Date(),
          productionTime: _apiData?.data?.data?.productionTime
            ? `0001-01-01 ${_apiData?.data?.data?.productionTime}`
            : null,
          productCode: _apiData?.data?.data?.productVariant || "",
          productVariant: _apiData?.data?.data?.productVariantDescription || "",
          codentifyStatus: _apiData?.data?.data?.codentifyStatus || "",
        };
        // console.log("codentify data", response);
        yield put(actions.setUnitDetailsLoading(false));
        yield put(actions.setCodentifyCodeDetails(response));
        yield put(actions.setCodentifyCodeLoading(false));
      }
      else
      {
        yield put(commonActions.errorAlert("Issue while retrieving the code "+_apiData.data.data.codentifyResult.webServiceException));
      }
    }
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setCodentifyCodeLoading(false));
    yield put(actions.setUnitDetailsLoading(false));
  }
}

function* getCigarettePackDetailsSaga(action) {
  try {
    const _api = yield call(getCigarettePackDetailsAPI, action.payload);
    yield put(actions.setCigaretteCodeDetails(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
  }
}

function* getCigaretteOptionsSaga(action) {
  try {
    const _api = yield call(getCigaretteOptionsAPI, action.payload);
    yield put(actions.setCigaretteOptionsData(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

export function* watchLogNewSamplesSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_AUDIT_TYPE_AND_INSPECTOR,
      getAuditTypeAndInspectorSaga
    ),
    takeEvery(actionTypes.GET_AUDIT_SUB_TYPE, getAuditSubTypeSaga),
    takeEvery(
      actionTypes.GET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE,
      getAuditSubTypeFromAuditTypeSaga
    ),
    takeEvery(actionTypes.GET_PRODUCTION_CENTER_DATA, getProductionCenterSaga),
    takeEvery(actionTypes.GET_PRODUCTION_CENTER_LIST, getProductionCentersSaga),
    takeEvery(
      actionTypes.GET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER,
      getProductionUnitsByProductionCenterSaga
    ),
    takeEvery(
      actionTypes.GET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER,
      getProductVariantAndBrandByProductionCenterSaga
    ),
    takeEvery(
      actionTypes.GET_PROJECT_AND_LOCATION_TYPES,
      getProjectsAndLocationTypeSaga
    ),
    takeEvery(actionTypes.GET_FILTER_CODES, getFilterCodeSaga),
    takeEvery(actionTypes.GET_SAMPLE_MARKET_LIST, getMarketListSaga),
    takeEvery(
      actionTypes.GET_MARKETED_BRAND_BY_MARKET,
      getMarketedBrandByMarketSaga
    ),
    takeEvery(actionTypes.GET_PERIOD_DROP_DOWN, getPeriodDropDownSaga),
    takeEvery(actionTypes.SAVE_SAMPLE_DATA, saveSampleDataSaga),
    takeEvery(
      actionTypes.GET_SAMPLE_PRODUCTION_CODE,
      getSampleProductionCodeSaga
    ),
    takeEvery(
      actionTypes.GET_CODENTIFY_CODE_DETAILS,
      getCodentifyCodeDetailsSaga
    ),

    takeEvery(
      actionTypes.GET_CIGARETTE_CODE_DETAILS,
      getCigarettePackDetailsSaga
    ),
    takeEvery(actionTypes.GET_CIGARETTE_OPTIONS_DATA, getCigaretteOptionsSaga),
  ]);
}
