import { actionTypes } from "./actions";
const initialState = {
  SearchBySampleId: [],
  SampleToInspectionList: [],
  SampleToValidateList: [],
  TotalSampleToValidateCount: 0,
  TotalSampleToInspectCount: 0,
  TotalSampleResultCount: 0,
  isSampleToInspectLoading: true,
  isSampleToValidateLoading:true
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case actionTypes.SET_SearchBySampleID_Grid_Count:
      return {
        ...state,
        TotalSampleResultCount: payload,
      };

    case actionTypes.SET_SearchBySampleID:
      return {
        ...state,
        SearchBySampleId: payload,
      };
    case actionTypes.SET_SAMPLE_TO_INSPECTION:
      return {
        ...state,
        SampleToInspectionList: payload,
      };
    case actionTypes.SET_SAMPLE_TO_VALIDATE:
      return {
        ...state,
        SampleToValidateList: payload,
      };
    case actionTypes.SET_INSPECT_GRID_COUNT:
      return {
        ...state,
        TotalSampleToInspectCount: payload,
      };
    case actionTypes.SET_VALIDATE_GRID_COUNT:
      return {
        ...state,
        TotalSampleToValidateCount: payload,
      };

      case actionTypes.SET_INSPECT_LOADER:
      return {
        ...state,
        isSampleToInspectLoading: payload,
      };
    case actionTypes.SET_VALIDATE_LOADER:
      return {
        ...state,
        isSampleToValidateLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
