/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = [];


export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_IMAGES_DATA:
            return [
                ...state,
                ...payload,
            ];
        case actionTypes.RESET_IMAGES_DATA:
            return [];
        default:
            return [
                ...state,
            ];
    }
};
