import { useRoutes, Link as RouterLink } from "react-router-dom";
import { Box, Button, Typography, Container } from "@mui/material";
import notFoundImage from "assets/images/illustration_404.svg"; // relative path to image
import { MainRoutes, routesMap as defaultRoutes } from "./MainRoutes";

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes({ commonState: { menus, isMenuLoading } }) {
  const isAuthorized = sessionStorage.getItem("isAuthorized");
  let loggedIn;
  if (!isAuthorized) {
    loggedIn = false;
  }
  loggedIn = JSON.parse(isAuthorized);

  const routesToShow =
    !isMenuLoading && menus?.length > 0
      ? MainRoutes(loggedIn, menus)
      : defaultRoutes(loggedIn);

  return useRoutes(routesToShow) || <NotFound />;
}

export function NotFound() {
  return (
    <Container>
      <Box>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <div>
            <Typography variant="h3" paragraph>
              Sorry, page not found!
            </Typography>
          </div>
          <Typography sx={{ color: "text.secondary" }}>
            Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
            mistyped the URL? Be sure to check your spelling.
          </Typography>

          <div>
            <Box
              component="img"
              src={notFoundImage}
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </div>

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            Go to Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
