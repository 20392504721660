/*eslint-disable */

import { IconTruckDelivery } from "@tabler/icons";
import { actions, actionTypes } from "./actions";


const initialState = {
  isTreeLoaded: true
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_TREE_LOAD:
      //console.log("Is dictionary Tree load",payload);
      return {
        ...state,
        isTreeLoaded: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
