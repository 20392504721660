import { actionTypes } from "./actions";

const initialState = {
  localProjectTypeData: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LOCAL_PROJECT_TYPES:
      return {
        ...state,
        localProjectTypeData: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
