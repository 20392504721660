/*eslint-disable */

import { API } from "api/config";

// API

export const fetchBrandGroupsListAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getBrandGroupList", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchAddBrandGroupsListAPI = (payload) => {
  return API.post("/adminService/api/BrandGroups/addBrandGroup", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const fetchEditBrandGroupsListAPI = (payload) => {
  return API.put("/adminService/api/BrandGroups/updateBrandGroup", payload)
    .then((res) => res)
    .catch((error) => error);
};

export const fetchBrandGroupsByIdAPI = (params) => {
  return API.get("/adminService/api/BrandGroups/getBrandGroupById", { params })
    .then((res) => res)
    .catch((error) => error);
};
