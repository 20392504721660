import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getDictionaryExpandDefectAPI,
  getEditDefectNCDropDownAPI,
  getNcAttributeFieldsAPI,
  getDictionaryHierarchyAPI,
  saveDefectDictionaryAPI,
  getDefectDropdownsAPI,
  getDefectDropdownsForCopyAPI,
  defectDictionaryPublishAPI,
  defectDictionarySendEmailAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../../state/actions";

function* saveDefectDictionarySaga(action) {
  try {
    const _api = yield call(saveDefectDictionaryAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getDictionaryExpandDefectSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryExpandDefectAPI, action.payload);
    yield put(actions.setDictionaryExpandDefect(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getEditDefectNCDropDownSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getEditDefectNCDropDownAPI, action.payload);
    yield put(actions.setDictionaryNCDropdown(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getNcAttributeFieldsSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getNcAttributeFieldsAPI, action.payload);
    yield put(actions.setDictionaryNCAttribute(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getDictionaryHierarchySaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDictionaryHierarchyAPI, action.payload);
    yield put(actions.setDictionaryHierarchy(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getDefectDropdownsSaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDefectDropdownsAPI, action.payload);
    yield put(actions.setDefectDropdowns(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getDefectDropdownsForCopySaga(action) {
  try {
    yield put(commonActions.setLoading(true));
    const _api = yield call(getDefectDropdownsForCopyAPI, action.payload);
    yield put(actions.setDefectDropdownsForCopy(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* publishDefectDictionarySaga(action) {
  try {
    const mailPayload = {
      isPublish: action.payload.action === "add",
      defectId: action.payload.nonConformityId,
      defectCode: action.payload.fullCode,
    };
    yield put(commonActions.setLoading(true));
    const _api = yield call(defectDictionaryPublishAPI, action.payload.publish);
    if (
      action.payload.publish.pageMode.toUpperCase() !== "SAVE" &&
      action.payload.publish.action === "add"
    ) {
      yield call(defectDictionarySendEmailAPI, {
        ...action.payload.email,
        defectId: _api.data.data.defectId,
      });
    } else if (
      action.payload.publish.pageMode.toUpperCase() !== "SAVE" &&
      action.payload.publish.action === "edit"
    ) {
      yield call(defectDictionarySendEmailAPI, action.payload.email);
    }
    if (_api.data.data) {
      yield put(rootAction.setTreeLoad(true));
    }
    // yield put(actions.setDefectDropdowns(_api.data.data));
    yield put(commonActions.setLoading(false));
    if (action.payload.publish.action == "add") {
      yield put(commonActions.successAlert("Defect Published Successfully"));
    } else if (action.payload.publish.action == "edit") {
      if (action.payload.publish.pageMode == "reject") {
        yield put(commonActions.successAlert("non conformity Rejected."));
      } else {
        yield put(commonActions.successAlert("non conformity updated."));
      }
    }
  } catch (error) {
    yield put(rootAction.setTreeLoad(true));
    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.errorAlert(
        error.response.data.error.errorMessage || "Unknown Error"
      )
    );
  }
}

export function* watchDictionaryDefecttSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_Dictionary_Expand_Defect,
      getDictionaryExpandDefectSaga
    ),
    takeEvery(
      actionTypes.GET_Dictionary_NC_Dropdown,
      getEditDefectNCDropDownSaga
    ),
    takeEvery(
      actionTypes.GET_Dictionary_NC_Attribute,
      getNcAttributeFieldsSaga
    ),
    takeEvery(actionTypes.GET_Dictionary_Hierarchy, getDictionaryHierarchySaga),
    takeEvery(actionTypes.Save_Dictionary_Defect, saveDefectDictionarySaga),
    takeEvery(actionTypes.GET_DEFECT_DROPDOWNS, getDefectDropdownsSaga),
    takeEvery(
      actionTypes.GET_DEFECT_DROPDOWNS_FOR_COPY,
      getDefectDropdownsForCopySaga
    ),
    takeEvery(
      actionTypes.PUBLISH_DICTIONARY_DEFECT,
      publishDefectDictionarySaga
    ),
  ]);
}
