/*eslint-disable */

import { API } from "api/config";

//  API

export const getTranslationGridAPI = (payload) => {
  return API.get("/adminService/api/Translation/getTranslationGrid", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getTranslationGridbyIdAPI = (payload) => {
  return API.get("/adminService/api/Translation/getTranslationGridById", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const updateTranslationAPI = (payload) => {
  return API.put("/adminService/api/Translation/updateTranslation",payload)
    .then((res) => res)
    .catch((error) => error);
};

export const getTranslationDictionaryTypesAPI = (payload) => {
  return API.get(
    "/adminService/api/Translation/getTranslationUploadDictionaryTypes",
    {
      params: payload,
    }
  ).then((res) => res);
};

export const downloadTranslationTemplateAPI = (payload) => {
  return API.get(
    "/adminService/api/Translation/downloadTranslationUploadTemplate",
    {
      params: payload,
    }
  ).then((res) => res);
};
