export const actionTypes = {
    GET_Dictionary_Family: "GET_Dictionary_Family",
    SET_Dictionary_Family: "SET_Dictionary_Family",
    ADD_Dictionary_Family: "ADD_Dictionary_Family",
    UPDATE_Dictionary_Family: "UPDATE_Dictionary_Family",
  };
  
  export const actions = {
    getDictionaryUpdateFamily: (payload) => ({
      type: actionTypes.GET_Dictionary_Family,
      payload,
    }),
  
    setDictionaryUpdateFamily: (payload) => ({
      type: actionTypes.SET_Dictionary_Family,
      payload,
    }),

    addtDictionaryUpdateFamily: (payload) => ({
        type: actionTypes.ADD_Dictionary_Family,
        payload,
      }),

    updateDictionaryUpdateFamily: (payload) => ({
        type: actionTypes.UPDATE_Dictionary_Family,
        payload,
      }),
  };
  