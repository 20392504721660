/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchMachineListAPI = () => {
  return API.get("/adminService/api/MasterData/getMachineList")
    .then((res) => res)
    .catch((error) => error);
};

export const addMachineModelsAPI = (payload) => {
  return API.post("/adminService/api/MasterData/addMachineModel", payload)
    .then((res) => res)
    .catch((error) => error);
};
