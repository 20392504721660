/*eslint-disable */
export const actionTypes = {
  GET_MACHINE_LIST: "GET_MACHINE_LIST",
  SET_MACHINE_LIST: "SET_MACHINE_LIST",
  ADD_MACHINE_MODELS: "ADD_MACHINE_MODELS",
};

export const actions = {
  getMachineList: (payload) => ({
    type: actionTypes.GET_MACHINE_LIST,
    payload,
  }),

  setMachineList: (payload) => ({
    type: actionTypes.SET_MACHINE_LIST,
    payload,
  }),

  addMachineModels: (payload) => ({
    type: actionTypes.ADD_MACHINE_MODELS,
    payload,
  }),
};
