/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getInspectionDataAPI,
  savePreliminarychecklistDataAPI,
  getNonConfirmityDetailAPI,
  saveInspectionAPI,
  submitPackdetailsAPI,
  getPackDetailsAPI
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* saveInspectionSaga(action) {
  try {
    const _api = yield call(saveInspectionAPI, action.payload);
    if (_api.data.status == 200) {
      yield put(actions.setSaveInspectionStatus(true));
      yield put(commonActions.successAlert("Inspection Details saved"));
      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getNonConfirmityDetailSaga(action) {
  try {
    const _api = yield call(getNonConfirmityDetailAPI, action.payload);
    yield put(actions.setNonConfirmityDetail(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
    console.log(error);
  }
}

function* getInspectionDataSaga(action) {
  try {
    const _api = yield call(getInspectionDataAPI, action.payload);
    yield put(actions.setInspectionData(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
    console.log(error);
  }
}

function* savePreliminarychecklistDataSaga(action) {
  try {
    const _api = yield call(savePreliminarychecklistDataAPI, action.payload);
    if (action.payload.preliminaryCheckResult == "1") {
      yield put(commonActions.successAlert("Preliminary Check is submitted"));
      yield put(actions.setSavePreliminaryChecklistResult(true));
    }
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* submitPackdetailsSaga(action) {
  try {
    const _api = yield call(submitPackdetailsAPI, action.payload);
    yield put(actions.updatePackDetails({id: action.payload.sampleSequenceId.toString(), saved: true}));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.response.data.error.errorMessage || error.data));
  }
}

function* getPackDetailsSaga(action) {
  try {
    const _api = yield call(getPackDetailsAPI, action.payload);
    yield put(actions.setPackDetails(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.response.data.error.errorMessage || error.data));
    console.log(error);
  }
}

export function* watchSampleInspectionSaga() {
  yield all([
    takeEvery(actionTypes.GET_INSPECTION_DATA, getInspectionDataSaga),
    takeEvery(actionTypes.SAVE_PRELIMINARY_CHECKLIST_DATA, savePreliminarychecklistDataSaga),
    takeEvery(actionTypes.GET_NC_DETAILS, getNonConfirmityDetailSaga),
    takeEvery(actionTypes.SAVE_INSPECTION_DATA, saveInspectionSaga),
    takeEvery(actionTypes.SUBMIT_PACK_DETAILS, submitPackdetailsSaga),
    takeEvery(actionTypes.GET_PACK_DETAILS, getPackDetailsSaga),
  ]);
}
