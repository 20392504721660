/*eslint-disable */

import { actionTypes } from "./actions";
import { decodeBase64 } from "utils";

const initialState = {
  helpEdit: {
    id: null,
    helpText: "",
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_HELP:
      return {
        ...state,
        helpEdit: payload[0],
      };

    default:
      return {
        ...state,
      };
  }
};
