/*eslint-disable */
export const actionTypes = {
  GET_DICTIONARY_TREE_LIST: "GET_DICTIONARY_TREE_LIST",
  SET_DICTIONARY_TREE_PARENT_INFO: "SET_DICTIONARY_TREE_PARENT_INFO",
  SET_DICTIONARY_TREE_LIST: "SET_DICTIONARY_TREE_LIST",
  SET_TOTAL_TREE_NODE_COUNT: "SET_TOTAL_TREE_NODE_COUNT",
  SET_EXPANDED_NODE_ID: "SET_EXPANDED_NODE_ID",
  RESET_DICTIONARY: "RESET_DICTIONARY",
  SET_TREE_LOADING: "SET_TREE_LOADING",
  SET_NC_MAPPING_IDS: "SET_NC_MAPPING_IDS",
  SEARCH_NODE_BY_CODE: "SEARCH_NODE_BY_CODE",
  SET_SEARCH_NODE_BY_CODE: "SET_SEARCH_NODE_BY_CODE",
  MAKE_TREE_WITH_SEARCH_DATA: "MAKE_TREE_WITH_SEARCH_DATA",
  SET_TREE_WITH_SEARCH_DATA: "SET_TREE_WITH_SEARCH_DATA",
  SET_FILTERS: "SET_FILTERS",
  SET_OFFLINE_PARAMS: "SET_OFFLINE_PARAMS",
  SET_CREATE_OFFLINE_DIC: "SET_CREATE_OFFLINE_DIC",
  GET_ALL_Dictionary: "GET_ALL_Dictionary",
  EXPORT_DICTIONARY: "EXPORT_DICTIONARY",
};

export const actions = {
  getDictionaryTreeList: (payload) => ({
    type: actionTypes.GET_DICTIONARY_TREE_LIST,
    payload,
  }),
  setDictionaryTreeParentInfo: (payload) => ({
    type: actionTypes.SET_DICTIONARY_TREE_PARENT_INFO,
    payload,
  }),
  setOfflineDictionaryParams: (payload) => ({
    type: actionTypes.SET_OFFLINE_PARAMS,
    payload,
  }),
  setDictionaryTreeList: (payload) => ({
    type: actionTypes.SET_DICTIONARY_TREE_LIST,
    payload,
  }),
  setDictionaryTreeListWithSearchData: (payload) => ({
    type: actionTypes.SET_TREE_WITH_SEARCH_DATA,
    payload,
  }),
  resetDictionaryTreeList: () => ({
    type: actionTypes.RESET_DICTIONARY,
  }),
  setTotalNodeCount: (payload) => ({
    type: actionTypes.SET_TOTAL_TREE_NODE_COUNT,
    payload,
  }),
  setExpandedNodeIds: () => ({
    type: actionTypes.SET_EXPANDED_NODE_ID,
  }),
  setNcMappingIds: (payload) => ({
    type: actionTypes.SET_NC_MAPPING_IDS,
    payload,
  }),
  setIsTreeLoading: (payload) => ({
    type: actionTypes.SET_TREE_LOADING,
    payload,
  }),
  searchTreeNodeByCode: (payload) => ({
    type: actionTypes.SEARCH_NODE_BY_CODE,
    payload,
  }),
  setTreeSearchResultByCode: (payload) => ({
    type: actionTypes.SET_SEARCH_NODE_BY_CODE,
    payload,
  }),
  setTreeWithSearchData: (payload) => ({
    type: actionTypes.MAKE_TREE_WITH_SEARCH_DATA,
    payload,
  }),
  setFilters: (payload) => ({
    type: actionTypes.SET_FILTERS,
    payload,
  }),
  getAllDictionary: (payload) => ({
    type: actionTypes.GET_ALL_Dictionary,
    payload,
  }),
  setAllDictionary: (payload) => ({
    type: actionTypes.SET_CREATE_OFFLINE_DIC,
    payload,
  }),

  exportDictionaryTree: (payload) => ({
    type: actionTypes.EXPORT_DICTIONARY,
    payload,
  }),
};
