import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchPurchasingLocationTypeAPI,
  disablePurchasingLocationTypeAPI,
  editPurchasingLocationTypeAPI,
  addPurchasingLocationTypeAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getPurchasingLocationTypeSaga(action) {
  try {
    const _api = yield call(fetchPurchasingLocationTypeAPI, action.payload);
    yield put(actions.setPurchasingLocationType(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* disablePurchasingLocationTypeSaga(action) {
  try {
    const _api = yield call(disablePurchasingLocationTypeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(actions.getPurchasingLocationType());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editPurchasingLocationTypeSaga(action) {
  try {
    const _api = yield call(editPurchasingLocationTypeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(actions.getPurchasingLocationType());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addPurchasingLocationTypeSaga(action) {
  try {
    const _api = yield call(addPurchasingLocationTypeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(actions.getPurchasingLocationType());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchPurchasingLocationTypeSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_PURCHASING_LOCATION_TYPE,
      getPurchasingLocationTypeSaga
    ),
    takeEvery(
      actionTypes.DISABLE_PURCHASING_LOCATION_TYPE,
      disablePurchasingLocationTypeSaga
    ),
    takeEvery(
      actionTypes.EDIT_PURCHASING_LOCATION_TYPE,
      editPurchasingLocationTypeSaga
    ),
    takeEvery(
      actionTypes.ADD_PURCHASING_LOCATION_TYPE,
      addPurchasingLocationTypeSaga
    ),
  ]);
}
