import axios from "axios";
//import { getCookie, setCookie } from "utils";
import jwt from "jwt-decode";
import { getLocalStorageOrDefault, useLocalStorage } from "sessionManager/localSesionManager";
import {refreshTokens} from "../containers/Pages/Login/auth";

axios.defaults.timeout === 120000;

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_CVQA_BASE_URL}`,
});

//const userInfo = getCookie("userInfo");
const userInfo = getLocalStorageOrDefault("userInfo", null);

API.interceptors.request.use(
  async (config) => {
    // console.log("from axios", getCookie("token"));
    // console.log("from Interceptor userInfo", getCookie("userInfo"));

    const { username } = getLocalStorageOrDefault("token", null) ? jwt(getLocalStorageOrDefault("token", null)) : {};
    config.headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getLocalStorageOrDefault("token", null)}`,
      "x-username": username ? username : "",
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (config) => {
    return config;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      // setCookie("token", "", new Date());
      // setCookie("userInfo", "", new Date());
      // setCookie("userRole", "", new Date());

      //commented on 18-08-2022
      // useLocalStorage("token", "");
      // useLocalStorage("userInfo", "");
      // useLocalStorage("userRole", "");
      // sessionStorage.removeItem("isAuthorized");
      // sessionStorage.removeItem("userRoleInfo");
      // sessionStorage.removeItem("userMembershipRoleInfo");
      //window.location.href = "/login";
      const originalRequest = error.config;
      try {
        await refreshTokens(localStorage.getItem('refreshToken'));
        return axios(originalRequest);
      } catch (err) {
        console.log(err);
      }
      return;
    }
    return Promise.reject(error);
  }
);
