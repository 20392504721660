import { API } from "api/config";

export const downloadDictionaryTemplateApi = (payload) => {
  return API.get(`/adminService/api/DictionaryUpload/downloadUploadDictionaryTemplate?isOnlyCentral=${payload}`)
    .then((res) => res)
};

export const getImageNamesApi = (payload) => {
  return API.post(`/adminService/api/DictionaryUpload/getImageNames?type=${payload}`)
    .then((res) => res)
};
export const getXmlNamesApi = (payload) => {
  return API.get(`/adminService/api/FileHandler/Downloadxml?${payload}`)
    .then((res) => res).catch((error) => {
      return [{ error: error }]
    })
}

export const getImageData = (payload) => {
  return API.get(`/adminService/api/FileHandler/bulkImageDownload?FileName=${payload}`)
    .then((res) => res).catch((error) => {
      return [{ error: error.message, name: payload }];
    });
}
export const downloadDictionaryTemplateNormalApi = () => {
  return API.get(`/adminService/api/DictionaryUpload/downloadUploadDictionaryTemplateNormal`)
    .then((res) => res)
};

export const validateDictionaryApi = (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post(`/adminService/api/DictionaryUpload/validateUploadDictionary?isCentral=${payload[1]}`, payload[0], config)
    .then((res) => res)
};

export const validateNormalDictionaryApi = (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post(`/adminService/api/DictionaryUpload/validateNormalDictionaryUpload`, payload[0], config)
    .then((res) => res)
};

export const uploadDictionaryApi = (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post("/adminService/api/DictionaryUpload/UploadDictionary", payload, config)
    .then((res) => res)
};

export const uploadNormalDictionaryApi = (payload) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return API.post("/adminService/api/DictionaryUpload/uploadNormalDictionary", payload, config)
    .then((res) => res)
};
