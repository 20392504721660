import { actionTypes, actions } from "./actions";

const initialState = {
    auditAdministrationData: [],
    subTypeDictionaryData: [],
    subTypeDetailsData: {
        auditSubTypeId: 0,
        auditSubTypeCode: "",
        auditSubTypeName: "",
        multiInspector: "",
        auditTypeId: 0,
        auditTypeCode: "",
        auditTypeName: "",
        hasCigarettesPerPack: {},
        nbBundles: {},
        nbCases: {},
        nbPacksPerBundle: {},
        periodAfter: {},
        periodDuration: {},
        periodBefore: {},
        dictionaryList: []
    },
    auditAdminDetailsData: [],
    auditAdminParam: {
        code: 0
    },
    subTypeDetailsEdit: {
        auditSubTypeId: 0,
        auditSubTypeName: "",
        auditSubTypeCode: "",
        auditTypeCode: "",
        hasCigarettesPerPack: "",
        nbBundles: "",
        nbCases: "",
        nbPacksPerBundle: "",
        periodAfter: "",
        periodDuration: "",
        periodBefore: "",
        dictionaryList: [],
        defaultDictionaryList: [],
        dictionaryIds: ""
    },
    filterColumnList: [],
    filterTypeList: []
}
function checkemptyTakedefault(data,defaultValue) {
    if (data == null ||
        data == undefined) {
        return defaultValue;
    } 
    return data;
}
const getDictionaryIds = (row, dictionaryListState) => {
    var dictionaryids = dictionaryListState.filter(function (e) {
        return e.selected === 1;
    }).map(function (e) {
        return e.idDictionary == null ? 0 : e.idDictionary;
    }).join(",");
    if (dictionaryids.indexOf(row.idDictionary) == -1) {
        dictionaryids = dictionaryids + ',' + row.idDictionary;
    }
    else {
        var arrayIds = dictionaryids.split(',');
        for (var i = 0; i < arrayIds.length; i++) {
            if (arrayIds[i] == row.idDictionary) {
                arrayIds.splice(i, 1);
            }
        }
        dictionaryids = arrayIds.join(',');
    }


    return dictionaryids;
};

const removeFromDefaultList = (row, dictionaryListState) => {
    var dicArr = dictionaryListState;
    var index = dicArr.findIndex(function (o) {
        return o.idDictionary == row.idDictionary;
    })
    if (index != -1) dicArr.splice(index, 1);
    else dicArr.push(row);
    return dicArr;
};
const auditAdministrationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_AUDIT_ADMINISTRATION_INFO:
            return {
                ...state,
                auditAdministrationData: payload
            };
        case actionTypes.SET_VFI_CONFIGURATION:
            return {
                ...state,
                auditAdministrationData: state.auditAdministrationData.map(auditAdminData => {
                    if (auditAdminData.auditSubTypeId == payload.auditSubTypeId) {
                        return {
                            ...auditAdminData, vfiConfig: payload.vfiConfig == 0 ? 1 : 0
                        }
                    }
                    else
                        return auditAdminData;
                })
            };
        case actionTypes.GET_AUDIT_SUBTYPE_DICTIONARY_INFO:
            return {
                ...state,
                //subTypeDictionaryData: payload,
                subTypeDictionaryData: {
                    ...state.subTypeDictionaryData,
                    ...payload,
                },
            };
        case actionTypes.SET_AUDIT_SUBTYPE_DICTIONARY_INFO:
            return {
                ...state,
                // subTypeDictionaryData: payload,
                subTypeDictionaryData: {
                    ...state.subTypeDictionaryData,
                    ...payload,
                },
            };
        case actionTypes.GET_AUDIT_SUBTYPE_DETAILS_INFO:
            return {
                ...state,
                subTypeDetailsEdit: payload,
            };
        case actionTypes.SET_AUDIT_SUBTYPE_DETAILS_INFO:
            return {
                ...state,
                subTypeDetailsData: payload,
            };
        case actionTypes.CONVERT_AUDIT_SUBTYPE_DETAILS_FORMIK:
            return {
                ...state,
                subTypeDetailsEdit: {
                    ...state.subTypeDetailsEdit,
                    auditSubTypeId: state?.subTypeDetailsData.auditSubTypeId,
                    auditSubTypeName: state?.subTypeDetailsData.auditSubTypeName,
                    auditTypeCode: state?.subTypeDetailsData.auditTypeCode,
                    auditSubTypeCode: state?.subTypeDetailsData.auditSubTypeCode,
                    hasCigarettesPerPack: checkemptyTakedefault(state?.subTypeDetailsData.hasCigarettesPerPack.value,"0"),
                    nbBundles: checkemptyTakedefault(state?.subTypeDetailsData.nbBundles.value,"0"),
                    nbCases: checkemptyTakedefault(state?.subTypeDetailsData.nbCases.value,"0"),
                    nbPacksPerBundle: checkemptyTakedefault(state?.subTypeDetailsData.nbPacksPerBundle.value,"0"),
                    periodBefore: checkemptyTakedefault(state?.subTypeDetailsData.periodBefore.value,"0"),
                    periodAfter: checkemptyTakedefault(state?.subTypeDetailsData.periodAfter.value,"0"),
                    periodDuration: checkemptyTakedefault(state?.subTypeDetailsData.periodDuration.value,"0"),
                    dictionaryList: state?.subTypeDetailsData.dictionaryList,
                    defaultDictionaryList: state?.subTypeDetailsData.dictionaryList.filter(function (e) {
                        return e.selected === 1;
                    }),
                    dictionaryIds: state?.subTypeDetailsData.dictionaryList.filter(function (e) {
                        return e.selected === 1;
                    }).map(function (e, i) {
                        return e.idDictionary == null ? "0" : e.idDictionary;
                    }).join(",")
                },
            };
        case actionTypes.SELECT_UNSELECT_DICTIONARY_DATA:
            return {
                ...state,
                subTypeDetailsEdit: {
                    ...state.subTypeDetailsEdit,
                    dictionaryList: state.subTypeDetailsEdit.dictionaryList.map(dic => {
                        if (dic.idDictionary == payload.idDictionary) {
                            return {
                                ...dic,
                                selected: dic.selected > 0 ? 0 : 1
                            }
                        }
                        return dic
                    }),                    
                    dictionaryIds: getDictionaryIds(payload, state?.subTypeDetailsEdit.defaultDictionaryList),
                    defaultDictionaryList: removeFromDefaultList(payload, state.subTypeDetailsEdit.defaultDictionaryList),
                },
            };
        case actionTypes.EDIT_AUDIT_SUBTYPE_DETAILS_INFO:
            return {
                ...state,
                subTypeDetailsEdit: {
                    ...state.subTypeDetailsEdit,
                    ...payload,
                },
            };
        case actionTypes.GET_AUDIT_ADMIN_DETAILS_INFO:
            return {
                ...state,
                auditAdminParam: {
                    ...state.auditAdminParam,
                    ...payload,
                },
            };
        case actionTypes.SET_AUDIT_ADMIN_DETAILS_INFO:
            return {
                ...state,
                auditAdminDetailsData: payload,
            };
        case actionTypes.SET_FILTER_COLUMN_LIST:
            return {
                ...state,
                filterColumnList: payload
            };
        case actionTypes.SET_FILTER_TYPE_LIST:
            return {
                ...state,
                filterTypeList: payload
            };
        default:
            return state;
    }
};

export default auditAdministrationReducer;