import PropTypes from "prop-types";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { Box, Drawer, useMediaQuery } from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";
import Divider from "@mui/material/Divider";
// project imports
import MenuList from "components/MenuList";
import { useSelector } from "react-redux";
//import { getCookie } from "utils";
import { getLocalStorageOrDefault } from "sessionManager/localSesionManager";

const drawerWidth = 260;
// style constant
const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderRight: "none",
    [theme.breakpoints.up("md")]: {
      // top: '98px'
      top: "60px",
    },
  },
  ScrollHeight: {
    height: "calc(100vh - 150px)",
    paddingLeft: "16px",
    paddingRight: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 56px)",
    },
  },
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5rem  1.5rem 0.5rem 1.5rem ",
    marginLeft: "auto",
    marginRight: "auto",
    fontWeight: "bold",
  },
  sidebar: {
    overflow: "hidden",
    marginTop: "10px",
  },
  roleInfo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    paddingBottom: "1rem",
  },
}));

// ===========================|| SIDEBAR DRAWER ||=========================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const commonState = useSelector((state) => state.customization);
  const username =
    commonState?.userInfo?.email?.split("@")[0]?.split(".")?.join(" ") || "";
  //const role = commonState?.userInfo?.role || getCookie("userRole") || "";
  const role = commonState?.userInfo?.role || getLocalStorageOrDefault("userRole",null) || "";
  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "block" } }}>
        <div className={classes.boxContainer}>{username}</div>
        <div className={classes.roleInfo}>{role}</div>
      </Box>
      <Divider sx={{ bgcolor: "rgba(20,61,119,0.2)" }} />
      <BrowserView className={classes.sidebar}>
        <PerfectScrollbar component="div" className={classes.ScrollHeight}>
          <MenuList imdlMenus={commonState?.menus} />
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList imdlMenus={commonState?.menus} />
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </nav>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
