/*eslint-disable */
export const actionTypes = {
  GET_NEWS: "GET_NEWS",
  SET_NEWS: "SET_NEWS",
  GET_NEWS_BY_ID: "GET_NEWS_BY_ID",
  SET_NEWS_BY_ID: "SET_NEWS_BY_ID",
  EDIT_NEWS: "EDIT_NEWS",
  SET_NEWS_EDIT: "SET_NEWS_EDIT",
  ADD_NEWS: "ADD_NEWS",
  DELETE_NEWS: "DELETE_NEWS",
};

export const actions = {
  getNews: (payload) => ({
    type: actionTypes.GET_NEWS,
    payload: payload,
  }),

  setNews: (payload) => ({
    type: actionTypes.SET_NEWS,
    payload: payload,
  }),

  setNewsById: (payload) => ({
    type: actionTypes.SET_NEWS_BY_ID,
    payload: payload,
  }),

  getNewsById: (payload) => ({
    type: actionTypes.GET_NEWS_BY_ID,
    payload: payload,
  }),

  editNews: (payload) => ({
    type: actionTypes.EDIT_NEWS,
    payload: payload,
  }),

  setNewsEdit: (payload) => ({
    type: actionTypes.SET_NEWS_EDIT,
    payload: payload,
  }),

  addNews: (payload) => ({
    type: actionTypes.ADD_NEWS,
    payload: payload,
  }),

  deleteNews: (payload) => ({
    type: actionTypes.DELETE_NEWS,
    payload: payload,
  }),
};
