import React from "react";
import { Link } from "react-router-dom";
// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import Logo from "components/Logo/Logo";

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to="/">
    <Logo />
  </ButtonBase>
);

export default LogoSection;
