// ==============================|| MENU ITEMS ||============================== //

const menus = {
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      breadcrumbs: true,
    },
    {
      id: "audit",
      title: "Audit",
      type: "collapse",
      children: [
        {
          id: "auditLogin",
          title: "Login",
          type: "item",
          url: "/audit/login",
          breadcrumbs: true,
        },
        {
          id: "auditInspection",
          title: "Inspection",
          type: "item",
          url: "/audit/inspection",
          breadcrumbs: true,
        },
        {
          id: "auditValidation",
          title: "Validation",
          type: "item",
          url: "/audit/validation",
          breadcrumbs: true,
        },
        {
          id: "auditReports",
          title: "Reports",
          type: "item",
          url: "/audit/reports",
          breadcrumbs: true,
        },
        // added breadcrumb for the rest of routes
        {
          id: "auditLoginSample",
          title: "Inspection",
          url: "/audit/sample-process",
          type: "item",
          breadcrumbs: true,
          isHide: true,
        },
        {
          id: "auditValidateSample",
          title: "Validation",
          url: "/audit/sample-validate",
          type: "item",
          breadcrumbs: true,
          isHide: true,
        },
        {
          id: "auditReportsSample",
          title: "Reports",
          url: "/audit/sample-report",
          type: "item",
          breadcrumbs: true,
          isHide: true,
        },
        {
          id: "auditInspectionBatch",
          title: "Inspection",
          url: "/audit/batch-inspection",
          type: "item",
          breadcrumbs: true,
          isHide: true,
        },
      ],
    },
    {
      id: "translation",
      title: "Translation",
      type: "collapse",
      children: [
        {
          id: "pendingTranslations",
          title: "Waiting for Translation",
          type: "item",
          url: "/translation/pending-translations",
          breadcrumbs: true,
        },
        {
          id: "findInDictionary",
          title: "Find In A Dictionary",
          type: "item",
          url: "/translation/find-dictionary",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "admin",
      title: "Admin Panel",
      type: "collapse",
      url: "/admin/dictionary",
      children: [
        {
          id: "adminDictionary",
          title: "Dictionary",
          type: "item",
          url: "/admin/dictionary",
          breadcrumbs: true,
        },
        {
          id: "adminBrandGroups",
          title: "Brand Groups",
          type: "item",
          url: "/admin/brand-groups",
          breadcrumbs: true,
        },
        {
          id: "adminMarketedBrand",
          title: "Marketed Brand",
          type: "item",
          url: "/admin/maketed-brand",
          breadcrumbs: true,
        },
        {
          id: "adminLaboratoryAdmin",
          title: "Laboratory Admin",
          type: "item",
          url: "/admin/laboratory-admin",
          breadcrumbs: true,
        },
        {
          id: "adminProductionCenter",
          title: "Production Center",
          type: "item",
          url: "/admin/production-center",
          breadcrumbs: true,
        },
        {
          id: "adminNews",
          title: "News",
          type: "item",
          url: "/admin/news",
          breadcrumbs: true,
        },
        {
          id: "adminHelp",
          title: "Help",
          type: "item",
          url: "/admin/help",
          breadcrumbs: true,
        },
        {
          id: "adminAuditAdministration",
          title: "Audit Administration",
          type: "item",
          url: "/admin/audit-administration",
          breadcrumbs: true,
        },
        {
          id: "adminAttribute",
          title: "Attribute",
          type: "item",
          url: "/admin/attribute",
          breadcrumbs: true,
        },
        {
          id: "adminIpsmAttributes",
          title: "IPSM Attributes",
          type: "item",
          url: "/admin/ipsm-attributes",
          breadcrumbs: true,
        },
        {
          id: "adminPreliminaryChecklist",
          title: "Preliminary Checklist",
          type: "item",
          url: "/admin/preliminary-checklist",
          breadcrumbs: true,
        },
        {
          id: "adminProductionUnits",
          title: "Production Units",
          type: "item",
          url: "/admin/production-units",
          breadcrumbs: true,
        },
        {
          id: "adminMachineModels",
          title: "Machine Models",
          type: "item",
          url: "/admin/machine-models",
          breadcrumbs: true,
        },
        {
          id: "adminPurchasingLocationType",
          title: "Purchasing Location Type",
          type: "item",
          url: "/admin/purchasing-location-type",
          breadcrumbs: true,
        },
        {
          id: "adminLocalProjectTypes",
          title: "Local Project Types",
          type: "item",
          url: "/admin/local-project-types",
          breadcrumbs: true,
        },
        {
          id: "adminProject",
          title: "Project",
          type: "item",
          url: "/admin/project",
          breadcrumbs: true,
        },
        {
          id: "adminLoginHistory",
          title: "Login History",
          type: "item",
          url: "/admin/login-history",
          breadcrumbs: true,
        },
        {
          id: "adminAccessManagement",
          title: "Access Management",
          type: "item",
          url: "/admin/access-management",
          breadcrumbs: true,
        },
        {
          id: "adminInspectionChecklist",
          title: "Inspection Checklist",
          type: "item",
          url: "/admin/inspection-checklist",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "data_download",
      title: "Data Download",
      type: "collapse",
      url: "/data_download/download-xml-image",
      children: [
        {
          id: "downloadXmlImage",
          title: "Download Xml and Images",
          type: "item",
          url: "/data_download/download-xml-image",
          breadcrumbs: true,
        }
      ],
    },
  ],
};

const menuItems = {
  items: [menus],
};

export default menuItems;
