import { actionTypes } from "./actions";

const initialState = {
  dictionaryGetFamily: {
    // FamilyCode: "",
    // FamilyName: "",
    // active: "",
  },
  addDictionaryFamily: {},
  updateDictionaryFamily: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_Dictionary_Family:
      return {
        ...state,
        dictionaryGetFamily: payload[0],
      };

    case actionTypes.ADD_Dictionary_Family:
      return {
        ...state,
        addDictionaryFamily: payload,
      };

    case actionTypes.UPDATE_Dictionary_Family:
      return {
        ...state,
        updateDictionaryFamily: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
