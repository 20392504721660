//react
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//mui
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";

import { useFormik } from "formik";

//project-related-import
import { actions } from "../state/actions";
import { actions as commonAction } from "store/actions/common";
import Form from "components/Form";
import BundleList from "../DummyData/BundleList";
import RemoveInspectorPopup from "./RemoveInspectorPopup";
import bundleList from "../DummyData/BundleList";

const AssignInspector = ({ open, handleClose, batchNo }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const commonState = useSelector((state) => state.customization);
  const username =
    commonState?.userInfo?.email?.split("@")[0]?.split(".")?.join(" ") || "";
  const dispatch = useDispatch();
  const state = useSelector((state) => state.BatchDevice);

  const [assignedBundles, setAssignedBundles] = useState();
  const totalBundles = state?.bundleList?.bundlesList?.length;
  const [removeInspector, setRemoveInspector] = useState(false);
  const [updatedList, setUpdatedList] = useState();
  const userName = commonState?.userInfo?.username;
  const navigate = useNavigate();

  const userRoleInfo = JSON.parse(sessionStorage.getItem("userRoleInfo"));

  const bundleListParams = {
    batchNo: batchNo,
    userId: userRoleInfo && userRoleInfo.id,
    "x-username": userName,
  };

  const inspectorListParams = {
    isBatch: 1,
    userLogin:
      commonState?.userInfo?.username?.split("@")[0]?.split("_")[1] || "",
    "x-username": userName,
  };

  useEffect(() => {
    dispatch(commonAction.setLoading(true));

    dispatch(actions.getInspectorList(inspectorListParams));

    dispatch(actions.getAssignedBundleList(bundleListParams));
  }, [batchNo]);

  useEffect(() => {}, [state?.bundleList?.bundlesList]);

  const initialState = {
    inspector: "me",
  };

  const columns = [
    {
      field: "id",

      hide: true,
    },
    {
      field: "label",
      headerName: "Label",

      width: 210,
    },
    {
      field: "status",
      headerName: "Status",

      width: 100,
    },
    {
      field: "inspectorAssigned",
      headerName: "Inspector Assigned",
      width: 200,
    },
  ];

  const assignInspector = () => {
    const inspectorCode = state?.inspectorList?.inspectors?.find(
      (x) =>
        x?.fullName?.toLowerCase() === formik.values.inspector?.toLowerCase()
    )?.id;

    const updatedBundleList = selectionModel?.map((id) => {
      let section = state?.bundleList?.bundlesList?.find(
        (item) => item.id === id
      );
      if (section) {
        if (
          formik.values.inspector != "" &&
          formik.values.inspector?.toLowerCase() === "me"
        ) {
          const userName = commonState?.userInfo?.principalName;
          const userVal = state?.inspectorList?.inspectors?.find(
            (x) =>
              x?.userName?.toLowerCase() ===
              commonState?.userInfo?.principalName?.toLowerCase()
          );
          section.inspectorAssigned = userVal.fullName;
          section.inspectorAssignedCode = userVal?.id?.toString();
          section.statusUpdate = "ADDED";
        } else {
          section.inspectorAssigned = formik.values.inspector;
          section.inspectorAssignedCode = inspectorCode?.toString();
          section.statusUpdate = "ADDED";
        }
        section.status = "InProgress";
      }
      return section;
    });

    state?.bundleList?.bundlesList?.map(
      (obj) => updatedBundleList?.find((o) => o?.id === obj?.id) || obj
    );

    dispatch(actions.setAssignedBundleList(state?.bundleList));
    setUpdatedList(state?.bundleList?.bundlesList);
    const assignedBundleVal = state?.bundleList?.bundlesList?.filter(
      (x) => x.inspectorAssigned?.toLowerCase() != "unassigned"
    )?.length;

    setAssignedBundles(assignedBundleVal);
  };

  const remove = () => {
    setRemoveInspector(true);
  };

  const closeRemovePopup = () => {
    setRemoveInspector(false);
  };
  const removeInspectorHandler = () => {
    const updatedBundleList = selectionModel?.map((id) => {
      let section = state?.bundleList?.bundlesList?.find(
        (item) => item.id === id
      );
      if (section) {
        section.inspectorAssigned = "Unassigned";
        section.status = "Pending";
        section.statusUpdate = "REMOVED";
        section.inspectorAssignedCode = "[unassigned]";
      }

      return section;
    });
    state?.bundleList?.bundlesList?.map(
      (obj) => updatedBundleList?.find((o) => o?.id === obj?.id) || obj
    );

    dispatch(actions.setAssignedBundleList(state?.bundleList));
    setUpdatedList(state?.bundleList?.bundlesList);

    const assignedBundleVal = state?.bundleList?.bundlesList?.filter(
      (x) => x.inspectorAssigned?.toLowerCase() != "unassigned"
    )?.length;

    setAssignedBundles(assignedBundleVal);
  };

  const formik = useFormik({
    initialValues: initialState,
    onSubmit: () => {
      const payload = {
        userId: userRoleInfo && userRoleInfo.id,
        sampleId: batchNo,
        assignedBatchSampleList: state?.bundleList?.bundlesList,
        old_assignedBatchSampleList: state?.bundleList?.old_bundlesList,
      };

      const params = {
        "x-username": userName,
      };

      dispatch(actions.saveAssignedBundleList(payload, params));

      formik.resetForm();
      handleClose();
    },
  });

  return (
    <Form formik={formik} handleClose={handleClose} open={open} title={batchNo}>
      <Grid container>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5" data-testid="dashboard-header">
            Assign to Inspector:
          </Typography>
          <FormControl fullWidth sx={{ mt: "20px" }}>
            <Select
              labelId="mutiple-select-label"
              name="inspector"
              autoWidth={false}
              value={formik.values.inspector}
              onChange={formik.handleChange}
            >
              <MenuItem value={"me"}>Me</MenuItem>

              {state?.inspectorList?.inspectors?.map((inspectorList) => {
                return (
                  <MenuItem
                    value={inspectorList.fullName}
                    key={inspectorList.id}
                  >
                    {inspectorList.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={2} sx={{ ml: "100px", mt: "20px" }}>
          <Button variant="contained" onClick={assignInspector}>
            Assign{" "}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={2} sx={{ mt: "20px" }}>
          <Button variant="contained" onClick={remove}>
            Remove{" "}
          </Button>
        </Grid>
        {removeInspector && (
          <RemoveInspectorPopup
            open={removeInspector}
            handleClose={closeRemovePopup}
            handleRemoveInspector={removeInspectorHandler}
          />
        )}
      </Grid>
      <Grid container sx={{ mt: "30px" }}>
        {" "}
        <Typography variant="h5" data-testid="dashboard-header">
          {totalBundles
            ? `Assigned Samples: ${
                assignedBundles
                  ? assignedBundles
                  : state?.bundleList?.assignedBundles
              } / ${totalBundles}`
            : "Assigned Samples:"}
        </Typography>
      </Grid>
      <Grid container sx={{ background: "#fff", mt: "15px" }}>
        <div
          data-testid="sample-inspect-grid"
          style={{ height: 400, width: "100%", marginTop: "10px" }}
        >
          <DataGrid
            rows={state?.bundleList?.bundlesList || updatedList || []}
            columns={columns}
            getRowId={(row) => row.id}
            disableSelectionOnClick
            pagination
            loading={commonState?.isLoading}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20]}
            checkboxSelection
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onSelectionModelChange={(ids) => {
              setSelectionModel(ids);
            }}
            selectionModel={selectionModel}
          />
        </div>
      </Grid>
    </Form>
  );
};

export default AssignInspector;
