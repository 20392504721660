/*eslint-disable */
export const actionTypes = {
    GET_DICTIONARY_DROPDOWN: "GET_DICTIONARY_DROPDOWN",
    SET_DICTIONARY_DROPDOWN: "SET_DICTIONARY_DROPDOWN",
    ADD_DICTIONARY: "ADD_DICTIONARY",
  };
  
  export const actions = {
    getDictionaryDropdownsList: () => ({
      type: actionTypes.GET_DICTIONARY_DROPDOWN
    }),
    setDictionaryDropdownsList: (payload) => ({
      type: actionTypes.SET_DICTIONARY_DROPDOWN,
      payload
    }),
    setDictionaryList: (payload) => ({
      type: actionTypes.ADD_DICTIONARY, 
      payload
    })
  };
  