export const actionTypes = {
  GET_W_Translation: "GET_W_Translation",
  SET_W_Translation: "SET_W_Translation",
  GET_Translation_Edit: "GET_Translation_Edit",
  SET_Translation_Edit: "SET_Translation_Edit",
  //GET_UpdateTranslation: "GET_UpdateTranslation",
  UpdateTranslation: "UpdateTranslation",
  Set_Description: "Set_Description",
  Get_Description: "Get_Description",
  SET_Translation_Edit_Cell: "SET_Translation_Edit_Cell",
  SET_Translation_Count: "SET_Translation_Count",
  GET_TRANSLATION_DICTIONARY_TYPES: "GET_TRANSLATION_DICTIONARY_TYPES",
  SET_TRANSLATION_DICTIONARY_TYPES: "SET_TRANSLATION_DICTIONARY_TYPES",
  DOWNLOAD_TRANSLATION_TEMPLATE: "DOWNLOAD_TRANSLATION_TEMPLATE",
  SET_IS_TRANSLATION_UPDATE: "SET_IS_TRANSLATION_UPDATE",
};

export const actions = {

  setTranslationUpdated: (payload) => ({
    type: actionTypes.SET_IS_TRANSLATION_UPDATE,
    payload,
  }),

  getTranslationDescription: (payload) => ({
    type: actionTypes.Get_Description,
    payload,
  }),

  setTranslationDescription: (payload) => ({
    type: actionTypes.Set_Description,
    payload,
  }),

  updateTranslation: (payload) => ({
    type: actionTypes.UpdateTranslation,
    payload,
  }),

  getWTranslation: (payload) => ({
    type: actionTypes.GET_W_Translation,
    payload,
  }),

  setWTranslation: (payload) => ({
    type: actionTypes.SET_W_Translation,
    payload,
  }),

  setTranslationCount: (payload) => ({
    type: actionTypes.SET_Translation_Count,
    payload,
  }),

  getTranslationEdit: (payload) => ({
    type: actionTypes.GET_Translation_Edit,
    payload,
  }),

  setTranslationEdit: (payload) => ({
    type: actionTypes.SET_Translation_Edit,
    payload,
  }),

  setTranslationEditCell: (payload) => ({
    type: actionTypes.SET_Translation_Edit_Cell,
    payload,
  }),

  getTranslationDictionaryTypes: (payload) => ({
    type: actionTypes.GET_TRANSLATION_DICTIONARY_TYPES,
    payload,
  }),

  setTranslationDictionaryTypes: (payload) => ({
    type: actionTypes.SET_TRANSLATION_DICTIONARY_TYPES,
    payload,
  }),

  downloadTranslationTemplate: (payload) => ({
    type: actionTypes.DOWNLOAD_TRANSLATION_TEMPLATE,
    payload,
  }),
};
