/*eslint-disable */

import { API } from "api/config";

//  API
export const fetchProductChecklistAPI = (payload) => {
  return API.get(
    "/adminService/api/InspectionChecklist/editInspectionChecklistProduct",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const addDictionaryChecklistAPI = (payload) => {
  return API.post(
    "/adminService/api/InspectionChecklist/addInspectionChecklist",
    payload
  ).then((res) => res);
};

export const addProductCategoryChecklistAPI = (payload) => {
  return API.post(
    "/adminService/api/InspectionChecklist/addInspectionChecklistProductCategory",
    JSON.stringify(payload)
  )
    .then((res) => res)
    .catch((error) => error);
};
export const editProductCategoryChecklistAPI = (payload) => {
  return API.put(
    "/adminService/api/InspectionChecklist/updateInspectionChecklistProductCategory",
    JSON.stringify(payload)
  )
    .then((res) => res)
    .catch((error) => error);
};
export const fetchInspectionListAPI = () => {
  return API.get("/adminService/api/InspectionChecklist/getInspectionChecklist")
    .then((res) => res)
    .catch((error) => error);
};
export const fetchDeviceChecklistInfoAPI = (payload) => {
  return API.get(
    "/adminService/api/InspectionChecklist/editInspectionChecklistDetail",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const fetchInspectionChecklistNcCodesAPI = (payload) => {
  return API.get(
    "adminService/api/InspectionChecklist/editChecklistProductCategoryDetail",
    {
      params: payload,
    }
    //"adminService/api/InspectionChecklist/editChecklistProductCategoryDetail?id=9&productId=2"
  )
    .then((res) => res)
    .catch((error) => error);
};

export const fetchInspectionChecklistNcCodesEditAPI = (payload) => {
  return API.put(
    "/adminService/api/InspectionChecklist/updateInspectionChecklistNcCodes",
    JSON.stringify(payload)
  )
    .then((res) => res)
    .catch((error) => error);
};

export const fetchInspectionChecklistNcCodesAddAPI = (payload) => {
  return API.post(
    "/adminService/api/InspectionChecklist/addInspectionChecklistNcCodes",
    JSON.stringify(payload)
  ).then((res) => res);
};

export const addProductChecklistAPI = (payload) => {
  return API.post(
    "/adminService/api/InspectionChecklist/addInspectionChecklistProduct",
    payload
  ).then((res) => res);
};

export const editProductChecklistAPI = (payload) => {
  return API.put(
    "/adminService/api/InspectionChecklist/updateInspectionChecklistProduct",
    payload
  ).then((res) => res);
};
