import { actionTypes } from "./actions";

const initialState = {
  productionUnitData: [],
  machineList: [],
  // {
  //   machineModelCode:"",
  //   machineType:"",
  // },
  productionCenter: [],
  // {
  //   productionCenter: "",
  //   productionCenterDesc: "",
  //   manufacturerTypeDesc: "",
  // },
  productionUnitEditData:
  {
    id: 0,
    disable: 0,
    productionCenter: "",
    unitCode: "",
    activationDate: "",
    deActivationDate: "",
    packType: "",
    vqiTarget: "",
    cogid: "",
    machineModelCode: [],
    machineType: "",
  },
  productionUnitDataCount: 0,
  saveProductionUnitSuccess: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PRODUCTION_UNIT:
      return {
        ...state,
        productionUnitData: payload,
      };

    case actionTypes.SET_PRODUCTION_UNIT_COUNT:
      return {
        ...state,
        productionUnitDataCount: payload,
      };

    case actionTypes.SET_EDIT_PRODUCTION_UNIT:
      return {
        ...state,
        productionUnitEditData: payload,
      };

    case actionTypes.SET_PRODUCTION_CENTER:
      return {
        ...state,
        productionCenter: payload,
      };

    case actionTypes.SET_MACHINE_LIST:
      return {
        ...state,
        machineList: payload,
      };
    case actionTypes.SAVE_PRODUCTION_UNIT_SUCCESS:
      return {
        ...state,
        saveProductionUnitSuccess: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
