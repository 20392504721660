import { createTheme } from "@mui/material/styles";

// assets
import colors from "assets/scss/_themes-vars.module.scss";

// project imports
import componentStyleOverrides from "./compStyleOverride";
import themePalette from "./palette";
import themeTypography from "./typography";

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export function theme(customization) {
  const color = colors;

  const themeOption = {
    colors: color,
    heading: color.black100,
    paper: color.paper,
    backgroundHeader: color.white50,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.white100,
    darkTextSecondary: color.grey500,
    textDark: color.white100,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
    backgroundImage: color.blue150,
    customization,
  };

  return createTheme({
    direction: "ltr",
    palette: themePalette(themeOption),
    mixins: {
      toolbar: {
        minHeight: "48px",
        padding: "16px",
        "@media (min-width: 600px)": {
          minHeight: "48px",
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: themeTypography(themeOption),
    components: componentStyleOverrides(themeOption),
  });
}

export default theme;
