import { API } from "api/config";

export const downloadNcMappingAPI = (payload) => {
  return API.get("/adminService/api/NCMapping/downloadNCMappingTemplate", {
    params: payload,
  }).then((res) => res);
};

export const downloadLatestNcMappingAPI = (payload) => {
  return API.get("/adminService/api/NCMapping/downloadLatestNCMapping", {
    params: payload,
  }).then((res) => res);
};

export const uploadNcTemplateAPI = (payload) => {
  return API.post("/adminService/api/NCMapping/uploadDefectMapping", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then((res) => res);
};
