//react
import React from "react";
import { useDispatch } from "react-redux";

//mui

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  dialog: {
    position: "absolute",
    width: theme.spacing(50),
  },
  modelHeader: {
    backgroundColor: theme.palette.primary.main,
    fontSize: "20px",
    color: theme.palette.background.paper,
  },
  cancelButton: {
    border: `2px solid ${theme.palette.grey[500]}`,
    color: theme.palette.grey[500],
  },
  popupBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: theme.spacing(20),
    margin: "0 auto",
    marginTop: theme.spacing(5),
  },
}));

export default function RemoveInspector({
  open,
  handleClose,
  popupSize = "sm",
  handleRemoveInspector,
}) {
  const classes = useStyles();
  const handleFormClose = () => {
    handleClose();
  };

  const remove = () => {
    handleRemoveInspector();
    handleClose();
  };

  return (
    <div>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleFormClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={popupSize}
      >
        <DialogContent>
          You are about to unassign this sample . Any non conformity related to
          this bundle will be removed from this inspection{" "}
        </DialogContent>

        <Divider sx={{ borderColor: "#c9c9c9" }} />
        <DialogActions>
          <div className={classes.popupBottom}>
            <Button
              autoFocus
              size="small"
              variant="outlined"
              type="submit"
              onClick={remove}
            >
              Ok
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
