import { takeEvery, call, put, all, take } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchDictionaryTreeListAPI,
  fetchDictionaryTreeSearchAPI,
  fetchAllDictionaryTreeListAPI,
  exportDictionayApi,
} from "../api/treeEndpoint";
import { actions as commonActions } from "store/actions/common";
import { API } from "api/config";
import moment from "moment";

const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

function* getDictionaryTreeListSaga(action) {
  try {
    yield put(actions.setIsTreeLoading(true));
    //const Param = action.payload;
    let dictionaryParam = { ...action.payload };
    dictionaryParam.dictionaryFilter = dictionaryParam.startingLevel < 1 ? "" : dictionaryParam.startingLevel == 1 ? dictionaryParam.dictionaryFilter : null;
    dictionaryParam.productFamilyFilter = dictionaryParam.startingLevel < 2 ? "" : dictionaryParam.startingLevel == 2 ? dictionaryParam.productFamilyFilter : null;
    dictionaryParam.familyFilter = dictionaryParam.startingLevel < 3 ? "" : dictionaryParam.startingLevel == 3 ? dictionaryParam.familyFilter : null;
    dictionaryParam.categoryFilter = dictionaryParam.startingLevel < 4 ? "" : dictionaryParam.startingLevel == 4 ? dictionaryParam.categoryFilter : null;
    dictionaryParam.defectFilter = dictionaryParam.startingLevel < 5 ? "" : dictionaryParam.startingLevel == 5 ? dictionaryParam.defectFilter : null;
    const _api = yield call(fetchDictionaryTreeListAPI, dictionaryParam);
    if (_api) {
      yield put(
        actions.setDictionaryTreeList(_api.data.data, action.parentInfo)
      );
    }
    yield put(actions.setIsTreeLoading(false));
  } catch ({
    response: {
      status,
      data: {
        error: { errorCode, errorMessage },
      },
    },
  }) {
    yield put(actions.setIsTreeLoading(false));
    // yield put(commonActions.infoAlert(errorMessage));
  }
}

function* getDictionaryTreeSearchResultSaga(action) {
  // try {
  let paramsArray = action.payload;
  //console.log("Defect Dictionary parameters",paramsArray);
  yield put(actions.setIsTreeLoading(true));

  // const responses = yield paramsArray.map(item => {
  //   //const res = call(fetchDictionaryTreeListAPI, item.parameter);
  //   console.log("Defect Dictionary request", item.parameter);
  //   return call(fetchDictionaryTreeListAPI, item.parameter);
  // })

  // yield responses.map(response => {
  //   console.log("Defect Dictionary Children Info", response);
  //   return true;//put(Actions.fileUploaded(response));
  // })
  let responseArray = [];
  for (let i = 0; i < paramsArray.length; i++) {
    try {
      //console.log("Defect Dictionary request", paramsArray[i].parameter);
      const _api = yield call(
        fetchDictionaryTreeListAPI,
        paramsArray[i].parameter
      );
      if (_api) {
        //console.log("Defect Dictionary parent info",paramsArray[i].parentInfo);
        //console.log("Defect Dictionary Children Info", _api.data.data);
        //yield put(actions.setDictionaryTreeListWithSearchData({ parent: paramsArray[i].parentInfo, childrenInfo: _api.data.data }));
        //yield take(actions.setDictionaryTreeListWithSearchData(_api.data.data, paramsArray[i].parentInfo));
        responseArray.push({
          parentInfo: paramsArray[i].parentInfo,
          childrenInfo: _api.data.data,
        });
      }
    } catch ({
      response: {
        status,
        data: {
          error: { errorCode, errorMessage },
        },
      },
    }) {
      yield put(actions.setIsTreeLoading(false));
      yield put(commonActions.infoAlert(errorMessage));
    }
  }
  yield put(actions.setDictionaryTreeListWithSearchData(responseArray));
  yield put(actions.setIsTreeLoading(false));

  // } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
  //   yield put(actions.setIsTreeLoading(false));
  //   yield put(commonActions.errorAlert(ErrorMessage));
  // }
}

function* getDictionaryTreeSearchSaga(action) {
  try {
    //yield put(actions.setIsTreeLoading(true));
    console.log("Defect Dictionary Search Request", action.payload);
    const _api = yield call(fetchDictionaryTreeSearchAPI, action.payload);
    if (_api) {
      yield put(actions.setTreeSearchResultByCode(_api.data.data));
      console.log("Defect Dictionary Search Response", _api.data.data);
    }
    // yield put(actions.setIsTreeLoading(false));
  } catch ({
    response: {
      status,
      data: {
        error: { ErrorCode, ErrorMessage },
      },
    },
  }) {
    yield put(actions.setIsTreeLoading(false));
    yield put(commonActions.errorAlert(ErrorMessage));
  }
}
function* getAllDictionaryTreeListSaga(action) {
  try {
    yield put(actions.setIsTreeLoading(true));
    const _api = yield call(fetchAllDictionaryTreeListAPI, action.payload);
    if (_api) {
      console.log("Create offline Dictionary Row Data", _api.data.data);
      yield put(actions.setAllDictionary(_api.data.data));
    }

    yield put(actions.setIsTreeLoading(false));
  } catch (error) {
    console.log("Saga Error", error);
    yield put(actions.setIsTreeLoading(false));
  }
}

function* exportDictionarySaga(action) {
  try {
    const _api = yield call(exportDictionayApi, action.payload);
    let contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let blob1 = b64toBlob(_api.data.data, contentType);
    let blobUrl1 = URL.createObjectURL(blob1);

    // create <a> tag dinamically
    var fileLink = document.createElement("a");
    fileLink.href = blobUrl1;
    const filename = "Report_" + moment().format("YYYY-MM-DD");
    // it forces the name of the downloaded file
    fileLink.download = filename;

    // triggers the click event
    fileLink.click();
    yield put(commonActions.setLoading(false));
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong"));
  }
}

export function* watchDictionaryTreeListSaga() {
  yield all([
    takeEvery(actionTypes.GET_DICTIONARY_TREE_LIST, getDictionaryTreeListSaga),
    takeEvery(actionTypes.SEARCH_NODE_BY_CODE, getDictionaryTreeSearchSaga),
    takeEvery(
      actionTypes.MAKE_TREE_WITH_SEARCH_DATA,
      getDictionaryTreeSearchResultSaga
    ),
    takeEvery(actionTypes.GET_ALL_Dictionary, getAllDictionaryTreeListSaga),
    takeEvery(actionTypes.EXPORT_DICTIONARY, exportDictionarySaga),
  ]);
}
