/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchIpsmAttributesAPI,
  updateIpsmAttributesAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getIpsmAttributesSaga(action) {
  try {
    const _api = yield call(fetchIpsmAttributesAPI, action.payload);
    yield put(actions.setIpsmAttributes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* updateIpsmAttributesSaga(action) {
  try {
    const _api = yield call(updateIpsmAttributesAPI, action.payload);
    yield put(actions.setUpdatedIpsmAttributes(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getIpsmAttributes());
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error.error.message));
  }
}

export function* watchIpsmAttributesSaga() {
  yield all([
    takeEvery(actionTypes.GET_IPSM_ATTRIBUTES, getIpsmAttributesSaga),
    takeEvery(actionTypes.UPDATE_IPSM_ATTRIBUTES, updateIpsmAttributesSaga),
  ]);
}
