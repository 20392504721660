import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TreeView } from '@mui/x-tree-view';
import PlusSquare from "./TreeDetails/PlusSquare";
import MinusSquare from "./TreeDetails/MinusSquare";
import StyledTreeItem from "./TreeDetails/StyledTreeItem";
import Checkbox from "@mui/material/Checkbox";
import { object } from "prop-types";
import { actions } from "../../containers/Admin/Dictionary/DictionaryTree/state/actions";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles({
  root: {
    height: "50vh",
    flexGrow: 1,
    // maxWidth: 400,
    overflow: "auto"
  }
});

export default function DictionaryTree({
  treeData,
  parentCallback,
  addCheckbox,
  expandedNodeIds,
  moduleType = "ADMIN",
  generateReportCallBack
}) {
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();
  const classes = useStyles();
  const data = treeData;
  const state = useSelector((state) => state.dictionaryTree);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const prepareTree = (data) =>
    Array.isArray(data) ? data.map((node) => renderTree(node)) : "";

  const renderLabelWithCheckbox = nodes => (
    <>
      <Checkbox
        checked={nodes.isChecked}
        onChange={(event) => getOnChange(event.currentTarget.checked, nodes)}
        onClick={(e) => e.stopPropagation()}
        sx={{ padding: "0" }}
      />
      <span style={{ fontSize: "small" }} key={nodes.id}>{getLabel(nodes)}</span>
    </>
  );
  const renderLabel = nodes => <span style={{ fontSize: "small" }} key={nodes.id}>{getLabel(nodes)}</span>

  const getLabel = (nodes) => {
    if (moduleType == "ADMIN") {
      if (nodes.level >= 4)
        return nodes.code + " | " + nodes.familyName + " : " + nodes.categoryName;
      else
        return (nodes.code ? nodes.code + " | " : "") + nodes.label;
    }
    else
      return nodes.label

  }

  const itemSelectionHandler = (item) => {
    parentCallback(item);
  };
  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.nodeId}
      nodeId={nodes.nodeId}
      label={addCheckbox ? renderLabelWithCheckbox(nodes) : renderLabel(nodes)}
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : ""}
    </StyledTreeItem>
  );

  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes.nodeId);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.nodeId === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (getNodeById(node, id) instanceof Object) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }
      return null;
    }
    return getAllChild(getNodeById(node, id));
  }

  const checkAllChildren = (nodes) => {
    if (nodes.children) {
      nodes.children.forEach((each) => {
        // if (each.label != null) {
        each.isChecked = nodes.isChecked;
        if (Array.isArray(each.children)) {
          checkAllChildren(each);
        }
        // }
      })
    }
    return true;
  };

  function getOnChange(checked, nodes) {
    nodes.isChecked = checked;
    if (nodes.level == 4) {
      dispatch(actions.setNcMappingIds(nodes));
    }
    checkAllChildren(nodes);
    const allNode = getChildById(data, nodes.nodeId);
    let array = checked
      ? [...selected, ...allNode]
      : selected.filter((value) => !allNode.includes(value));
    setSelected(array);
    generateReportCallBack(nodes);
  }

  let selectedNode = {};
  const findNode = (nodes, parentData) => {
    for (let i = 0; i < parentData.length; i++) {
      if (parentData[i]?.nodeId != nodes) {
        if (parentData[i]?.children) {
          if (parentData[i]?.children.length > 0) {
            findNode(nodes, parentData[i]?.children);
          }
        }
      } else {
        selectedNode.data = parentData[i];
        return true;
      }
    }
  };
  const handleNodeChange = (event, nodes) => {
    //const nodeData = findNode(nodes[0], data);
    // console.log("Parent Node Id",nodes);
    // console.log("Parent Tree",data);
    // console.log("Parent Sected node",selectedNode.data);
    // itemSelectionHandler(selectedNode.data);
    setExpandedNodes(nodes);
  };
  const handleNodeSelect = (event, nodes) => {
    const nodeData = findNode(nodes, data);
    itemSelectionHandler(selectedNode.data);
  };

  return (
    <TreeView
      className={classes.root}
      aria-label="dictionary"
      defaultCollapseIcon={<MinusSquare />}
      defaultExpandIcon={<PlusSquare />}
      expanded={expandedNodeIds}
      onNodeToggle={handleNodeChange}
      onNodeSelect={handleNodeSelect}
      sx={{ flexGrow: 1 }}
    >
      {prepareTree(data)}
    </TreeView>
  );
}
