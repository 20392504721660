/*eslint-disable */
export const actionTypes = {
  GET_IPSM_ATTRIBUTES: "GET_IPSM_ATTRIBUTES",
  SET_IPSM_ATTRIBUTES: "SET_IPSM_ATTRIBUTES",
  UPDATE_IPSM_ATTRIBUTES: "UPDATE_IPSM_ATTRIBUTES",
  SET_UPDATDED_IPSM_ATTRIBUTES: "SET_UPDATDED_IPSM_ATTRIBUTES",
};

export const actions = {
  getIpsmAttributes: (payload) => ({
    type: actionTypes.GET_IPSM_ATTRIBUTES,
    payload: payload,
  }),
  setIpsmAttributes: (payload) => ({
    type: actionTypes.SET_IPSM_ATTRIBUTES,
    payload: payload,
  }),
  updateIpsmAttributes: (payload) => ({
    type: actionTypes.UPDATE_IPSM_ATTRIBUTES,
    payload: payload,
  }),
  setUpdatedIpsmAttributes: (payload) => ({
    type: actionTypes.SET_UPDATDED_IPSM_ATTRIBUTES,
    payload: payload,
  }),
};
