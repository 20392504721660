import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchPreliminaryChecklistAPI,
  editPreliminaryChecklistAPI,
  editPreliminaryCheckSteplistAPI,
  getPreliminaryChecklistStepAttributeAPI,
  addPreliminaryChecklistStepAPI,
  getCheckListAttributeSourceListAPI,
  savePreliminaryChecklistStepAPI,
  addPreliminaryChecklistAttributeAPI,
  savePreliminaryChecklistAttributeAPI,
  updatePreliminaryChecklistStepAPI
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* savePreliminaryChecklistAttributeSaga(action) {
  try {    
    yield put(commonActions.setLoading(true));
    const _api = yield call(savePreliminaryChecklistAttributeAPI,action.payload);
      yield put(commonActions.successAlert(_api.data.data));   
    
      const params = {
        id: action.payload.stepId,
      };
      
      const _data = yield call(editPreliminaryCheckSteplistAPI, params);
      yield put(actions.setPreliminaryChecklistStep(_data.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}


function* addPreliminaryChecklistAttributeSaga(action) {
  try {    
    yield put(commonActions.setLoading(true));
    const _api = yield call(addPreliminaryChecklistAttributeAPI,action.payload);
      yield put(commonActions.successAlert(_api.data.data));   
    
      const params = {
        id: action.payload.stepId,
      };
      
      const _data = yield call(editPreliminaryCheckSteplistAPI, params);
      yield put(actions.setPreliminaryChecklistStep(_data.data.data));

      yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}



function* getPreliminaryChecklistSaga(action) {
  try {
    const _api = yield call(fetchPreliminaryChecklistAPI, action.payload);
    yield put(actions.setPreliminaryChecklist(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getCheckListAttributeSourceListsaga(action) {
  try {
    const _api = yield call(getCheckListAttributeSourceListAPI, action.payload);
    yield put(actions.setCheckListAttributeSourceList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
    // yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* editPreliminaryChecklistSaga(action) {
  try {
    const _api = yield call(editPreliminaryChecklistAPI, action.payload);
    yield put(actions.setPreliminaryChecklistEdit(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* getPreliminaryCheckSteplistSaga(action) {
  try {
    const _api = yield call(editPreliminaryCheckSteplistAPI, action.payload);
    yield put(actions.setPreliminaryChecklistStep(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* savePreliminaryChecklistStepSaga(action) {
  try {    
    yield put(commonActions.setLoading(true));
    const _api = yield call(updatePreliminaryChecklistStepAPI,action.payload);
      yield put(commonActions.successAlert(_api.data.data));   
    
    const params = {
      id: action.payload.stepId,
    };
    
    yield put(actions.getPreliminaryChecklistStep(params));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}


function* editPreliminaryCheckSteplistSaga(action) {
  try {
    const _api = yield call(getPreliminaryChecklistStepAttributeAPI, action.payload);
    //console.log("Prelim-->",_api.data.data);
    yield put(actions.setPreliminaryChecklistStepEdit(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

function* addPreliminaryChecklistStepSaga(action) {
  try {
    //console.log("Saga");
    const _api = yield call(addPreliminaryChecklistStepAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));  
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.message || error.data));
  }
}

export function* watchPreliminaryChecklistSaga() {
  yield all([
    takeEvery(
      actionTypes.GET_PRELIMINARY_CHECKLIST,
      getPreliminaryChecklistSaga
    ),
    takeEvery(
      actionTypes.EDIT_PRELIMINARY_CHECKLIST,
      editPreliminaryChecklistSaga
    ),

    takeEvery(
      actionTypes.GET_PRELIMINARY_CHECKLIST_STEP,
      getPreliminaryCheckSteplistSaga
    ),
    takeEvery(
      actionTypes.ADD_PRELIMINARY_CHECKLIST_STEP,
      addPreliminaryChecklistStepSaga
    ),
     takeEvery(
      actionTypes.GET_PRELIMINARY_ATTRIBUTE_SOURCELIST,
      getCheckListAttributeSourceListsaga
    ),
    takeEvery(
      actionTypes.EDIT_PRELIMINARY_CHECKLIST_STEP,
      editPreliminaryCheckSteplistSaga
    ),
    takeEvery(
      actionTypes.SAVE_PRELIMINARY_CHECKLIST_STEP,
      savePreliminaryChecklistStepSaga
    ),
    takeEvery(
      actionTypes.ADD_PRELIMINARY_CHECKLIST_ATTRIBUTE,
      addPreliminaryChecklistAttributeSaga
    ),
    takeEvery(
      actionTypes.SAVE_PRELIMINARY_CHECKLIST_ATTRIBUTE,
      savePreliminaryChecklistAttributeSaga
    )
  ]);
}
