import { actionTypes } from "./actions";

const initialState = {
  dictionaryGetProduct: [],
  dictionaryGetProductData: {},
  addDictionaryProduct: {},
  updateDictionaryProduct: {},
  updateDictionaryObjectStatus: {},
  dictionaryHierarchy: [],
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {

    case actionTypes.SET_Dictionary_Hierarchy:
      return {
        ...state,
        dictionaryHierarchy: payload,
      };

    case actionTypes.SET_Dictionary_Product:
      return {
        ...state,
        dictionaryGetProduct: payload,
      };

      case actionTypes.SET_Dictionary_Product_Data:
      return {
        ...state,
        dictionaryGetProductData: payload,
      };

    case actionTypes.ADD_Dictionary_Product:
      return {
        ...state,
        addDictionaryProduct: payload,
      };

    case actionTypes.UPDATE_Dictionary_Product:
      return {
        ...state,
        updateDictionaryProduct: payload,
      };

      case actionTypes.UPDATE_Dictionary_Object_Status:
      return {
        ...state,
        updateDictionaryObjectStatus: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
