/*eslint-disable */
export const actionTypes = {
  GET_LABORATORY_ADMIN: "GET_LABORATORY_ADMIN",
  SET_LABORATORY_ADMIN: "SET_LABORATORY_ADMIN",
  GET_LABORATORY_ADMIN_BY_ID: "GET_LABORATORY_ADMIN_BY_ID",
  SET_LABORATORY_ADMIN_BY_ID: "SET_LABORATORY_ADMIN_BY_ID",
  EDIT_LABORATORY_ADMIN: "EDIT_LABORATORY_ADMIN",
  SET_LABORATORY_ADMIN_EDIT: "SET_LABORATORY_ADMIN_EDIT",
  ADD_LABORATORY_ADMIN: "ADD_LABORATORY_ADMIN",
  SET_LABORATORY_ADMIN_ADD: "SET_LABORATORY_ADMIN_ADD",
};

export const actions = {
  getLaboratoryAdmin: (payload) => ({
    type: actionTypes.GET_LABORATORY_ADMIN,
    payload: payload,
  }),

  setLaboratoryAdmin: (payload) => ({
    type: actionTypes.SET_LABORATORY_ADMIN,
    payload: payload,
  }),

  setLaboratoryAdminById: (payload) => ({
    type: actionTypes.SET_LABORATORY_ADMIN_BY_ID,
    payload: payload,
  }),

  getLaboratoryAdminById: (payload) => ({
    type: actionTypes.GET_LABORATORY_ADMIN_BY_ID,
    payload: payload,
  }),

  editLaboratoryAdmin: (payload) => ({
    type: actionTypes.EDIT_LABORATORY_ADMIN,
    payload: payload,
  }),

  setLaboratoryAdminEdit: (payload) => ({
    type: actionTypes.SET_LABORATORY_ADMIN_EDIT,
    payload: payload,
  }),

  addLboratoryAdmin: (payload) => ({
    type: actionTypes.ADD_LABORATORY_ADMIN,
    payload: payload,
  }),

  setLaboratoryAdminAdd: (payload) => ({
    type: actionTypes.SET_LABORATORY_ADMIN_ADD,
    payload: payload,
  }),
};
