/*eslint-disable */

import { API } from "api/config";

//  API
export const fetchAuditAdministartionDataAPI = (payload) => {
  return API.get(
    "/adminService/api/AuditAdmin/getAuditAdminDetails"
  )
    .then((res) => res)
    .catch((error) => error);
};

export const fetchAuditSubTypeDictionaryListAPI = (payload) => {
  return API.get("/adminService/api/MasterData/getAuditSubTypeDictionaryList",
    {
      params: payload,
    })
    .then((res) => res)
    .catch((error) => error);
};
export const fetchAuditSubTypeDetailsAPI = (payload) => {
  return API.get("/adminService/api/AuditAdmin/editAuditSubTypeDetails",
    {
      params: payload,
    })
    .then((res) => res)
    .catch((error) => error);
};
export const fetchAuditAdminDetailsAPI = (payload) => {
  return API.get("/adminService/api/AuditAdmin/getEditAuditAdminDetails",
    {
      params: payload,
    })
    .then((res) => res)
    .catch((error) => error);
};
export const editAuditSubtypeDetailsAPI = (payload) => {
  return API.put("/adminService/api/AuditAdmin/saveAuditSubTypeAttributeConfig",JSON.stringify(payload))
    .then((res) => res)
    .catch((error) => error);
};
export const saveVFIConfigurationAPI = (payload) => {
  return API.put("/adminService/api/AuditAdmin/saveVFIConfiguration",payload)
    .then((res) => res)
    .catch((error) => error);
};
export const getFilterColumnListAPI = () => {
  return API.get("/adminService/api/MasterData/getFilterColumnList")
    .then((res) => res)
    .catch((error) => error);
};
export const getFilterTypeListAPI = () => {
  return API.get("/adminService/api/MasterData/getFilterTypeList")
    .then((res) => res)
    .catch((error) => error);
};
export const addAuditSubTypeFilterConfigAPI = (payload) => {
  return API.post("/adminService/api/AuditAdmin/addAuditSubTypeFilterConfig",
    payload)
    .then((res) => res)
    .catch((error) => error);
};
export const deleteFilterConfigValueAPI = (payload) => {
  return API.delete("/adminService/api/AuditAdmin/deleteFilterConfigValue?id="+payload)
    .then((res) => res)
    .catch((error) => error);
};
