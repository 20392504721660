import { API } from "api/config";

export const fetchUserDictionaryAttributeAPI = (payload) => {
  return API.get(
    "/adminService/api/DictionaryAttributes/getUserAttributesDictionary",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const saveUserDictionaryAttributeAPI = (payload) => {
  return API.put(
    "/adminService/api/DictionaryUpload/saveOfflineDictionaryAttributes",
    payload
  ).then((res) => res);
};
