import logo from "assets/images/CVQA_Logo.png";
import { makeStyles } from "@mui/styles";

// ===========================|| LOGO SVG ||=========================== //

const useStyles = makeStyles(() => ({
  logo: {},
}));

const Logo = () => {
  const classes = useStyles();
  return <img src={logo} className={classes.logo} width="100" alt="cvqa" />;
};

export default Logo;
