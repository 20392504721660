import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Dialog from "components/Dialog";

import LanguagesList from "../../Translations/FindDictionary/LanguagesList/LanguagesList";
import DictionaryTree from "../../Admin/Dictionary/DictionaryTree/DictionaryTree";
import DefectDetails from "../../Admin/Dictionary/DictionaryDefect/DefectDetails";
import TreeResult from "../../Admin/Dictionary/DictionaryDetails/TreeResult";
import MainCard from "components/MainCard/MainCard";
import { actions } from "../../Translations/FindDictionary/state/actions";
import { makeStyles } from "@mui/styles";
import FormControl from "@mui/material/FormControl";


const initialTranslationState = {
    From: "",
    To: "",
};
const useStyles = makeStyles(() => ({
    dictMenu: {
        display: "flex",
        justifyContent: "right",
    },
    dictionaryWrapper: {
        border: "1px solid #d8d7d7",
        margin: 0,
        padding: 0,
        zIndex: 10,
    },
    menuBlock: {
        margin: "0",
        display: "flex",
        padding: "1rem",
        backgroundColor: "#f6f6f6",
        borderBottom: "1px solid #d8d7d7",
    },
    iconsBlock: {
        justifyContent: "right",
        textAlign: "right",
    },

    Language: {
        height: "5px",
    },
    treeResult: {
        marginTop: "-13px",
        paddingTop: "0px !important",
    },
    treeViewScroll: {
        overflow: "auto",
        height: "100%",
    },
    treeViewHeight: {
        height: "50vh",
    },
    textField: {
        paddingBottom: 0,
        marginTop: 0,
        backgroundColor: "white",
    },
}));
export default function DashboardDictionary({ open, handleClose,handleAddNonConfirmity,dictionaryMode=0, filterParameter, }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [openDevice, setDeviceOpen] = useState(false);
    const commonState = useSelector((state) => state.customization);
    const stateRoot = useSelector((state) => state.dictionaryRoot);
    const [fields, setFields] = useState(initialTranslationState);
    const pareantInfoState = useSelector(
        (state) => state.dictionaryTree?.dictionarySelectedParentInfo
    );
    const [openUpdateTranslation, setUpdateTranslationOpen] = useState(false);
    const [openUpdateDefectTranslation, setUpdateDefectTranslationOpen] =
        useState(false);
    const [openTranslationUpload, setOpenTranslationUpload] = useState(false);
    const handleUpdateTranslationClose = () => setUpdateTranslationOpen(false);
    const handleUpdateDefectTranslationClose = () =>
        setUpdateDefectTranslationOpen(false);
    //const [languageCode, setLanguageCode] = useState({code: "EN",name: "English",});

    const [languageCode, setLanguageCode] = useState(commonState?.globalLanguage);

    const [searchText, setSearchDictionary] = useState("");
    const state = useSelector((state) => state.dictionaryTree);
    const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1];
    const dictionaryTreeRef = useRef();


    const onSearchFilterTree = (event) => {
        setSearchDictionary(event.target.value);
    }
    const handleTranslationUpdateOpen = (parentInfo) => {
        if (parentInfo.level == 4 || parentInfo.level == 5) {
            const params = {
                detailDefectId: parentInfo.id,
                languageCode: languageCode.code, //"ES",
                userLogin: login,
                dictionaryType: parentInfo.dictionaryType,
            }
            console.log("Params--->", params);

            dispatch(actions.getFindInDictionaryDefectById(params));
            setUpdateDefectTranslationOpen(true);
        } else {

            const params = {
                ObjectId: parentInfo.id,
                LanguageCode: languageCode.code, //"ES",
                UserLogin: login,
                DictionaryType: parentInfo.dictionaryType,
                Level: parentInfo.level,
            }
            //console.log("Params", params);
            dispatch(actions.getFindInDictionaryLevelById(params));
            setUpdateTranslationOpen(true);
        }
    };

    const handleTranslationUploadOpen = () => {
        setOpenTranslationUpload(!openTranslationUpload);
    };
    const handleTranslationUploadClose = () => {
        setOpenTranslationUpload(false);
    };
    const handleEditDefectTranslation = () => {
        setUpdateDefectTranslationOpen(true);
    };
    const handleLanguageChange = (e, value) => {
        setLanguageCode((prevState) => ({
            ...prevState,
            code: value?.code || "EN",
            name: value?.name || "English",
        }));
    };

    const handleOpen = (parentInfo, pupopType) => {
        if (pupopType == 'openTree') {
            dictionaryTreeRef.current(parentInfo);
        }
        // else if (pupopType == "edit") {
        //   if (parentInfo.level == 4) setUpdateDefectTranslationOpen(true);
        // }
        else if (pupopType == 'edit') {
            handleTranslationUpdateOpen(parentInfo);
        }
        else if (pupopType == 'addTreeNC') {
            // alert(JSON.stringify(parentInfo));
            handleAddNonConfirmity(pupopType,parentInfo,"selectOption");
        }
    };

    // useEffect(() => {
    //   console.log("Find in a Dictionary Tree load dictionary mode true",stateRoot.isTreeLoaded);
    //   dispatch(rootAction.setTreeLoad(true));
    //  }, [DictionaryMode]);

    const title = "Defect Dictionary";
    const variant = "contained";
    const popupSize = "lg";
    return (
        <Dialog title={title} handleClose={handleClose} open={open} variant={variant} popupSize={popupSize}>
            <MainCard>
                <Grid container spacing={1}>
                    <Grid item xs={5} className={classes.dictionaryWrapper}>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    className={classes.textField}
                                    autoFocus
                                    label="Search Dictionary"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    name="name"
                                    size="small"
                                    onKeyUp={onSearchFilterTree}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} className={classes.menuBlock}>
                            <Grid item xs={8}>
                                <LanguagesList
                                    language={languageCode}
                                    handleLanguageChange={handleLanguageChange}
                                    label="Translate To"
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={classes.treeViewHeight}
                            sx={{ height: "25%" }}
                        >
                            <Grid container className={classes.treeViewScroll}>
                                <DictionaryTree language={languageCode} searchText={searchText} dictionaryMode={dictionaryMode} treeFunc={dictionaryTreeRef} filterParams={filterParameter} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={7} className={classes.treeResult}>
                        {pareantInfoState.level == 4 || pareantInfoState.level == 5 ? (
                            <DefectDetails language={languageCode} editHandler={handleEditDefectTranslation} parentInfo={pareantInfoState} handleOpen={handleOpen} dictionaryMode={dictionaryMode} />
                        ) : (
                            <TreeResult handleOpen={handleOpen} dictionaryMode={dictionaryMode} />
                        )}
                    </Grid>
                </Grid>
            </MainCard>
        </Dialog>
    );
}
