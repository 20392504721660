export function inspectionXmlGenerated(data) {
  let labelXml = `<?xml version="1.0" encoding="utf-8"?>
   <DieCutLabel Version="8.0" Units="twips">
      <PaperOrientation>Landscape</PaperOrientation>
      <Id>LargeAddress</Id>
      <PaperName>30321 Large Address</PaperName>
      <DrawCommands>
         <RoundRectangle X="0" Y="0" Width="2025" Height="5020" Rx="270" Ry="270" />
      </DrawCommands>
      <ObjectInfo>
         <AddressObject>
            <Name>Address</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>True</IsVariable>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Middle</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
               <Element>
                  <String>${data.marketDesc}</String>
                  <Attributes>
                     <Font Family="Arial" Size="16" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                     <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                  </Attributes>
               </Element>
            </StyledText>
            <ShowBarcodeFor9DigitZipOnly>False</ShowBarcodeFor9DigitZipOnly>
            <BarcodePosition>Suppress</BarcodePosition>
            <LineFonts>
               <Font Family="Arial" Size="16" Bold="True" Italic="False" Underline="False" Strikeout="False" />
            </LineFonts>
         </AddressObject>
         <Bounds X="322" Y="150" Width="1671" Height="390" />
      </ObjectInfo>
      <ObjectInfo>
         <TextObject>
            <Name>TEXT</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <HorizontalAlignment>Center</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
               <Element>
                  <String>${data.marketedBrandLongDesc}</String>
                  <Attributes>
                     <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                     <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                  </Attributes>
               </Element>
            </StyledText>
         </TextObject>
         <Bounds X="2107" Y="178" Width="2768" Height="600" />
      </ObjectInfo>
      <ObjectInfo>
         <TextObject>
            <Name>TEXT_1</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
               <Element>
                  <String>Numer PZ: ${data.admissionNumber}
   Data przyjęcia: ${data.admissionDate}
   Inspektor: 
   
   Pakiet:</String>
                  <Attributes>
                     <Font Family="Arial" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                     <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                  </Attributes>
               </Element>
            </StyledText>
         </TextObject>
         <Bounds X="322" Y="613" Width="1920" Height="1020" />
      </ObjectInfo>
      <ObjectInfo>
         <TextObject>
            <Name>TEXT_2</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <HorizontalAlignment>Left</HorizontalAlignment>
            <VerticalAlignment>Top</VerticalAlignment>
            <TextFitMode>ShrinkToFit</TextFitMode>
            <UseFullFontHeight>True</UseFullFontHeight>
            <Verticalized>False</Verticalized>
            <StyledText>
               <Element>
                  <String>ID: ${data.id}              l.p.
   </String>
                  <Attributes>
                     <Font Family="Arial" Size="10" Bold="True" Italic="False" Underline="False" Strikeout="False" />
                     <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                  </Attributes>
               </Element>
               <Element>
                  <String>Ilość paczek: ${data.noPacks}
   Data inspekcji:${data.inspectionDate}
   
   Okres:  ${data.periodStart}-${data.periodEnd}</String>
                  <Attributes>
                     <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False" />
                     <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
                  </Attributes>
               </Element>
            </StyledText>
         </TextObject>
         <Bounds X="2302" Y="788" Width="2340" Height="1065" />
      </ObjectInfo>
      <ObjectInfo>
         <ShapeObject>
            <Name>RECTANGLE</Name>
            <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />
            <BackColor Alpha="0" Red="255" Green="255" Blue="255" />
            <LinkedObjectName></LinkedObjectName>
            <Rotation>Rotation0</Rotation>
            <IsMirrored>False</IsMirrored>
            <IsVariable>False</IsVariable>
            <ShapeType>Rectangle</ShapeType>
            <LineWidth>45</LineWidth>
            <LineAlignment>Center</LineAlignment>
            <FillColor Alpha="0" Red="255" Green="255" Blue="255" />
         </ShapeObject>
         <Bounds X="3952" Y="1108" Width="720" Height="345" />
      </ObjectInfo>
   </DieCutLabel>`;
  return labelXml;
}
