export const actionTypes = {
  SET_USER_LOGGEDIN: "SET_USER_LOGGEDIN",
  GET_USER_INFO: "GET_USER_INFO",
  SET_USER_INFO: "SET_USER_INFO",
  SET_MENU: "@customization/SET_MENU",
  MENU_OPEN: "@customization/MENU_OPEN",
  SET_LOADING: "SET_LOADING",
  SHOW_TOAST: "SHOW_TOAST",
  HIDE_TOAST: "HIDE_TOAST",
  SET_UPDATED: "SET_UPDATED",
  LOG_USER_INFO: "LOG_USER_INFO",
  GET_ADMIN_ROLES: "GET_ADMIN_ROLES",
  SET_ADMIN_ROLES: "SET_ADMIN_ROLES",

  // DROP DOWNS
  GET_LABOARATORY_LIST: "GET_LABOARATORY_LIST",
  SET_LABOARATORY_LIST: "SET_LABOARATORY_LIST",
  GET_IMAGE_FROM_AWS: "GET_IMAGE_FROM_AWS",
  SET_IMAGE_FROM_AWS: "SET_IMAGE_FROM_AWS",
  SET_LANGUAGE: "SET_LANGUAGE",

  SET_PAGE_REDIRECT: "SET_PAGE_REDIRECT",

  // IMDL Menus
  GET_IMDL_MENUS: "GET_IMDL_MENUS",
  SET_IMDL_MENUS: "SET_IMDL_MENUS",
  SET_MENU_LOADING: "SET_MENU_LOADING",

  SET_USER_MEMBERSHIP_ROLES: "SET_USER_MEMBERSHIP_ROLES",
  SET_USER_ROLES_ACCESS: "SET_USER_ROLES_ACCESS",
};

export const actions = {
  setUserLoggedIn: (payload) => ({
    type: actionTypes.SET_USER_LOGGEDIN,
    payload,
  }),

  logUserInfo: (payload) => ({
    type: actionTypes.LOG_USER_INFO,
    payload,
  }),

  getUserInfo: (payload) => ({
    type: actionTypes.GET_USER_INFO,
    payload,
  }),

  setPageRedirect: (payload) => ({
    type: actionTypes.SET_PAGE_REDIRECT,
    payload,
  }),

  setUserInfo: (payload) => ({
    type: actionTypes.SET_USER_INFO,
    payload,
  }),
  setMenu: (payload) => ({
    type: actionTypes.SET_MENU,
    payload,
  }),
  openMenu: (payload) => ({
    type: actionTypes.MENU_OPEN,
    payload,
  }),
  setLoading: (payload) => ({
    type: actionTypes.SET_LOADING,
    payload,
  }),
  setUpdated: (payload) => ({
    type: actionTypes.SET_UPDATED,
    payload: payload,
  }),
  hideToast: () => ({
    type: actionTypes.HIDE_TOAST,
    payload: { open: false, severity: "info", message: "" },
  }),
  infoAlert: (message) => ({
    type: actionTypes.SHOW_TOAST,
    payload: { open: true, severity: "info", message },
  }),
  successAlert: (message) => ({
    type: actionTypes.SHOW_TOAST,
    payload: { open: true, severity: "success", message },
  }),
  errorAlert: (message) => ({
    type: actionTypes.SHOW_TOAST,
    payload: { open: true, severity: "error", message },
  }),

  getAdminRoleInfo: (payload) => ({
    type: actionTypes.GET_ADMIN_ROLES,
    payload,
  }),

  setAdminRoleInfo: (payload) => ({
    type: actionTypes.SET_ADMIN_ROLES,
    payload,
  }),

  // DROP DOWNS
  getLaboaratoryList: () => ({
    type: actionTypes.GET_LABOARATORY_LIST,
  }),
  setLaboaratoryList: (payload) => ({
    type: actionTypes.SET_LABOARATORY_LIST,
    payload,
  }),
  // Image details from aws s3 bucket in base 64 format
  GetImageListsFromAws: (payload) => ({
    type: actionTypes.GET_IMAGE_FROM_AWS,
    payload,
  }),
  setImageListsFromAws: (payload) => ({
    type: actionTypes.SET_IMAGE_FROM_AWS,
    payload,
  }),
  setLanguageCode: (payload) => ({
    type: actionTypes.SET_LANGUAGE,
    payload,
  }),

  // IMDL Menus
  getIMDLMenus: (payload) => ({
    type: actionTypes.GET_IMDL_MENUS,
    payload,
  }),

  setIMDLMenus: (payload) => ({
    type: actionTypes.SET_IMDL_MENUS,
    payload,
  }),

  setMenuLoading: (payload) => ({
    type: actionTypes.SET_MENU_LOADING,
    payload,
  }),

  setUserMembershipRoles: (payload) => ({
    type: actionTypes.SET_USER_MEMBERSHIP_ROLES,
    payload,
  }),
  setUserRoleAccess: (payload) => ({
    type: actionTypes.SET_USER_ROLES_ACCESS,
    payload,
  }),
};
