/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {
  dictionaryDropdownLists: {
    codeFormat: [],
    auditSubType: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_DICTIONARY_DROPDOWN:
      return {
        ...state,
        dictionaryDropdownLists: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
