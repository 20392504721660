/*eslint-disable */

import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchMarketedBrandAPI,
  fetchSubMarketAPI,
  fetchCompanyGroupAPI,
  deleteCompanyGroupAPI,
  fetchAllMarketListAPI,
  fetchBrandGroupListAPI,
  fetchCompanyGroupListAPI,
  addCompanyGroupAPI,
  editCompanyGroupAPI,
  addMarkedBrandAPI,
  editMarketedBrandAPI,
  editSubMarketAPI,
  addSubMarketAPI,
  deleteSubMarketAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getMarketedBrandSaga(action) {
  try {
    const _api = yield call(fetchMarketedBrandAPI, action.payload);
    yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getSubmarketSaga(action) {
  try {
    const _api = yield call(fetchSubMarketAPI, action.payload);
    yield put(actions.setSubmarket(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getCompanyGroupSaga(action) {
  try {
    const _api = yield call(fetchCompanyGroupAPI, action.payload);
    yield put(actions.setCompanyGroups(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* deleteCompanyGroupSaga(action) {
  try {
    const _api = yield call(deleteCompanyGroupAPI, action.payload);
    console.log("successfully deleted");
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getMarketedBrand());
    // yield put(actions.setCompanyGroups(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

// drop downs

function* getAllMarketListSaga(action) {
  try {
    const _api = yield call(fetchAllMarketListAPI, action.payload);
    yield put(actions.setMarketsList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getBrandGroupSaga(action) {
  try {
    const _api = yield call(fetchBrandGroupListAPI, action.payload);
    yield put(actions.setBrandGroupsList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getCompanyGroupListSaga(action) {
  try {
    const _api = yield call(fetchCompanyGroupListAPI, action.payload);
    yield put(actions.setCompanyGroupsList(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addCompanyGroupSaga(action) {
  try {
    const _api = yield call(addCompanyGroupAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Company group added successfully"));
    yield put(actions.getCompanyGroups()); 
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editCompanyGroupSaga(action) {
  try {
    const _api = yield call(editCompanyGroupAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Company group updated successfully"));
    yield put(actions.getCompanyGroups()); 
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addMarketedBrandSaga(action) {
  try {
    const _api = yield call(addMarkedBrandAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.successAlert("Marketed brand added successfully"));
    yield put(commonActions.setLoading(false));
    yield put(actions.getMarketedBrand()); 
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editMarketedBrandSaga(action) {
  try {
    const _api = yield call(editMarketedBrandAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Marketed brand added successfully"));
    yield put(actions.getMarketedBrand());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addSubMarketSaga(action) {
  try {
    const _api = yield call(addSubMarketAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Sub Market added successfully"));
    yield put(actions.getSubmarket()); 
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editSubMarketSaga(action) {
  try {
    const _api = yield call(editSubMarketAPI, action.payload);
    // yield put(actions.setMarketedBrand(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert("Sub Market updated successfully"));
    yield put(actions.getSubmarket()); 
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* deleteSubMarketSaga(action) {
  try {
    const _api = yield call(deleteSubMarketAPI, action.payload);
    console.log("successfully deleted");
    yield put(commonActions.setLoading(false));
    yield put(commonActions.successAlert(_api.data.data));
    yield put(actions.getSubmarket());
    // yield put(actions.setCompanyGroups(_api.data.data));
    // yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchMarketedBrandSaga() {
  yield all([
    takeEvery(actionTypes.GET_MARKETED_BRAND, getMarketedBrandSaga),
    takeEvery(actionTypes.GET_SUBMARKET, getSubmarketSaga),
    takeEvery(actionTypes.GET_COMPANY_GROUPS, getCompanyGroupSaga),
    takeEvery(actionTypes.DELETE_COMPANY_GROUP, deleteCompanyGroupSaga),
    takeEvery(actionTypes.GET_ALL_MARKET_LIST, getAllMarketListSaga),
    takeEvery(actionTypes.GET_BRAND_GROUPS_LIST, getBrandGroupSaga),
    takeEvery(actionTypes.GET_COMPANY_GROUPS_LIST, getCompanyGroupListSaga),
    takeEvery(actionTypes.ADD_COMPANY_GROUP, addCompanyGroupSaga),
    takeEvery(actionTypes.EDIT_COMPANY_GROUP, editCompanyGroupSaga),
    takeEvery(actionTypes.ADD_MARKETED_BRAND, addMarketedBrandSaga),
    takeEvery(actionTypes.EDIT_MARKETED_BRAND, editMarketedBrandSaga),
    takeEvery(actionTypes.ADD_SUBMARKET, addSubMarketSaga),
    takeEvery(actionTypes.EDIT_SUBMARKET, editSubMarketSaga),
    takeEvery(actionTypes.DELETE_SUBMARKET, deleteSubMarketSaga),
  ]);
}
