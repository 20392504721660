import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Popover from '@mui/material/Popover';
import { useEffect, useState } from "react";
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// style constant
const useStyles = makeStyles((theme) => ({
  headerAvatar: {
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    transition: "all .2s ease-in-out",
    // background: theme.palette.secondary.light,
    background: "#143d77",
    color: "#fff",
    '&[aria-controls="menu-list-grow"],&:hover': {
      color: "#1e81f4",
      backgroundColor: "#fff",
    },
  },
  box: {
    marginLeft: "10px",
    marginRight: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "16px",
    },
  },
  popupStyle: {
    width: "260px",
    height: "90px"
  },
  formControlStyle: {
    marginTop: "20px",
    marginLeft: "5px",
    width: "95%"
  }
}));
const eLearningData = [
  { name: 'Routine Audit Type Video', value: 'https://workpoint.pmiapps.biz/projects/ISPS2/Central%20Visual%20Quality%20Audit/Execute/CVQAII_eLearning_Video_Sample_Routine_Audit_Type.zip' },
  { name: 'ICT Audit Type Video', value: 'https://workpoint.pmiapps.biz/projects/ISPS2/Central%20Visual%20Quality%20Audit/Execute/CVQAII_eLearning_Video_Sample_ICT_Audit_Type.zip' },
  { name: 'TPM Audit Type Video', value: 'https://workpoint.pmiapps.biz/projects/ISPS2/Central%20Visual%20Quality%20Audit/Execute/CVQAII_eLearning_Video_Sample_TPM_Audit_Type.zip' }
];
// ===========================|| NOTIFICATION ||=========================== //

const Dictionary = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (data) => {
    handleClose();
    window.open(data.target.value, '_blank');
  }
  const handleToggle = () => {
    // navigate('/admin/help')
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <Box component="span" className={classes.box}>
        <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleClick}>
          <Avatar
            variant="rounded"
            className={classes.headerAvatar}
            onClick={handleToggle}
            color="inherit"
          >
            <Tooltip title="ELearning">
              <CastForEducationOutlinedIcon
                stroke={1.5}
                size="1.3rem"
                className={classes.feedbackIcon}
              />
            </Tooltip>
          </Avatar>
        </ButtonBase>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <Grid container spacing={2} className={classes.popupStyle}>
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.formControlStyle}>
                <TextField
                  size="small"
                  label="Select e-Learning"
                  onChange={handleChange}
                  select>
                  {eLearningData.map((data) => (
                    <MenuItem key={data.name} value={data.value}>
                      {data.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
          </Grid>

        </Popover>
      </Box>
    </>
  );
};

export default Dictionary;
