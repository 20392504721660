import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  downloadNcMappingAPI,
  downloadLatestNcMappingAPI,
  uploadNcTemplateAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";
import { downloadFile } from "utils";

function* downloadNcMappingSaga(action) {
  try {
    const _api = yield call(downloadNcMappingAPI, action.payload);
    yield put(commonActions.setLoading(false));
    downloadFile(
      "NCUploadTemplate.csv", // Name and extension changed as per old app
      "text/csv",
      _api.data
    );
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* downloadLatestNcMappingSaga(action) {
  try {
    const _api = yield call(downloadLatestNcMappingAPI, action.payload);
    yield put(commonActions.setLoading(false));
    downloadFile(
      "LatestNCMappingTemplate.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      _api.data
    );
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* uploadNcTemplateSaga(action) {
  try {
    const _api = yield call(uploadNcTemplateAPI, action.payload);
    yield put(actions.setUploadNcTemplate(_api.data.data));
    yield put(commonActions.setLoading(false));
    yield put(actions.setIsUpload(false));
  } catch (error) {
    if (error.response.data.status == 400) {
      yield put(actions.setUploadNcFailure(true));
    }
    if (error.response.data.status == 500) {
      yield put(commonActions.errorAlert("Upload failed"));
    }

    yield put(commonActions.setLoading(false));
    yield put(actions.setIsUpload(false));
  }
}

export function* watchNcMappingTemplateSaga() {
  yield all([
    takeEvery(actionTypes.GET_NC_MAPPING_TEMPLATE_FILE, downloadNcMappingSaga),
    takeEvery(
      actionTypes.GET_LATEST_NC_MAPPING_TEMPLATE_FILE,
      downloadLatestNcMappingSaga
    ),
    takeEvery(actionTypes.UPLOAD_NC_TEMPLATE, uploadNcTemplateSaga),
  ]);
}
