import { API } from "api/config";

//For getting Audit Type,Inspector,Production Center dropdowns
export const getAdvancedSearchDropdownsAPI = (payload) => {
    console.log("Edit call payload",payload);
    return API.get("/adminService/api/SampleId/getAdvancedSearchDropdowns",{
      params: payload,
    })
      .then((res) => res)
      .catch((error) => error);
  };

  //For getting sample staus
  export const getSampleStatusAPI = (payload) => {
    console.log("Edit call payload",payload);
    return API.get("/adminService/api/SampleId/getSampleStatus")
      .then((res) => res)
      .catch((error) => error);
  };

  //For getting Production varient based on production center Id
  export const getProductVariantAPI = (payload) => {
    return API.get("/adminService/api/AuditHome/getProductVariant",{
      params: payload,
    })
      .then((res) => res)
      .catch((error) => error);
  };

  export const getSamplesByAdvancedSearchAPI = (payload) => {
    return API.get("/adminService/api/SampleId/getSamplesByAdvancedSearch",{
      params: payload,
    })
      .then((res) => res)
      .catch((error) => error);
  };

//For getting ProductionUnitId based on production Center Id
  export const getProductionUnitByProductionCenterIdAPI = (payload) => {
    return API.get("/adminService/api/SampleId/getProductionUnitByProductionCenterId",{
      params: payload,
    })
      .then((res) => res)
      .catch((error) => error);
  };
