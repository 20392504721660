import { API } from "api/config";

// API

export const getDictionaryExpandDefectAPI = (payload) => {
  return API.get(
    "/adminService/api/DictionaryDefect/getDictionaryExpandDefect",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getEditDefectNCDropDownAPI = (payload) => {
  return API.get("/adminService/api/DictionaryDefect/getEditDefectNCDropDown", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getNcAttributeFieldsAPI = (payload) => {
  return API.get("/adminService/api/DictionaryDefect/getNcAttributeFields", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const getDictionaryHierarchyAPI = (payload) => {
  return API.get(
    "/adminService/api/Dictionary/DictionaryTreeView/getDictionaryHierarchy",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const saveDefectDictionaryAPI = (payload) => {
  return API.post(
    "/adminService/api/DictionaryDefect/saveDefectDictionary",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getDefectDropdownsAPI = (payload) => {
  return API.get(
    "/adminService/api/Dictionary/DictionaryTreeView/getDefectDropdowns",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const getDefectDropdownsForCopyAPI = (payload) => {
  return API.get(
    "/adminService/api/Dictionary/DictionaryTreeView/getSaveAsCopyDefectDropdowns",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const defectDictionaryPublishAPI = (payload) => {
  return API.post(
    "/adminService/api/DictionaryDefect/saveDefectDictionary",
    payload
  );
};

export const defectDictionarySendEmailAPI = (payload) => {
  return API.post(
    "/adminService/api/DictionaryDefect/SendDefectEmail",
    payload
  );
};
