import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dialog from "components/Dialog";
import FormControl from "@mui/material/FormControl";
import { styled } from '@mui/material/styles';
import { useFormik } from "formik";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import * as yup from "yup";
import { actions as commonActions } from "../../../../store/actions/common";
import { actions as searchActions } from "../state/actions";
import { actions as advSearchActions } from "containers/Dashboard/DashboardDetails/state/actions";

const validationSchema = yup.object({
    ncCode: yup.string().required("The NC Code field is required"),
    description: yup.string().required("The Description field is required"),
});
const initialSearchState = {
    SearchCode: "",
    SearchType: "",
    active: ""
};
const rows = [
    {
      id: 456891,
      productionCenter: "RU04 2G - PH",
      productionDate: "29-09-2022",
      Status: "BDG6009",
      productionCode: "BDG6009",
      productionUnit: "BDG6009",
      inspector: "BDG6009",
    }
  
  ];
export default function AddAdvanceSearchForm({ open, handleClose, param, type }) {
    const state = useSelector((state) => state.Search);
    const advSearchState = useSelector((state) => state.advanceSearch);
    const [SearchFields, setSearchFields] = React.useState(initialSearchState);
    const commonState = useSelector((state) => state.customization);
    const [pageSize, setPageSize] = useState(20);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(20);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const Input = styled('input')({
        display: 'none',
    });

    const [fromDateValue, setFromDateValue] = React.useState(null);
    const [toDateValue, setToDateValue] = React.useState(null);

    const formik = useFormik({
        initialValues: SearchFields,
        validationSchema,
        onSubmit: (values) => {
            formik.resetForm();
            handleClose();
        },
    });

    useEffect(() => {
        setCount(state?.TotalSampleResultCount);
    },[state?.TotalSampleResultCount]);
    
      useEffect(() => {
        dispatch(commonActions.setLoading(true));
        const adv_params = {
            auditType: advSearchState?.advancedSearchCondition.auditType,
            sampleStatus: advSearchState?.advancedSearchCondition.sampleStatus,
            inspectorId: advSearchState?.advancedSearchCondition.inspectorId,
            productionCenterId: advSearchState?.advancedSearchCondition.productionCenterId,
            productionDateFrom: advSearchState?.advancedSearchCondition.productionDateFrom,
            productionDateTo: advSearchState?.advancedSearchCondition.productionDateTo,
            productVariantId: advSearchState?.advancedSearchCondition.productVariantId,
            productionUnitId: advSearchState?.advancedSearchCondition.productionUnitId,
            sampleId: advSearchState?.advancedSearchCondition.sampleId,
            userLogin: advSearchState?.advancedSearchCondition.userLogin,
            pageMode: advSearchState?.advancedSearchCondition.pageMode,
            pageNumber: page,
            pageSize: pageSize,
          };
          
          if (typeof auditType === "undefined") {
            //name = true
        }
        else
        {
            dispatch(advSearchActions.getAdvancedSearch(adv_params));
            dispatch(commonActions.setLoading(false));
        }
        
    }, [page, pageSize])

    //this is for sample ID Search
    useEffect(() => {
        console.log("Search--->", type);
        dispatch(searchActions.setSearchBySampleID(""));//reset the grid by passing blank
        if (type == "SingleSearch")
        {
            
            dispatch(commonActions.setLoading(true));
            dispatch(searchActions.getSearchBySampleID(param));
        }
            //dispatch(advSearchActions.getAdvancedSearch(""));            
        
      }, []);
    
    //   useEffect(() => {
    //     console.log("Simple Search State Value---->",state);
    //   }, [state]);

    //   useEffect(() => {
    //     console.log("Advance Search State Value---->",advSearchState);
    //   }, [advSearchState])

    const handleEditForm = (row, actionType) => {
        //console.log("Audit Grid Click Event", { details: row, action: actionType });
        if (actionType == "sampleIdClick") {
            if (
              row.statusDescription == "Pending" ||
              row.statusDescription == "Canceled" ||
              row.statusDescription == "Rejected"
            ) {
              navigate(
                "/audit/log-new-sample?sampleId=" +
                  row.idSample +
                  "&status=" +
                  row.statusDescription
              );
            } else if (row.statusDescription == "InProgress") {
              // refer to old application for batch flow. should be redirected to after validation page
              if (row.auditTypeId == "RRP-P1 Device") {
                navigate(
                  "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&pageType=validationInProgress&validationState=1" +
                    "&batchNo=" +
                    row.batchNo
                );
                return;
              }
              navigate(
                "/audit/sample-process?auditType=" +
                  row.auditTypeCode +
                  "&auditSubType=" +
                  row.auditSubTypeCode +
                  "&sampleId=" +
                  row.idSample
              );
            } else if (row.statusDescription == "Finished") {
              if (row.auditTypeId == "RRP-P1 Device") {
                navigate(
                  "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&validationState=1" +
                    "&batchNo=" +
                    row.batchNo
                );
              } else {
                navigate(
                  "/audit/sample-validate?auditType=" +
                    row.auditTypeCode +
                    "&auditSubType=" +
                    row.auditSubTypeCode +
                    "&sampleId=" +
                    row.idSample
                );
              }
            } else if (row.statusDescription == "Validated") {
              if (row.auditTypeId == "RRP-P1 Device") {
                navigate(
                  "/audit/batch-validation?auditType=ROUTINE&auditSubType=ROUTINE_RRP-P1DEVICE&pageType=reports&validationState=0" +
                    "&batchNo=" +
                    row.batchNo
                );
              } else {
                navigate(
                  "/audit/sample-report?auditType=" +
                    row.auditTypeCode +
                    "&auditSubType=" +
                    row.auditSubTypeCode +
                    "&sampleId=" +
                    row.idSample
                );
              }
            }
          handleClose();
        }
      };

    const handleSampleDetails = (row) => {
        console.log("fields", row);
      };
    const columns = [

        {
            field: "idSample",
            headerName: "ID",
            width: 75,
            headerClassName: "super-app-theme--header",
            renderCell: (params) => (
                <Link
                    href="#"
                    color="#3c3f42"
                    onClick={() => handleEditForm(params.row, "sampleIdClick")}
                    underline="always"
                >
                    {params.row.idSample}
                </Link>
            ),
        },
        {
            field: "statusDescription",
            headerName: "Status",
            width: 90,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "productionCenter",
            headerName: "Production Center",
            width: 150,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "productionDate",
            headerName: "Production Date",
            width: 150,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "productVariant",
            headerName: "Production Code",
            width: 150,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "productionUnit",
            headerName: "Production Unit",
            width: 190,
            headerClassName: "super-app-theme--header",
        },
        {
            field: "inspectorName",
            headerName: "Inspector",
            width: 100,
            headerClassName: "super-app-theme--header",
        }
    ];
    const title = "Search Result";
    const variant = "contained";
    const popupSize = "md";//lg-large
    
    return (
        <Dialog title={title} handleClose={handleClose} open={open} variant={variant} popupSize={popupSize}>
            <Grid container sx={{ background: "#fff" }}>
                <div style={{ height: 400, width: "100%", marginTop: "10px" }}>
                    <DataGrid
                        rows={state?.SearchBySampleId || []}
                        getRowId={(row) => row.idSample}
                        columns={columns}
                        loading={commonState.isLoading}
                        disableSelectionOnClick
                        // pagination
                        // pageSize={pageSize}
                        rowsPerPageOptions={[1]}
                        // paginationMode="server"
                        // rowCount= {count}
                        // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        // onPageChange={(newPage) => {setPage(newPage += 1)}}
                        // page={page - 1}
                    />
                </div>
            </Grid>
        </Dialog>
    );
}
