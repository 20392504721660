import { API } from "api/config";

//  API

export const fetchAccessManagementAPI = () => {
  return API.get(
    "/adminService/api/AccessManagement/getPlatformDictionaryAccess"
  )
    .then((res) => res)
    .catch((error) => error);
};

export const addPlatformDictionaryAccessAPI = (payload) => {
  return API.post(
    "/adminService/api/AccessManagement/addPlatformDictionaryAccess",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const fetchPlatformDictionaryListAPI = (payload) => {
  return API.get("/adminService/api/AccessManagement/getPlatformDictionary", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const editPlatformDictionaryListAPI = (payload) => {
  return API.put(
    "/adminService/api/AccessManagement/updatePlatformDictionaryAccess",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

// drop down
export const fetchDictionaryListAPI = () => {
  return API.get("/adminService/api/MasterData/getDictionaryList")
    .then((res) => res)
    .catch((error) => error);
};
