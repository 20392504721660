/*eslint-disable */
export const actionTypes = {
  SET_TREE_LOAD: "SET_TREE_LOAD",
  SET_EXPANDED_NODE_ID: "SET_EXPANDED_NODE_ID"
  };
  
  export const actions = {
    setTreeLoad: (payload) => ({
      type: actionTypes.SET_TREE_LOAD,
      payload
    }),
    setExpandedNodeIds: () => ({
      type: actionTypes.SET_EXPANDED_NODE_ID
    })    
  };
  