import { actionTypes } from "./actions";

const initialState = {
  projectsData: [],
  projectsEdit: {
    name: "",
    description: "",
    brandCode: "",
    lab: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PROJECTS:
      return {
        ...state,
        projectsData: payload,
      };

    case actionTypes.SET_PROJECTS_BY_ID:
      return {
        ...state,
        projectsEdit: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
