import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchDictionaryDropdownsListAPI,
  addDictionaryAPI
} from "../api/endpoint";

import { actions as commonActions } from "store/actions/common";
import { actions as rootAction } from "../../state/actions";

function* getDictionaryDropdownListSaga() {
  try {
    const _api = yield call(fetchDictionaryDropdownsListAPI);
    yield put(actions.setDictionaryDropdownsList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(ErrorMessage));
  }
}

function* addDictionarySaga(action) {
  try {
    const _api = yield call(addDictionaryAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
    if (_api.data.data)
      yield put(rootAction.setTreeLoad(true));
  } catch ({ response: { status, data: { error: { ErrorCode, ErrorMessage } } } }) {
    yield put(commonActions.setLoading(false));

    yield put(commonActions.errorAlert(ErrorMessage));

  }
}

export function* watchAddDictionarySaga() {
  yield all([
    takeEvery(actionTypes.GET_DICTIONARY_DROPDOWN, getDictionaryDropdownListSaga),
    takeEvery(actionTypes.ADD_DICTIONARY, addDictionarySaga)
  ]);
}
