import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import { downloadDictionaryTemplateApi, getImageNamesApi, getXmlNamesApi, getImageData, downloadDictionaryTemplateNormalApi, validateNormalDictionaryApi, validateDictionaryApi, uploadDictionaryApi, uploadNormalDictionaryApi } from "../api/endpoint";

import { actions as commonActions } from "store/actions/common";
import { downloadFile } from "utils";

function* downloadDictionary(action) {
  try {
    const _api = yield call(downloadDictionaryTemplateApi, action.payload);
    yield put(commonActions.setLoading(false));
    downloadFile(
      action.payload ? "DictionaryUploadTemplate.xlsx" : "DictionaryCentralLowHighUploadTemplate.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      _api.data
    );
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getImages(action) {
  try {
    const _api = yield call(getImageNamesApi, action.payload);
    yield put(actions.setImageNames(_api.data.data));
    yield put(commonActions.setLoading(false));

  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getXml(action) {
  try {
    const _api = yield call(getXmlNamesApi, action.payload);
    if (_api[0]?.error) {
      yield put(actions.setXmlData(_api));
      yield put(commonActions.setLoading(false));
    } else {
      yield put(actions.setXmlData(_api.data.data));
      yield put(commonActions.setLoading(false));
    }

  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* getImageList(action) {
  try {
    const _api = yield call(getImageData, action.payload);
    if (_api?.[0]?.error) {
      yield put(actions.setImagesData(_api));
      yield put(commonActions.setLoading(false));
    } else {
      yield put(actions.setImagesData(_api.data.data));

      yield put(commonActions.setLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}


function* downloadNormalDictionary(action) {
  try {
    const _api = yield call(downloadDictionaryTemplateNormalApi);
    yield put(commonActions.setLoading(false));
    downloadFile("DictionaryNormalUploadTemplate.xlsx",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      _api.data
    );
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

function* validateDictionary(action) {
  try {
    const _api = yield call(validateDictionaryApi, action.payload);
    yield put(actions.setUploadDictionary(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data || "Unknown Error"));
  }
}

function* validateNormalDictionary(action) {
  try {
    const _api = yield call(validateNormalDictionaryApi, action.payload);
    yield put(actions.setUploadNormalDictionary(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data || "Unknown Error"));
  }
}

function* uploadDictionary(action) {
  try {
    const _api = yield call(uploadDictionaryApi, action.payload);
    yield put(actions.setUploadDictionary(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data || "Unknown Error"));
  }
}

function* uploadNormalDictionary(action) {
  try {
    const _api = yield call(uploadNormalDictionaryApi, action.payload);
    yield put(actions.setUploadNormalDictionary(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert(error?.error?.errorMessage || error.data || "Unknown Error"));
  }
}

export function* watchUploadDictionarySaga() {
  yield all([
    takeEvery(actionTypes.DOWNLOAD_DICTIONARY_TEMPLATE, downloadDictionary),
    takeEvery(actionTypes.DOWNLOAD_NORMAL_DICTIONARY_TEMPLATE, downloadNormalDictionary),
    takeEvery(actionTypes.VALIDATE_UPLOAD_DICTIONARY, validateDictionary),
    takeEvery(actionTypes.VALIDATE_NORMAL_UPLOAD_DICTIONARY, validateNormalDictionary),
    takeEvery(actionTypes.UPLOAD_DICTIONARY, uploadDictionary),
    takeEvery(actionTypes.GET_IMAGES, getImages),
    takeEvery(actionTypes.GET_XML, getXml),
    takeEvery(actionTypes.GET_IMAGES_DATA, getImageList),
    takeEvery(actionTypes.UPLOAD_NORMAL_DICTIONARY, uploadNormalDictionary),
  ]);
}
