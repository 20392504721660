/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchProjectsAPI = () => {
  return API.get("/adminService/api/Project/getProject")
    .then((res) => res)
    .catch((error) => error);
};

export const deleteProjectsAPI = (payload) => {
  return API.put(`/adminService/api/Project/deleteProject`, payload).then(
    (res) => res
  );
};

export const addProjectsAPI = (payload) => {
  return API.post(`/adminService/api/Project/addProject`, payload).then(
    (res) => res
  );
};

export const editProjectsAPI = (payload) => {
  return API.put(`/adminService/api/Project/saveProject`, payload).then(
    (res) => res
  );
};

export const getProjectsByIdAPI = (payload) => {
  return API.get(`/adminService/api/Project/getProjectById`, {
    params: payload,
  }).then((res) => res);
};
