import { API } from "api/config";

export const fetchMarketsListAPI = (payload) => {
    return API.get("/adminService/api/MasterData/getMarketList", {
        params: payload,
    })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchBrandGroupListAPI = () => {
    return API.get("/adminService/api/MasterData/getBrandGroupList")
        .then((res) => res)
        .catch((error) => error);
};

export const fetchMarketedBrandsListAPI = (params) => {
    return API.get("/adminService/api/MasterData/getMarketedBrandList", { params })
        .then((res) => res)
        .catch((error) => error);
};

export const fetchLaboratoryListAPI = () => {
    return API.get("/adminService/api/MasterData/getLaboratoryList")
        .then((res) => res)
        .catch((error) => error);
};