import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Form from "components/Form";
import { actions as commonAction } from "store/actions/common";
import { useSelector, useDispatch } from "react-redux";
import MainCard from "components/MainCard/MainCard";
import AuditFilterForm from "../AuditDetails/AuditFilterForm";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { actions as auditActions } from "../state/actions";
import { actions as commonActions } from "store/actions/common";
import { actions } from "../state/actions";

const ConfirmationPopup = ({
  title,
  formik,
  handleClose,
  saveButtonText,
  variant,
  popupSize,
  type,
  extraButtonText,
  popup,
  message,
  data,
}) => {
  const state = useSelector((state) => state.auditReport);
  const commonState = useSelector((state) => state.customization);
  const [validateCheck, setValidateCheck] = useState(false);
  const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1];
  const dispatch = useDispatch();

  formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (popup != "VALIDATE") {
        const params = {
          sampleId: state?.auditEdit?.idSample,
          status:
            popup == "DELETE" 
              ? "Canceled"
              // : popup == "VALIDATE"
              // ? "validateSamples"
              : "Ripped",//"Rejected",
          userLogIn: "PMI\\" + login,
          updateSampleSequenceStatus: 1,
          rejectReason: null,
          ncrNumber: null,
        };
        console.log(popup, params);
        dispatch(actions.setSampleStatus(params));
        dispatch(auditActions.getAuditReport(state?.auditSearchCondition));
        dispatch(auditActions.setAuditReportSearch(""));
      } else {
        const param_v = {
          sampleIDs: data.toString(),
          userLogIn: "PMI\\" + login,
          //status: "Validated",
        };
        //console.log("validate", data.toString());
        dispatch(actions.setValidateStatus(param_v));
        //dispatch(auditActions.getAuditReport(state?.auditSearchCondition));
        //setValidateCheck(true);
      }
      handleClose();
      formik.resetForm();
    },
  });

  return (
    <Form
      title={title}
      formik={formik}
      handleClose={handleClose}
      open={open}
      saveButtonText={saveButtonText}
      variant={variant}
      popupSize={popupSize}
      type={type}
      extraButtonText={extraButtonText}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="subtitle1">
            {/* Would you like to proceed with canceling Sample {state?.auditEdit?.idSample} ? */}
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ConfirmationPopup;
