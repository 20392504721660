import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// material-ui
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Bundles from "../DictionaryDetails/Images/bundles.jpg";
import Case from "../DictionaryDetails/Images/case.jpg";
import Cigarattes from "../DictionaryDetails/Images/cigarates.jpg";
import CommunicationItems from "../DictionaryDetails/Images/communication_items.jpg";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Box from "@mui/material/Box";
import TabPanel from "components/TabPanel/TabPanel";
import { CTabs, CTab } from "components/Tab/Tab";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { actions } from "./state/actions";
import { useNavigate } from "react-router-dom";
import ImageTag from "../DictionaryDetails/imageDetails";
import vidPlay from "../../../../assets/images/playButtom.png";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
// ==============================|| DICTIONARY ||============================== //
const useStyles = makeStyles(() => ({
  MainImage: {
    width: "100%",
    height: "400px"
  },
  DisplayImage: {
    height: "400px",
    display: "flex",
    alignItems: "center",
    border: "1px solid gray",
  },
  ThumbnailImage: {
    height: "125px",
    display: "flex",
    alignItems: "center",
    border: "1px solid gray",
  },
  VideoPlay: {
    display: "block",
    margin: "7px 0 0 27px",
    width: "54px",
    height: "54px",
    //background: "url({vidPlay}) no-repeat 2px 2px",
    position: "absolute",
  },
}));

const DefectData = {
  imageList: {
    defectId: 5819,
    link: Bundles,
    thumbnail: "632665202141645597.thumbnail.png",
    childImageList: [
      {
        defectId: 5819,
        multimediaOrderComponent: 1,
        multimediaIdComponent: 526,
        multimediaCompType: "Image",
        multimediaIdDirectory: 0,
        multimediaDescription: "High",
        multimediaLink: Case,
        multimediaThumbnail: "632665202141645597.thumbnail.png",
        multimediaCodeMime: "jpeg",
      },
      {
        defectId: 5819,
        multimediaOrderComponent: 2,
        multimediaIdComponent: 1902,
        multimediaCompType: "Image",
        multimediaIdDirectory: 0,
        multimediaDescription: "RYO / MYO",
        multimediaLink: Cigarattes,
        multimediaThumbnail: "634111558182430250.thumbnail.png",
        multimediaCodeMime: "jpeg",
      },
      {
        defectId: 5819,
        multimediaOrderComponent: 2,
        multimediaIdComponent: 1902,
        multimediaCompType: "Image",
        multimediaIdDirectory: 0,
        multimediaDescription: "RYO / MYO",
        multimediaLink: Cigarattes,
        multimediaThumbnail: "634111558182430250.thumbnail.png",
        multimediaCodeMime: "jpeg",
      },
    ],
  },
  description: {
    low: null,
    high: "L: A piece of TT ³ 2mm but &lt; 5mm is on the film or between the film and the blank/label<br>",
  },
  attribute: [
    {
      idAttribute: 118,
      nameAttribute: "Agent/QAS",
      valueAttribute: "Agent",
      codeDomainAttribute: "AGENT_QA",
    },
    {
      idAttribute: 108,
      nameAttribute: "Inspected In",
      valueAttribute: "Visual Quality Audit",
      codeDomainAttribute: "INSPECTION_IN",
    },
    {
      idAttribute: 4,
      nameAttribute: "Intensity Level",
      valueAttribute: "High Level",
      codeDomainAttribute: "IL",
    },
    {
      idAttribute: 105,
      nameAttribute: "Local",
      valueAttribute: "No",
      codeDomainAttribute: "LOCAL",
    },
    {
      idAttribute: 111,
      nameAttribute: "Non-conformity Type",
      valueAttribute: "In Production",
      codeDomainAttribute: "NC_TYPE",
    },
    {
      idAttribute: 0,
      nameAttribute: "Pack Type",
      valueAttribute: "",
      codeDomainAttribute: "PACK_TYPE",
    },
    {
      idAttribute: 5,
      nameAttribute: "Product Component",
      valueAttribute: "Wrapping",
      codeDomainAttribute: "PC",
    },
    {
      idAttribute: 106,
      nameAttribute: "Product Integrity",
      valueAttribute: "Yes",
      codeDomainAttribute: "NON_CONF_PROD",
    },
    {
      idAttribute: 116,
      nameAttribute: "PRSSM",
      valueAttribute: "Yes",
      codeDomainAttribute: "PRSSM",
    },
    {
      idAttribute: 0,
      nameAttribute: "Severity",
      valueAttribute: "",
      codeDomainAttribute: "SEVERITY_LEVEL",
    },
    {
      idAttribute: 7,
      nameAttribute: "Supplier",
      valueAttribute: "Non-Supplier",
      codeDomainAttribute: "S",
    },
    {
      idAttribute: 0,
      nameAttribute: "Product Version",
      valueAttribute: "",
      codeDomainAttribute: null,
    },
    {
      idAttribute: 0,
      nameAttribute: "Weighting Factor",
      valueAttribute: "19",
      codeDomainAttribute: null,
    },
    {
      idAttribute: 0,
      nameAttribute: "Material Group",
      valueAttribute: "F0101",
      codeDomainAttribute: null,
    },
    {
      idAttribute: 0,
      nameAttribute: "Consumer Sensitive",
      valueAttribute: "NO",
      codeDomainAttribute: null,
    },
    {
      idAttribute: 0,
      nameAttribute: "Intensity Level",
      valueAttribute: "1",
      codeDomainAttribute: null,
    },
  ],
  comments: null,
  defectMapping: null,
};

const initialState = {
  imageList: {
    childImageList: [],
  },
  description: {
    low: "",
    high: "",
  },
  attribute: [],
  comments: "",
  defectMapping: "",
  generalSetting: {},
  categoryId: "",
  familyId: "",
  dictionaryId: "",
  productFamilyId: ""
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DefectDetails = ({ language, editHandler, parentInfo, handleOpen, dictionaryMode, addDictionary }) => {
  const classes = useStyles();
  const [openAddProduct, setProductOpen] = React.useState(false);
  const [openAddFamily, setFamilyOpen] = React.useState(false);
  const [openAddCategory, setCategoryOpen] = React.useState(false);
  const [openAddDefect, setDefectOpen] = React.useState(false);
  const [openAddDictionary, setDictionaryOpen] = React.useState(false);
  const [mediaType, setMediaType] = React.useState("image");
  const pareantInfoState = useSelector(
    (state) => state.dictionaryTree?.dictionarySelectedParentInfo
  );
  const state = useSelector((state) => state.dictionaryDefect);
  const handleProductClose = () => setProductOpen(false);
  const handleFamilyClose = () => setFamilyOpen(false);
  const handleCategoryClose = () => setCategoryOpen(false);
  const handleDefectClose = () => setDefectOpen(false);
  const handleDictionaryClose = () => setDictionaryOpen(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const [fields, setFields] = useState(initialState);
  const [displayImageData, setDisplayImageData] = useState("");
  const commonState = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const hostName =
    window.location.protocol +
    "//" +
    window.location.hostname +
    ":" +
    window.location.port +
    "/";

  const getEmailDetailsFromSessionStore = JSON.parse(sessionStorage.getItem("emailDetails")) || {};

  useEffect(() => {
    const params = {
      DefectNCId:
        Object.keys(getEmailDetailsFromSessionStore).length !== 0 ?
          getEmailDetailsFromSessionStore?.nonConformity :
          pareantInfoState.id,
      languageCode: language?.code, //"EN",
      userLogin: commonState?.userInfo?.username?.split("@")[0]?.split("_")[1],
      Publish: pareantInfoState.isPublish,
      DicMode: 2,
      DicType: pareantInfoState.dictionaryType,
      isGcrs: 0,
    };
    //console.log("Params--->", params);
    dispatch(actions.getDictionaryExpandDefect(params));
  }, [pareantInfoState]);

  useEffect(() => {
    setFields({
      ...fields,
      imageList: {
        defectId: state?.dictionaryDefect?.imageList?.defectId,
        link: state?.dictionaryDefect?.imageList?.link,
        thumbnail: state?.dictionaryDefect?.imageList?.thumbnail,
        childImageList: state?.dictionaryDefect?.imageList?.childImageList,
      },
      description: state?.dictionaryDefect?.description,
      attribute: state?.dictionaryDefect?.attribute,
      defectMapping: state?.dictionaryDefect?.defectMapping,
      comments: state?.dictionaryDefect?.comments,
      generalSetting: state?.dictionaryDefect?.generalSetting,
      dictionaryId: state?.dictionaryDefect?.dictionaryId,
      familyId: state?.dictionaryDefect?.familyId,
      productFamilyId: state?.dictionaryDefect?.productFamilyId,
      categoryId: state?.dictionaryDefect?.categoryId,
    });
  }, [state?.dictionaryDefect]);

  useEffect(() => {
    setDisplayImageData("");
  }, [fields]);

  const handleEditDefectDictionary = (pareantInfoState, actionType = "edit") => {
    //setDefectOpen(true);
    // <AddDefect open={openAddDefect}
    // handleClose={handleDefectClose}
    // type={"Edit"}
    // ></AddDefect>

    handleOpen(pareantInfoState, actionType);
  };

  const handleImageBind = (e) => {
    console.log("Image Details", e);
  };

  const handleChildData = (imageData, type) => {
    setDisplayImageData(imageData);
    if (type == "Video") {
      setMediaType("video");
      setDisplayImageData(imageData);
      //setDisplayImageData(`data:video/mp4;base64,${imageData}`);
    } else {
      setMediaType("image");
      setDisplayImageData(imageData);
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <h2> {pareantInfoState.label} </h2>
          </Grid>
          <Grid item xs={1}>
            {
              dictionaryMode != 1 ?
                <IconButton
                  aria-label="edit"
                  disabled={addDictionary === true ? false : true}
                  onClick={() => handleEditDefectDictionary(pareantInfoState)}
                >
                  <EditIcon />
                </IconButton>
                :
                <Button aria-label="edit" title="Add Non Conformity" color="primary" //correct the title
                  variant="outlined" onClick={() => handleEditDefectDictionary(pareantInfoState, 'addTreeNC')}>
                  Add Non Conformity
                </Button>
            }

          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={9}>

            {displayImageData != "" ? (
              displayImageData == "loading" ?
                <CircularProgress />
                :
                <div className={classes.DisplayImage}>
                  {mediaType == "image" ? (
                    <img className={classes.MainImage} src={displayImageData} />
                  ) : (
                    <video
                      controls
                      autoPlay
                      style={{ height: "220px", width: "300px", margin: "30px" }}
                    >
                      <source
                        src={`data:video/mp4;base64,${displayImageData}`}
                      ></source>
                    </video>
                  )}
                </div>
            ) : (

              <div className={classes.DisplayImage}>
                <ImageTag
                  //ImageData={{ thumbUrl: fields.imageList.thumbnail }}
                  ImageData={{ thumbUrl: state?.dictionaryDefect?.imageList?.link }}
                  imgMaxHeight={"400px"}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={3}>
            <ImageList cols={1} sx={{ margin: 0, overflowY: 'scroll', height: 400 }}>
              {fields.imageList.childImageList?.map((item) => {
                //const imageData = {"thumbUrl": item.multimediaCompType =="Movie" ? item.multimediaThumbnail : item.multimediaLink};
                //const imageData = {"thumbUrl": item.multimediaLink, "type": item.multimediaCompType};
                const imageData = {
                  thumbUrl:
                    item.multimediaCompType == "Movie"
                      ? item.multimediaThumbnail
                      : item.multimediaLink,
                  type: item.multimediaCompType,
                  mediaLink: item.multimediaLink,
                };
                return (
                  <ImageListItem key={item.multimediaIdComponent}>
                    <div className={classes.ThumbnailImage}>
                      <ImageTag
                        ImageData={imageData}
                        type={"defect"}
                        parentCallBack={handleChildData}
                      />
                      {/* {item.multimediaCompType == "Movie" && <img src={vidPlay} className={classes.VideoPlay} onClick={handleChildData}/>} */}
                    </div>
                  </ImageListItem>
                );
              })}
            </ImageList>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <CTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <CTab label="Description" {...a11yProps(0)} />
                  <CTab label="Attributes" {...a11yProps(1)} />
                  <CTab label="Comments" {...a11yProps(2)} />
                </CTabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {pareantInfoState.dictionaryType == "C" ?
                      <>
                        <Grid item xs={2}>
                          Title
                        </Grid>
                        <Grid item xs={9}>
                          {/* <TextEditor
                            className={classes.MainImage}
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            value={fields.description?.title || "<p></p>"}
                            init={{
                              height: "120",
                              toolbar: ` bold underline  `,
                              forced_root_block: false,
                            }}
                            disabled={true}
                          /> */}
                          <textarea
                            className={classes.MainImage}
                            style={{ width: '100%', height: "100px", borderColor: "#acb0b4", borderRadius: '5px' }}
                            // aria-label="maximum height"
                            // placeholder="Maximum 4 rows"
                            value={fields.description?.title || ""}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          Description
                        </Grid>
                        <Grid item xs={9}>
                          {/* <TextEditor
                            className={classes.MainImage}
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            value={fields.description?.desc || "<p></p>"}
                            init={{
                              height: "120",
                              toolbar: ` bold underline  `,
                              forced_root_block: false,
                            }}
                            disabled={true}
                          /> */}
                          <textarea
                            className={classes.MainImage}
                            style={{ width: '100%', height: "100px", borderColor: "#acb0b4", borderRadius: '5px' }}
                            aria-label="maximum height"
                            // placeholder="Maximum 4 rows"
                            value={fields.description?.desc || ""}
                            disabled={true}
                          />
                        </Grid>
                      </> :
                      <>
                        <Grid item xs={2}>
                          Low
                        </Grid>
                        <Grid item xs={9}>
                          {/* <TextareaAutosize
                          maxRows={4}
                          aria-label="maximum height"
                          placeholder="Maximum 4 rows"
                          defaultValue={fields.description?.low}
                          className={classes.MainImage}
                        /> */}
                          {/* <TextEditor
                            className={classes.MainImage}
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            //defaultValue={fields.description?.high}
                            value={fields.description?.low || "<p></p>"}
                            init={{
                              height: "120",
                              toolbar: ` bold underline  `,
                              forced_root_block: false,
                            }}
                            disabled={true}
                          /> */}
                          <textarea
                            // className={classes.MainImage}
                            style={{ width: '100%', height: "100px", borderColor: "#acb0b4", borderRadius: '5px' }}
                            // aria-label="maximum height"
                            // placeholder="Maximum 4 rows"
                            value={fields.description?.low || ""}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          High
                        </Grid>
                        <Grid item xs={9}>
                          {/* <TextareaAutosize
                                              maxRows={4}
                                              aria-label="maximum height"
                                              placeholder="Maximum 4 rows"
                                              defaultValue={fields.description?.high}
                                              className={classes.MainImage}
                                          /> */}
                          {/* <TextEditor
                            className={classes.MainImage}
                            maxRows={4}
                            aria-label="maximum height"
                            placeholder="Maximum 4 rows"
                            //defaultValue={fields.description?.high}
                            value={fields.description?.high || "<p></p>"}
                            init={{
                              height: "120",
                              toolbar: ` bold underline  `,
                              forced_root_block: false,
                            }}
                            disabled={true}
                          /> */}
                          <textarea
                            // className={classes.MainImage}
                            style={{ width: '100%', height: "100px", borderColor: "#acb0b4", borderRadius: '5px' }}
                            // aria-label="maximum height"
                            // placeholder="Maximum 4 rows"
                            value={fields.description?.high || ""}
                            disabled={true}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={2}>
                  <Grid item xs={20}>
                    <Grid container spacing={2}>
                      {fields.attribute?.map((item, i) => (
                        <Grid key={i} item xs={6}>
                          {" "}
                          {item.nameAttribute} :
                          <b>&nbsp;&nbsp;{item.valueAttribute}</b>{" "}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    {/* {fields.attribute?.map((item) => (
                                    <Grid key={item.idAttribute} item xs={6}> {item.valueAttribute} </Grid>
                                    ))} */}
                    {/* <Grid item xs={6}>Weighting Factor : 9</Grid>
                                        <Grid item xs={6}>Weighting Factor : 9</Grid>
                                        <Grid item xs={6}>Weighting Factor : 9</Grid>
                                        <Grid item xs={6}>Weighting Factor : 9</Grid> */}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={2}>
                {/* <TextareaAutosize
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Maximum 4 rows"
                  defaultValue={fields.comments}
                  className={classes.MainImage}
                /> */}
                {/* <TextEditor
                  className={classes.MainImage}
                  maxRows={4}
                  aria-label="maximum height"
                  placeholder="Maximum 4 rows"
                  //defaultValue={fields.description?.high}
                  value={fields.description?.comments}
                  init={{
                    height: "120",
                    toolbar: ` bold underline  `,
                    forced_root_block: false,
                  }}
                /> */}
                <textarea
                  // className={classes.MainImage}
                  style={{ width: '100%', height: "100px", borderColor: "#acb0b4", borderRadius: '5px' }}
                  // aria-label="maximum height"
                  // placeholder="Maximum 4 rows"
                  value={fields.description?.comments}
                  disabled={true}
                />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DefectDetails;
