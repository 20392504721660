/*eslint-disable */
export const actionTypes = {
  DOWNLOAD_DICTIONARY_TEMPLATE: "DOWNLOAD_DICTIONARY_TEMPLATE",
  DOWNLOAD_NORMAL_DICTIONARY_TEMPLATE: "DOWNLOAD_NORMAL_DICTIONARY_TEMPLATE",
  VALIDATE_UPLOAD_DICTIONARY: "VALIDATE_UPLOAD_DICTIONARY",
  VALIDATE_NORMAL_UPLOAD_DICTIONARY: "VALIDATE_NORMAL_UPLOAD_DICTIONARY",
  SET_VALIDATE_DICTIONARY: "SET_VALIDATE_DICTIONARY",
  SET_VALIDATE_NORMAL_DICTIONARY: "SET_VALIDATE_NORMAL_DICTIONARY",
  UPLOAD_DICTIONARY: "UPLOAD_DICTIONARY",
  GET_IMAGES: "GET_IMAGES",
  GET_IMAGES_DATA: "GET_IMAGES_DATA",
  SET_XML_DATA: "SET_XML_DATA",
  GET_XML: "GET_XML",
  SET_IMAGES_DATA: "SET_IMAGES_DATA",
  UPLOAD_NORMAL_DICTIONARY: "UPLOAD_NORMAL_DICTIONARY",
  SET_IMAGE_NAMES: "SET_IMAGE_NAMES",
  RESET: "RESET",
  RESET_IMAGES_DATA: "RESET_IMAGES_DATA",
  RESET_XML_DATA: "RESET_XML_DATA"
};

export const actions = {
  downloadDictionaryTemplate: (payload) => ({
    type: actionTypes.DOWNLOAD_DICTIONARY_TEMPLATE,
    payload
  }),
  downloadNormalDictionaryTemplate: (payload) => ({
    type: actionTypes.DOWNLOAD_NORMAL_DICTIONARY_TEMPLATE,
    payload
  }),
  validateUploadDictionary: (payload) => ({
    type: actionTypes.VALIDATE_UPLOAD_DICTIONARY,
    payload
  }),
  validateNormalUploadDictionary: (payload) => ({
    type: actionTypes.VALIDATE_NORMAL_UPLOAD_DICTIONARY,
    payload
  }),
  setUploadDictionary: (payload) => ({
    type: actionTypes.SET_VALIDATE_DICTIONARY,
    payload
  }),
  setUploadNormalDictionary: (payload) => ({
    type: actionTypes.SET_VALIDATE_NORMAL_DICTIONARY,
    payload
  }),
  uploadDictionary: (payload) => ({
    type: actionTypes.UPLOAD_DICTIONARY,
    payload
  }),
  setImageNames: (payload) => ({
    type: actionTypes.SET_IMAGE_NAMES,
    payload
  }),
  resetImagesData: (payload) => ({
    type: actionTypes.RESET_IMAGES_DATA,
    payload
  }),
  resetXmlData: (payload) => ({
    type: actionTypes.RESET_XML_DATA,
    payload
  }),
  resetImageNames: (payload) => ({
    type: actionTypes.RESET,
    payload
  }),
  getImages: (payload) => ({
    type: actionTypes.GET_IMAGES,
    payload
  }),
  setImagesData: (payload) => ({
    type: actionTypes.SET_IMAGES_DATA,
    payload
  }),

  getXml: (payload) => ({
    type: actionTypes.GET_XML,
    payload
  }),
  setXmlData: (payload) => ({
    type: actionTypes.SET_XML_DATA,
    payload
  }),

  getImageList: (payload) => ({
    type: actionTypes.GET_IMAGES_DATA,
    payload
  }),
  uploadNormalDictionary: (payload) => ({
    type: actionTypes.UPLOAD_NORMAL_DICTIONARY,
    payload
  })
};
