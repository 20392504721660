import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { API } from "api/config";
import { actions as commonActions } from "store/actions/common";
import CircularProgress from "@mui/material/CircularProgress";
import noImage from "./Images/b3553b28-a07f-4b20-8dc9-259d72b07aca.PNG";
import vidPlay from "../../../../assets/images/playButtom.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  VideoPlay: {
    display: "block",
    margin: "7px 0 0 27px",
    width: "54px",
    height: "54px",
    //background: "url({vidPlay}) no-repeat 2px 2px",
    position: "absolute",
  },
}));

export default function ImageData({
  ImageData,
  imgheight = "auto",
  imgwidth = "100%",
  imgMaxHeight = "auto",
  type = "noDefect",
  parentCallBack,
}) {
  const [base64Data, setBase64Data] = useState("");
  const classes = useStyles();
  const dispatch = useDispatch();
  //   const fileExtArr = ImageData != null ? ImageData?.thumbUrl?.split(".") : [];
  //   const fileExt = fileExtArr != null ? fileExtArr[fileExtArr.length - 1] : "";
  useEffect(() => {
    if (ImageData.thumbUrl) {
      //dispatch(commonActions.setLoading(true));
      try {
        API.get(
          `/adminService/api/FileHandler/bulkImageDownload?FileName=${ImageData.thumbUrl}`
        )
          .then((res) => {
            setBase64Data(res.data.data[0].base64Data);
          })
          .catch((error) => {
            setBase64Data("NoImage");
            // console.log("NoImage",ImageData.thumbUrl);
          });
      } catch (error) {
        setBase64Data("NoImage");
      }
    } else {
      setBase64Data("NoImage");
    }
  }, [ImageData]);

  const handleImageClick = (e) => {
    parentCallBack("loading", "");
    if (ImageData.type == "Movie") {
      loadVideo(ImageData.mediaLink);
      //console.log("Link-->", vidLink);
      //parentCallBack(vidLink.data.data[0].base64Data);
    } else {
      parentCallBack(e.target.src, "Image");
    }
  };

  const loadVideo = async (link) => {
    const filename = link.split(".");
    const vidFile = filename[1] == "avi" ? filename[0].concat(".ogv") : link;
    const response = await API.get(
      `/adminService/api/FileHandler/bulkImageDownload?FileName=${vidFile}`
    );
    //console.log("Video-->", response);
    parentCallBack(response.data.data[0].base64Data, "Video");
    //return response;
  };

  return (
    <>
      {base64Data == "" ? (
        <CircularProgress />
      ) : base64Data == "NoImage" ? (
        <img
          style={{
            maxHeight: imgMaxHeight,
            height: imgheight,
            width: imgwidth,
          }}
          src={noImage}
        />
      ) : type == "noDefect" ? (
        <img
          style={{
            maxHeight: imgMaxHeight,
            height: imgheight,
            width: imgwidth,
          }}
          src={`data:image/png;base64,${base64Data}`}
          srcSet={`data:image/png;base64,${base64Data}`}
          loading="lazy"
        />
      ) : (
        //   : fileExt == "avi" || fileExt == "mp4" ? (
        //     <video controls style={{ height: imgheight, width: imgwidth }}>
        //       <source src={`data:video/mp4;base64,${base64Data}`}></source>
        //     </video>
        //   )
        <>
          <img
            style={{
              maxHeight: imgMaxHeight,
              height: imgheight,
              width: imgwidth,
            }}
            src={`data:image/png;base64,${base64Data}`}
            srcSet={`data:image/png;base64,${base64Data}`}
            loading="lazy"
            onClick={(e) => handleImageClick(e)}
          />

          {ImageData.type == "Movie" && (
            <img
              src={vidPlay}
              className={classes.VideoPlay}
              onClick={(e) => handleImageClick(e)}
            />
          )}
        </>
      )}
    </>
  );
}
