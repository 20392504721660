import { API } from "api/config";

// API

export const getDictionaryProductAPI = (payload) => {
  return API.get("/adminService/api/DictionaryProductFamily/getDictionaryProductType", {
    params: payload,
  })
    .then((res) => res);
};

export const getDictionaryProductDataAPI = (payload) => {
  return API.get("/adminService/api/DictionaryProduct/getDictionaryProduct", {
    params: payload,
  })
    .then((res) => res);
};

export const addProductDictionaryAPI = (payload) => {
  return API.post("/adminService/api/DictionaryProduct/addProductDictionary", payload)
    .then((res) => res);
};

//
export const getDictionaryHierarchyAPI = (payload) => {
  return API.post("/adminService/api/Dictionary/DictionaryTreeView/getDictionaryHierarchy", payload)
    .then((res) => res);
};

export const updateDictionaryObjectStatusAPI = (payload) => {
    return API.put("/adminService/api/DictionaryProduct/updateDictionaryObjectStatus",JSON.stringify(payload))
      .then((res) => res);
  };

  export const updateProductDictionaryAPI = (payload) => {
    return API.put("/adminService/api/DictionaryProduct/updateProductDictionary",payload)
      .then((res) => res);
  };