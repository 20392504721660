/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchHelpAPI = () => {
  return API.get("/adminService/api/Help/getHelp")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchHelpEditAPI = (payload) => {
  return API.put("/adminService/api/Help/saveHelp", JSON.stringify(payload))
    .then((res) => res)
    .catch((error) => error);
};