import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  getFindInDictionaryLevelByIdAPI,
  submitTranslationAPI,
  getFindInDictionaryDefectByIdAPI,
  ApproveOrRejectFindInDictionaryDefectByIdAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getFindInDictionaryLevelByIdSaga(action) {
  try {
    const _api = yield call(getFindInDictionaryLevelByIdAPI, action.payload);
    //console.log("Get Dict Payload-->", action.payload);

    for (const element of _api.data.data) {
      //console.log(element);
      element.DictionaryType = action.payload.DictionaryType;
      element.LanguageCode = action.payload.LanguageCode;
      element.Level = action.payload.Level;
    }
    //console.log("Get Dict Response-->", _api.data.data);
    yield put(actions.setFindInDictionaryLevelById(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch ({
    response: {
      status,
      data: {
        error: { ErrorCode, ErrorMessage },
      },
    },
  }) {
    yield put(commonActions.setLoading(false));

    if (status == 500) {
      yield put(commonActions.errorAlert("Something went wrong!!"));
    } else {
      yield put(commonActions.errorAlert(ErrorMessage));
    }
  }
}


function* submitTranslationAPISaga(action) {
  try {
    const _api = yield call(submitTranslationAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
  } catch (error) {
    console.log(error);
  }
}

function* submitTranslationAPISaga_test(action) {
  try {
    yield call(submitTranslationAPI, action.payload);
    yield put(commonActions.setLoading(false));
  } catch ({
    response: {
      status,
      data: {
        error: { ErrorCode, ErrorMessage },
      },
    },
  }) {
    yield put(commonActions.setLoading(false));

    if (status == 500) {
      yield put(commonActions.errorAlert(ErrorMessage));
    } else {
      yield put(commonActions.errorAlert(ErrorMessage));
    }
  }
}

function* approveOrRejectFindInDictionaryDefectByIdSaga(action) {
  try {
    const _api = yield call(ApproveOrRejectFindInDictionaryDefectByIdAPI, action.payload);
    yield put(commonActions.successAlert(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    // console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Error"));
  }
}

function* getFindInDictionaryDefectByIdSaga(action) {
  try {
    console.log("payload", action);
    const _api = yield call(getFindInDictionaryDefectByIdAPI, action.payload);
    yield put(actions.setFindInDictionaryDefectById(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
  }
}

export function* watchFindInDictionaryLevelSaga() {
  yield all([
    takeEvery(actionTypes.GET_FindInDictionaryLevel,getFindInDictionaryLevelByIdSaga),
    takeEvery(actionTypes.SET_Translation, submitTranslationAPISaga),
    takeEvery(actionTypes.GET_FindInDictionaryDefect, getFindInDictionaryDefectByIdSaga),
    takeEvery(actionTypes.SET_DefectTranslation, approveOrRejectFindInDictionaryDefectByIdSaga),
  ]);
}
