import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import PopupTitleCard from "components/PopupTitleCard/PopupTitleCard";

const CVQADialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  dialog: {
    position: "absolute",
    // top: 60
  },
  modelHeader: {
    backgroundColor: "rgb(30,129,244)",
    fontSize: "1rem",
    color: "#fff",
  },
  cancelButton: {
    border: "1px solid #938f8f",
    color: "#938f8f",
  },
  popupBottom: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    // width: "100px",
    margin: "0 auto",
    minWidth: "175px",
  },
  ApprovalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100px",
    margin: "0 auto",
    minWidth: "175px",
    marginRight: "12px",
    "& button": {
      marginLeft: "1rem",
    },
  },
  uploadButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: "100px",
    margin: "0 auto",
    minWidth: "max-content",
    marginRight: "12px",
    "& button": {
      marginLeft: "1rem",
    },
  },
  defaultButton: {
    marginRight: "12px",
    minWidth: "max-content",
  },
}));

export default function Form({
  title,
  children,
  formik,
  open,
  handleClose,
  popupSize = "sm",
  saveButtonText = "Save",
  variant = "outlined",
  type,
  handleApproveOrReject,
  handleTemplateDownload,
  showButton = false,
  extraButtonText,
}) {
  const classes = useStyles();
  // const handleFormClose = (e) => {
  //   formik.resetForm();
  //   handleClose(e);
  // };
  const handleFormClose = (
    event,
    reason
  ) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      formik.resetForm();
      handleClose(event);
    }
  };

  const handleBackdropClick = () => {
    //these fail to keep the modal open
    event.stopPropagation();
    return false;
  };

  return (
    <div>
      <CVQADialog
        classes={{
          paper: classes.dialog,
        }}
        onClose={handleFormClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={popupSize}
        onBackdropClick={handleBackdropClick}
      >
        <PopupTitleCard
          id="customized-dialog-title"
          className={classes.modelHeader}
          onClose={handleFormClose}
        >
          {title}
        </PopupTitleCard>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>{children}</DialogContent>
          {
            (type != "cigarettesLeft" && type != "printSticker") &&
            <Divider sx={{ borderColor: "#c9c9c9" }} />
          }
          <DialogActions>
            <div className={classes.popupBottom}>
              {type === "approvalForm" ? (
                !showButton &&
                <div className={classes.ApprovalButtons}>
                  <Button
                    autoFocus
                    name="approve"
                    size="small"
                    variant="outlined"
                    onClick={handleApproveOrReject}
                  >
                    Approve
                  </Button>
                  <Button
                    autoFocus
                    name="reject"
                    size="small"
                    variant="outlined"
                    onClick={handleApproveOrReject}
                  >
                    Reject
                  </Button>
                </div>
              ) : type === "uploadForm" ? (
                <div className={classes.uploadButtons}>
                  <Button
                    autoFocus
                    component="span"
                    name="DownloadTemplate"
                    size="small"
                    variant="contained"
                    onClick={(e) =>
                      handleTemplateDownload(e, "downloadTemplate")
                    }
                  >
                    Download Template
                  </Button>
                  <Button
                    autoFocus
                    name="DownloadLatestDefectMapping"
                    size="small"
                    variant="contained"
                    onClick={(e) =>
                      handleTemplateDownload(e, "downloadLatestTemplate")
                    }
                  >
                    Download Latest Defect Mapping
                  </Button>
                </div>
              ) : type === "translationUpload" ? (
                <Button
                  autoFocus
                  component="span"
                  name="DownloadTemplate"
                  size="small"
                  variant="contained"
                >
                  Download Template
                </Button>
              ) : type === "uploadTranslation" ? (
                showButton && <div className={classes.defaultButton}>
                  <Button
                    autoFocus
                    size="small"
                    variant={variant}
                    type="submit"
                  >
                    {saveButtonText}
                  </Button>
                </div>
              ) : type === "uploadDictionary" ? (
                <div className={classes.uploadButtons}>
                  {showButton &&
                    <Button
                      autoFocus
                      size="small"
                      variant={variant}
                      type="submit"
                    >
                      {saveButtonText}
                    </Button>
                  }
                  <Button
                    autoFocus
                    size="small"
                    variant={variant}
                    onClick={handleApproveOrReject}
                  >
                    {extraButtonText}
                  </Button>
                </div>
              ) : type === "editDefect" ? (
                <div className={classes.uploadButtons}>
                  {saveButtonText.show &&
                    <Button
                      autoFocus
                      size="small"
                      variant={variant}
                      type="submit"
                    >
                      {saveButtonText.text}
                    </Button>}
                  {extraButtonText.show &&
                    <Button
                      autoFocus
                      size="small"
                      variant={variant}
                      onClick={handleApproveOrReject}
                    >
                      {extraButtonText.text}
                    </Button>}
                </div>
              ) : type === "cigarettesLeft" ? null : (
                <div className={classes.defaultButton}>
                  <Button
                    autoFocus
                    size="small"
                    variant={variant}
                    type="submit"
                  >
                    {saveButtonText}
                  </Button>
                </div>
              )}

              {type !== "translationUpload" && type != "cigarettesLeft" && (
                <Button
                  size="small"
                  className={classes.cancelButton}
                  onClick={handleFormClose}
                >
                  {type == "confirmation" ? extraButtonText : "Cancel"}
                </Button>
              )}
            </div>
          </DialogActions>
        </form>
      </CVQADialog>
    </div>
  );
}
