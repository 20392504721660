const bundleList = {
  availableBundles: "3",
  assignedBundles: "0",
  bundlesList: [
    {
      Id: "19263",
      Label: "S-20220511-19263",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
    {
      Id: "19264",
      Label: "S-20220511-19264",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
    {
      Id: "19265",
      Label: "S-20220511-19265",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
  ],
  old_bundlesList: [
    {
      Id: "19263",
      Label: "S-20220511-19263",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
    {
      Id: "19264",
      Label: "S-20220511-19264",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
    {
      Id: "19265",
      Label: "S-20220511-19265",
      InspectorAssigned: "Unassigned",
      InspectorAssignedCode: "[unassigned]",
      UnassignedBundle: "1",
      StatusUpdate: "NOUPDATE",
      Status: "Pending",
    },
  ],
};

export default bundleList;
