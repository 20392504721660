import { actionTypes } from "./actions";

const initialState = {
  ipsmAtrributesData: [],
  ipsmAttributesEdit: {
    ipsmCode: "",
    ipsmDescription: "",
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_IPSM_ATTRIBUTES:
      return {
        ...state,
        ipsmAtrributesData: payload,
      };

    case actionTypes.UPDATE_IPSM_ATTRIBUTES:
      return {
        ...state,
        ipsmAttributesEdit: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
