import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import Form from "components/Form";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector, useDispatch } from "react-redux";
import { actions as commonActions } from "store/actions/common";
import { actions } from "./state/actions";
import { actions as searchActions } from "containers/Dashboard/MySamples/state/actions";
import MainCard from "components/MainCard/MainCard";
import AuditFilterForm from "containers/Audit/AuditDetails/AuditFilterForm";
import AuditCommonGrid from "containers/Audit/AuditDetails/AuditCommonGrid";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import PopupTitleCard from "components/PopupTitleCard/PopupTitleCard";



const CVQADialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = makeStyles(() => ({
  dialog: {
    position: "absolute",
    // top: 60
  },
  modelHeader: {
    backgroundColor: "rgb(30,129,244)",
    fontSize: "1rem",
    color: "#fff",
  },
}));




const validationSchema = yup.object({
  ncCode: yup.string().required("The NC Code field is required"),
  description: yup.string().required("The Description field is required"),
});
const initialSearchState = {
  //   SearchCode: "",
  //   SearchType: "",
  //   active: "",
  AuditType: "",
  Status: "",
  Inspector: "",
  ProductionCenter: "",
  ProductCode: "",
  ProductionUnit: "",
  SearchId: "",
};
export default function AddAdvanceSearchForm({
  open,
  handleClose,
  onAdvanceSearchClick,
}) {
  const [SearchFields, setSearchFields] = React.useState(initialSearchState);
  const [auditType, setAuditType] = React.useState("");
  const [prodCenter, setProdCenter] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [inspector, setInspector] = React.useState("");
  const [prodCode, setProductCode] = React.useState("");
  const Input = styled("input")({
    display: "none",
  });
  const [auditTypeVal, setAuditTypeVal] = React.useState("");
  const [statusVal, setStatusVal] = React.useState("");
  const commonState = useSelector((state) => state.customization);
  const [fromDateValue, setFromDateValue] = React.useState(null);
  const [toDateValue, setToDateValue] = React.useState(null);
  const login = commonState?.userInfo?.username?.split("@")[0]?.split("_")[1];  //"amarioni";//"bghosh"; //
  //const pmiID = "PMI\\bghosh";
  
  const state = useSelector((state) => state.advanceSearch);
  const [advanceSearch, setadvanceSearch] = useState(false);

  const classes = useStyles();
  const handleFormClose = (e) => {
    handleClose(e);
  };

  const handleSearchClick = () => {
    setadvanceSearch(true);
  }

  const title = "Search for Sample";
  const saveButtonText = "Search";
  const variant = "contained";
  const popupSize = "md"; //lg-large
  return (
    <CVQADialog
      classes={{
        paper: classes.dialog,
      }}
      onClose={handleFormClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      maxWidth={popupSize}
    >
      <PopupTitleCard
        id="customized-dialog-title"
        className={classes.modelHeader}
        onClose={handleFormClose}
      >
        {title}
      </PopupTitleCard>
      {!advanceSearch ?
      (<>
        <Grid>
          <MainCard>
            <AuditFilterForm sourcePage="ADVSEARCH" parentCallback={handleSearchClick} />
          </MainCard>
        </Grid>
      </>) : (<>
        <MainCard>
          <AuditCommonGrid sourcePage="ADVSEARCH" handleFormClose={handleFormClose}/>
        </MainCard>
      </>
      )}
    </CVQADialog>
  );
}
