import React, { useMemo, memo } from "react";
import { useDispatch } from "react-redux";

import {
  useDymoCheckService,
  useDymoFetchPrinters,
  useDymoOpenLabel,
  dymoRequestBuilder,
} from "react-dymo-hooks";
import Button from "@mui/material/Button";
import { actions as commonActions } from "store/actions/common";

import { inspectionXmlGenerated } from "./InspectionXml";

const DymoLabelPreview = memo(({ xml, statusDymoService }) => {
  const { label, statusOpenLabel } = useDymoOpenLabel(statusDymoService, xml);

  const style = { background: "hsla(0, 0%, 50%, 0.66)", padding: 7 };

  if (statusOpenLabel === "loading") {
    return <h4>Loading Preview...</h4>;
  } else if (statusOpenLabel === "error") {
    return <h4>Error..</h4>;
  } else if (statusOpenLabel === "success") {
    return (
      <>
        <img
          src={"data:image/png;base64," + label}
          alt="dymo label preview"
          style={style}
        />
      </>
    );
  } else {
    return <></>;
  }
});

export default function DymoPrinter({ rowXmlMapping, count }) {
  const statusDymoService = useDymoCheckService();
  const dispatch = useDispatch();

  const { statusFetchPrinters, printers } =
    useDymoFetchPrinters(statusDymoService);

  const xmlMemo = useMemo(
    () => inspectionXmlGenerated(rowXmlMapping),
    [rowXmlMapping]
  );

  const params = {
    data: `printerName=${encodeURIComponent(
      printers?.[0]?.name
    )}&printParamsXml=&labelXml=${encodeURIComponent(xmlMemo)}&labelSetXml=`,
  };

  const handlePrintLabel = () => {
    for (let i = 1; i <= count; i++) {
      dymoRequestBuilder({
        method: "POST",
        wsAction: "printLabel",
        axiosOtherParams: params,
      })
        .then(() => {
          dispatch(commonActions.successAlert("Printed successfully"));
        })
        .catch(() => {
          dispatch(commonActions.errorAlert("Print failed"));
        });
    }
  };

  return (
    <div>
      {statusDymoService === "loading" && (
        <h5>Checking if dymo service is available...</h5>
      )}
      {statusDymoService === "error" && <h5>Error</h5>}
      {statusDymoService === "success" && (
        <>
          <h5 style={{ color: "green" }}>
            Dymo Service is running in your PC.
          </h5>
        </>
      )}
      <DymoLabelPreview xml={xmlMemo} statusDymoService={statusDymoService} />
      {statusFetchPrinters === "success" && (
        <>
          {printers?.length > 0 && (
            <Button onClick={handlePrintLabel}> Start Printing</Button>
          )}
        </>
      )}
    </div>
  );
}
