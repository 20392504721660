/*eslint-disable */

import { actionTypes } from "./actions";

const initialState = {
  laboratoryAdminData: [],
  laboratoryAdminEdit: {
    laboratoryCode: "",
    description: "",
    codentifyEnabled: 1,
    productionUnitAutomationEnabled: 1,
    createSampleFromFile: 0,
    company: "",
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_LABORATORY_ADMIN:
      return {
        ...state,
        laboratoryAdminData: payload,
      };

    case actionTypes.SET_LABORATORY_ADMIN_BY_ID:
      return {
        ...state,
        laboratoryAdminEdit: { ...state.laboratoryAdminEdit, ...payload[0] },
      };

    case actionTypes.SET_LABORATORY_ADMIN_EDIT:
      return {
        ...state,
        laboratoryAdminEdit: { ...state.laboratoryAdminEdit, ...payload },
      };

    default:
      return {
        ...state,
      };
  }
};
