/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchLaboratoryAdminAPI = (id) => {
  return API.get("/adminService/api/Admin/getLaboratories")
    .then((res) => res)
    .catch((error) => error);
};

export const fetchLaboratoryAdminByIdAPI = (code) => {
  return API.get("/adminService/api/Admin/editLaboratories", {
    params: { code },
  })
    .then((res) => res)
    .catch((error) => error);
};

export const fetchLaboratoryAdminAddAPI = (payload) => {
  return API.post(
    "/adminService/api/Admin/addLaboratory",
    JSON.stringify(payload)
  ).then((res) => res);
};

export const fetchLaboratoryAdminEditAPI = (payload) => {
  return API.put(
    "/adminService/api/Admin/saveLaboratory",
    JSON.stringify(payload)
  )
    .then((res) => res)
    .catch((error) => error);
};
