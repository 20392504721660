/*eslint-disable */
export const actionTypes = {
  GET_PROJECTS: "GET_PROJECTS",
  SET_PROJECTS: "SET_PROJECTS",
  DELETE_PROJECTS: "DELETE_PROJECTS",
  ADD_PROJECTS: "ADD_PROJECTS",
  EDIT_PROJECTS: "EDIT_PROJECTS",
  GET_PROJECTS_BY_ID: "GET_PROJECTS_BY_ID",
  SET_PROJECTS_BY_ID: "SET_PROJECTS_BY_ID",
};

export const actions = {
  getProjects: () => ({
    type: actionTypes.GET_PROJECTS,
  }),
  setProjects: (payload) => ({
    type: actionTypes.SET_PROJECTS,
    payload,
  }),
  deleteProjects: (payload) => ({
    type: actionTypes.DELETE_PROJECTS,
    payload,
  }),
  addProjects: (payload) => ({
    type: actionTypes.ADD_PROJECTS,
    payload,
  }),
  editProjects: (payload) => ({
    type: actionTypes.EDIT_PROJECTS,
    payload,
  }),
  getProjectsById: (payload) => ({
    type: actionTypes.GET_PROJECTS_BY_ID,
    payload,
  }),
  setProjectsById: (payload) => ({
    type: actionTypes.SET_PROJECTS_BY_ID,
    payload,
  }),
};
