import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import Form from "components/Form";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { actions as commonActions } from "store/actions/common";
import { actions as auditActions } from "../state/actions";
import FormLabel from '@mui/material/FormLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";

const BundlesInspectionPopup = ({sample, formik, handleClose, saveButtonText, variant, popupSize, open}) => {
  const inspectionState = useSelector((state) => state?.auditReport);
  const state = useSelector((state) => state?.auditReport?.myBundlesToInspect);
  const commonState = useSelector((state) => state.customization);
  const login = commonState?.userInfo?.email?.split("@")[0]?.split(".").join(", ");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [availableBundles, setAvailableBundles] = useState(state?.availableBundles || "");
  const [assignedBundles, setAssignedBundles] = useState(state?.assignedBundles || "");
  const [count, setCount] = useState(`${state?.assignedBundles}/${state?.availableBundles}` || "");
  const [showCount, setShowCount] = useState(true);
  const [dataList, setDataList] = useState(state?.bundlesList);
  const userRoleInfo = JSON.parse(sessionStorage.getItem("userRoleInfo"));
  
  useEffect(() => {
    if (parseInt(assignedBundles) <= parseInt(availableBundles)) {
      setCount(`${assignedBundles}/${availableBundles}`)
    }
    if (!isNaN(parseInt(assignedBundles)) && assignedBundles == availableBundles) {
      setShowCount(false);
    }
  }, [assignedBundles])

  useEffect(() => {
    setDataList(state?.bundlesList);
    setAvailableBundles(state.availableBundles)
    setAssignedBundles(state.assignedBundles)
    setCount((`${state?.assignedBundles}/${state?.availableBundles}`))
  }, [state])

  useEffect(() => {
    if (inspectionState?.saveSuccess) {
      const selectedSample = inspectionState?.auditReport.find((report) => report.idSample == sample.idSample);
      dispatch(auditActions.setSaveResult(false));
      navigate(
        "/audit/sample-process?auditType="+selectedSample.auditTypeCode+"&auditSubType="+selectedSample.auditSubTypeCode+"&sampleId=" + sample.idSample
      )
      handleClose();
      formik.resetForm();
    }
  }, [inspectionState])

  const updateBundleList = () => {
    let bundlesList = dataList;
    let updated = false;
    for (let i = 0; i <= bundlesList.length; i++) {
      if (updated) {
        return bundlesList;
      } else {
        if (bundlesList[i].inspectorAssigned == "Unassigned") {
          bundlesList[i].inspectorAssigned = `${userRoleInfo.first_Name}${userRoleInfo.last_Name}`;
          bundlesList[i].inspectorAssignedCode = userRoleInfo.id.toString();
          // bundlesList[i].unassignedBundle = "0";
          bundlesList[i].statusUpdate = "ADDED";
          // bundlesList[i].status = "Pending";
          updated = true;
        }
      }
    }
  }

  const changeCount = () => {
    const increased = parseInt(assignedBundles)+1;
    const bundleDetails = updateBundleList();
    setDataList(bundleDetails);
    setAssignedBundles(increased.toString());
  }

  const handleDeleteClick = (index) => {
    let bundlesList = dataList;
    bundlesList[index].inspectorAssigned = "Unassigned";
    bundlesList[index].inspectorAssignedCode = "[Unassigned]";
    // bundlesList[index].unassignedBundle = "1";
    bundlesList[index].statusUpdate = "REMOVED";
    bundlesList[index].status = "Pending";
    const decreased = parseInt(assignedBundles)-1;
    setAssignedBundles(decreased.toString());
    setShowCount(true);
    dispatch(commonActions.successAlert(deletePopupText));
    
  }

  const deletePopupText = "You are about to unassign this bundle. Any non conformity related to this bundle will be removed from the inspection.";

  formik = useFormik({
    initialValues: {},
    onSubmit: () => {
      if (sample?.statusDescription?.toUpperCase() != "VALIDATED") {
        const params = {
          post: {
            userLogin: userRoleInfo?.login,
            assignedBundlesList: dataList,
            old_assignedBundlesList: state?.old_bundlesList,
          },
          sampleId: sample.idSample
        };
        dispatch(auditActions.saveBundleList(params));
        // handleClose();
        // formik.resetForm();
      } else {
        handleClose();
        formik.resetForm();
      }
    },
  });

  return(
    <Form
      title={sample.idSample}
      formik={formik}
      handleClose={handleClose}
      open={open}
      saveButtonText={saveButtonText}
      variant={variant}
      popupSize={popupSize}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="subtitle1">
            My bundles to inspect / available:
          </Typography>
          <Grid item xs={12} sm={12} md={12}>
            <FormLabel component="span">
              {count}
            </FormLabel>
            {showCount ?
            <IconButton
              aria-label="add"
              title="Add"
              onClick={changeCount}
              sx={{marginLeft:1}}
            >
              <AddIcon />
            </IconButton> :
            null}
          </Grid>
          <Typography variant="subtitle1">
            Assigned bundles:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} sx={{ paddingLeft:4, paddingTop:1 }}>
          <TableContainer
            component={Paper}
            sx={{ width:'100%', paddingTop:1, border: 1, borderColor: '#f1f1f1' }}
          >
            <Table sx={{ minWidth: 250 }} aria-label="my bundles to inspect">
              <TableBody>
                {state?.bundlesList?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{p:1}} align="left">{row.label}</TableCell>
                    <TableCell sx={{p:1}} align="left">{row.status}</TableCell>
                    <TableCell sx={{p:1}} align="left">{row.inspectorAssigned}</TableCell>
                    <TableCell sx={{p:1}} align="left">
                      {row.inspectorAssigned != "Unassigned" && inspectionState?.statusDescription != "Finished" ?
                        <IconButton
                          aria-label="Delete"
                          title="Delete"
                          onClick={() => handleDeleteClick(index)}
                          sx={{p:0}}
                        >
                          <DeleteIcon />
                        </IconButton> :
                        null
                      }
                      </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {commonState.isLoading ?
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Grid> :
      null}
    </Form>
  )
}

export default BundlesInspectionPopup;