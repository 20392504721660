/*eslint-disable */
export const actionTypes = {
  GET_PRODUCTION_CENTERS: "GET_PRODUCTION_CENTERS",
  SET_PRODUCTION_CENTERS: "SET_PRODUCTION_CENTERS",
  GET_PRODUCTION_CENTER_BY_ID: "GET_PRODUCTION_CENTER_BY_ID",
  SET_PRODUCTION_CENTER_BY_ID: "SET_PRODUCTION_CENTER_BY_ID",
  GET_LABOATORY_OPTIONS: "GET_LABOATORY_OPTIONS",
  SET_LABOATORY_OPTIONS: "SET_LABOATORY_OPTIONS",
  EDIT_PRODUCTION_CENTER: "EDIT_PRODUCTION_CENTER",
};

export const actions = {
  getProductionCenters: (payload) => ({
    type: actionTypes.GET_PRODUCTION_CENTERS,
    payload,
  }),

  setProductionCenters: (payload) => ({
    type: actionTypes.SET_PRODUCTION_CENTERS,
    payload,
  }),

  getProductionCenterById: (payload) => ({
    type: actionTypes.GET_PRODUCTION_CENTER_BY_ID,
    payload,
  }),

  setProductionCenterById: (payload) => ({
    type: actionTypes.SET_PRODUCTION_CENTER_BY_ID,
    payload,
  }),

  getLaboratoryOptions: (payload) => ({
    type: actionTypes.GET_LABOATORY_OPTIONS,
    payload,
  }),

  setLaboratoryOptions: (payload) => ({
    type: actionTypes.SET_LABOATORY_OPTIONS,
    payload,
  }),

  editProductionCenter: (payload) => ({
    type: actionTypes.EDIT_PRODUCTION_CENTER,
    payload,
  }),
};
