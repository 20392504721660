import { actionTypes } from "./actions";

const initialState = {
  selectedAuditTypeCode: "",
  selectedAuditSubTypeCode: "",
  newSampleId: "",
  auditTypesData: [],
  inspectorData: [],
  auditSubTypesData: [],
  auditSubTypesDataFromAuditType: [],
  isSampleProductionCodeLoading: false,
  isSampleCodentifyCodeLoading: false,
  sampleCodentifyCodeDetails: {
    brandCode: "",
    description: "",
    productionOrder: "",
    productionCenter: "",
    productionFullName: "",
    productionDate: new Date(),
    productionTime: null,
    productCode: "",
    productVariant: "",
  },
  sampleProductionCodeDetails: {
    productionCenter: "",
    productionFullName: "",
    id: "",
    productionUnit: "",
    productionDate: new Date(),
    productionTime: null,
  },
  cigarretCodeDetails: "",
  isUnitDetailsLoading: false,
  unitDetailsData: {
    productionCenterData: [],
    productVariantData: [],
    productionUnitData: [],
    brandCodeData: [],
    purchasingLocationTypeData: [],
    localProjectTypeData: [],
    projectData: [],
    filterCodeData: [],
    marketListData: [],
    marketedBrandData: [],
    periodData: [],
    cigaretteOptionsData: [],
  },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_AUDIT_TYPE_AND_INSPECTOR:
      return {
        ...state,
        auditTypesData: payload.audtiType,
        inspectorData: payload.inspectors,
      };
    case actionTypes.SET_SELECTED_AUDIT_TYPE_CODE:
      return {
        ...state,
        selectedAuditTypeCode: payload,
      };
    case actionTypes.SET_SELECTED_AUDIT_SUB_TYPE_CODE:
      return {
        ...state,
        selectedAuditSubTypeCode: payload,
      };

    case actionTypes.SET_NEW_SAMPLE_ID:
      return {
        ...state,
        newSampleId: payload,
      };

    case actionTypes.SET_AUDIT_SUB_TYPE:
      return {
        ...state,
        auditSubTypesData: payload,
      };

    case actionTypes.SET_AUDIT_SUB_TYPE_FROM_AUDIT_TYPE:
      return {
        ...state,
        auditSubTypesDataFromAuditType: payload,
      };

    case actionTypes.SET_PRODUCTION_CENTER_DATA:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          productionCenterData: payload,
        },
      };

    case actionTypes.SET_PRODUCT_vARIANT_BY_PRODUCTION_CENTER:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          productVariantData: payload,
        },
      };

    case actionTypes.SET_PRODUCTION_UNITS_BY_PRODUCTION_CENTER:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          productionUnitData: payload,
        },
      };

    case actionTypes.SET_PRODUCT_VARIANT_AND_BRAND_BY_PRODUCTION_CENTER:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          productVariantData: payload?.productVariant || [],
          brandCodeData: payload?.brandCode || [],
        },
      };

    case actionTypes.SET_PROJECT_AND_LOCATION_TYPES:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          purchasingLocationTypeData: payload?.purchasingLocationType || [],
          localProjectTypeData: payload?.localProjectType || [],
          projectData: payload?.projectStatus || [],
        },
      };

    case actionTypes.SET_FILTER_CODES:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          filterCodeData: payload,
        },
      };

    case actionTypes.SET_SAMPLE_MARKET_LIST:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          marketListData: payload,
        },
      };

    case actionTypes.SET_MARKETED_BRAND_BY_MARKET:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          marketedBrandData: payload,
        },
      };

    case actionTypes.SET_PERIOD_DROP_DOWN:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          periodData: payload?.periodModel || [],
        },
      };

    case actionTypes.SET_SAMPLE_PRODUCTION_CODE_LOADING:
      return {
        ...state,
        isSampleProductionCodeLoading: payload,
      };

    case actionTypes.SET_SAMPLE_PRODUCTION_CODE:
      return {
        ...state,
        sampleProductionCodeDetails: payload,
      };

    case actionTypes.SET_CIGARETTE_CODE_DETAILS:
      return {
        ...state,
        cigarretCodeDetails: payload,
      };

    case actionTypes.SET_CODENTIFY_CODE_LOADING:
      return {
        ...state,
        isSampleCodentifyCodeLoading: payload,
      };

    case actionTypes.SET_CODENTIFY_CODE_DETAILS:
      return {
        ...state,
        sampleCodentifyCodeDetails: payload,
      };

    case actionTypes.SET_UNIT_DETAILS_LOADING:
      return {
        ...state,
        isUnitDetailsLoading: payload,
      };

    case actionTypes.SET_CIGARETTE_OPTIONS_DATA:
      return {
        ...state,
        unitDetailsData: {
          ...state.unitDetailsData,
          cigaretteOptionsData: payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
