import { actionTypes } from "./actions";

const initialState = {
  // auditType: [],
  auditDropdowns: {
    auditType: [],
    auditSubType: [],
    inspectors: [],
    productionCenter: [],
  },

  selectedSampleIndex: 0,
  isSaveSummaryLoading: false,
  isError: false,
  isfinishInspectionPage: false,
  batchSummaryDetails: [],
  sampleBatchDetails: {
    sampleId: "",
    sampleNo: "",
    batchSampleCategory: [],
    batchSampleList: [],
    sampleDataFromSampleId: {},
  },
  productVariant: [],
  prodCenter: {},
  prodVariant: {},
  bundleList: [],
  inspectorList: [],
  oldBundleList: [],
  productVariantByProCenter: [],
  finishInspectionDetails: [],
  isFinishInspectionSuccess: false,
  availableSampleDetails: [],
  materialCodeDetailsForHolder: [],
  materialCodeDetailsForCharger: [],
  remainingSamples: "",
  validationSampleList: {
    sampleId: "",
    sampleNo: "",
    batchSampleCategory: [],
    sampleDataFromSampleId: {},
  },
  validatedSummaryDetails: {},
  validationPageCompleted: false,

  codentifyDetailsKitVal: {
    brandCode: "",
    description: "",
    productionOrder: "",
    productionCenter: "",
    productionFullName: "",
    productionDate: new Date(),
    productionTime: null,
    productCode: "",
    productVariant: "",
    mid: "",
  },

  codentifyDetailsChargerVal: {
    brandCode: "",
    description: "",
    productionOrder: "",
    productionCenter: "",
    productionFullName: "",
    productionDate: new Date(),
    productionTime: null,
    productCode: "",
    productVariant: "",
    bundleListSaved: false,
    mid: "",
  },

  codentifyDetailsHolderVal: {
    brandCode: "",
    description: "",
    productionOrder: "",
    productionCenter: "",
    productionFullName: "",
    productionDate: new Date(),
    productionTime: null,
    productCode: "",
    productVariant: "",
    mid: "",
  },
  codentifyExist: false,
  redirectOnFinishInspection: false,
  sampleExistCountInfo: {
    sampleId: "",
    isExists: "",
  },
  isCondentifyVisibilityKit: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_Prod_Center:
      return {
        ...state,
        prodCenter: payload,
      };

    case actionTypes.SET_CODENTIFY_VISIBLITY_KIT:
      return {
        ...state,
        isCondentifyVisibilityKit: payload,
      };

    case actionTypes.SET_SAMPLE_EXIST_COUNT:
      return {
        ...state,
        sampleExistCountInfo: payload,
      };

    case actionTypes.SET_Prod_Variant:
      return {
        ...state,
        prodVariant: payload,
      };

    case actionTypes.SET_Audit_Dropdowns:
      return {
        ...state,
        auditDropdowns: payload,
      };
    case actionTypes.SET_Product_Variant:
      return {
        ...state,
        productVariant: payload,
      };

    case actionTypes.SET_PRODUCT_VARIANT_BY_PRODUCTION_CENTER:
      return {
        ...state,
        productVariantByProCenter: payload,
      };

    case actionTypes.SET_ASSIGNED_BUNDLE_LIST:
      return {
        ...state,
        bundleList: payload,
      };

    case actionTypes.SET_INSPECTOR_LIST:
      return {
        ...state,
        inspectorList: payload,
      };

    case actionTypes.FINISH_INSPECTION_SUCCESS:
      return {
        ...state,
        isFinishInspectionSuccess: payload,
      };

    case actionTypes.SET_CODENTIFY_AVAILABILITY:
      return {
        ...state,
        codentifyExist: payload,
      };

    case actionTypes.SET_SAMPLE_BATCH_DETAILS:
      return {
        ...state,
        sampleBatchDetails: payload,
        batchSummaryDetails: [
          {
            sampleId: payload.sampleId,
            sampleNo: payload.sampleNo,
            batchSampleCategory: payload.batchSampleCategory,
          },
        ],
      };

    case actionTypes.SET_REDIRECT_ON_FINISH_INSPECTION:
      return {
        ...state,
        redirectOnFinishInspection: payload,
      };

    case actionTypes.SET_SAMPLE_BATCH_DETAILS_BY_SAMPLE_ID: {
      const sampleIndex = state?.batchSummaryDetails?.findIndex(
        (sample) => sample?.sampleId === payload?.sampleId
      );
      let tempBatch = [...state?.batchSummaryDetails];
      if (sampleIndex > -1) {
        tempBatch[sampleIndex].sampleId =
          state?.batchSummaryDetails[sampleIndex]?.sampleId;
        tempBatch[sampleIndex].sampleNo =
          state?.batchSummaryDetails[sampleIndex]?.sampleNo;
        tempBatch[sampleIndex].batchSampleCategory =
          state?.batchSummaryDetails[sampleIndex]?.batchSampleCategory;
      } else {
        tempBatch.push({
          ...payload,
        });
      }
      const currentIndex = tempBatch.findIndex(
        (sample) => sample?.sampleId === payload?.sampleId
      );
      return {
        ...state,
        batchSummaryDetails: [...tempBatch],
        selectedSampleIndex: currentIndex,
      };
    }

    case actionTypes.SET_SAMPLE_BATCH_FORM_EDIT: {
      const sampleIndex = state?.batchSummaryDetails?.findIndex(
        (sample) => sample?.sampleId === payload?.sampleId
      );
      let tempBatch = [...state?.batchSummaryDetails];
      if (sampleIndex > -1) {
        tempBatch[sampleIndex].sampleId = payload.sampleId;
        tempBatch[sampleIndex].sampleNo = payload.sampleNo;
        tempBatch[sampleIndex].batchSampleCategory =
          payload.batchSampleCategory;
      }

      return {
        ...state,
        batchSummaryDetails: [...tempBatch],
      };
    }

    case actionTypes.SET_FINISH_INSPECTION:
      return {
        ...state,
        finishInspectionDetails: payload,
      };

    case actionTypes.SET_BATCH_SAMLE_AVAILABLE_DETAILS:
      return {
        ...state,
        availableSampleDetails: payload,
      };
    case actionTypes.SET_BATCH_ERROR_STATUS:
      return {
        ...state,
        isError: payload,
      };

    case actionTypes.SET_FINISH_INSPECTION_PAGE_STATUS:
      return {
        ...state,
        isfinishInspectionPage: payload,
      };

    case actionTypes.SET_MATERIAL_CODE_FOR_HOLDER:
      return {
        ...state,
        materialCodeDetailsForHolder: payload,
      };

    case actionTypes.SET_MATERIAL_CODE_FOR_CHARGER:
      return {
        ...state,
        materialCodeDetailsForCharger: payload,
      };

    case actionTypes.SET_VALIDATION_SAMPLE_LIST:
      return {
        ...state,
        validationSampleList: payload,
      };

    case actionTypes.SET_CODENTIFY_COE_DETAILS_CHARGER:
      return {
        ...state,
        codentifyDetailsChargerVal: payload,
      };

    case actionTypes.SET_CODENTIFY_COE_DETAILS_HOLDER:
      return {
        ...state,
        codentifyDetailsHolderVal: payload,
      };

    case actionTypes.SET_CODENTIFY_COE_DETAILS_KIT:
      return {
        ...state,
        codentifyDetailsKitVal: payload,
      };

    case actionTypes.SET_VALIDATE_BATCH_DETAILS:
      return {
        ...state,
        validationPageCompleted: payload,
      };

    case actionTypes.GET_ASSIGNED_BUNDLE_LIST_DATA:
      return {
        ...state,
        bundleListSaved: payload,
      };

    case actionTypes.SET_VALLIDATED_SAMPLES_BY_SAMPLENO: {
      return {
        ...state,
        validatedSummaryDetails: payload,
      };
    }

    case actionTypes.SET_SAVE_SUMMARY_LOADING: {
      return {
        ...state,
        isSaveSummaryLoading: payload,
      };
    }

    case actionTypes.SET_FINISH_INSPECTION_SAMPLE_LIST: {
      return {
        ...state,
        remainingSamples:
          payload?.batchSampleCategory?.[0]?.remaining_Samples ?? "",
      };
    }

    case actionTypes.SET_AUDIT_SUB_TYPE_LIST:
      return {
        ...state,
        auditDropdowns: {
          ...state.auditDropdowns,
          auditSubType: payload,
        },
      };

    default:
      return {
        ...state,
      };
  }
};
