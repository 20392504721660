/*eslint-disable */

import { IconTruckDelivery } from "@tabler/icons";
import { actions, actionTypes } from "./actions";


const initialState = {
  dictionaryTreeList: [],
  dictionaryTreeCurrentChildList: [],
  dictionaryTreeRequest: {
    startingLevel: 0,
    noLevel: 0,
    userId: "",
    userLogin: "",
    dictionaryFilter: "",
    productFamilyFilter: "",
    familyFilter: "",
    categoryFilter: "",
    defectFilter: "",
    productFamilyAttributeId: "",
    showOnlyPublished: 0,
    showHidden: 0,
    prdNcTypeOnly: 0,
    languageCode: "",
    dictionaryMode: "",
    attributeType: "",
    productionCenter: "",
    auditSubType: "",
    sampleId: ""
  },
  dictionarySelectedParentInfo: {
    level: 0,
    id: 0,
    code: "",
    label: "",
    imageUrl: "",
    parentId: 0,
    familyName: "",
    categoryName: "",
    thumbUrl: "",
    isPublish: 0,
    hide: 0,
    productType: "",
    componentId: "",
    codeForSorting: "",
    ncVersion: "",
    dictionaryType: "",
    children: []
  },
  totalTreeNodeCount: 0,
  expandedNodeIds: ['1'],
  isTreeLoading: false,
  ncMappingIds: [],
  searchTreeNodeIds: [],
  offlineDictionaryParams: {
    dictionaryIds: [],
    productids: [],
    familyIds: [],
    categoryIds: [],
    defectIds: [],
    attributeInfo: null,
    languageCode: "EN",
    dictionaryType: "N",
  }
};
let nodeId = 0;
let isParentFound = false;
// let totalNodeCount = 0;// state.totalTreeNodeCount;
// let expandedTreeNodeIds = [];

const insertChildInParent = (dictionaryTree, childrenData, parentInfo, totalNodeCount, expandedTreeNodeIds) => {
  isParentFound = false;
  nodeId = parseInt(totalNodeCount);
  // console.log("Defect Dictionary Tree", dictionaryTree);
  //console.log("Defect Dictionary parent Info", parentInfo);
  let result = new Object();
  if (dictionaryTree.length == 0 || parentInfo.id == 0) {
    //console.log("Defect Dictionary No Parent", parentInfo);
    dictionaryTree.splice(0, dictionaryTree.length);//Remove tree and re-create tree with new language
    childrenData.forEach(function (elem, index) {
      nodeId++;
      elem.nodeId = nodeId.toString();
      elem.children?.forEach(function (Celem, Cindex) {
        nodeId++;
        Celem.nodeId = nodeId.toString();
        Celem.label = Celem.label != null ? Celem.label : "";
        Celem.code = Celem.code != null ? Celem.code : "";
      });
      dictionaryTree.push(elem);
    });
    result.treeList = dictionaryTree;
    result.totalCount = nodeId;
    totalNodeCount = nodeId;
    //console.log("Defect Dictionary incoming tree list", result);
    return result;
  }
  // else {

  // }

  CreateChildTree(dictionaryTree, childrenData, parentInfo, expandedTreeNodeIds)
  //console.log("Defect Dictionary Final tree result", dictionaryTree);
  //totalNodeCount = nodeId;//giving error after uncomment. we need it to create unique node id
  result.treeList = dictionaryTree;
  result.totalCount = nodeId;
  totalNodeCount = nodeId;
  return result;
}

const findNodeidFromTree = (dictionaryTree, parentInfo) => {
  for (let i = 0; i < dictionaryTree.length; i++) {
    if (parseInt(dictionaryTree[i]?.id) == parseInt(parentInfo?.id) && parseInt(dictionaryTree[i]?.level) == parseInt(parentInfo?.level)) {
      parentInfo.nodeId = dictionaryTree[i].nodeId;
      //console.log("Defect Dictionary Search Node", dictionaryTree[i].nodeId);
      break;
    } else {

      if (dictionaryTree[i].children) {
        if (dictionaryTree[i].children.length > 0) {
          findNodeidFromTree(dictionaryTree[i].children, parentInfo);
        }
      }
    }
    // if (parseInt(dictionaryTree[i]?.id) != parseInt(parentInfo?.id) && parseInt(dictionaryTree[i]?.level) != parseInt(parentInfo?.level)) {
    //   if (dictionaryTree[i].children) {
    //     if (dictionaryTree[i].children.length > 0) {
    //       findNodeidFromTree(dictionaryTree[i].children, parentInfo);
    //     }
    //   }
    // } else {
    //   parentInfo.nodeId = dictionaryTree[i].nodeId;
    //   //console.log("Defect Dictionary Search Node", dictionaryTree[i].nodeId);
    //   break;
    // }
  }
};
function CreateChildTree(dictionaryTree, childrenData, parentInfo, expandedTreeNodeIds) {
  //console.log("Defect Dictionary expand the defect", expandedTreeNodeIds);
  //console.log("Defect Dictionary Tree Info", dictionaryTree);
  // console.log("Defect Dictionary Parent Info", parentInfo);
  for (let i = 0; i < dictionaryTree.length; i++) {
    var obj = dictionaryTree[i];

    if (parentInfo.nodeId === 0) {
      findNodeidFromTree(dictionaryTree, parentInfo);
    }
    //console.log("Defect Dictionary parent Info", parentInfo);

    if (dictionaryTree[i].nodeId == parentInfo.nodeId) {
      //console.log("Defect Dictionary tree node id", dictionaryTree[i].nodeId);
      //console.log("Defect Dictionary Parent node id", parentInfo.nodeId);
      if (dictionaryTree[i].hasOwnProperty('children')) {
        isParentFound = true;
        if (Array.isArray(dictionaryTree[i].children)) {
          dictionaryTree[i].children.splice(0, dictionaryTree[i].children.length);//Remove all children
        } else {
          dictionaryTree[i].children = [];
        }
        childrenData.forEach(function (elem, index) {
          nodeId++;
          elem.nodeId = nodeId.toString();
          elem.isChecked = !!parentInfo.isChecked;
          elem.children?.forEach(function (Celem, Cindex) {
            nodeId++;
            Celem.nodeId = nodeId.toString();
          });
          dictionaryTree[i].children.push(elem);
        });
        if (expandedTreeNodeIds.indexOf(dictionaryTree[i].nodeId) == -1) {
          expandedTreeNodeIds.push(dictionaryTree[i].nodeId);
        }
        //console.log("Defect Dictionary children inserted successfully at node", expandedTreeNodeIds);
        break;
      }
    }
    if (dictionaryTree[i].children) {
      //console.log("Search in progress");
      if (CreateChildTree(dictionaryTree[i].children, childrenData, parentInfo, expandedTreeNodeIds)) {
        console.log("Search for Updated");
      }
    }
  }
}

function searchTextFromTree(dictionaryTree, searchTerm) {
  let expandedNodeIds = [];
  for (let i = 0; i < dictionaryTree.length; i++) {
    let treeObject = dictionaryTree[i];
    if (treeObject.hasOwnProperty('children')) {
      //console.log("children Data", treeObject.children);
      let childrenArray = treeObject.children.filter((item) => item.label != null ? item.label.indexOf(searchTerm) > -1 : true);
      childrenArray.forEach((item) => {
        expandedNodeIds.push(item.nodeId);
      });
    }

    if (obj.children) {
      console.log("Search in progress");
      if (CreateChildTree(obj.children, childrenData, parentInfo)) {
        console.log("Search for Updated");
      }
    }
  }
  return expandedNodeIds;
}

function MakeDictionaryTree(flat) {
  let result = new Object();
  // Create root for top-level node(s)
  const root = [];
  //let level = 0;
  let NodeId = 0;
  flat.forEach(node => {
    NodeId = NodeId + 1
    // No parentId means top level
    if (!node.parentId) {
      //level = node.level + 1;
      //console.log("Create Offline node data", node);
      node.nodeId = NodeId;
      node.isChecked = false;
      return root.push(node);
    }
    // Insert node as child of parent in flat array
    const parentIndex = flat.findIndex(el => el.id === node.parentId && el.level === node.level - 1);
    if (parentIndex >= 0) {
      if (!flat[parentIndex].children) {
        //level = node.LEVEL;
        node.nodeId = NodeId;
        node.isChecked = false;
        return flat[parentIndex].children = [node];
      }
      //level = node.LEVEL;
      node.nodeId = NodeId;
      node.isChecked = false;
      flat[parentIndex].children.push(node);
    }

  });
  result.treeList = root;
  result.totalCount = NodeId;

  return result;
}

function manageNcMappingIds(ncMappingIds, payload) {
  return payload.isChecked ?
    [...ncMappingIds, payload.id] :
    ncMappingIds.filter((id) => id !== payload.id)
}

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.GET_DICTIONARY_TREE_LIST:
      return {
        ...state,
        dictionaryTreeRequest: payload,
      };
    case actionTypes.SET_DICTIONARY_TREE_PARENT_INFO:
      return {
        ...state,
        dictionarySelectedParentInfo: payload,
      };
    case actionTypes.SET_TOTAL_TREE_NODE_COUNT:

      return {
        ...state,
        totalTreeNodeCount: payload,
      };
    case actionTypes.SET_DICTIONARY_TREE_LIST:
      let result = insertChildInParent(state.dictionaryTreeList, payload, state.dictionarySelectedParentInfo, state.totalTreeNodeCount, []);

      return {
        ...state,
        dictionaryTreeList: result.treeList,
        totalTreeNodeCount: result.totalCount,
        dictionaryTreeCurrentChildList: payload.filter((item) => item.level > 0)
      };
    case actionTypes.SET_TREE_WITH_SEARCH_DATA:
      console.log("Defect Dictionary tree data", payload);
      // let resultData = insertChildInParent(state.dictionaryTreeList, payload.childrenInfo, payload.parent, state.totalTreeNodeCount);
      let treeList = state.dictionaryTreeList;
      let totalNodeCount = state.totalTreeNodeCount;
      let expandedTreeNodeIds = [];
      let count = 0;
      // payload.forEach(element => {
      //   console.log("Defect Dictionary parent info", element.parentInfo);
      //   console.log("Defect Dictionary children info", element.childrenInfo);
      //   let result = insertChildInParent(treeList, element.childrenInfo, element.parentInfo, totalNodeCount);
      //   count++;
      //   treeList = result.treeList;
      //   console.log("Defect Dictionary Update tree list", treeList);
      //   totalNodeCount = result.totalCount;
      // });
      console.log("Defect Dictionary loop count", count);
      for (let index = 0; index < payload.length; index++) {
        const element = payload[index];
        // console.log("Defect Dictionary parent info", element.parentInfo);
        // console.log("Defect Dictionary children info", element.childrenInfo);
        var resultData = insertChildInParent(treeList, element.childrenInfo, element.parentInfo, totalNodeCount, expandedTreeNodeIds);
        totalNodeCount = resultData.totalCount;
        treeList = resultData.treeList,
          count++;
      }

      console.log("Defect Dictionary Expanded Node List", expandedTreeNodeIds);
      return {
        ...state,
        dictionaryTreeList: treeList,
        totalTreeNodeCount: totalNodeCount,
        dictionaryTreeCurrentChildList: payload.length > 0 ? payload[2]?.childrenInfo : [],
        expandedNodeIds: expandedTreeNodeIds

      };

    case actionTypes.GET_ALL_Dictionary:
      return {
        ...state,
        dictionaryTreeList: payload
      };
    case actionTypes.SET_CREATE_OFFLINE_DIC:
      let treeResult = MakeDictionaryTree(payload);
      console.log("Create offline Dictionary Tree response", treeResult);
      return {
        ...state,
        dictionaryTreeList: treeResult.treeList,
        totalTreeNodeCount: treeResult.totalCount,
        //dictionaryTreeCurrentChildList: payload.filter((item) => item.level > 0)
      };
    case actionTypes.RESET_DICTIONARY:
      return {
        ...state,
        dictionaryTreeList: [],
        totalTreeNodeCount: 0,
        dictionaryTreeCurrentChildList: []
      };
    case actionTypes.SET_EXPANDED_NODE_ID:
      return {
        ...state,
        expandedNodeIds: [...state.expandedNodeIds, payload]
      };
    case actionTypes.SET_TREE_LOADING:
      return {
        ...state,
        isTreeLoading: payload
      };
    case actionTypes.SET_OFFLINE_PARAMS:
      return {
        ...state,
        offlineDictionaryParams: payload
      };
    case actionTypes.SET_NC_MAPPING_IDS:
      return {
        ...state,
        ncMappingIds: manageNcMappingIds(state.ncMappingIds, payload)
      };
    case actionTypes.SET_SEARCH_NODE_BY_CODE:
      return {
        ...state,
        searchTreeNodeIds: payload
      };
    case actionTypes.SET_FILTERS:
      return {
        ...state,
        dictionaryTreeRequest: {
          ...state.dictionaryTreeRequest,
          ...payload.dictionaryTreeRequest
        },
        dictionarySelectedParentInfo: {
          ...state.dictionarySelectedParentInfo,
          ...payload.dictionarySelectedParentInfo
        }
      };
    default:
      return {
        ...state,
      };
  }
};
