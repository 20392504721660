import { API } from "api/config";

export const getInspectionDataAPI = (payload) => {
  return API.get(`/adminService/api/SamplesListHandler/inspectSample`, {
    params: payload,
  })
  .then((res) => res);
};

export const getNonConfirmityDetailAPI = (payload) => {
  return API.post(`/adminService/api/InspectionChecklist/getNonConfirmityDetail`, payload)
  .then((res) => res)
};

export const saveInspectionAPI = (payload) => {
  return API.post(`/adminService/api/SamplesListHandler/saveInspection`, payload)
  .then((res) => res)
};

export const savePreliminarychecklistDataAPI = (payload) => {
  return API.put(`/adminService/api/SamplesListHandler/submitPreliminaryCheck`, payload)
    .then((res) => res)
};

export const submitPackdetailsAPI = (payload) => {
  return API.post(`/adminService/api/SamplesListHandler/submitPackdetails`, payload)
    .then((res) => res)
};

export const getPackDetailsAPI = (payload) => {
  return API.get(`/adminService/api/SamplesListHandler/getSampleSequenceData`, {
    params: payload,
  })
    .then((res) => res)
};
