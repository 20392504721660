import { API } from "api/config";

// API

export const getDictionaryUpdateFamilyAPI = (payload) => {
  return API.get("/adminService/api/DictionaryFamily/getDictionaryUpdateFamily", {
    params: payload,
  })
    .then((res) => res)
    .catch((error) => error);
};

export const addFamilyDictionaryAPI = (payload) => {
  return API.post("/adminService/api/DictionaryFamily/addFamilyDictionary", payload)
    .then((res) => res)
    // .catch((error) => error);
};

export const updateFamilyDictionaryAPI = (payload) => {
    return API.put("/adminService/api/DictionaryFamily/updateFamilyDictionary",payload)
      .then((res) => res)
      // .catch((error) => error);
  };