import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchAccessManagementAPI,
  fetchDictionaryListAPI,
  addPlatformDictionaryAccessAPI,
  fetchPlatformDictionaryListAPI,
  editPlatformDictionaryListAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getAccessManagementSaga(action) {
  try {
    const _api = yield call(fetchAccessManagementAPI, action.payload);
    yield put(actions.setAccessManagement(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addPlatformDictionaryAccessSaga(action) {
  try {
    const _api = yield call(addPlatformDictionaryAccessAPI, action.payload);
    yield put(commonActions.successAlert("Platform added Successfully"));
    yield put(commonActions.setLoading(false));
    yield put(actions.getAccessManagement());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getDictionaryListSaga(action) {
  try {
    const _api = yield call(fetchDictionaryListAPI, action.payload);
    yield put(actions.setDictionaryList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* getPlatformDictionaryListSaga(action) {
  try {
    const _api = yield call(fetchPlatformDictionaryListAPI, action.payload);
    console.log(_api.data.data);
    yield put(actions.setPlatformDictionaryList(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* editPlatformDictionaryAccessSaga(action) {
  try {
    const _api = yield call(editPlatformDictionaryListAPI, action.payload);
    yield put(commonActions.successAlert("Platform updated Successfully"));
    yield put(commonActions.setLoading(false));
    yield put(actions.getAccessManagement());
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

export function* watchAccessManagementSaga() {
  yield all([
    takeEvery(actionTypes.GET_ACCESS_MANAGEMENT, getAccessManagementSaga),
    takeEvery(actionTypes.GET_DICTIONARY_LIST, getDictionaryListSaga),
    takeEvery(
      actionTypes.ADD_PLATFORM_DICTIONARY_ACCESS,
      addPlatformDictionaryAccessSaga
    ),
    takeEvery(
      actionTypes.GET_PLATFORM_DICTIONARY_LIST,
      getPlatformDictionaryListSaga
    ),
    takeEvery(
      actionTypes.EDIT_PLATFORM_DICTIONARY_ACCESS,
      editPlatformDictionaryAccessSaga
    ),
  ]);
}
