import { takeEvery, call, put, all } from "redux-saga/effects";
import { actionTypes, actions } from "./actions";
import {
  fetchLocalProjectTypesAPI,
  disableLocalProjectTypeAPI,
  addLocalProjectTypeAPI,
  updateLocalProjectTypeAPI,
} from "../api/endpoint";
import { actions as commonActions } from "store/actions/common";

function* getLocalProjectTypesSaga(action) {
  try {
    const _api = yield call(fetchLocalProjectTypesAPI, action.payload);
    yield put(actions.setLocalProjectTypes(_api.data.data));
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* disableLocalProjectTypeSaga(action) {
  try {
    const _api = yield call(disableLocalProjectTypeAPI, action.payload);
    yield put(actions.getLocalProjectTypes());
    yield put(commonActions.setLoading(false));
  } catch (error) {
    console.log(error);
    // yield put(commonActions.setLoading(false));
  }
}

function* addLocalProjectTypeSaga(action) {
  try {
    const _api = yield call(addLocalProjectTypeAPI, action.payload);
    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.successAlert("Local Project Type added successfully")
    );
    yield put(actions.getLocalProjectTypes());
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong."));
  }
}

function* updateLocalProjectTypeSaga(action) {
  try {
    const _api = yield call(updateLocalProjectTypeAPI, action.payload);

    yield put(commonActions.setLoading(false));
    yield put(
      commonActions.successAlert("Local Project Type updated successfully")
    );
    yield put(actions.getLocalProjectTypes());
  } catch (error) {
    console.log(error);
    yield put(commonActions.setLoading(false));
    yield put(commonActions.errorAlert("Something went wrong."));
  }
}

export function* watchLocalProjectTypesSaga() {
  yield all([
    takeEvery(actionTypes.GET_LOCAL_PROJECT_TYPES, getLocalProjectTypesSaga),
    takeEvery(
      actionTypes.DISABLE_LOCAL_PROJECT_TYPE,
      disableLocalProjectTypeSaga
    ),
    takeEvery(actionTypes.ADD_LOCAL_PROJECT_TYPE, addLocalProjectTypeSaga),
    takeEvery(
      actionTypes.UPDATE_LOCAL_PROJECT_TYPE,
      updateLocalProjectTypeSaga
    ),
  ]);
}
