export const actionTypes = {
  GET_PRELIMINARY_CHECKLIST: "GET_PRELIMINARY_CHECKLIST",
  SET_PRELIMINARY_CHECKLIST: "SET_PRELIMINARY_CHECKLIST",
  EDIT_PRELIMINARY_CHECKLIST: "EDIT_PRELIMINARY_CHECKLIST",
  SET_PRELIMINARY_CHECKLIST_EDIT: "SET_PRELIMINARY_CHECKLIST_EDIT",
  GET_PRELIMINARY_CHECKLIST_STEP: "GET_PRELIMINARY_CHECKLIST_STEP",
  SET_PRELIMINARY_CHECKLIST_STEP: "SET_PRELIMINARY_CHECKLIST_STEP",
  EDIT_PRELIMINARY_CHECKLIST_STEP: "EDIT_PRELIMINARY_CHECKLIST_STEP",
  SET_PRELIMINARY_CHECKLIST_STEP_EDIT: "SET_PRELIMINARY_CHECKLIST_STEP_EDIT",
  ADD_PRELIMINARY_CHECKLIST_STEP: "ADD_PRELIMINARY_CHECKLIST_STEP",
  GET_PRELIMINARY_ATTRIBUTE_SOURCELIST: "GET_PRELIMINARY_ATTRIBUTE_SOURCELIST",
  SET_PRELIMINARY_ATTRIBUTE_SOURCELIST: "SET_PRELIMINARY_ATTRIBUTE_SOURCELIST",
  GET_PRELIMINARY_STEP_ATTRIBUTE: "GET_PRELIMINARY_STEP_ATTRIBUTE",
  SET_PRELIMINARY_STEP_ATTRIBUTE:"SET_PRELIMINARY_STEP_ATTRIBUTE",
  SAVE_PRELIMINARY_CHECKLIST_STEP:"SAVE_PRELIMINARY_CHECKLIST_STEP",
  ADD_PRELIMINARY_CHECKLIST_ATTRIBUTE: "ADD_PRELIMINARY_CHECKLIST_ATTRIBUTE",
  SAVE_PRELIMINARY_CHECKLIST_ATTRIBUTE: "SAVE_PRELIMINARY_CHECKLIST_ATTRIBUTE",
};

export const actions = {

  savePreliminaryChecklistAttribute: (payload) => ({
    type: actionTypes.SAVE_PRELIMINARY_CHECKLIST_ATTRIBUTE,
    payload: payload,
  }),

  addPreliminaryChecklistAttribute: (payload) => ({
    type: actionTypes.ADD_PRELIMINARY_CHECKLIST_ATTRIBUTE,
    payload: payload,
  }),

  getPreliminaryChecklist: () => ({
    type: actionTypes.GET_PRELIMINARY_CHECKLIST,
  }),
  setPreliminaryChecklist: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_CHECKLIST,
    payload,
  }),
  editPreliminaryChecklist: (payload) => ({
    type: actionTypes.EDIT_PRELIMINARY_CHECKLIST,
    payload,
  }),
  setPreliminaryChecklistEdit: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_CHECKLIST_EDIT,
    payload,
  }),

  getPreliminaryChecklistStep: (payload) => ({
    type: actionTypes.GET_PRELIMINARY_CHECKLIST_STEP,
    payload: payload,
  }),
  setPreliminaryChecklistStep: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_CHECKLIST_STEP,
    payload: payload,
  }),
  editPreliminaryChecklistStep: (payload) => ({
    type: actionTypes.EDIT_PRELIMINARY_CHECKLIST_STEP,
    payload: payload,
  }),
  setPreliminaryChecklistStepEdit: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_CHECKLIST_STEP_EDIT,
    payload: payload,
  }),
  addPreliminaryChecklistStep: (payload) => ({
    type: actionTypes.ADD_PRELIMINARY_CHECKLIST_STEP,
    payload: payload,
  }),

  getCheckListAttributeSourceList: (payload) => ({
    type: actionTypes.GET_PRELIMINARY_ATTRIBUTE_SOURCELIST,
    payload: payload,
  }),
  setCheckListAttributeSourceList: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_ATTRIBUTE_SOURCELIST,
    payload: payload,
  }),

  getPreliminarySetAttibute: (payload) => ({
    type: actionTypes.GET_PRELIMINARY_STEP_ATTRIBUTE,
    payload: payload,
  }),
  setPreliminarySetAttibute: (payload) => ({
    type: actionTypes.SET_PRELIMINARY_STEP_ATTRIBUTE,
    payload: payload,
  }),

  updatePreliminaryChecklistStep: (payload) => ({
    type: actionTypes.SAVE_PRELIMINARY_CHECKLIST_STEP,
    payload: payload,
  }),
  

};
