export const actionTypes = {
  GET_SearchBySampleID: "GET_SearchBySampleID",
  SET_SearchBySampleID: "SET_SearchBySampleID",
  GET_SAMPLE_TO_INSPECTION: "GET_SAMPLE_TO_INSPECTION",
  GET_SAMPLE_TO_VALIDATE: "GET_SAMPLE_TO_VALIDATE",
  SET_SAMPLE_TO_INSPECTION: "SET_SAMPLE_TO_INSPECTION",
  SET_SAMPLE_TO_VALIDATE: "SET_SAMPLE_TO_VALIDATE",
  SET_INSPECT_GRID_COUNT: "SET_INSPECT_GRID_COUNT",
  SET_VALIDATE_GRID_COUNT: "SET_VALIDATE_GRID_COUNT",
  SET_SearchBySampleID_Grid_Count: "SET_SearchBySampleID_Grid_Count",
  SET_INSPECT_LOADER: "SET_INSPECT_LOADER",
  SET_VALIDATE_LOADER: "SET_VALIDATE_LOADER",
};

export const actions = {
  setInspectLoader: (payload) => ({
    type: actionTypes.SET_INSPECT_LOADER,
    payload,
  }),
  setValidateLoader: (payload) => ({
    type: actionTypes.SET_VALIDATE_LOADER,
    payload,
  }),
  setSearchBySampleIDGridCount: (payload) => ({
    type: actionTypes.SET_SearchBySampleID_Grid_Count,
    payload,
  }),

  getSearchBySampleID: (payload) => ({
    type: actionTypes.GET_SearchBySampleID,
    payload,
  }),
  setSearchBySampleID: (payload) => ({
    type: actionTypes.SET_SearchBySampleID,
    payload,
  }),
  getSamplesToInspectionList: (payload) => ({
    type: actionTypes.GET_SAMPLE_TO_INSPECTION,
    payload,
  }),
  setSamplesToInspectionList: (payload) => ({
    type: actionTypes.SET_SAMPLE_TO_INSPECTION,
    payload,
  }),
  getSamplesToValidateList: (payload) => ({
    type: actionTypes.GET_SAMPLE_TO_VALIDATE,
    payload,
  }),
  setSamplesToValidateList: (payload) => ({
    type: actionTypes.SET_SAMPLE_TO_VALIDATE,
    payload,
  }),
  setSamplesToInspectionTotalCount: (payload) => ({
    type: actionTypes.SET_INSPECT_GRID_COUNT,
    payload,
  }),
  setSamplesToValidateTotalCount: (payload) => ({
    type: actionTypes.SET_VALIDATE_GRID_COUNT,
    payload,
  })
};