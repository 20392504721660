import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// material-ui
import { makeStyles, useTheme } from "@mui/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

// project imports
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import navigation from "components/MenuItems";
import { actions } from "store/actions/common";

// assets
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Toaster from "components/Toast/Toast";

const drawerWidth = 260;

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.headerColor,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.headerColor,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
  toolbar: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  breadCrumbs: {
    width: "100%",
    marginLeft: "280px",
    marginTop: "10px",
    transition: "0.5s ease",
  },
  breadCrumbsClosed: {
    marginLeft: "45px",
    marginTop: "10px",
    width: "100%",
    transition: "0.5s ease",
  },
}));

// ===========================|| MAIN LAYOUT ||=========================== //

const MainLayout = () => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const urlParams = new URLSearchParams(window.location.search);
  const pageType = urlParams.get("pageType") || "";

  // additional routes needed for breadcrumbs

  const navigationItems =
    pageType !== "reports"
      ? {
          id: "auditValidatebatchBeforeValidation",
          title: "Validation",
          type: "item",
          url: "/audit/batch-validation",
          breadcrumbs: true,
          isHide: true,
        }
      : {
          id: "auditValidatebatchAfterValidation",
          title: "Reports",
          type: "item",
          url: "/audit/batch-validation",
          breadcrumbs: true,
          isHide: true,
        };

  const updatedNavigationItems = JSON.parse(JSON.stringify(navigation));

  updatedNavigationItems.items?.[0]?.children?.[1]?.children.push(
    navigationItems
  );
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch(actions.setMenu(!leftDrawerOpened));
  };

  useEffect(() => {
    dispatch(actions.setMenu(!matchDownMd));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          // bgcolor: theme.palette.background.default,
          backgroundColor: "#143d77 !important",
          transition: leftDrawerOpened
            ? theme.transitions.create("width")
            : "none",
        }}
        className={leftDrawerOpened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Header
            leftDrawerOpened={leftDrawerOpened}
            handleLeftDrawerToggle={handleLeftDrawerToggle}
          />
          <div
            className={
              leftDrawerOpened ? classes.breadCrumbs : classes.breadCrumbsClosed
            }
          >
            <Breadcrumbs
              separator={ChevronRightIcon}
              navigation={updatedNavigationItems}
              title
            />
          </div>
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />

      {/* main content */}
      <main
        className={`${classes.content} ${
          leftDrawerOpened ? classes.contentShift : ""
        }`}
      >
        <Toaster />
        <Outlet />
      </main>
    </div>
  );
};

export default MainLayout;
