export const actionTypes = {
  GET_DAILY_LOGIN_HISTORY: "GET_DAILY_LOGIN_HISTORY",
  SET_DAILY_LOGIN_HISTORY: "SET_DAILY_LOGIN_HISTORY",
  GET_WEEKLY_LOGIN_HISTORY: "GET_WEEKLY_LOGIN_HISTORY",
  SET_WEEKLY_LOGIN_HISTORY: "SET_WEEKLY_LOGIN_HISTORY",
  GET_MONTHLY_LOGIN_HISTORY: "GET_MONTHLY_LOGIN_HISTORY",
  SET_MONTHLY_LOGIN_HISTORY: "SET_MONTHLY_LOGIN_HISTORY",
};

export const actions = {
  getDailyLoginHistory: (payload) => ({
    type: actionTypes.GET_DAILY_LOGIN_HISTORY,
    payload,
  }),
  setDailyLoginHistory: (payload) => ({
    type: actionTypes.SET_DAILY_LOGIN_HISTORY,
    payload,
  }),
  getWeeklyLoginHistory: (payload) => ({
    type: actionTypes.GET_WEEKLY_LOGIN_HISTORY,
    payload,
  }),
  setWeeklyLoginHistory: (payload) => ({
    type: actionTypes.SET_WEEKLY_LOGIN_HISTORY,
    payload,
  }),

  getMonthlyLoginHistory: (payload) => ({
    type: actionTypes.GET_MONTHLY_LOGIN_HISTORY,
    payload,
  }),
  setMonthlyLoginHistory: (payload) => ({
    type: actionTypes.SET_MONTHLY_LOGIN_HISTORY,
    payload,
  }),
};
