import { API } from "api/config";

export const fetchDictionaryTreeListAPI = (payload) => {
  return API.get("/adminService/api/Dictionary/DictionaryTreeView/getDictionaryTreeView", {
    params: payload,
  })
    .then((res) => res);
};
export const fetchDictionaryTreeSearchAPI = (payload) => {
  return API.get("/adminService/api/Dictionary/DictionaryTreeView/getDictionaryByCode", {
    params: payload,
  })
    .then((res) => res);
};
export const fetchAllDictionaryTreeListAPI = (payload) => {
  return API.get("/adminService/api/DictionaryTreeViews/getDictionaryTreeViews", {
    params: payload,
  }, { timeout: 120000 })
    .then((res) => res)
    .catch((error) => error);
};

export const exportDictionayApi = (payload) => {
  return API.get("/adminService/api/Report/getOfflineDictionaryExcel", {
    params: payload,
  }).then((res) => res);
};

