import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import itemData from './TreeResultData';
import ImageTag from './imageDetails';
import { actions as commonActions } from "store/actions/common";
import { actions as treeAction } from "../DictionaryTree/state/actions";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";

const treeResultData = [];

export default function TitlebarImageList({ handleOpen, dictionaryMode }) {
  const treeState = useSelector((state) => state.dictionaryTree);
  const [treeResultData, setTreeResultData] = useState(treeState?.dictionaryTreeCurrentChildList);
  const commonState = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  useEffect(() => {
    setTreeResultData(treeState?.dictionaryTreeCurrentChildList);
  }, [treeState?.dictionaryTreeCurrentChildList]);

  const handleEditOpen = (nodeItem, actionType) => {
    dispatch(treeAction.setDictionaryTreeParentInfo(nodeItem));
    handleOpen(nodeItem, actionType);
  }
  const getLabel = (nodes) => {
    if (nodes.level >= 4)
      return nodes.code + " | " + nodes.familyName + " : " + nodes.categoryName;
    else
      return (nodes.code ? nodes.code + " | " : "") + nodes.label;
  }
  return (
    <>
      {
        treeState.isTreeLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <ImageList cols={3} gap={4}>
            {
              treeResultData && treeResultData.map((item) => (
                <ImageListItem key={item.id} sx={{ border: '1px solid' }}  >
                  <span onClick={() => handleEditOpen(item, 'openTree')} style={{ cursor: "pointer" }}>
                    <ImageTag ImageData={item} />
                  </span>

                  <ImageListItemBar
                    title={getLabel(item)}
                    actionIcon={
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.label}`}
                      >
                        {
                          (dictionaryMode == 2 || dictionaryMode == 3) &&
                          <IconButton aria-label="edit" title="Edit" color="primary"
                            variant="outlined" onClick={() => handleEditOpen(item, 'edit')}>
                            <EditTwoToneIcon />
                          </IconButton>
                        }
                        {
                          (dictionaryMode == 1 && item.level == 4) &&
                          <IconButton aria-label="edit" title="Add Non Conformity" color="primary" //correct the title
                            variant="outlined" onClick={() => handleEditOpen(item, 'addTreeNC')}>
                            <AddIcon />
                          </IconButton>
                        }
                      </IconButton>
                    }
                  />
                </ImageListItem>
              ))}
          </ImageList>
        )
      }
    </>
  );
}