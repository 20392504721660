import { actionTypes } from "./actions";

const initialState = {
  dictionaryCategory: {},
  addDictionaryCategory: {},
  updateDictionaryCategory: {},
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_Dictionary_Category:
      return {
        ...state,
        dictionaryCategory: payload,
      };

    case actionTypes.ADD_Dictionary_Category:
      return {
        ...state,
        addDictionaryCategory: payload,
      };

    case actionTypes.UPDATE_Dictionary_Category:
      return {
        ...state,
        updateDictionaryCategory: payload,
      };

    default:
      return {
        ...state,
      };
  }
};