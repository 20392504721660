import { actionTypes } from "./actions";

const initialState = {
  preliminaryChecklistData: [],
  preliminaryChecklistEdit: {
    name: "",
    description: "",
    checkLists: [],
  },
  preliminaryChecklistStep: {
    idStep: "",
    typeCode: "",
    title: "",
    sequenceOrder: "",
    checkListsStep: [],
  },
  preliminaryChecklistStepEdit: {
    id: "",
    idStep: "",
    sourceType: "",
    sourceId: "",
    value: "",
    sequenceOrder: "",
    // id:"",
    // idStep: "",
    // sourceType: "",
    // sourceId: "",
    // value: "",
    // sequenceOrder: "",
  },
  PreliminaryChecklistAttribute: {
    stepId: 0,
      attributeId: 0,
      sequence: 0,
      sourceId: "",
      sourceType: "",
  },

  //dropdown
  CheckListAttributeSourceList: [],
  // CheckListAttributeSourceList:{
  //   //parameterCode:"",
  // },
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_PRELIMINARY_CHECKLIST:
      return {
        ...state,
        preliminaryChecklistData: payload,
      };

    case actionTypes.SET_PRELIMINARY_CHECKLIST_EDIT:
      return {
        ...state,
        preliminaryChecklistEdit: payload,
      };

    case actionTypes.SET_PRELIMINARY_CHECKLIST_STEP:
      return {
        ...state,
        preliminaryChecklistStep: payload,
      };

    case actionTypes.SET_PRELIMINARY_CHECKLIST_STEP_EDIT:
      return {
        ...state,
        preliminaryChecklistStepEdit: payload,
      };

      case actionTypes.SET_PRELIMINARY_ATTRIBUTE_SOURCELIST:
          return {
            ...state, CheckListAttributeSourceList: payload,
          };

      case actionTypes.SET_PRELIMINARY_STEP_ATTRIBUTE:
        return{
          ...state, PreliminaryChecklistAttribute: payload,
        }

    default:
      return {
        ...state,
      };
  }
};
