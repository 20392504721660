/*eslint-disable */

import { API } from "api/config";

//  API

export const fetchPurchasingLocationTypeAPI = (payload) => {
  return API.get(
    "/adminService/api/PurchasingLocationType/getPurchasingLocation",
    {
      params: payload,
    }
  )
    .then((res) => res)
    .catch((error) => error);
};

export const disablePurchasingLocationTypeAPI = (payload) => {
  return API.put(
    "/adminService/api/PurchasingLocationType/savePurchasingLocation",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const editPurchasingLocationTypeAPI = (payload) => {
  return API.put(
    "/adminService/api/PurchasingLocationType/savePurchasingLocation",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};

export const addPurchasingLocationTypeAPI = (payload) => {
  return API.post(
    "/adminService/api/PurchasingLocationType/addPurchasingLocation",
    payload
  )
    .then((res) => res)
    .catch((error) => error);
};
